<template>
  <div class="lp-my-teachers">
    <MyTeachersTabs
      :tabs="tabs"
      :activeTab="activeTab"
      v-model:tab="activeTab"
    />
    <keep-alive>
      <CurrentTeachers
        v-if="activeTab === 1"
        :teachersList="teachersCurrentList"
        :isShow="isShowCurrentTeacher"
      />
      <FavoritesTeachers
        v-else
        :teachersList="teachersFavoritesList"
        :isShow="isShowFavoritesTeachers"
      />
    </keep-alive>
  </div>
</template>

<script>
import i18n from '@/i18n';
import {
  ref, computed, watch, onBeforeMount
} from 'vue';
import MyTeachersTabs from '@/components/MyTeachers/MyTeachersTabs/MyTeachersTabs';
import CurrentTeachers from '@/components/MyTeachers/CurrentTeachers/CurrentTeachers';
import FavoritesTeachers from '@/components/MyTeachers/FavoritesTeachers/FavoritesTeachers';
import { useStore } from 'vuex';
import UsersApi from '@/api/Users/api';

export default {
  name: 'MyTeachers',
  components: {
    MyTeachersTabs,
    CurrentTeachers,
    FavoritesTeachers
  },
  setup () {
    const activeTab = ref(1);
    const tabs = [
      {
        id: 1,
        title: i18n.global.t('myTeachers.tabName.currentTeachers')
      },
      {
        id: 2,
        title: i18n.global.t('myTeachers.tabName.favorites')
      }
    ];

    const store = useStore();

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const activeUser = computed(() => store.getters.activeUser);

    const teachersCurrentList = ref([]);
    const isShowCurrentTeacher = ref(true);
    const getCurrentTeachers = async () => {
      await setLoaderRun(true);
      const { _id: userId } = activeUser.value;
      if (!userId) return;
      try {
        const { data: { resource } } = await UsersApi.getCurrentTeachers(userId);
        teachersCurrentList.value = resource;
        isShowCurrentTeacher.value = resource.length > 0;
        await setLoaderRun(false);
      } catch (err) {
        await setLoaderRun(false);
        console.error(err);
      }
    };

    const teachersFavoritesList = computed(() => store.getters.teachersFavoritesList);

    const validationSelectedTeachers = ref([]);
    const isShowFavoritesTeachers = ref(false);
    const getFavoritesTeachers = async () => {
      await setLoaderRun(true);
      try {
        await setLoaderRun(false);
      } catch (err) {
        await setLoaderRun(false);
        console.error(err);
      }
    };

    watch(activeUser, (newVal, oldVal) => {
      if (!oldVal._id) {
        getCurrentTeachers();
      }
      isShowFavoritesTeachers.value = teachersFavoritesList.value.length > 0;
    });

    watch(activeTab, (newVal) => {
      if (newVal === 2 && teachersFavoritesList.value !== validationSelectedTeachers.value) {
        getFavoritesTeachers();
      }
    });

    onBeforeMount(getCurrentTeachers);

    return {
      isShowCurrentTeacher,
      isShowFavoritesTeachers,
      tabs,
      activeTab,
      teachersCurrentList,
      teachersFavoritesList
    };
  }
};
</script>
