<template>
  <div class="lp-students-list-empty">
    <div>
      <img
        class="lp-students-list-empty__image"
        src="@/assets/images/studentListEmpty.png"
      />
    </div>
    <div class="lp-students-list-empty__title">
      {{ $t('myStudents.StudentsListEmpty.title') }}
    </div>
    <div class="lp-students-list-empty__text">
      {{ $t('myStudents.StudentsListEmpty.text') }}
      <br>
      {{ $t('myStudents.StudentsListEmpty.description') }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'MyNotStudents'
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-students-list-empty {
  @include global-font;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 115px;

  &__image {
    width: 384px;
    height: 107px;
    object-fit: cover;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &__text {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
  }
}

</style>
