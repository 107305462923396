<template>
  <div class="lp-webcam">
    <video
      class="lp-webcam__video"
      ref="camVideo"
      @dblclick="openFullScreen"
      playsinline
      autoplay
      :srcObject="stream && stream.id ? stream : null"
    />
    <video
      class="lp-webcam__my-cam"
      @dblclick="openMyCamFullScreen"
      ref="myCamVideo"
      playsinline
      autoplay
      :srcObject="myStream && myStream.id ? myStream : null"
      muted
    />
    <WebcamControls
      @video-enabled="changeEnabledVideo"
      @audio-enabled="changeEnabledAudio"
      @setDevice="setDevice"
      :allDevises="allDevises"
    />
  </div>
</template>

<script>
import {
  inject,
  ref,
  reactive,
  watch,
  onBeforeMount
} from 'vue';
import WebcamControls from '@/components/Main/Webcam/WebcamControls';
import { isEqual } from 'lodash';
import { AUDIO_INPUT, VIDEO_INPUT } from '@/constants/webCam';
import { useStore } from 'vuex';

export default {
  name: 'MyWebcam',
  components: { WebcamControls },
  props: {
    stream: Object,
    myStream: Object,
    controls: Boolean,
    sessionInfo: Object
  },
  setup (props, { emit }) {
    const store = useStore();
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const socket = inject('socket');
    const muted = ref(false);
    const videoDevices = ref([]);
    const audioDevices = ref([]);

    const myCamVideo = ref(null);
    const openMyCamFullScreen = () => {
      if (!myCamVideo.value || document.fullscreenElement) return;
      if (myCamVideo.value.webkitEnterFullscreen) {
        myCamVideo.value.webkitEnterFullscreen();
      } else if (myCamVideo.value.mozRequestFullScreen) {
        myCamVideo.value.mozRequestFullScreen();
      }
    };

    const camVideo = ref(null);
    const openFullScreen = () => {
      if (!camVideo.value || document.fullscreenElement) return;
      if (camVideo.value.webkitEnterFullscreen) {
        camVideo.value.webkitEnterFullscreen();
      } else if (camVideo.value.mozRequestFullScreen) {
        camVideo.value.mozRequestFullScreen();
      }
    };

    const activeDevices = reactive({
      video: {},
      audio: {}
    });

    const setAudio = (index) => {
      activeDevices.audio = audioDevices.value[index || 0];
    };
    const setVideo = (index) => {
      activeDevices.video = videoDevices.value[index || 0];
    };

    const changeEnabledVideo = () => {
      activeDevices.video.enabled = !activeDevices.video.enabled;
    };
    const changeEnabledAudio = () => {
      activeDevices.audio.enabled = !activeDevices.audio.enabled;
    };

    const getDevices = () => {
      const { sessionInfo } = props;
      if (!sessionInfo || (sessionInfo && !sessionInfo.user)) return;
      setLoaderRun(true);
      navigator.mediaDevices.getUserMedia({ video: true, audio: true }).
        then((stream) => {
          videoDevices.value = stream.getVideoTracks();
          setVideo();
          audioDevices.value = stream.getAudioTracks();
          setAudio();
          emit('update:my-stream', stream);
          setLoaderRun(false);
        }).
        catch((err) => {
          console.error(err);
          setLoaderRun(true);
        });
      socket.emit('broadcaster', sessionInfo);
    };

    const setDevice = (device) => {
      const { kind, deviceId } = device;
      const constraints = {
        video: device && kind === VIDEO_INPUT ? { deviceId: { exact: deviceId } } : false,
        audio: device && kind === AUDIO_INPUT ? { deviceId: { exact: deviceId } } : false
      };
      navigator.mediaDevices.getUserMedia(constraints).
        then((stream) => {
          if (kind === VIDEO_INPUT) {
            videoDevices.value = stream.getVideoTracks();
            setVideo();
          } else {
            audioDevices.value = stream.getAudioTracks();
            setAudio();
          }
        });
    };

    watch(() => props.sessionInfo, (newVal = {}, oldVal = {}) => {
      const { user, lesson } = newVal;
      if (!user || !lesson || isEqual(newVal, oldVal)) return;
      getDevices();
    });

    const allDevises = ref([]);
    const getConnectedDevices = async () => {
      allDevises.value = await navigator.mediaDevices.enumerateDevices();
    };

    onBeforeMount(() => {
      getConnectedDevices();
      getDevices();
    });

    return {
      muted,
      myCamVideo,
      camVideo,
      openMyCamFullScreen,
      openFullScreen,
      activeDevices,
      changeEnabledVideo,
      changeEnabledAudio,
      allDevises,
      getDevices,
      setDevice
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-webcam {
  @include global-font;
  position: relative;
  display: grid;
  grid-template-rows: 1fr 60px;
  width: 100%;
  height: 100%;

  &__video {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 15px rgba($color-black, 0.05);
    border-radius: 4px 4px 0 0;
    background-color: $color-white;
  }

  &__my-cam {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba($color-black, 0.05);
    background-color: $color-white;
    cursor: pointer;
  }
}

</style>
