<template>
  <div
    v-if="open"
    class="lp-payment-modal-enough-funds"
  >
    <img
      class="lp-payment-modal-enough-funds__img"
      alt="card"
      :src="cardImage"
    />
    <div class="lp-payment-modal-enough-funds__title">
      {{ $t('paymentMethods.modal.titleEnoughFunds') }}
    </div>
  </div>
  <div
    v-else
    class="lp-payment-modal-new-card"
  >
    <img
      class="lp-payment-modal-new-card__img"
      alt="card"
      :src="newCard"
      @click.stop="openModalNewCard"
    />
    <div class="lp-payment-modal-new-card__title">
      {{ $t('paymentMethods.modal.descriptionNewCard') }}
      <br />
      {{ $t('paymentMethods.modal.descriptionInfoNewCard') }}
    </div>
  </div>
</template>

<script>

import visa from '@/assets/images/enoughFundsVisa.png';
import masterCard from '@/assets/images/enoughFundsMasterCard.png';
import newCard from '@/assets/images/addCard.png';

export default {
  name: 'NoMoneyCardModal',
  props: {
    open: Boolean,
    cardImage: String,
    openModalNewCard: Function
  },
  setup () {
    return {
      masterCard,
      visa,
      newCard
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-payment-modal {

  &-enough-funds {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__img {
      width: 270px;
      height: 187px;
      object-fit: cover;
      margin: 40px 123px 0;
    }

    &__title {
      margin: 12px 0 33px;
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
    }
  }

  &-new-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__img {
      margin: 69px 192px 20px;
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      text-align: center;
      margin-bottom: 44px;
    }
  }

}

</style>
