<template>
  <div class="lp-login-form">
    <CustomInput
      v-for="input of registrationInputs"
      :key="input.label"
      @focus="input.error = false"
      @submit="$emit('submit')"
      v-bind="input"
      v-model="input.model"
    />
    <div class="lp-login-language">
      <CustomCheckbox
        v-bind="language"
        v-model="language.modelValue"
      />
    </div>
  </div>
</template>

<script>
import registrationInputs from '@/constants/inputs/registrationInputs';
import CustomInput from '@/components/Main/Fields/CustomInput';
import CustomCheckbox from '@/components/Main/Fields/CustomCheckbox';
import { reactive, watch } from 'vue';

export default {
  name: 'RegistrationForm',
  components: {
    CustomInput,
    CustomCheckbox
  },
  setup (props, { emit }) {
    const language = reactive({
      label: 'login.language',
      modelValue: false
    });

    const setLanguage = () => {
      emit('update:languageLetters', language.modelValue);
    };

    watch(language, setLanguage);

    return {
      registrationInputs,
      language
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-login-form {
  display: grid;
  grid-gap: 16px;
  width: 100%;
  margin-bottom: 28px;

  &__input {
    min-height: 80px;
  }

  &__link {
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    color: $color-accent;
  }
}

.lp-login-language {
  margin-top: 10px;
}

</style>
