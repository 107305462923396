<template>
  <div class="lp-calendar-weeks">
    <perfect-scrollbar class="lp-calendar-weeks-table">
      <div
        class="lp-calendar-weeks-table-column"
        :class="{
          'lp-calendar-weeks-table-column_first': index === 0,
          'lp-calendar-weeks-table-column_last': index === 6,
          'lp-calendar-weeks-table-column_today': today === day.date
        }"
        :ref="index === 0 ? 'column' : ''"
        v-for="(day, index) of week"
        :key="day.date"
      >
        <div
          class="lp-calendar-weeks-table-column__head"
          :class="{
            'lp-calendar-weeks-table-column__head_past': day.isPast,
            'lp-calendar-weeks-table-column__head_first': index === 0,
            'lp-calendar-weeks-table-column__head_last': index === 6
          }"
        >
          <span class="lp-calendar-weeks-table-column__title lp-calendar-weeks-table-column__title_full">
            {{ day.title }}
          </span>
          <span class="lp-calendar-weeks-table-column__title lp-calendar-weeks-table-column__title_short">
            {{ day.shortTitle }}
          </span>
        </div>
        <div class="lp-calendar-weeks-table-column-lessons">
          <CalendarLesson
            v-for="(lesson) of lessons[day.date]"
            mode="week"
            :key="lesson._id"
            :lesson="lesson"
          />
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { fill, last } from 'lodash';
import moment from 'moment';
import { computed, watchEffect } from 'vue';
import CalendarLesson from '@/components/Main/Calendar/CalendarLesson';

export default {
  name: 'CalendarWeeks',
  components: { CalendarLesson },
  props: {
    date: String,
    locale: String,
    lessons: Object
  },
  setup (props, { emit }) {
    const today = moment().format('YYYY-MM-DD');

    const startWeek = computed(() => moment(props.date));

    const week = computed(() => fill(new Array(7), startWeek.value).map((el, i) => {
      const date = moment(el).add(i, 'day');
      const localDate = date.locale(props.locale);
      return {
        title: localDate.format('DD dddd'),
        shortTitle: localDate.format('DD dd'),
        date: date.format('YYYY-MM-DD'),
        isPast: moment().subtract('day', 1).isAfter(date)
      };
    }));
    watchEffect(week, emit('update:range', [week.value[0].date, last(week.value).date]));

    return { week, today };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-calendar-weeks {
  @include global-font;

  &-table {
    display: grid;
    grid-template-columns: repeat(7, minmax(150px, 1fr));
    border-radius: 4px;
    border-bottom: 1px solid $color-alto;
    width: 100%;
    padding-top: 1px;
    overflow: hidden;
    height: calc(100vh - 280px);

    &-column {
      text-align: center;
      border-right: 1px solid $color-alto;
      background-color: $color-white;
      transition: 0.3s ease-in-out;

      &_today {
        background-color: $color-moon-raker;
      }

      &_first {
        border-radius: 4px 0 0 4px ;
        border-left: 1px solid $color-alto;
      }

      &_last {
        border-radius: 0 4px 4px 0;
      }

      &__title {
        &_full {
          @media (max-width: 1300px) {
            display: none;
          }
        }

        &_short {
          display: none;
          @media (max-width: 1300px) {
            display: block;
          }
        }
      }

      &__head {
        position: sticky;
        top: 0;
        padding: 10px 0;
        background: $color-moon-raker;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $color-accent;
        box-shadow:0 0 0 1px $color-alto;
        z-index: 1;
        margin-bottom: 8px;

        &_past {
          background: $color-alabaster;
          color: $color-gray;
        }

        &_first {
          border-radius: 4px 0 0 0;
        }

        &_last {
          border-radius: 0 4px 0 0;
        }
      }

      &-lessons {
        display: grid;
        grid-gap: 8px;
        padding: 0 1px 0 3px;
      }
    }
  }
}

</style>
