<template>
  <div class="lp-profile-stats">
    <ProfileStatsLessons
      class="lp-profile-stats__item"
      v-if="!hide.includes('lessons')"
      :isTeacher="isTeacher"
      :lessons="user.lessons"
    />
    <ProfileStatsRating
      class="lp-profile-stats__item"
      v-if="!hide.includes('comments')"
      :isTeacher="isTeacher"
      :value="user.rating"
    />
    <ProfileStatsExperience
      class="lp-profile-stats__item"
      v-if="!hide.includes('experience')"
      :isTeacher="isTeacher"
      :value="user.workExperience"
    />
  </div>
</template>

<script>
import ProfileStatsLessons from '@/components/Profile/ProfileStats/ProfileStatsLessons';
import ProfileStatsExperience from '@/components/Profile/ProfileStats/ProfileStatsExperience';
import ProfileStatsRating from '@/components/Profile/ProfileStats/ProfileStatsRating';
import { computed } from 'vue';
import ROLE_TYPES from '@/constants/enums/roles';

export default {
  name: 'ProfileStats',
  components: { ProfileStatsRating, ProfileStatsExperience, ProfileStatsLessons },
  props: {
    user: Object,
    hide: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const isTeacher = computed(() => props.user.role === ROLE_TYPES.TEACHER);

    return { isTeacher };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-profile-stats {
  @include global-font;
  display: grid;
  grid-template-rows: repeat(auto-fit, 217px);
  grid-gap: 18px;

  &__item {
    min-height: 217px;
    min-width: 293px;
    padding: 20px 40px 20px;
    color: $color-text;
    border-radius: 4px;
  }
}
</style>
