<template>
  <div class="lp-user-avatar">
    <img
      class="lp-user-avatar__image"
      :class="{
        'lp-user-avatar__image_small': small,
        'lp-user-avatar__image_order': order
      }"
      v-if="avatar.url"
      alt="avatar"
      :src="avatar.url"
    />
    <div
      class="lp-user-avatar__image"
      :class="{
        'lp-user-avatar__image_small': small,
        'lp-user-avatar__image_order': order
      }"
      v-else
    >
      <userIcon />
    </div>
    <div
      class="lp-user-avatar-country"
      :class="{
        'lp-user-avatar-country_small': small,
        'lp-user-avatar-country_order': order
      }"
    >
      <img
        class="lp-user-avatar-country__flag"
        :class="{
          'lp-user-avatar-country__flag_small': small,
          'lp-user-avatar-country__flag_order': order
        }"
        v-if="country.icon"
        :src="country.icon"
        alt="country"
        @mouseenter="openPopupCountry = true"
        @mouseleave="openPopupCountry = false"
      >
      <div class="lp-user-avatar-country__border"></div>
      <Tooltip
        class="lp-user-avatar-country__tooltip"
        :text="country.name"
        :open="openPopupCountry"
      />
    </div>
  </div>
</template>

<script>
import { userIcon } from '@/constants/icons';
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import { ref } from 'vue';

export default {
  name: 'UserAvatar',
  components: {
    Tooltip,
    userIcon
  },
  props: {
    avatar: Object,
    country: Object,
    small: Boolean,
    order: Boolean
  },
  setup () {
    const openPopupCountry = ref(false);

    return { openPopupCountry };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-user-avatar {
  position: relative;
  display: inline-block;

  &__image {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid $color-white;
    background-color: $color-white;
    width: 156px;
    height: 156px;
    object-fit: cover;

    &_small {
      border: 0;
      width: 100px;
      height: 100px;
    }

    &_order {
      border: 0;
      width: 72px;
      height: 72px;
    }
  }

  &-country {
    position: absolute;
    right: 17px;
    bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_small {
      right: 9px;
      bottom: 0;
    }

    &_order {
      right: 6px;
      bottom: 1px;
    }

    &__flag {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      object-fit: cover;
      box-sizing: border-box;
      border-radius: 50%;
      cursor: pointer;
      z-index: 1;

      &_small {
        width: 21px;
        height: 21px;
      }

      &_order {
        width: 15px;
        height: 15px;
      }
    }

    &__border {
      position: absolute;
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border-radius: 50%;
      background-color: $color-white;
    }

    &__tooltip {
      top: 35px
    }
  }
}

</style>
