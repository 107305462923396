<template>
  <div class="lp-sidebar-admin">
    <div class="lp-sidebar-admin-menu">
      <router-link
        class="lp-sidebar-admin-menu-item"
        :class="{'lp-sidebar-admin-menu-item_active': item.routeNames.includes(currentRoute.name)}"
        :to="getRoute(item)"
        v-for="item of menuListAdmin"
        :key="item.id"
        @click.prevent="logOut"
      >
        <div class="lp-sidebar-admin-menu-item__icon">
          <component
            :is="item.icon"
          />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  computed, ref, watch, onBeforeMount
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import sidebarMenuAdmin from '@/constants/sidebarMenuAdmin';
import { get } from 'lodash';
import ROLE_TYPES from '@/constants/enums/roles';
import { VueCookieNext } from 'vue-cookie-next';

export default {
  name: 'SidebarAdmin',
  setup () {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const setActiveUser = (data) => store.dispatch('setActiveUser', data);
    const menuListAdmin = ref([]);
    const setSidebarMenu = () => {
      const role = activeUser.value.role || ROLE_TYPES.SUPER_ADMIN;
      menuListAdmin.value = sidebarMenuAdmin.filter((item) => !item.access || item.access === role);
    };

    const router = useRouter();
    const currentRoute = computed(() => router.currentRoute.value);

    watch(activeUser, setSidebarMenu);
    onBeforeMount(setSidebarMenu);

    const logOut = () => {
      setActiveUser({});
      VueCookieNext.removeCookie('token');
      VueCookieNext.removeCookie('remember');
      router.push({ name: 'Login' });
    };

    const getRoute = (item) => {
      const routeName = get(item, 'route.name', '');
      return routeName && routeName !== currentRoute.value.name ? item.route : '';
    };

    return {
      menuListAdmin,
      currentRoute,
      getRoute,
      logOut
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-sidebar-admin {
  @include global-font;
  background-color: $color-white;
  width: 71px;
  min-width: 71px;
  padding: 34px 9px;
  overflow: hidden;
  transition: 0.3s cubic-bezier(0.1, 1, 0.3, 1);
  height: 100vh;
  display: flex;
  align-items: flex-end;

  &-menu {
    display: grid;
    grid-gap: 10px;

    &-item {
      display: grid;
      grid-template-columns: 52px auto;
      grid-gap: 1px;
      align-items: center;
      background-color: $color-white;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.3s ease-out;

      svg {
        fill: $color-gray;
        stroke: $color-gray;
        stroke-width: 0;
        transition: 0.3s ease-out;
      }

      &:active {
        box-shadow: inset 5px 5px 5px rgba($color-accent, 0.05);
      }

      &_active,
      &:hover {
        background-color: $color-moon-raker;
      }

      &_active &__title,
      &_active &__icon svg,
      &:hover &__title,
      &:hover &__icon svg {
        color: $color-accent;
        stroke: $color-accent;
        fill: $color-accent;
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;

        svg {
          transition: 0.2s ease-out;
        }
      }

      &__title {
        margin-left: 0;
        white-space: nowrap;
        color: $color-dove-gray;
        opacity: 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        transition: 0.3s ease-out;

        &_open {
          margin-left: -6px;
          opacity: 1;
        }
      }
    }
  }

}

</style>
