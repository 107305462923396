<template>
  <div class="lp-profile-teacher-timetable">
    <Timetable
      ref="timetable"
      :teacherId="teacher._id"
      :title="$t('profile.sections.timetable')"
      :timeRange="openFull ? undefined : timeRange "
      :updatedLesson="updatedLesson"
      v-model:selected="lessonDate"
    />
    <div class="lp-profile-teacher-timetable-footer">
      <button
        class="lp-button lp-button_border"
        @click="openFull = !openFull"
      >
        {{ $t(openFull ? 'buttons.displayShortTimeTable' : 'buttons.displayFullTimeTable') }}
      </button>
    </div>
  </div>
</template>

<script>
import Timetable from '@/components/Main/Timetable/Timetable';
import { ref, watch } from 'vue';

export default {
  name: 'ProfileTeacherTimetable',
  components: { Timetable },
  props: { teacher: Object, updatedLesson: Object },
  setup (props, { emit }) {
    const timeRange = ref([0, 14]);
    const openFull = ref(false);
    const timetable = ref(null);
    const lessonDate = ref('');

    watch(openFull, () => {
      if (!timetable.value || openFull.value) return;
      timetable.value.$el.scrollIntoView();
    });

    watch(lessonDate, () => {
      emit('update:date', lessonDate.value);
      emit('update:timetable', true);
    });

    return {
      timeRange,
      openFull,
      timetable,
      lessonDate
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-profile-teacher-timetable {
  @include global-font;
  background-color: $color-white;
  box-shadow: 0 0 15px rgba($color-black, 0.02);
  padding: 28px 42px;

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
  }
}

</style>
