<template>
  <div
    class="lp-table"
    :class="customTableClass"
  >
    <div
      class="lp-table-row lp-table-row_header"
      id="tableHeader"
      :style="{'grid-template-columns': columnsGrid}"
    >
      <div
        class="lp-table-column__title"
        :class="{'lp-table-row__pointer': column.pointer}"
        v-for="column in columns"
        :key="column.id"
        :style="{
          justifyContent: column.justifyContent || 'flex-start',
          textAlign: column.textAlign || 'left'
        }"
        @click="onClickSort(column)"
      >
        <span>
          {{ $t(column.title) }}
        </span>
        <div
          v-if="column.sort"
          class="lp-table-column__arrow"
        >
          <arrowSort :class="{'lp-table-column__arrow-rotate': sort === column.prop}" />
        </div>
      </div>
    </div>
    <perfect-scrollbar
      :style="{maxHeight: maxHeight}"
      class="lp-table__body"
      id="table-scroll"
    >
      <div
        class="lp-table-row lp-table-row_one"
        :class="isActiveType(row) ? `lp-table-row_type-${isActiveType(row)}` : ''"
        :style="{'grid-template-columns': columnsGrid}"
        v-for="(row, index) in rows"
        :key="row.id"
      >
        <div
          class="lp-table-column"
          v-for="column in columns"
          :key="column.id"
          :style="{
            justifyContent: column.justifyContent || 'flex-start',
            textAlign: column.textAlign || 'left'
          }"
        >
          <component
            :is="getComponentByType(column)"
            :row="row"
            :column="column"
            :index="index"
            :heightRow="heightRow"
          />
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import AmountCell from '@/components/Main/Table/AmountCell/AmountCell';
import ImageCell from '@/components/Main/Table/ImageCell/ImageCell';
import TimeCell from '@/components/Main/Table/TimeCell/TimeCell';
import TextCell from '@/components/Main/Table/TextCell/TextCell';
import MixinCell from '@/components/Main/Table/MixinCell/MixinCell';
import DateCell from '@/components/Main/Table/DateCell/DateCell';
import { get } from 'lodash';
import { computed, onMounted, ref } from 'vue';
import ImageWithTextCell from '@/components/Main/Table/ImageWithTextCell/ImageWithTextCell';
import StatusCell from '@/components/Main/Table/StatusCell/StatusCell';
import SubscribeCell from '@/components/Main/Table/DateCell/SubscribeCell';
import { arrowSort } from '@/constants/icons';

export default {
  name: 'Table',
  components: {
    AmountCell,
    ImageCell,
    TextCell,
    DateCell,
    MixinCell
  },
  props: {
    rows: Array,
    columns: Array,
    customTableClass: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: String,
      default: 'auto'
    },
    limit: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    totalPages: {
      type: Number,
      default: 0
    },
    sort: {
      type: String,
      default: ''
    },
    sortQuery: {
      type: Function,
      default: () => (() => {})
    }
  },
  setup (props) {
    const columnsGrid = computed(() => props.columns.map((item) => item.width).join(' '));

    const getComponentByType = (column) => {
      const type = get(column, 'type', '');
      switch (type) {
      case 'image':
        return ImageCell;
      case 'imageWithText':
        return ImageWithTextCell;
      case 'status':
        return StatusCell;
      case 'time':
        return TimeCell;
      case 'amount':
        return AmountCell;
      case 'date':
        return DateCell;
      case 'subscribeDate':
        return SubscribeCell;
      case 'mixin':
        return MixinCell;
      default:
        return TextCell;
      }
    };

    const onClickSort = (column) => {
      if (!column.sort) return;
      const sort = props.sort === column.prop ? '' : column.prop;
      props.sortQuery(sort);
    };

    const heightRow = ref(0);
    const getHeightRow = () => {
      const row = document.querySelector('.lp-table-row_one');
      heightRow.value = row ? row.clientHeight : 84;
    };

    const isActiveType = (row) => {
      const isActive = row.isActive;
      const isActiveVideoCallUser = row.isActiveVideoCallUser;
      const isActiveAll = isActive && isActiveVideoCallUser;
      if (isActive) {
        return isActiveAll ? 'all' : 'crm';
      }
      return isActiveVideoCallUser ? 'call' : '';
    };

    onMounted(getHeightRow);

    return {
      getComponentByType,
      onClickSort,
      isActiveType,
      columnsGrid,
      arrowSort,
      heightRow
    };
  }
};

</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-table {
  position: relative;

  &-row {
    display: grid;
    grid-gap: 20px;
    border-bottom: 1px solid $color-alto;
    padding: 0 40px;

    &:last-child {
      border-bottom: 0;
    }

    &_header {
      background-color: $color-moon-raker;
      color: $color-accent;
      border-radius: 4px 4px 0 0;
      margin-bottom: 6px;
      align-items: center;
      position: sticky;
      top: 68px;
      z-index: 5;
      border-bottom: none;
      box-shadow: 0 0 20px rgba($color-black, .08);
    }

    &__pointer {
      cursor: pointer;
      transition: 0.3s;
    }

  }

  &-column {
    padding: 15px 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    position: relative;

    &__title {
      display: flex;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      padding: 10px 0;
    }

    &__arrow {
      margin-right: -5px;
      margin-left: 5px;
    }

    &__arrow-rotate {
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }

  &_admin {
    .lp-table {
      &-column__title{
        font-size: 13px;
        font-weight: 700;
        padding: 12px 0;
        display: flex;
      }

      &-column {
        max-height: 84px;
        min-height: 84px;
      }

      &-row {
        padding: 0 20px;
        margin-bottom: 0;

        &_type-crm, &_type-call, &_type-all {
          border-left: 4px solid;
          padding: 0 20px 0 16px;
        }

        &_type-crm {
          border-left-color: $color-emerald;
        }

        &_type-call {
          border-left-color: $color-accent;
        }

        &_type-all {
          border-left-color: $color-red;
        }

        &_header {
          position: static;
        }
      }
    }
  }
}

</style>
