import { uniqueId } from 'lodash';
import { reactive } from 'vue';

const inputs = [
  {
    autofocus: true,
    type: 'password',
    label: 'profile.forms.password.inputs.currentPassword',
    modelName: 'currentPassword',
    modelValue: '',
    emptyError: 'errors.emptyPassword',
    canShowPass: true
  },
  {
    type: 'password',
    label: 'profile.forms.password.inputs.newPassword',
    modelName: 'newPassword',
    modelValue: '',
    emptyError: 'errors.emptyPassword',
    validError: 'errors.isShortPassword',
    canShowPass: true,
    isValid: (val) => String(val).length >= 8
  },
  {
    type: 'password',
    label: 'profile.forms.password.inputs.newPasswordConfirm',
    modelName: 'newPasswordConfirm',
    modelValue: '',
    emptyError: 'errors.emptyPassword',
    equalError: 'errors.notConfirmPassword',
    isEqual: 'newPassword',
    canShowPass: true
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('profile-input-');
  item.autocomplete = 'off';
  return item;
}));
