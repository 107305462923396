export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';
export const SET_AVATAR_USER = 'SET_AVATAR_USER';
export const SET_LOADER_RUN = 'SET_LOADER_RUN';
export const SET_MAIN_SCROLL = 'SET_MAIN_SCROLL';
export const SET_LESSONS_LIST = 'SET_LESSONS_LIST';
export const SET_EDIT_FORM = 'SET_EDIT_FORM';
export const SET_TEACHERS_LIST = 'SET_TEACHERS_LIST';
export const SET_CARD_LIST = 'SET_CARD_LIST';
export const SET_STUDENTS_LIST = 'SET_STUDENTS_LIST';
export const SET_TEACHERS_FAVORITES_LIST = 'SET_TEACHERS_FAVORITES_LIST';
export const SET_MODAL_IDS = 'SET_MODAL_IDS';
