<template>
  <div class="lp-country-select">
    <CustomSelect
      :options="countryList"
      :searchable="true"
      :modelValue="modelValue"
      :error="error"
      :fixed="fixed"
      v-model="selectedCountry"
      keyProp="name"
      :required="required"
      :label="label"
      @focus="$emit('focus')"
    >
      <template #header="{keyProp, value}">
        <div
          class="lp-country-select-country"
          :class="{'lp-country-select-country_text': !value.icon}"
        >
          <img
            class="lp-country-select-country__flag"
            v-if="value.icon"
            :alt="value[keyProp]"
            :src="value.icon"
          />
          {{ value[keyProp] }}
        </div>
      </template>
      <template #item="{keyProp, item}">
        <div
          class="lp-country-select-country"
          :class="{'lp-country-select-country_text': !item.icon}"
        >
          <img
            class="lp-country-select-country__flag"
            v-if="item.icon"
            :alt="item[keyProp]"
            :src="item.icon"
          />
          {{ item[keyProp] }}
        </div>
      </template>
    </CustomSelect>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import CustomSelect from '@/components/Main/Fields/CustomSelect';
import country from '@/constants/country';
import i18n from '@/i18n';

export default {
  name: 'CountrySelect',
  components: { CustomSelect },
  props: {
    modelValue: Object,
    required: Boolean,
    label: String,
    listFilter: Array,
    error: [Boolean, String],
    fixed: Boolean,
    displayAll: Boolean
  },
  setup (props, { emit }) {
    const selectedCountry = ref({});

    const allValue = {
      name: i18n.global.t('country.all'),
      id: ''
    };

    watch(selectedCountry, () => {
      emit('update:modelValue', selectedCountry);
    });

    const countryList = computed(() => {
      const list = props.listFilter ? country.filter((item) => props.listFilter.includes(item.id)) : country;
      return props.displayAll ? [allValue].concat(list) : list;
    });

    return { countryList, allValue, selectedCountry };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-country-select {
  @include global-font;

  &-country {
    display: grid;
    grid-template-columns: 18px auto;
    grid-gap: 8px;
    align-items: center;

    &_text {
      grid-template-columns: auto;
    }

    &__flag {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

</style>
