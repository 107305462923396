import i18n from '@/i18n';

export default [
  {
    id: '1',
    title: i18n.global.t('subjects.pop'),
    style: {
      backgroundColor: '#3275F7',
      color: '#FFFFFF'
    }
  },
  {
    id: '2',
    title: i18n.global.t('subjects.academic'),
    style: {
      backgroundColor: '#FFCD00',
      color: '#2C2C2C'
    }
  },
  {
    id: '3',
    title: i18n.global.t('subjects.jazz'),
    style: {
      backgroundColor: '#7500BA',
      color: '#FFFFFF'
    }
  },
  {
    id: '4',
    title: i18n.global.t('subjects.folk'),
    style: {
      backgroundColor: '#2C2C2C',
      color: '#FFFFFF'
    }
  },
  {
    id: '5',
    title: i18n.global.t('subjects.child'),
    style: {
      backgroundColor: '#48D16E',
      color: '#FFFFFF'
    }
  }
];
