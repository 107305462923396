import { uniqueId } from 'lodash';

const columns = [
  {
    title: 'teachers.columns.fio',
    type: 'imageWithText',
    prop: 'fullName',
    subProp: [
      {
        prop: 'createdAt',
        text: 'teachers.columns.registrationDate',
        dateFormat: 'DD.MM.YYYY',
        style: {
          fontSize: '0.75em',
          whiteSpace: 'nowrap'
        }
      },
      '_id'
    ],
    displayImage: 'uploadFileUrl',
    width: 'minmax(220px, 1fr)',
    pointer: false,
    isActive: 'isActive',
    isActiveVideoCallUser: 'isActiveVideoCallUser'
  },
  {
    title: 'teachers.columns.country',
    type: 'imageWithText',
    prop: {
      country: 'country',
      counterparty: 'counterparty'
    },
    width: 'minmax(160px, 0.18fr)',
    pointer: false,
    placeholder: 'country'
  },
  {
    title: 'teachers.columns.city',
    prop: 'city',
    type: 'text',
    width: 'minmax(100px, 0.15fr)',
    pointer: false
  },
  {
    title: 'teachers.columns.lessons',
    prop: 'finishedLessons',
    type: 'text',
    width: 'minmax(50px, 0.05fr)',
    pointer: true,
    sort: true,
    justifyContent: 'center'
  },
  {
    title: 'teachers.columns.lessonsDuration',
    prop: 'averageLessonLength',
    type: 'time',
    width: 'minmax(120px, 150px)',
    pointer: true,
    sort: true,
    justifyContent: 'center',
    textAlign: 'center'
  },
  {
    title: 'teachers.columns.lastActivity',
    prop: 'lastActivity',
    type: 'date',
    width: 'minmax(130px, 0.16fr)',
    pointer: true,
    sort: true,
    showTime: false,
    formatDate: 'DD.MM.yyyy, HH:mm',
    formatTime: 'HH:mm',
    justifyContent: 'center',
    textAlign: 'center'
  },
  {
    title: 'teachers.columns.files',
    prop: 'totalUploadsCount',
    type: 'text',
    width: 'minmax(100px, 0.1fr)',
    pointer: false,
    justifyContent: 'center'
  },
  {
    title: 'teachers.columns.email',
    prop: 'email',
    type: 'text',
    width: 'minmax(250px, 0.8fr)',
    pointer: false
  },
  // {
  //   title: 'teachers.columns.status',
  //   prop: {
  //     accountStatus: 'accountStatus',
  //     freeTimeLeft: 'freeTimeLeft'
  //   },
  //   type: 'status',
  //   width: 'minmax(100px, 0.15fr)',
  //   pointer: false,
  //   justifyContent: 'center',
  //   textAlign: 'center'
  // },
  // {
  //   title: 'teachers.columns.subscription',
  //   prop: {
  //     subscriptionExpireDate: 'subscriptionExpireDate',
  //     accountStatus: 'accountStatus'
  //   },
  //   type: 'subscribeDate',
  //   width: 'minmax(100px, 0.15fr)',
  //   pointer: false,
  //   showTime: false,
  //   formatDate: 'DD.MM.yyyy',
  //   formatTime: 'HH:mm',
  //   prefix: 'global.till'
  // },
  {
    title: 'teachers.columns.crm.title',
    prop: 'crm',
    mixinProps: [
      {
        prop: ['finishedCRMLessons', 'pendingCRMLessons'],
        format: '0'

      },
      {
        prop: 'totalCRMStudents',
        format: '0'
      },
      {
        prop: 'totalCRMStudentGroups',
        format: '0'
      },
      {
        prop: 'totalPayments',
        format: '0'
      },
      {
        prop: 'totalExpenses',
        format: '0'
      },
      {
        prop: 'totalCRMLessonPassTemplates',
        format: '0'
      },
      {
        prop: 'totalEarnedOnCRM',
        prefix: 'currency',
        format: '00.00'

      }
    ],
    mixinDropProps: [
      {
        title: 'teachers.columns.crm.finishedLessons',
        prop: 'finishedCRMLessons',
        format: '0'
      },
      {
        title: 'teachers.columns.crm.pendingCRMLessons',
        prop: 'pendingCRMLessons',
        format: '0'
      },
      {
        title: 'teachers.columns.crm.totalCRMStudents',
        prop: 'totalCRMStudents',
        format: '0'
      },
      {
        title: 'teachers.columns.crm.totalCRMStudentGroups',
        prop: 'totalCRMStudentGroups',
        format: '0'
      },
      {
        title: 'teachers.columns.crm.totalPayments',
        prop: 'totalPayments',
        format: '0'
      },
      {
        title: 'teachers.columns.crm.totalExpenses',
        prop: 'totalExpenses',
        format: '0'
      },
      {
        title: 'teachers.columns.crm.totalCRMLessonPassTemplates',
        prop: 'totalCRMLessonPassTemplates',
        format: '0'
      },
      {
        title: 'teachers.columns.crm.totalEarnedOnCRM',
        prop: 'totalEarnedOnCRM',
        format: '00.00',
        prefix: 'currency'
      }
    ],
    type: 'mixin',
    width: 'minmax(350px, 0.8fr)',
    pointer: false
  }
];

const listPaymentsStudent = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default listPaymentsStudent;
