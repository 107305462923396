<template>
  <div
    class="lp-teachers-list-empty"
    :class="{'lp-teachers-list-empty_favorites': favoritesShow}"
  >
    <img
      class="lp-teachers-list-empty__image"
      :class="{'lp-teachers-list-empty__image_favorites': favoritesShow}"
      :src="imageUrl"
    />
    <div
      class="lp-teachers-list-empty__title"
      :class="{'lp-teachers-list-empty__title_favorites': favoritesShow}"
    >
      {{ title }}
    </div>
    <div class="lp-teachers-list-empty__text">
      {{ text }}
      <br>
      {{ description }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'TeachersListEmpty',
  props: {
    imageUrl: String,
    widthImg: String,
    heightImg: String,
    marginImg: String,
    title: String,
    text: String,
    description: String,
    favoritesShow: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-teachers-list-empty {
  @include global-font;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 109px;

  &_favorites {
    margin-top: 95px;
  }

  &__image {
    width: 207px;
    height: 249px;
    object-fit: cover;

    &_favorites {
      width: 228px;
      height: 107px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 30px;

    &_favorites {
      margin-top: 33px;
    }
  }

  &__text {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
  }
}

</style>
