import { createStore } from 'vuex';
import activeUser from './ActiveUser/index';
import loaderRun from './LoaderRun/index';
import mainScroll from './MainScroll/index';
import teacherList from './Teachers/index';
import myTeacherList from './MyTeachers/index';
import studentList from './Students/index';
import lessonsList from './Lessons/index';
import Payments from './Payments/index';
import modalIds from './ModalIds/index';

export default createStore({
  modules: {
    activeUser,
    loaderRun,
    mainScroll,
    teacherList,
    studentList,
    lessonsList,
    Payments,
    myTeacherList,
    modalIds
  }
});
