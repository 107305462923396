import CURRENT_SERVER from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  getTeachers ({
    page = 1,
    limit = 50,
    lessonCostRange = '0&lessonCostRange=1000',
    sort,
    country,
    subjects,
    lessonDate,
    lessonTimeRange,
    trialLessonAgreement
  }) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const query = [`page=${page}`, `per_page=${limit}`];
    if (sort) query.push(`sort=${sort}`);
    if (country) query.push(`country=${country}`);
    if (lessonCostRange) query.push(`lessonCostRange=${lessonCostRange}`);
    if (subjects) query.push(`subjects=${subjects}`);
    if (lessonDate) query.push(`lessonDate=${lessonDate}`);
    if (lessonTimeRange) query.push(`lessonTimeRange=${lessonTimeRange}`);
    if (trialLessonAgreement) query.push(`trialLessonAgreement=${trialLessonAgreement}`);
    return instCred.get(`users/teachers?${query.join('&')}`);
  },
  getTeacherById (id) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get(`users/teachers/${id}`);
  },
  getMaxCost () {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get('users/teachers/maxlessoncost');
  },
  getToolTeachersStatisticks ({
    page = 1, perPage = 50, sort
  }) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const query = [`page=${page}`, `limit=${perPage}`];
    if (sort) query.push(`sort={"${sort}":-1}`);
    return instCred.get(`mainivoice/toolteacherstatistics?${query.join('&')}`);
  },
  getToolTeachersAciveCounter () {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get('mainivoice/toolteacherstatistics/countactive');
  },
  getCallAciveCounter () {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get('mainivoice/activevideocallusers/countactive');
  }
};
