<template>
  <div class="lp-profile-stat-rating">
    <div class="lp-profile-stat-rating__icon">
      <bigStarIcon />
    </div>
    <div class="lp-profile-stat-rating__value">
      {{ value || 0 }}
    </div>
    <div class="lp-profile-stat-rating__title">
      {{ $t('profile.stats.teacher.rating') }}
    </div>
  </div>
</template>

<script>
import { bigStarIcon } from '@/constants/icons';

export default {
  name: 'ProfileStatsRating',
  components: { bigStarIcon },
  props: {
    value: Number
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-profile-stat-rating {
  @include global-font;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: $color-cumulus;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__value {
    text-align: center;
    font-weight: bold;
    font-size: 37px;
    line-height: 1;
  }

  &__title {
    text-align: center;
    margin-top: 4px;
    font-weight: bold;
    font-size: 14px;
    text-transform: capitalize;
  }
}

</style>
