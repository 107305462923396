<template>
  <div class="lp-user-rate">
    <div class="lp-user-rate-stars">
      <span
        class="lp-user-rate-stars__progress"
        :style="{width: `${percent}%`}"
      />
      <starsIcon />
    </div>
    {{ number }}
  </div>
</template>

<script>
import { starsIcon } from '@/constants/icons';
import numeral from 'numeral';

export default {
  name: 'UserRate',
  components: { starsIcon },
  props: {
    rate: Number
  },
  setup (props) {
    const percent = (props.rate * 5) / 100;
    const number = numeral(props.rate).format('0.0');

    return { percent, number };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-user-rate {
  display: inline-grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 8px;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: $color-text;

  &-stars {
    position: relative;
    display: inline-block;
    height: 16px;

    &__progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 0;
      background-color: $color-supernova;
      transition: 0.3s ease-out;
      z-index: 3;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 100%;
      background-color: $color-alto;
      transition: 0.3s ease-out;
      z-index: 2;
    }

    svg {
      position: relative;
      fill: $color-white;
      z-index: 3;
    }
  }
}
</style>
