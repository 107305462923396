<template>
  <section class="lp-landing-facts">
    <div
      class="lp-landing-facts-item"
      v-for="fact of facts"
      :key="fact.text"
    >
      <div class="lp-landing-facts-item__icon">
        <component :is="fact.icon" />
      </div>
      <div class="lp-landing-facts-item__text">
        {{ fact.text }}
      </div>
    </div>
  </section>
</template>

<script>
import {
  shieldIcon, computerIcon, calendarIcon, freeLabelIcon
} from '@/constants/icons/landing';
import { shallowRef } from 'vue';

export default {
  name: 'Facts',
  components: {
    shieldIcon,
    computerIcon,
    calendarIcon,
    freeLabelIcon
  },
  setup () {
    const facts = [
      {
        icon: shallowRef(shieldIcon).value,
        text: 'Только профессиональные преподаватели, зарекомендовавшие себя в индустрии'
      },
      {
        icon: shallowRef(computerIcon).value,
        text: 'Обучаем вокалу детей от 5 лет и взрослых из любой точки мира.'
      },
      {
        icon: shallowRef(calendarIcon).value,
        text: 'Удобный график занятий и индивидуальный подход'
      },
      {
        icon: shallowRef(freeLabelIcon).value,
        text: 'Бесплатное пробное занятие'
      }
    ];

    return { facts };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-landing-facts {
  @include global-font;
  display: grid;
  grid-template-columns: repeat(auto-fit, 280px);
  justify-content: center;
  align-items: start;
  grid-gap: 60px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fit, 170px);
    grid-gap: 0 20px;
    padding: 10px;
  }

  &-item {
    display: grid;
    grid-gap: 20px;
    align-items: start;
    justify-content: center;
    padding-top: 40px;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 100px;
      height: 100px;
      border: 1px solid $color-white;
      background-color: $color-white;
      box-sizing: border-box;
      box-shadow: 0 0 17px rgba($color-black, 0.08);
      border-radius: 50%;

      @media (max-width: 767px) {
        padding: 19px;
        width: 60px;
        height: 60px;
      }
    }

    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      text-align: center;
      color: $color-text;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}

</style>
