<template>
  <div class="lp-teacher-card-achievements">
    <img
      class="lp-teacher-card-achievements__image"
      v-for="(achievement, index) of achievements"
      :key="index"
      :src="achievement"
      @click.stop="openAchievement = index + 1"
    />
    <Modal
      v-model:open="openAchievement"
      :slider="true"
      :hideButtons="['remove', 'save']"
    >
      <div class="lp-teacher-card-achievements-fullscreen">
        <Carousel
          :itemsToShow="1"
          :snapAlign="'center'"
          :wrapAround="true"
        >
          <Slide
            v-for="(achievement, index) in achievements"
            :key="index"
          >
            <div class="lp-teacher-card-achievements-fullscreen-item">
              <img
                class="lp-teacher-card-achievements-fullscreen-item__image"
                :src="achievement"
              />
            </div>
          </Slide>

          <template #addons>
            <div class="lp-teacher-card-achievements__nav">
              <Navigation />
            </div>
            <Pagination />
          </template>
        </Carousel>
      </div>
    </Modal>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import {
  Carousel, Slide, Pagination, Navigation
} from 'vue3-carousel';
import Modal from '@/components/Main/Modal/Modal';

export default {
  name: 'TeacherCardAchievements',
  components: {
    Modal,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  props: {
    teacher: Object
  },
  setup (props) {
    const openAchievement = ref(false);
    const achievements = computed(() => props.teacher.achievements);

    return {
      openAchievement,
      achievements
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-teacher-card-achievements {
  padding: 28px 28px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-height: 181px;
  width: 100%;
  height: 100%;
  margin-top: -24px;

  @media (max-width: 767px) {
    margin-top: -12px;
    padding: 14px 14px 0;
    min-height: 100%;
  }

  &__nav {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &__image {
    width: auto;
    height: 105px;
    max-height: 181px;
    object-fit: cover;
    margin: 24px 12px 0;
    cursor: pointer;
    transition: 0.2s ease-in;

    @media (max-width: 767px) {
      height: 80px;
      max-height: 90px;
      margin: 12px 6px 0;
    }

    &:hover {
      transform: scale(1.08);
    }
  }

  &-fullscreen {
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1024px) {
        height: 70vh;
      }

      &__image {
        width: auto;
        height: 70vh;
        object-fit: cover;

        @media (max-width: 1024px) {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

</style>
