<template>
  <div
    class="lp-calendar-popup"
    ref="popup"
    :style="getStylePopup()"
    v-click-outside="closePopup"
  >
    <div class="lp-calendar-popup-header">
      <div class="lp-calendar-popup-header__title">
        {{ day }}
      </div>
      <div
        class="lp-calendar-popup-header__cross lp-cross-button"
        @click="closePopup"
      >
        <crossIcon />
      </div>
    </div>
    <perfect-scrollbar
      class="lp-calendar-popup-body"
      :options="{wheelPropagation: false}"
    >
      <CalendarLesson
        v-for="lesson of lessons"
        :mode="lesson.lessonStatus === statusClose ? 'week' : 'modal'"
        :key="lesson._id"
        :lesson="lesson"
      />
    </perfect-scrollbar>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import moment from 'moment';
import i18n from '@/i18n';
import { get } from 'lodash';
import { crossIcon } from '@/constants/icons';
import CalendarLesson from '@/components/Main/Calendar/CalendarLesson';
import LESSON_STATUS from '@/constants/enums/lessonStatus';

export default {
  name: 'CalendarLessonsPopup',
  components: {
    CalendarLesson,
    crossIcon
  },
  props: {
    date: String,
    lessons: Array
  },
  setup (props, { emit }) {
    const locale = get(i18n, 'global.locale.value', 'ru');
    const day = computed(() => moment(props.date).locale(locale).format('DD dd'));
    const statusClose = LESSON_STATUS.PENDING;

    const closePopup = () => emit('update:open', '');

    const popup = ref(null);

    const getStylePopup = () => {
      const parent = document.getElementById(`date${props.date}`);
      if (!parent || !popup.value) return {};
      const { offsetHeight, offsetWidth } = popup.value;
      const { innerWidth, innerHeight } = window;
      const position = parent.getBoundingClientRect();
      const { left, top } = position;
      const style = {
        position: 'fixed',
        top: `${top - 22}px`,
        left: `${left - 66.5}px`
      };
      const comesLeft = innerWidth < (left + offsetWidth);
      const comesTop = innerHeight < (top + offsetHeight);
      if (comesLeft) style.left = `${position.left - 17 - offsetWidth}px`;
      if (comesTop) {
        style.bottom = '30px';
        style.top = 'auto';
      }
      return style;
    };

    return {
      day,
      popup,
      statusClose,
      closePopup,
      getStylePopup
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-calendar-popup {
  @include global-font;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  border-radius: 4px;
  width: 266px;

  &-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-moon-raker;
    border: 1px solid $color-alto;
    border-radius: 4px 4px 0 0;
    padding: 10px;

    &__title {
      color: $color-accent;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      letter-spacing: 0.07em;
    }

    &__cross {
      position: absolute;
      width: 42px;
      height: 42px;
      top: auto;
      right: 0;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    padding: 21px 18px;
    overflow: hidden;
    max-height: 50vh;
  }
}

</style>
