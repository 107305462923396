<template>
  <div class="lp-radio">
    <label
      class="lp-radio-item"
      :class="{'lp-radio-item_checked': item.value}"
      v-for="item of options"
      :key="item.id"
    >
      <input
        class="lp-radio-item__input"
        :checked="item.value"
        :name="item.name"
        :value="item.id"
        @change="setChecked($event, item)"
        type="radio"
      >
      <span
        class="lp-radio-item__label"
      >
        {{ $t(item.label) }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomRadio',
  props: {
    options: Array
  },
  setup (props) {
    const setChecked = (e, item) => {
      item.value = e.target.checked;
      props.options.forEach((el) => {
        if (item.id === el.id) {
          el.value = e.target.checked;
        } else {
          el.value = false;
        }
        return el;
      });
    };

    return { setChecked };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-radio {
  display: grid;
  grid-gap: 16px;

  &-item {
    display: grid;
    grid-template-columns: 16px auto;
    grid-gap: 8px;
    position: relative;
    user-select: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      &:before {
        border-color: $color-accent-hover;
      }
    }

    &:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid $color-accent;
      border-radius: 50%;
      box-sizing: border-box;
      transition: 0.2s ease-in;
    }

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-accent;
      transition: 0.2s ease-in;
      transform: scale(0);
    }

    &_checked {
      &:after {
        transform: scale(1);
      }
  }

    &:hover {
      &:after {
        background-color: $color-accent-hover;
      }
    }

    &__input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    &__label {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }
  }
}

</style>
