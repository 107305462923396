<template>
  <transition
    name="fade"
    appear
    mode="out-in"
  >
    <div
      v-if="data.totalPages > 1"
      class="lp-pagination"
      :class="[
        {'lp-pagination_sticky': sticky},
        customClass
      ]"
    >
      <div
        v-if="data.totalPages > 1"
        class="lp-pagination-item lp-pagination-item_nav"
        :class="{'lp-pagination-item_disabled': activePage === 0}"
        @click="clickPage(data.prevPage)"
      >
        <i class="icon-arrow icon-arrow_left">
          <paginationRightArrow />
        </i>
      </div>
      <div
        v-for="(page, index) in pages"
        :key="`${index}bb${page}`"
        class="lp-pagination-item"
        :class="
          {'lp-pagination-item_active': activePage === index,
           'lp-pagination-item_empty': page === '...'}"
        @click="clickPage(page, index)"
      >
        {{ page }}
      </div>
      <div
        v-if="data.totalPages > 1"
        class="lp-pagination-item lp-pagination-item_nav"
        :class="{'lp-pagination-item_disabled': activePage === pages.length - 1}"
        @click="clickPage(data.nextPage)"
      >
        <i class="icon-arrow">
          <paginationRightArrow />
        </i>
      </div>
    </div>
  </transition>
</template>

<script>
import { paginationRightArrow } from '@/constants/icons';
import scrollTo from '@/constants/utils/scrollTo';
import { ref, onMounted, watch } from 'vue';

export default {
  name: 'Pagination',
  components: {
    paginationRightArrow
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    getQuery: {
      type: Function
    },
    limit: {
      type: Number,
      default: 1
    },
    sticky: {
      type: Boolean,
      default: true
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const activePage = ref(0);
    const pages = ref([1, 2, 3]);

    function setPages ({
      totalPages, page, prevPage, nextPage
    }) {
      if (totalPages <= 3) {
        activePage.value = page - 1;
        const firstPages = [1, 2, 3];
        pages.value = firstPages.splice(0, totalPages);
        return;
      }
      if (totalPages === nextPage || totalPages === page) {
        activePage.value = totalPages === nextPage ? 3 : 4;
        pages.value = totalPages === nextPage
          ? [1, '...', prevPage, page, nextPage]
          : [1, '...', prevPage - 1, prevPage, page];
        return;
      }
      const isPrevAndNextPage = prevPage && nextPage;
      const activePageSecond = nextPage ? 0 : 2;
      const checkPage = totalPages === nextPage + 1 ? totalPages : nextPage + 1;
      const pagesSecond = nextPage
        ? [page, nextPage, checkPage, '...', totalPages]
        : [prevPage - 1, prevPage, page];
      pages.value = isPrevAndNextPage ? [prevPage, page, nextPage, '...', totalPages] : pagesSecond;
      activePage.value = isPrevAndNextPage ? 1 : activePageSecond;
    }

    function scrollToElem (selector) {
      return document.querySelector(`#${selector}`).offsetTop;
    }

    function clickPage (page) {
      if (page === '...' || !page || props.data.page === page) {
        return;
      }
      const query = {
        perPage: props.limit,
        page
      };
      props.getQuery(query);
      const container = document.getElementById('table-scroll');
      scrollTo(container, scrollToElem('tableHeader'), 200, 'clear');
    }

    watch(() => props.data, setPages);

    onMounted(() => {
      setPages(props.data);
    });

    return {
      activePage,
      pages,
      clickPage,
      setPages
    };
  }
};
</script>

<style lang="scss">
@import "../../../../sass/style.scss";
.lp-pagination {
  &-item {
    &_disabled {
      pointer-events: none;

      path {
        fill: $color-gray;
      }
    }
  }
}

</style>

<style lang="scss" scoped>
@import "../../../../sass/style.scss";

.lp-pagination {
  position: relative;
  z-index: 1;
  display: flex;
  margin: auto;
  justify-content: center;
  background: $color-white;
  border-radius: 4px;
  width: max-content;
  height: 36px;
  align-items: center;

  &_offset {
    margin-top: 16px;
  }

  &_sticky {
    position: sticky;
    bottom: 0;
  }

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 0 3px;
    cursor: pointer;
    background: transparent;
    user-select: none;
    transition: background-color .2s ease-in;
    font-family: $global-font;
    font-size: 14px;
    color: $color-gray;
    font-weight: 500;
    border-radius: 4px;

    &_empty {
      text-align: center;
      margin: 0 -6px;
    }

    &_nav {
      background-color: transparent;
    }

    &_double {
      margin: 0;

      i {
        margin: -3px;
      }
    }

    &:last-child,
    &:first-child {
      margin: 0 9px;
    }

    &:hover {
      background-color: $color-moon-raker;
    }

    &_active {
      background-color: $color-moon-raker !important;
      color: $color-accent !important;
      font-weight: 700;
    }
  }

  &__nav {
    display: block;
    color: $color-white;
  }
}

.icon-arrow {
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: color .15s ease-in;

  &_left {
    transform: rotate(180deg);
  }
}

</style>
