<template>
  <div class="lp-date-select">
    <CustomSelect
      :options="optionsDays"
      :searchable="true"
      :error="error"
      :label="label"
      :fixed="fixed"
      :required="required"
      keyProp="value"
      :placeholder="$t('global.day')"
      v-model="date.day"
      @focus="$emit('focus')"
    />
    <CustomSelect
      :options="optionsMonths"
      :withoutLabel="true"
      :searchable="true"
      :error="!!error"
      :required="required"
      :fixed="fixed"
      keyProp="value"
      :placeholder="$t('global.month')"
      v-model="date.month"
      @focus="$emit('focus')"
    />
    <CustomSelect
      :options="optionsYears"
      :withoutLabel="true"
      :searchable="true"
      :error="!!error"
      :fixed="fixed"
      :required="required"
      keyProp="value"
      :placeholder="$t('global.year')"
      v-model="date.year"
      @focus="$emit('focus')"
    />
  </div>
</template>

<script>
import CustomSelect from '@/components/Main/Fields/CustomSelect';
import { reactive, watch, onBeforeMount } from 'vue';
import { days, months, years } from '@/constants/date';
import { find } from 'lodash';

export default {
  name: 'DateSelect',
  components: {
    CustomSelect
  },
  props: {
    modelValue: String,
    required: Boolean,
    label: String,
    error: [Boolean, String],
    fixed: Boolean
  },
  setup (props, { emit }) {
    const date = reactive({
      day: {},
      month: {},
      year: {}
    });

    const optionsDays = days.map((item) => ({ value: item }));
    const optionsMonths = months.map((item, index) => ({ id: index + 1, value: item }));
    const optionsYears = years.map((item) => ({ value: item }));

    watch(date, () => {
      const { day = {}, month = {}, year = {} } = date;
      const dateISO = `${year.value}-${month.id}-${day.value}`;
      emit('update:modelValue', dateISO);
    });

    onBeforeMount(() => {
      const fullDate = new Date(props.modelValue);
      const day = find(optionsDays, { value: fullDate.getDate() });
      const month = find(optionsMonths, { id: fullDate.getMonth() + 1 });
      const year = find(optionsYears, { value: fullDate.getFullYear() });
      date.day = day;
      date.month = month;
      date.year = year;
    });

    return {
      date,
      optionsDays,
      optionsMonths,
      optionsYears
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-date-select {
  @include global-font;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;
  align-items: center;
}
</style>
