<template>
  <div class="lp-landing-footer">
    <div class="lp-landing-footer__wrapper">
      <div
        class="lp-landing-footer__logo"
        @click="scrollTo('lp-landing')"
      >
        <whiteLogoIcon />
      </div>
      <nav class="lp-landing-footer-menu">
        <button
          class="lp-landing-footer-menu__item"
          @click="scrollTo('lp-landing-main-wrapper')"
        >
          О школе
        </button>
        <button
          class="lp-landing-footer-menu__item"
          @click="scrollTo('lp-landing-teachers-wrapper', -150)"
        >
          Наши преподаватели
        </button>
        <button
          class="lp-landing-footer-menu__item"
          @click="scrollTo('lp-landing-price-wrapper')"
        >
          Стоимость обучения
        </button>
        <a
          class="lp-landing-footer-menu__item"
          target="_blank"
          href="https://tool.musio.io/"
        >
          Учителям
        </a>
      </nav>
      <div class="lp-landing-footer__copyright">
        © {{ year }} Musio.io Все права защищены.
      </div>
    </div>
  </div>
</template>

<script>
import { whiteLogoIcon } from '@/constants/icons/landing';
import { computed } from 'vue';

export default {
  name: 'LandingFooter',
  components: {
    whiteLogoIcon
  },
  setup () {
    const year = computed(() => (new Date()).getFullYear());

    const scrollTo = (className, offset = 0) => {
      const ps = document.querySelector('.lp-container__content');
      const [target] = document.getElementsByClassName(className);
      const coordinates = target.getBoundingClientRect();
      const top = (ps.scrollTop + coordinates.top) + offset;
      ps.scrollTo({
        top,
        behavior: 'smooth'
      });
    };

    return {
      scrollTo,
      year
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-landing-footer {
  @include global-font;
  width: 100%;
  position: relative;
  background-color: $color-mine-shaft;
  padding: 25px;

  &__logo {
    cursor: pointer;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1290px;
    margin: auto;

    @media (max-width: 1020px) {
      flex-direction: column;
    }
  }

  &-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 48px;

    @media (max-width: 1020px) {
      display: none;
    }

    &__item {
      @include global-font;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-white;
      cursor: pointer;
      transition: 0.3s ease-in;

      &:hover, &_active {
        color: $color-silver-dark
      }
    }
  }

  &__copyright {
    font-weight: normal;
    font-size: 16px;
    line-height: 125%;
    color: $color-white;
  }
}

</style>
