<template>
  <div
    class="lp-my-teachers-tabs-header lp-tabs"
  >
    <div
      class="lp-my-teachers-tabs-header__item lp-tabs__item"
      :class="{
        'lp-tabs__item_active': activeTab === tab.id,
        'lp-tabs__item_disabled': tab.disabled
      }"
      @click="$emit('update:tab', tab.id)"
      v-for="tab of tabs"
      :key="tab.id"
    >
      {{ tab.title }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'MyTeachersTabs',
  props: {
    tabs: Array,
    activeTab: Number
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-my-teachers-tabs {
  @include global-font;

  &-header {
    grid-template-columns: repeat(2, auto) 1fr;
    padding: 0;

    &__item {
      font-weight: 800;
      font-size: 22px;
      line-height: 27px;
      padding: 0 0 12px;
      letter-spacing: 0.07em;
    }
  }
}

</style>
