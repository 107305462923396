<template>
  <div class="lp-calendar">
    <div class="lp-calendar-header">
      <div class="lp-calendar-header__today">
        {{ formatDate }}
      </div>
      <CalendarControls
        v-model:date="date"
        v-model:tab="tab"
        v-model:form="openForm"
        :editMode="edit"
        :date="date"
        :currentTab="currentTab"
      />
    </div>
    <div class="lp-calendar-body">
      <transition name="slideCalendar">
        <component
          class="lp-calendar-body__timetable"
          :is="getCalendar"
          :key="date"
          :locale="locale"
          :date="date"
          :editMode="edit"
          :lessons="groupLessons"
          @updateLessons="getLessons"
          v-model:range="dateRange"
        />
      </transition>
    </div>
    <LessonForm
      :open="openForm"
      :lessons="lessons"
      :editLesson="editLesson"
      :subjects="teacherSubjects"
      v-model:lessons="lessons"
      v-model:open="openForm"
    />
  </div>
</template>

<script>
import CalendarControls from '@/components/Main/Calendar/CalendarControls';
import {
  computed, onBeforeMount, ref, watch
} from 'vue';
import moment from 'moment';
import { get, groupBy, orderBy } from 'lodash';
import i18n from '@/i18n';
import CalendarMonths from '@/components/Main/Calendar/CalendarMonths';
import CalendarWeeks from '@/components/Main/Calendar/CalendarWeeks';
import CalendarDays from '@/components/Main/Calendar/CalendarDays';
import { useRouter } from 'vue-router';
import LessonForm from '@/components/Main/Calendar/LessonForm';
import LessonsApi from '@/api/Lessons/api';
import { useStore } from 'vuex';
import ROLE_TYPES from '@/constants/enums/roles';
import { getSubjects } from '@/constants/utils/getValueById';
import CalendarSettingsSchedule from '@/components/Main/Calendar/CalendarSettingsSchedule';
import LESSON_STATUS from '@/constants/enums/lessonStatus';

export default {
  name: 'Calendar',
  components: { LessonForm, CalendarControls },
  props: {
    edit: Boolean
  },
  setup (props) {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const isStudent = computed(() => activeUser.value.role === ROLE_TYPES.STUDENT);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const teacherSubjects = computed(() => {
      const subjects = get(activeUser.value, 'subjects', []);
      return getSubjects(subjects);
    });

    const router = useRouter();
    const currentTab = computed(() => get(router, 'currentRoute.value.params.unit', 'month'));

    const openForm = ref(false);

    const defaultDateRange = [moment().format('YYYY-MM-DD'), moment().add(1, 'month').format('YYYY-MM-DD')];
    const dateRange = ref([]);
    const date = ref(moment().format('YYYY-MM-DD'));
    const locale = get(i18n, 'global.locale.value', 'ru');

    const formatDate = computed(() => moment(date.value).locale(locale).format('MMMM YYYY'));

    const tab = ref('');
    watch(tab, () => router.replace({ params: { unit: tab.value } }));

    const getCalendar = computed(() => {
      if (props.edit) {
        return CalendarSettingsSchedule;
      }
      switch (currentTab.value) {
      case 'month':
        return CalendarMonths;
      case 'week':
        return CalendarWeeks;
      default:
        return CalendarDays;
      }
    });

    const lessons = computed(() => store.getters.lessonsList);
    const setLessons = (data) => store.dispatch('setLessonsList', data);
    const editLesson = computed(() => store.getters.editForm);
    watch(editLesson, () => {
      openForm.value = !!editLesson.value;
    });

    const groupLessons = computed(() => {
      const withDate = lessons.value.map((lesson) => {
        lesson.date = moment(lesson.lessonStartTime).format('YYYY-MM-DD');
        return lesson;
      });
      const sortByStartTime = orderBy(withDate, 'lessonStartTime');
      const weekendLesson = LESSON_STATUS.WEEKEND;
      const filtered = sortByStartTime.filter((item) => (props.edit ? item._id : item.lessonStatus !== weekendLesson));
      return groupBy(filtered, 'date');
    });

    const getLessons = () => {
      if (!activeUser.value._id) return;
      const date = dateRange.value.length > 0 ? dateRange.value : defaultDateRange;
      const query = { lessonDateRange: date };
      query[isStudent.value ? 'student' : 'teacher'] = activeUser.value._id;
      setLoaderRun(true);
      LessonsApi.getLessons(query).
        then(({ data }) => {
          const lessonsGroup = orderBy(data.resource, 'lessonStartTime');
          setLessons(lessonsGroup);
          setLoaderRun(false);
        }).
        catch((err) => {
          console.error(err);
          setLoaderRun(false);
        });
    };
    watch(dateRange, getLessons);
    watch(activeUser, getLessons);
    onBeforeMount(getLessons);

    return {
      openForm,
      currentTab,
      date,
      dateRange,
      formatDate,
      locale,
      tab,
      getCalendar,
      lessons,
      groupLessons,
      editLesson,
      teacherSubjects,
      getLessons
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-calendar {
  @include global-font;

  &-header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: center;
    margin-bottom: 12px;
    min-height: 37px;

    &__today {
      color: $color-text;
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
    }
  }

  &-body {
    position: relative;
    min-height: 63vh;

    &__timetable {
      left: 0;
      right: 0;
      background-color: $color-white;
    }
  }
}

</style>
