import CURRENT_SERVER from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  login (user) {
    user.login = user.email;
    const instCred = axios.create({
      baseURL: CURRENT_SERVER
    });
    return instCred.post('auth/login', user);
  },

  registration (user, lang) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        'Accept-Language': lang
      }
    });
    return instCred.post('auth/signup', user);
  },
  resetPassword (passwords) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('auth/email/reset-password', passwords);
  }
};
