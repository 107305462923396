<template>
  <div class="lp-infinity-scroll">
    <perfect-scrollbar
      class="lp-infinity-scroll__body"
      :style="{height}"
      :options="{minScrollbarLength: 100}"
      ref="wrapper"
      @ps-scroll-down="throttleScrollDown"
      @ps-scroll-up="throttleScrollUp"
    >
      <slot></slot>
      <div class="lp-infinity-scroll__loader">
        <PageLoader :loaderRun="loader" />
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import PageLoader from '@/components/Main/Loader/PageLoader';
import { computed, ref } from 'vue';
import { get, throttle } from 'lodash';

export default {
  name: 'InfiniteScroll',
  components: { PageLoader },
  props: {
    loader: Boolean,
    distance: Number,
    page: Number,
    limit: Number,
    total: Number,
    length: Number,
    elements: {
      type: Array,
      default: () => []
    },
    request: {
      type: Function,
      default: () => {}
    },
    height: {
      type: String,
      default: '100vh'
    }
  },
  setup (props, { emit }) {
    const wrapper = ref(null);
    const contentHeight = computed(() => get(wrapper, 'value.ps.contentHeight', {}));
    const containerHeight = computed(() => get(wrapper, 'value.ps.containerHeight', {}));

    const getNextPage = () => {
      emit('update:page', props.page + 1);
      props.request();
    };
    const gap = 4;
    const heightElement = computed(() => {
      const allHeight = props.elements.map((el) => el.offsetHeight);
      return Math.max(...allHeight);
    });
    const countDisplay = computed(() => Math.ceil(containerHeight.value / heightElement.value));
    const indexes = ref([]);
    const setHideIndex = (scrollTop) => {
      const hideIndex = Math.floor(scrollTop / heightElement.value);
      const minCountItems = countDisplay.value + gap;
      const maxIndex = hideIndex + minCountItems < 10 ? 10 : hideIndex + minCountItems;
      const minIndex = hideIndex - gap < 0 ? 0 : hideIndex - gap;
      indexes.value = [minIndex, maxIndex];
      emit('update:index', indexes.value);
    };

    const scrollDown = (e) => {
      if (props.loader || props.total <= props.length) return;
      const { scrollTop } = e.target;
      const positionScroll = contentHeight.value - containerHeight.value - scrollTop;
      if ((positionScroll <= props.distance)) getNextPage();
    };
    const scrollUp = (e) => {
      const { scrollTop } = e.target;
      return scrollTop;
    };
    const throttleScrollDown = computed(() => throttle(scrollDown, 500));
    const throttleScrollUp = computed(() => throttle(scrollUp, 10000));

    return {
      wrapper,
      throttleScrollDown,
      throttleScrollUp,
      setHideIndex
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-infinity-scroll {
  @include global-font;

  &__body {
    margin-right: -10px;
    padding-right: 10px;
    overflow: hidden;
  }

  &__loader {
    width: 100%;
    max-height: 172px;
  }
}

</style>
