<template>
  <div
    class="lp-date-picker"
    v-click-outside="closeDropList"
  >
    <span
      class="lp-label lp-date-picker__label"
      v-if="label"
      :class="{
        'lp-label_required': required
      }"
    >
      {{ $t(label) }}
    </span>
    <div
      class="lp-date-picker-input lp-input"
      :class="{
        'lp-input_error': error,
        'lp-date-picker-input_without-text': hideInput
      }"
      ref="selectHeader"
      @click="changeOpenDropList(!openDropList)"
    >
      <p
        class="lp-date-picker-input__date"
        v-if="!hideInput"
      >
        <template v-if="rangePicker">
          {{ range[0] && range[1] ? formatRange : placeholder }}
        </template>
        <template v-else>
          {{ range[0] ? formatOne : placeholder }}
        </template>
        <span
          class="lp-date-picker-input__time"
          v-if="!hideTime && time[0] !== time[1]"
        >
          , {{ time.join(' - ') }}
        </span>
      </p>
      <div class="lp-date-picker-input__icon">
        <calendarIcon />
      </div>
    </div>
    <transition name="slide-select">
      <div
        class="lp-date-picker-dropdown"
        :class="{'lp-date-picker-dropdown_hide-label': !label}"
        v-if="openDropList"
        :style="fixed ? getStyleDropList() : {}"
        @click.stop
      >
        <DatePickerHeader
          :year="currentYear"
          :month="currentMonth"
          @changeMonth="changeMonth"
        />
        <DatePickerBody
          :year="currentYear"
          :month="currentMonth"
          :currentDate="currentDate"
          :range="range"
          :minDate="minDate"
          :rangePicker="rangePicker"
          v-model:open="openDropList"
          v-model:dates="range"
        />
        <template v-if="!hideTime">
          <div class="lp-date-picker-dropdown__time-title">
            {{ $t('global.rangeTime') }}:
          </div>
          <TimePicker
            :modelValue="time"
            v-model="time"
          />
        </template>
        <button
          class="lp-button lp-button_border lp-button_small lp-date-picker-dropdown__reset"
          v-if="!hideReset"
          @click.stop="resetDates"
        >
          {{ $t('buttons.reset') }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { calendarIcon } from '@/constants/icons';
import {
  ref, computed, onBeforeMount, watchEffect, watch
} from 'vue';
import i18n from '@/i18n';
import moment from 'moment';
import DatePickerHeader from '@/components/Main/DatePicker/DatePickerHeader';
import DatePickerBody from '@/components/Main/DatePicker/DatePickerBody';
import TimePicker from '@/components/Main/DatePicker/TimePicker';
import { get } from 'lodash';

export default {
  name: 'DatePicker',
  components: {
    TimePicker,
    DatePickerHeader,
    DatePickerBody,
    calendarIcon
  },
  props: {
    hideReset: Boolean,
    hideTime: Boolean,
    hideInput: Boolean,
    placeholder: String,
    error: [Boolean, String],
    modelValue: Object,
    required: Boolean,
    label: String,
    fixed: Boolean,
    format: String,
    rangePicker: Boolean,
    minDate: [String, Date]
  },
  setup (props, { emit }) {
    const months = Object.values(i18n.global.tm('months'));
    const formatISO = 'YYYY-MM-DD';
    const formatDate = props.format || i18n.global.t('global.formatDate');
    const today = moment();
    const range = ref([]);
    const locale = get(i18n, 'global.locale.value', 'ru');
    const formatRange = computed(() => {
      const start = moment(range.value[0]).locale(locale).format(formatDate);
      const end = moment(range.value[1]).locale(locale).format(formatDate);
      return `${start} - ${end}`;
    });
    const formatOne = computed(() => {
      const start = moment(range.value[0]).locale(locale).format(formatDate);
      return `${start}`;
    });

    const time = ref(['00:00', '00:00']);

    const result = computed(() => ({ time: time.value, date: range.value }));

    watchEffect(range, emit('update:modelValue', result));

    const currentDate = ref(today.format(formatISO));
    const currentMonth = computed(() => months[+moment(currentDate.value).format('M') - 1]);
    const currentYear = computed(() => moment(currentDate.value).format('YYYY'));

    const openDropList = ref(false);
    const changeOpenDropList = (val) => {
      openDropList.value = val;
      emit('focus');
    };
    const closeDropList = () => {
      openDropList.value = false;
    };

    const changeMonth = (val) => {
      const date = moment(currentDate.value);
      const resultData = val ? date.add(1, 'months') : date.subtract(1, 'months');
      currentDate.value = resultData.format(formatISO);
    };

    const selectHeader = ref(null);
    const getStyleDropList = () => {
      if (!selectHeader.value) return {};
      const position = selectHeader.value.getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${4 + position.top + position.height}px`,
        left: `${position.left}px`,
        width: `${position.width}px`
      };
    };

    const setDefaultRange = () => {
      range.value = props.modelValue.date || [];
      time.value = props.modelValue.time || [];
    };

    const resetDates = (e) => {
      e.preventDefault();
      range.value = [];
      time.value = ['00:00', '00:00'];
      closeDropList();
    };

    watch(() => props.modelValue, (newVal) => {
      const newStart = get(newVal, ['date', 0], '');
      const oldStart = get(range.value, [0], '');
      if (newStart === oldStart) return;
      setDefaultRange();
    });
    onBeforeMount(setDefaultRange);

    return {
      range,
      time,
      formatRange,
      formatOne,
      currentDate,
      currentMonth,
      currentYear,
      openDropList,
      changeOpenDropList,
      closeDropList,
      selectHeader,
      getStyleDropList,
      changeMonth,
      resetDates
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-date-picker {
  @include global-font;
  background-color: $color-white;
  position: relative;
  display: grid;
  grid-gap: 4px;
  color: $color-text;

  &-input {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 18px;
    height: 100%;
    min-height: 42px;
    cursor: pointer;
    transition: 0.3s;

    &_without-text {
      justify-content: center;
      padding: 3px 3px;
      min-height: 36px;
      width: 100%;
      min-width: 36px;
      grid-template-columns: 0 16px;
    }

    &__date {
      &::first-letter {
        text-transform: uppercase;
      }
    }

    &__time {
      margin-left: -3px;
    }

    &:hover {
      opacity: 0.8;
    }

    &__icon {
      grid-column: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: $color-gray;
      }
    }
  }

  &-dropdown {
    @include global-font;
    position: absolute;
    top: 65px;
    right: 0;
    display: grid;
    min-width: 317px;
    max-width: 317px;
    height: auto;
    padding: 28px 20px 32px;
    background: $color-white;
    box-shadow: 0 0 15px rgba($color-black, 0.05);
    border-radius: 6px;
    border: 1px solid $color-wild-sand;
    z-index: 100;

    &_hide-label {
      top: 50px;
    }

    &__time-title {
      margin: 26px 0 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
    }

    &__reset {
      margin-top: 20px;
    }
  }
}

</style>
