<template>
  <transition name="fade">
    <div
      class="lp-loader"
      @click.stop.prevent
      v-if="loaderRun"
    >
      <div class="lp-loader-spinner">
        <div
          class="lp-loader-spinner-blade"
          v-for="(el, i) of blades"
          :key="i"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { fill } from 'lodash';

export default {
  name: 'Loader',
  setup () {
    const store = useStore();
    const loaderRun = computed(() => store.getters.loaderRun);

    const countBlades = 12;
    const blades = fill(new Array(countBlades), 0);

    return { loaderRun, blades };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

$spinner-size: 100px;
$spinner-color: $color-accent;

.lp-loader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba($color-white, 0.8);
  z-index: 999;

  &-spinner {
    width: 72px;
    height: 72px;
    margin-left: -12px;
    font-size: $spinner-size;
    position: relative;

    &-blade {
      position: absolute;
      left: .4629em;
      bottom: 0;
      width: 4px;
      height: 14px;
      border-radius: 2px;
      background-color: transparent;
      transform-origin: center -.2222em;
      animation: spinner-fade 1s infinite linear;

      $animation-delay: 0s;
      $blade-rotation: 0deg;

      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          animation-delay: $animation-delay;
          transform: rotate($blade-rotation);
          $blade-rotation: $blade-rotation + 30;
          $animation-delay: $animation-delay + .083;
        }
      }
    }
  }
}

@keyframes spinner-fade {
  0% {
    background-color: $spinner-color;
  }

  100% {
    background-color: transparent;
  }
}

</style>
