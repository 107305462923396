import teacherImg from '@/assets/images/landing/teachers/teacher5.jpg';
import achievement1 from '@/assets/images/landing/achievements/teacher5/achievement1.jpeg';
import achievement2 from '@/assets/images/landing/achievements/teacher5/achievement2.jpeg';
import achievement3 from '@/assets/images/landing/achievements/teacher5/achievement3.jpeg';
import achievement4 from '@/assets/images/landing/achievements/teacher5/achievement4.jpeg';
import achievement5 from '@/assets/images/landing/achievements/teacher5/achievement5.jpeg';

export default {
  image: teacherImg,
  name: 'Нина Исайкина',
  about: `Преподаватель эстрадного и академического вокала, певица, музыкант. 

           - Высшая квалификационная категория, стаж более 15 лет, музыкальное и педагогическое образование;

           - опыт работы с детьми  и взрослыми любого уровня подготовки, методические консультации для преподавателей;

           - индивидуальный подход и подбор методики с учётом физиологии и психологических особенностей;

           - член Общественной Российской Академии Голоса, участник Всероссийской Фониологической Ассамблеи 2021 года; 

           - использование современных образовательных технологий и ведущих  методик преподавания вокала (Complete Vocal Technique, ФМРГ В. Емельянова, SLS С. Риггса и др.);

           - Учащиеся - Лауреаты, Дипломанты различных вокальных конкурсов и фестивалей, стипендиаты Губернаторской премии, поступают в образовательные учреждения культуры на вокальную специализацию.

           - активно веду творческую деятельность. Выступаю с сольными номерами на сценических площадках города и области;

           - Лауреат Международных, Региональных и Областных вокальных конкурсов по двум направлениям (академический и эстрадный вокал).
           `,
  works: [
    {
      date: '2005 - по настоящее время',
      position: 'Преподаватель вокала',
      company: 'МБУ ДО «Детская школа искусств г. Оби»',
    },
    {
      date: '2010 - 2020 гг.',
      position: 'Народного женского вокального ансамбля «Родные напевы»',
      company: 'МБУ «Крылья Сибири»',
    },
    {
      date: '2013 - 2017 гг.',
      position: 'руководитель Образцового детского вокального ансамбля «Карамельки»',
      company: 'МБУ «Крылья Сибири»',
    },
  ],
  achievements: [achievement1, achievement2, achievement3, achievement4, achievement5],
  workText: `С 2005 года по настоящее время - Преподаватель вокала в МБУ ДО "Детская школа искусств г. Оби";

2010-2020 гг. – руководитель Народного женского вокального ансамбля «Родные напевы» МБУ «Крылья Сибири»;

2013-2017 гг. – руководитель Образцового детского вокального ансамбля «Карамельки» МБУ «Крылья Сибири»
`,
};
