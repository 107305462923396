<template>
  <div class="lp-login-license">
    <CustomCheckbox
      v-bind="applyLicense"
      v-model="applyLicense.modelValue"
    >
      <template #label>
        <p>
          <span>
            {{ $t('registration.license.text') }}
          </span>
          <span
            class="lp-login-license__link lp-link"
            @click.stop.prevent
          >
            {{ $t('registration.license.condition') }}
          </span>
          <span>
            {{ $t('registration.license.and') }}
          </span>
          <span
            class="lp-login-license__link lp-link"
            @click.stop.prevent
          >
            {{ $t('registration.license.policy') }}
          </span>
        </p>
      </template>
    </CustomCheckbox>
  </div>
</template>

<script>
import CustomCheckbox from '@/components/Main/Fields/CustomCheckbox';
import { reactive, watch } from 'vue';

export default {
  name: 'LoginLicense',
  components: {
    CustomCheckbox
  },
  emits: ['update:license'],
  setup (props, { emit }) {
    const applyLicense = reactive({
      hasLabelSlot: true,
      modelValue: false
    });

    watch(applyLicense, () => {
      emit('update:license', applyLicense.modelValue);
    });

    return { applyLicense };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-login-license {
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;

  &__link {
    color: $color-azure-radiance;
  }
}

</style>
