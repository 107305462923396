<template>
  <transition name="bounce">
    <div
      v-click-outside="closeMenu"
      class="lp-tooltip-menu"
      v-if="open"
    >
      <component
        :is="item.isRoute ? 'router-link' : 'div'"
        :to="item.isRoute ? item.to : ''"
        class="lp-tooltip-menu__item"
        :class="{'lp-tooltip-menu__item_active': item.active}"
        @click="item.isRoute ? '' : clickByItem(item)"
        v-for="item of options"
        :key="item.title"
      >
        {{ item.title }}
      </component>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'TooltipMenu',
  props: {
    options: Array,
    open: Boolean
  },
  setup (props, { emit }) {
    const closeMenu = () => {
      emit('update:open', false);
    };

    const clickByItem = (item) => {
      if (typeof item.action === 'function') item.action(item);
      closeMenu();
    };

    return { clickByItem, closeMenu };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-tooltip-menu {
  @include global-font;
  position: absolute;
  top: auto;
  left: auto;
  display: grid;
  grid-gap: 8px;
  padding: 9px;
  background: $color-white;
  border-radius: 3px;

  &__item {
    padding: 8px 16px;
    border-radius: 3px;
    color: $color-gray;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    background: transparent;
    transition: 0.3s ease-out;
    cursor: pointer;

    &_active {
      pointer-events: none;
    }

    &_active,
    &:hover {
      color: $color-accent;
      background: $color-moon-raker;
    }
  }
}

</style>
