import * as types from '@/constants/mutationTypes';

const state = {
  cardList: []
};
const getters = {
  cardList: (state) => state.cardList
};
const actions = {
  setCardList ({ commit }, data) {
    commit(types.SET_CARD_LIST, { data });
  }
};
const mutations = {
  [types.SET_CARD_LIST] (state, { data }) {
    state.cardList = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
