<template>
  <div
    class="lp-students"
  >
    <Student
      v-for="student in students"
      :key="student._id"
      :student="student"
    />
  </div>
</template>

<script>
import Student from '@/components/MyStudents/Students/Student/Student';

export default {
  name: 'Students',
  components: { Student },
  props: {
    students: Array
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-students {
  @include global-font;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(437px, 1fr));
  display: grid;
  grid-gap: 18px;
}

</style>
