<template>
  <div class="lp-profile-teacher-price">
    <div class="lp-profile-teacher-price__title">
      {{ $t('listTeachers.coast') }}
      <span class="lp-profile-teacher-price__duration">
        {{ $t('profile.forms.price.lessonDuration') }}
      </span>
    </div>
    <div class="lp-profile-teacher-price__coast">
      {{ coast }}
    </div>
    <template v-if="studentHasTrial">
      <div class="lp-profile-teacher-price__title lp-profile-teacher-price__title_trial">
        {{ $t('listTeachers.trialCoast') }}
      </div>
      <div
        class="lp-profile-teacher-price__coast lp-profile-teacher-price__coast_free"
      >
        {{ $t('global.free') }}
      </div>
    </template>
    <button
      class="lp-button lp-profile-teacher-price__sign-up"
      @click.stop="$emit('update:timetable', true)"
    >
      {{ $t('buttons.signUpLesson') }}
    </button>
  </div>
</template>

<script>
import numeral from 'numeral';
import {
  computed, onBeforeMount, ref, watch
} from 'vue';
import LessonsApi from '@/api/Lessons/api';
import { useStore } from 'vuex';

export default {
  name: 'ProfileTeacherPrice',
  props: {
    teacher: Object
  },
  setup (props) {
    const coastNumber = computed(() => props.teacher.lessonCost);
    const teacherHasTrial = computed(() => props.teacher.trialLessonAgreement || false);
    const coast = computed(() => (coastNumber.value ? numeral(coastNumber.value).format('$0.00') : 0));

    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const studentHasTrial = ref(false);
    const checkTrialLesson = () => {
      studentHasTrial.value = false;
      const query = {
        limit: 1,
        teacher: props.teacher._id,
        student: activeUser.value._id
      };
      if (!query.teacher || !query.student || !teacherHasTrial.value) return;
      LessonsApi.getLessons(query).
        then(({ data }) => {
          studentHasTrial.value = !data.totalDocs;
        }).
        catch((err) => {
          console.error(err);
        });
    };

    onBeforeMount(checkTrialLesson);
    watch(props, checkTrialLesson);
    watch(activeUser, checkTrialLesson);

    return { coast, studentHasTrial };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-profile-teacher-price {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 22px 20px;
  padding: 28px 28px 24px;
  background: $color-moon-raker;
  box-shadow: 0 0 15px rgba($color-black, 0.02);
  color: $color-text;

  &__title {
    font-weight: 800;
    font-size: 14px;
    line-height: 125%;
    text-transform: uppercase;

    &_trial {
      align-self: center;
    }
  }

  &__duration {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 152%;
    color: $color-gray;
    text-transform: lowercase;
  }

  &__coast {
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;

    &_free {
      padding: 8px 12px;
      background: $color-supernova;
      border-radius: 3px;
      color: $color-text;
    }
  }

  &__sign-up {
    margin-top: 20px;
    grid-column: 1/3;
  }
}

</style>
