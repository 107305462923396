<template>
  <div class="lp-teachers-timetable">
    <div class="lp-teachers-timetable-row lp-teachers-timetable-row__week">
      <div />
      <div
        class="lp-teachers-timetable-row__week-day"
        :class="{
          'lp-teachers-timetable-row__week-day_first': !index,
          'lp-teachers-timetable-row__week-day_last': index === daysWeek.length - 1
        }"
        v-for="(day, index) of daysWeek"
        :key="day"
      >
        {{ day }}
      </div>
    </div>
    <div class="lp-teachers-timetable-row lp-teachers-timetable-row__dates">
      <div />
      <div
        class="lp-teachers-timetable-row__date"
        :class="{'lp-teachers-timetable-row__date_today': item.date === today}"
        v-for="item of dates"
        :key="item.date"
      >
        {{ item.day }}
      </div>
    </div>
    <div
      class="lp-teachers-timetable-row lp-teachers-timetable-row__times"
      v-for="time of times"
      :key="time.id"
    >
      <div class="lp-teachers-timetable-row__time-range">
        {{ time.title }}
      </div>
      <div
        class="lp-teachers-timetable-row-date"
        v-for="date of time.dates"
        :key="date.date"
      >
        <div
          class="lp-teachers-timetable-row-date__lesson"
          :class="[`lp-teachers-timetable-row-date__lesson_${date.duration}`]"
          v-if="date.haveLesson"
          @mouseenter="openTimes = date.fullDate"
          @mouseleave="openTimes = ''"
        />
        <Tooltip
          class="lp-teachers-timetable-row-date__tooltip"
          v-if="date.haveLesson"
          :open="openTimes === date.fullDate"
        >
          <div
            v-for="timeLesson of date.lessonTimes"
            :key="timeLesson"
          >
            <p>{{ $t('listTeachers.available') }}: </p>
            {{ timeLesson }}
          </div>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, onBeforeMount, ref, watch
} from 'vue';
import i18n from '@/i18n';
import moment from 'moment';
import {
  fill, get, groupBy, filter, last, cloneDeep
} from 'lodash';
import ScheduleTemplatesApi from '@/api/ScheduleTemplates/api';
import timeRanges from '@/constants/timeRanges';
import Tooltip from '@/components/Main/Tooltip/Tooltip';

export default {
  name: 'ListTeachersTimetable',
  components: { Tooltip },
  props: {
    date: String,
    teacher: Object
  },
  setup (props) {
    const locale = get(i18n, 'global.locale.value', 'ru');
    const formatISO = 'YYYY-MM-DD';

    const today = moment().format(formatISO);

    const startDate = computed(() => {
      const momentDate = moment(props.date);
      const date = props.date && momentDate.isValid() ? momentDate : moment();
      return date.format(formatISO);
    });

    const daysWeek = computed(() => {
      const array = fill(new Array(7), startDate.value);
      return array.map((d, i) => moment(d).add(i, 'day').locale(locale).format('dd'));
    });

    const lessons = ref({});

    const dates = computed(() => fill(new Array(7), startDate.value).map((date, index) => {
      const dateMoment = moment(date).add(index, 'd');
      const dateISO = dateMoment.format(formatISO);
      return {
        day: dateMoment.format('DD'),
        date: dateISO
      };
    }));

    const dateRange = computed(() => [dates.value[0].date, dates.value[6].date]);

    const getLessonsByTime = ({ date }, { min, max }) => {
      const weekDay = moment(date).day();
      const lessonsDate = get(lessons.value, weekDay, []);
      return filter(lessonsDate, (el) => {
        const hourInRange = el.startHour >= min && el.endHour <= max;
        const byDate = moment(el.startDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD');
        return hourInRange && (el.repeatable || byDate);
      }) || [];
    };

    const haveLessons = ({ date }, { min, max }) => {
      const lessonsTime = getLessonsByTime({ date }, { min, max });
      return lessonsTime.length;
    };

    const lessonsTimes = ({ date }, { min, max }) => {
      const lessonsTime = getLessonsByTime({ date }, { min, max }).sort((a, b) => a.startHour - b.startHour);
      if (!lessonsTime[0]) return [];
      const start = moment(lessonsTime[0].startDate).format('HH:mm');
      const end = moment(last(lessonsTime).endDate).format('HH:mm');
      return [`${start} - ${end}`];
    };

    const getDurationLesson = ({ date }, { min, max }) => {
      // half / first-half / full
      const lessonsTime = getLessonsByTime({ date }, { min, max });
      lessonsTime.sort((a, b) => a.startHour - b.startHour);
      const [firstLesson, lastLesson] = [lessonsTime[0] || {}, last(lessonsTime) || {}];
      const isFullTime = firstLesson.startHour === min && lessonsTime.length === 6;
      if (isFullTime) return 'full';
      if (lessonsTime.length) {
        const middle = (max + min) / 2;
        return lastLesson.startHour <= middle ? 'first-half' : 'half';
      }
      return false;
    };

    const times = ref([]);
    const setTimes = () => {
      times.value = cloneDeep(timeRanges).map((time) => {
        time.dates = cloneDeep(dates.value).map((date) => {
          date.duration = getDurationLesson(date, time);
          date.haveLesson = haveLessons(date, time);
          date.lessonTimes = lessonsTimes(date, time);
          date.fullDate = `${date.date}T${time.id}`;
          return date;
        });
        return time;
      });
    };

    const setAvailableTime = (times) => {
      if (!Array.isArray(times)) return;
      const availableTime = times.map((item) => {
        const startDate = moment(item.startDate).format();
        const endDate = moment(item.endDate).format();
        const endTime = +moment(endDate).format('HH');
        item.dayOfWeek = moment(startDate).day();
        item.startDate = startDate;
        item.endDate = endDate;
        item.startHour = +moment(startDate).format('HH');
        item.endHour = endTime === 0 ? 24 : endTime;
        return item;
      });
      lessons.value = groupBy(availableTime, 'dayOfWeek');
      setTimes();
    };

    const getTemplate = () => {
      const { _id: teacherId, availableTime } = props.teacher;
      if (availableTime) setAvailableTime(availableTime);
      if (!teacherId || availableTime) return;
      ScheduleTemplatesApi.getSchedule(teacherId).
        then(({ data }) => {
          setAvailableTime(data.availableTime);
        });
    };
    watch(dateRange, getTemplate);
    onBeforeMount(() => {
      getTemplate();
    });

    const openTimes = ref('');

    return {
      dates,
      daysWeek,
      timeRanges,
      times,
      today,
      lessons,
      openTimes
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-teachers-timetable {
  @include global-font;
  display: inline-grid;
  cursor: default;

  &-row {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 75px repeat(7, 43px);
    text-align: center;

    &-date {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      background-color: $color-alabaster;
      box-shadow: 0 0 0 2px $color-white;

      &__lesson {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        &_first-half,
        &_half {
          cursor: pointer;
          background-color: $color-madang;
        }

        &_full {
          width: 100%;
          height: 100%;
          border: none;
          background-color: $color-emerald;
          cursor: pointer;
        }
      }

      &__tooltip {
        top: 36px;
        width: 100px;
        white-space: nowrap;
      }
    }

    &__week {
      margin-bottom: 1px;
      color: $color-accent;
      font-weight: bold;
      font-size: 14px;
      line-height: 125%;
      text-transform: uppercase;
      height: 25px;
    }

    &__week-day {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: $color-moon-raker;
      box-sizing: border-box;
      border-top: 1px solid $color-alto;
      border-bottom: 1px solid $color-alto;

      &_first {
        border-radius: 4px 0 0 4px;
        border-left: 1px solid $color-alto;
      }

      &_last {
        border-radius: 0 4px 4px 0;
        border-right: 1px solid $color-alto;
      }
    }

    &__dates {
      color: $color-black;
      font-weight: 500;
      font-size: 12px;
      line-height: 125%;
      height: 26px;
    }

    &__date {
      display: flex;
      justify-content: center;
      padding-top: 4px;
      height: 100%;

      &_today {
        background-color: $color-alabaster;
      }
    }

    &__times {
      height: 35px;
      font-weight: 500;
      font-size: 12px;
      line-height: 125%;
      color: $color-black;
    }

    &__time-range {
      white-space: nowrap;
      margin-right: 6px;
    }
  }
}

</style>
