<template>
  <div class="lp-classroom">
    <h3 class="lp-section-title lp-classroom__title">
      {{ $t('classroom.title') }}
    </h3>
    <ClassroomPlaceholder
      :lesson="nextLesson"
      :user="activeUser"
    />
  </div>
</template>

<script>
import ClassroomPlaceholder from '@/components/Classroom/ClassroomPlaceholder';
import LessonsApi from '@/api/Lessons/api';
import {
  computed, onBeforeMount, ref, watch
} from 'vue';
import { useStore } from 'vuex';
import ROLE_TYPES from '@/constants/enums/roles';
import moment from 'moment';
import { orderBy } from 'lodash';
import LESSON_STATUS from '@/constants/enums/lessonStatus';

export default {
  name: 'Classroom',
  components: { ClassroomPlaceholder },
  setup () {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const isStudent = computed(() => activeUser.value.role === ROLE_TYPES.STUDENT);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);
    const nextLesson = ref({});

    const getLastLessons = () => {
      const start = moment().utc().format();
      const end = moment().add(10, 'year').utc().format();
      const query = {
        limit: 100,
        sort: 'lessonStartTime',
        status: `${LESSON_STATUS.IN_PROGRESS}&lessonStatus=${LESSON_STATUS.PENDING}`,
        lessonDateRange: [start, end]
      };
      query[isStudent.value ? 'student' : 'teacher'] = activeUser.value._id;
      setLoaderRun(true);
      LessonsApi.getLessons(query).
        then(({ data }) => {
          const lessons = orderBy(data.resource, 'lessonStartTime').filter((lesson) => {
            const date = moment(lesson.lessonStartTime);
            const now = moment().format();
            return date.isAfter(now, 'seconds');
          });
          nextLesson.value = lessons[0] || {};
          setTimeout(setLoaderRun, 1000);
        }).
        catch((err) => {
          console.error(err);
          setLoaderRun(false);
        });
    };

    watch(activeUser, getLastLessons);
    onBeforeMount(getLastLessons);

    return { nextLesson, activeUser };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-classroom {
  @include global-font;
  display: grid;

  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__test-video {
    display: inline-block;
  }
}

</style>
