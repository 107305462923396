<template>
  <div></div>
</template>

<script>
export default {
  name: 'ProfileTeachersComments'
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

</style>
