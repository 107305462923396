<template>
  <div class="lp-student">
    <div class="lp-student-header">
      <router-link
        :to="goStudentProfile()"
        target="_blank"
      >
        <UserAvatar
          class="lp-student-header__avatar"
          :avatar="avatar"
          :country="country"
          :small="true"
        />
      </router-link>
      <div class="lp-student-header-description">
        <router-link
          :to="goStudentProfile()"
          target="_blank"
        >
          <div class="lp-student-header-description__initials">
            {{ `${student.firstName} ${student.lastName}` }}
          </div>
        </router-link>
        <div class="lp-student-header-description-location">
          <locationIcon />
          <span class="lp-student-header-description-location__name">
            {{ `${country.name}, ${student.city}` }}
          </span>
        </div>
        <div class="lp-student-header-description-info">
          <div class="lp-student-header-description-info__item">
            <lessonIcon />
            <span class="lp-student-header-description-info__lesson">{{ counterLesson }} {{ lesson }}</span>
          </div>
          <div class="lp-student-header-description-info__item">
            <shieldIcon />
            <span class="lp-student-header-description-info__percent">100%</span>
          </div>
          <div class="lp-student-header-description-info__item">
            <ratingIcon />
            <span class="lp-student-header-description-info__rating">5.0</span>
          </div>
        </div>
      </div>
    </div>
    <div class="lp-student-footer">
      <div class="lp-student-footer__about">
        {{ $t('profile.sections.about') }}
      </div>
      <div class="lp-student-footer__description">
        {{ student.about }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import UserAvatar from '@/components/Main/UserElements/UserAvatar';
import { getCountry } from '@/constants/utils/getValueById';
import declensionOfNum from '@/constants/utils/declensionOfNum';
import {
  locationIcon,
  lessonIcon,
  shieldIcon,
  ratingIcon
} from '@/constants/icons';
import i18n from '@/i18n';

export default {
  name: 'Student',
  components: {
    UserAvatar,
    locationIcon,
    shieldIcon,
    lessonIcon,
    ratingIcon
  },
  props: {
    student: Object
  },
  setup (props) {
    const goStudentProfile = () => ({
      name: 'ProfileStudent',
      params: { id: props.student._id }
    });

    const country = computed(() => getCountry(props.student.country));

    const avatar = computed(() => {
      const isNotObject = typeof props.student.uploadFile !== 'object';
      const isNull = props.student.uploadFile === null;
      return isNotObject || isNull ? {} : props.student.uploadFile;
    });

    const counterLesson = computed(() => props.student.lesson || 1);
    const lesson = computed(() => declensionOfNum(counterLesson.value, i18n.global.tm('global.declensionLessons')));

    return {
      avatar,
      country,
      lesson,
      counterLesson,
      getCountry,
      goStudentProfile
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/style";

.lp-student {
  background-color: $color-white;
  height: 270px;
  padding: 24px;

  &-header {
    display: flex;
    margin-bottom: 29px;

    &__avatar {
      width: 100px;
      height: 100px;
    }

    &-description {
      margin-left: 20px;
      margin-top: 2px;

      &__initials {
        position: relative;
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 8px;
        line-height: 125%;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 1px;
          background-color: $color-text;
          transition: 0.2s ease-out;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      &-location {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 500;
        line-height: 125%;
        margin-bottom: 14px;

        &__name {
          margin-left: 6.5px;
        }
      }

      &-info {
        display: flex;
        font-size: 12px;
        font-weight: bold;
        line-height: 125%;

        &__item {
          display: flex;
          align-items: center;
        }

        &__lesson {
          margin-left: 8.78px;
          margin-right: 25.33px;
        }

        &__percent {
          margin-left: 8.33px;
          margin-right: 24.15px;
        }

        &__rating {
          margin-left: 6.85px;
        }

      }
    }
  }

  &-footer {

    &__about {
      font-size: 16px;
      font-weight: 800;
      line-height: 125%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    &__description {
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      height: 60px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
}

</style>
