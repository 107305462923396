<template>
  <div class="lp-template-timetable">
    <Modal
      title="userTimetable.template.title"
      :hideButtons="['remove']"
      :cantClose="cantCloseForm"
      @save="saveTemplate"
      v-model:open="openModal"
    >
      <template #default>
        <div class="lp-template-timetable-body">
          <div class="lp-template-timetable-body-explanation">
            <div class="lp-template-timetable-body-explanation__title">
              {{ $t('userTimetable.template.explanation.title') }}
            </div>
            <div class="lp-template-timetable-body-explanation__details">
              {{ $t('userTimetable.template.explanation.details') }}
            </div>
          </div>
          <TemplateSchedule
            v-model:times="workTimes"
            :weekSchedule="true"
          />
        </div>
      </template>
      <template #footer>
        <div class="lp-template-timetable-footer">
          <div class="lp-template-timetable-footer-legends">
            <div class="lp-template-timetable-footer-legends-item">
              <span
                class="lp-template-timetable-footer-legends-item__designation
                       lp-template-timetable-footer-legends-item__designation_work"
              />
              <span class="lp-template-timetable-footer-legends-item__title">
                - {{ $t('userTimetable.template.legends.workTime') }}
              </span>
            </div>
            <div class="lp-template-timetable-footer-legends-item">
              <span class="lp-template-timetable-footer-legends-item__designation" />
              <span class="lp-template-timetable-footer-legends-item__title">
                - {{ $t('userTimetable.template.legends.notWorkTime') }}
              </span>
            </div>
            <div class="lp-template-timetable-footer-legends__management">
              {{ $t('userTimetable.template.mark') }}
            </div>
          </div>
          <button
            class="lp-button"
            @click="saveTemplate"
          >
            {{ $t('buttons.save') }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { onBeforeMount, ref, watch } from 'vue';
import ScheduleTemplatesApi from '@/api/ScheduleTemplates/api';
import { useStore } from 'vuex';
import TemplateSchedule from '@/components/Main/Calendar/TemplateSchedule';
import { get } from 'lodash';

export default {
  name: 'TemplateTimetable',
  components: { TemplateSchedule, Modal },
  props: {
    user: Object,
    open: Boolean
  },
  setup (props, { emit }) {
    const store = useStore();
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const openModal = ref(false);
    watch(openModal, () => emit('update:open', openModal.value));
    const cantCloseForm = ref(false);
    const workTimes = ref([]);
    const lengthSchedule = ref(1);

    const setRequired = () => {
      const userName = get(props.user, 'firstName', '');
      if (!userName) return;
      cantCloseForm.value = true;
      openModal.value = true;
    };

    const getTemplate = () => {
      const { _id: teacher } = props.user;
      if (!teacher) return;
      setLoaderRun(true);
      ScheduleTemplatesApi.getSchedule(teacher).
        then(({ data }) => {
          const { length } = get(data, 'availableTime', []);
          lengthSchedule.value = length;
          if (!length) setTimeout(setRequired, 250);
          setLoaderRun(false);
        }).
        catch((err) => {
          setLoaderRun(false);
          setRequired();
          console.error(err);
        });
    };

    onBeforeMount(getTemplate);
    watch(props, () => {
      getTemplate();
      openModal.value = props.open;
    });

    const saveTemplate = () => {
      const { _id: teacher } = props.user;
      const body = {
        teacher,
        availableTime: workTimes.value.map((item) => {
          item.repeatable = true;
          return item;
        })
      };
      if (!body.availableTime.length) return;
      ScheduleTemplatesApi.createSchedule(body).
        then(() => {
          openModal.value = false;
        }).
        catch((err) => {
          console.error(err);
        });
    };

    return {
      workTimes,
      saveTemplate,
      openModal,
      cantCloseForm
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-template-timetable {
  @include global-font;

  &-body {
    padding: 14px 33px 0;

    &-explanation {
      display: grid;
      grid-gap: 8px;
      margin-bottom: 28px;
      color: $color-text;

      &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
      }

      &__details {
        max-width: 620px;
        font-weight: 500;
        font-size: 14px;
        line-height: 125%;
      }
    }
  }

  &-footer {
    padding: 33px 13px 0;
    width: 100%;
    display: grid;
    align-items: end;
    grid-gap: 20px;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    color: $color-text;

    &-legends {
      display: inline-grid;
      grid-template-columns: auto 1fr;
      grid-gap: 17px 20px;

      &-item {
        display: inline-grid;
        grid-template-columns: 30px auto;
        grid-gap: 8px;
        align-items: center;

        &__designation {
          border: 1px solid $color-alto;
          box-sizing: border-box;
          border-radius: 3px;
          width: 30px;
          height: 16px;

          &_work {
            background: $color-emerald;
            border: none;
          }
        }

        &__title {
          white-space: nowrap;
          font-weight: normal;
          font-size: 14px;
          line-height: 125%;
        }
      }

      &__management {
        grid-column: 1/3;
        grid-row: 2;
        font-weight: 500;
        font-size: 12px;
        line-height: 125%;
      }
    }
  }
}

</style>
