import { cloneDeep, uniqueId } from 'lodash';
import moment from 'moment';
import { reactive } from 'vue';

const inputs = [
  {
    autofocus: true,
    type: 'text',
    required: true,
    label: 'profile.forms.education.inputs.educationInst',
    modelName: 'educationInst',
    modelValue: '',
    upperFirst: true,
    emptyError: 'errors.emptyEducationInst',
    style: {
      gridColumn: '1/3',
      gridRow: '1'
    }
  },
  {
    type: 'text',
    required: true,
    label: 'profile.forms.education.inputs.specialization',
    modelName: 'specialization',
    modelValue: '',
    upperFirst: true,
    emptyError: 'errors.emptySpecialization',
    style: {
      gridColumn: '1/3',
      gridRow: '2'
    }
  },
  {
    type: 'text',
    format: 'number',
    required: true,
    label: 'profile.forms.education.inputs.start',
    modelName: 'fromDate',
    modelValue: '',
    validError: 'errors.notYear',
    emptyError: 'errors.notYear',
    isValid: (val) => {
      const date = moment(`${val}-01-01`);
      const isYear = String(val).length === 4;
      const noTooPast = date.isAfter(moment().subtract(80, 'year'));
      const notFuture = date.isBefore(moment());
      return date.isValid() && isYear && notFuture && noTooPast;
    },
    getValue: (val) => new Date(`${val}-01-01`),
    getFullObject: (val) => new Date(val).getFullYear(),
    style: {
      gridColumn: '1',
      gridRow: '3'
    }
  },
  {
    type: 'text',
    format: 'number',
    label: 'profile.forms.work.inputs.end',
    modelName: 'toDate',
    modelValue: '',
    validError: 'errors.notYear',
    overError: 'errors.dateStartOverEnd',
    emptyError: 'errors.notYear',
    isOver: 'fromDate',
    isValid: (val) => {
      const date = moment(`${val}-01-01`);
      const isYear = String(val).length === 4;
      const noTooPast = date.isAfter(moment().subtract(80, 'year'));
      const notFuture = date.isBefore(moment());
      return !val || (date.isValid() && isYear && notFuture && noTooPast);
    },
    getValue: (val) => (val ? new Date(`${val}-01-01`) : null),
    getFullObject: (val) => (val ? new Date(val).getFullYear() : null),
    style: {
      gridColumn: '2',
      gridRow: '3'
    }
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('profile-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  return item;
}));
