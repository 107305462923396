import { cloneDeep, uniqueId } from 'lodash';
import { reactive } from 'vue';
import subjects from '@/constants/subjects';
import i18n from '@/i18n';
import { getCountry, getSubjects } from '@/constants/utils/getValueById';

const allSubjects = {
  id: '',
  title: i18n.global.t('subjects.all')
};

const inputs = [
  {
    type: 'select',
    label: 'listTeachers.filters.subjects',
    modelName: 'subjects',
    modelValue: allSubjects,
    keyProp: 'title',
    options: [allSubjects].concat(subjects),
    getValue: (model) => model.id,
    getFullObject: (item) => getSubjects([item])[0]
  },
  {
    type: 'timesOfDay',
    label: 'listTeachers.filters.time',
    modelName: 'timesOfDay',
    single: true,
    modelValue: {
      times: [],
      days: []
    },
    placeholder: i18n.global.t('global.anyTime')
  },
  {
    type: 'range',
    label: 'listTeachers.filters.price',
    modelName: 'lessonCostRange',
    modelValue: [0, 0],
    maxValue: 0,
    format: '$0',
    keyProp: 'title',
    getFullObject: (item) => item.replace(/\[|\]/g, '').split(',').map((item) => +item)
  },
  {
    type: 'countrySelect',
    label: 'listTeachers.filters.country',
    modelName: 'country',
    modelValue: { name: i18n.global.t('country.all'), id: '' },
    displayAll: true,
    getValue: (model) => model.id,
    getFullObject: getCountry
  },
  {
    type: 'checkbox',
    label: 'listTeachers.filters.free',
    modelName: 'trialLessonAgreement',
    modelValue: false
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('filter-teacher-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  return item;
}));
