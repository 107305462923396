import i18n from '@/i18n';
import { fill } from 'lodash';
import moment from 'moment';

const days = fill(new Array(31), 1).map((el, i) => el + i);

const months = [
  i18n.global.t('months.january'),
  i18n.global.t('months.february'),
  i18n.global.t('months.march'),
  i18n.global.t('months.april'),
  i18n.global.t('months.may'),
  i18n.global.t('months.june'),
  i18n.global.t('months.july'),
  i18n.global.t('months.august'),
  i18n.global.t('months.september'),
  i18n.global.t('months.october'),
  i18n.global.t('months.november'),
  i18n.global.t('months.december')
];

const yearsAgo = 100;
const startYear = +moment().subtract(yearsAgo, 'years').format('YYYY');

const years = fill(new Array(yearsAgo + 1), startYear).map((el, i) => el + i).reverse();

export { days, months, years };
