<template>
  <label
    class="lp-upload"
    for="upload"
  >
    <input
      class="lp-upload__input"
      :accept="accept ? `${accept}/*` : '*'"
      type="file"
      @change="inputFile"
      id="upload"
    >
    <slot></slot>
  </label>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'UploadInput',
  props: {
    accept: String,
    maxSizeMB: Number
  },
  setup (props, { emit }) {
    const maxSizeBytes = computed(() => props.maxSizeMB * 1e+6);

    const inputFile = (e) => {
      const file = e.target.files[0];
      const { size, type } = file;
      if (!type.includes(props.accept)) {
        emit('update:format', true);
      } else if (size > maxSizeBytes.value) {
        emit('update:limit', true);
      } else {
        emit('update:file', file);
      }
    };

    return { inputFile };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-upload {
  @include global-font;
  position: relative;
  display: inline-block;

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

</style>
