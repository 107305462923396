<template>
  <div class="lp-date-picker-body">
    <div class="lp-date-picker-body-row">
      <div
        class="lp-date-picker-body-row__week"
        v-for="day of daysWeek"
        :key="day"
      >
        {{ day }}
      </div>
    </div>
    <div class="lp-date-picker-body-dates">
      <div
        class="lp-date-picker-body-dates__date"
        :class="{
          'lp-date-picker-body-dates__date_out': item.month !== month || item.year !== year,
          'lp-date-picker-body-dates__date_today': item.date === today,
          'lp-date-picker-body-dates__date_active': rangeDates.includes(item.date),
          'lp-date-picker-body-dates__date_include': includeDateInRange(item.date),
          'lp-date-picker-body-dates__date_disabled': item.disabled
        }"
        @mouseenter="setRange(item.date, $event)"
        :id="item.date"
        v-for="item of dates"
        :key="item.date"
        @click="selectDate(item.date)"
      >
        {{ item.day }}
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n';
import {
  computed, reactive, ref, onBeforeMount
} from 'vue';
import { fill } from 'lodash';
import moment from 'moment';

export default {
  name: 'DatePickerBody',
  props: {
    month: String,
    year: String,
    range: Array,
    minDate: [String, Date],
    currentDate: String,
    rangePicker: Boolean
  },
  setup (props, { emit }) {
    const formatISO = 'YYYY-MM-DD';
    const daysWeek = i18n.global.tm('global.daysWeek');
    const months = Object.values(i18n.global.tm('months'));
    const today = computed(() => moment().format(formatISO));
    const range = reactive([]);
    const rangeDates = computed(() => range.sort((a, b) => new Date(a) - new Date(b)));
    const hoverDate = ref('');

    const lowerLimit = computed(() => {
      const min = new Date(props.minDate || '1970');
      return moment(min).format(formatISO);
    });

    const getStartDate = () => {
      const week = fill(new Array(7), 1).map((el, i) => {
        const startDateMonth = moment(props.currentDate).startOf('month');
        return startDateMonth.subtract(i, 'd');
      });
      return week.find((date) => date.day() === 1);
    };

    const dates = computed(() => fill(new Array(35), 1).map((date, index) => {
      const startDate = getStartDate();
      const momentDate = startDate.add(index, 'd');
      const disabled = moment(lowerLimit.value).isAfter(momentDate);
      return {
        day: momentDate.format('D'),
        date: momentDate.format(formatISO),
        month: months[+momentDate.format('M') - 1],
        year: momentDate.format('YYYY'),
        disabled
      };
    }));

    const selectDate = (date, stop) => {
      if (date.disabled) return;
      hoverDate.value = '';
      if (range.length === 2 || 0 || !props.rangePicker) {
        range.splice(0, 2);
      }
      range.push(date);
      if ((range.length === 2 || !props.rangePicker) && !stop) {
        emit('update:dates', rangeDates.value);
        emit('update:open', false);
      }
    };

    const includeDateInRange = (date) => {
      const minSelected = new Date(rangeDates.value[0]);
      const maxSelected = new Date(rangeDates.value[1]);
      const enterDate = new Date(hoverDate.value);
      const currentDate = new Date(date);
      const minDate = currentDate >= minSelected;
      const maxDate = currentDate < maxSelected;
      const minDateHover = (enterDate <= currentDate) && currentDate < minSelected;
      const maxDateHover = (enterDate > currentDate) && minDate;
      return (minDate && maxDate) || minDateHover || maxDateHover;
    };

    const setRange = (date) => {
      if (range.length !== 1 || !props.rangePicker) return;
      hoverDate.value = date;
    };

    const setDefaultRange = () => {
      if (props.range[0]) {
        selectDate(props.range[0], true);
      }
      if (props.range[1]) {
        selectDate(props.range[1], true);
      }
    };

    onBeforeMount(setDefaultRange);

    return {
      today,
      daysWeek,
      dates,
      rangeDates,
      lowerLimit,
      selectDate,
      includeDateInRange,
      setRange
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-date-picker-body {
  &-dates {
    display: grid;
    grid-gap: 12px;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(7, 28px);
    grid-template-rows: repeat(5, 28px);

    &__date {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      width: 28px;
      padding: 4px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      color: $color-black;
      transition: 0.2s ease-out;
      cursor: pointer;
      user-select: none;
      border: 1px solid transparent;

      &:hover {
        background-color: $color-moon-raker;
      }

      &_disabled {
        color: $color-silver-chalice;
        pointer-events: none;
      }

      &_include {
        &:after {
          content: '';
          position: absolute;
          top: -1px;
          left: -1px;
          width: calc(100% + 14px);
          height: calc(100% + 2px);
          background-color: $color-moon-raker;
          z-index: -1;
        }
        background-color: $color-moon-raker;
      }

      &_out {
        color: $color-silver-chalice;
      }

      &_today {
        border-color: rgba($color-accent, 0.25);

        &:hover {
          background-color: $color-moon-raker;
        }
      }

      &_active {
        color: $color-white;
        background-color: $color-accent;
      }
    }
  }

  &-row {
    display: grid;
    grid-gap: 12px;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(7, 28px);

    &__week {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      margin-bottom: 16px;
      text-align: center;
      font-size: 14px;
      line-height: 125%;
      font-weight: bold;
      color: $color-black;
      text-transform: capitalize;
    }
  }
}

</style>
