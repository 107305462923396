import CURRENT_SERVER from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  getFile (id) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get(`upload-file/${id}`);
  },
  deleteFile (id) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.delete(`upload-file/${id}`);
  },
  uploadFile (file) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('upload-file/', file);
  }
};
