<template>
  <transition name="bounce">
    <div
      class="lp-tooltip"
      v-if="open"
    >
      <div class="lp-tooltip-content">
        <slot>
          {{ $t(text) }}
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: String,
    open: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-tooltip {
  @include global-font;
  position: absolute;
  top: auto;
  left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  background: rgba($color-mine-shaft, 0.9);
  border-radius: 2px;
  pointer-events: none;
  z-index: 10;

  &:after {
    content:'';
    display:block;
    width:0;
    height:0;
    position:absolute;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-top: 4px solid transparent;
    border-bottom:4px solid rgba($color-mine-shaft, 0.9);
    left: auto;
    top: -8px;
  }

  &-content {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    color: $color-white;
  }
}

</style>
