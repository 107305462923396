<template>
  <div class="lp-profile-student">
    <ProfileHeader
      class="lp-profile-student__header"
      :viewMode="true"
      :avatar="studentAvatar"
      :user="student"
    />
    <ProfileStats
      class="lp-profile-student__stats"
      :hide="['comments']"
      :user="student"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import {
  computed, onBeforeMount, ref, watch
} from 'vue';
import { useRouter } from 'vue-router';
import { find, get } from 'lodash';
import StudentsApi from '@/api/Students/api';
import ProfileHeader from '@/components/Profile/ProfileHeader/ProfileHeader';
import ProfileStats from '@/components/Profile/ProfileStats/ProfileStats';

export default {
  name: 'ProfileStudent',
  components: { ProfileStats, ProfileHeader },
  setup () {
    const store = useStore();
    const studentsList = computed(() => store.getters.studentsList);

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const router = useRouter();
    const studentId = computed(() => get(router, 'currentRoute.value.params.id', ''));

    const student = ref({});
    const studentAvatar = computed(() => get(student.value, 'uploadFile', {}));

    const setTitle = () => {
      const { firstName, lastName } = student.value;
      if (!firstName && !lastName) return;
      document.title = `${firstName} ${lastName}`;
    };

    const setStudent = () => {
      const teacherFromStore = find(studentsList.value, { _id: studentId.value });
      if (teacherFromStore) {
        student.value = teacherFromStore;
        setTitle();
        return;
      }
      if (!studentId.value) return;
      setLoaderRun(true);
      StudentsApi.getStudentById(studentId.value).
        then(({ data }) => {
          student.value = data;
          setTitle();
          studentsList.value.push(data);
          setLoaderRun(false);
        }).
        catch((err) => {
          console.error(err);
          setLoaderRun(false);
        });
    };
    watch(studentId, setStudent);
    onBeforeMount(setStudent);

    return { student, studentAvatar };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-profile-student {
  @include global-font;
  display: grid;
  grid-template-columns: 1fr 293px;
  grid-template-rows: repeat(3, auto);
  grid-gap: 20px 24px;
  cursor: default;

  &__header {
    grid-row: 1/end;
  }

  &__stats {
    grid-column: 2;
    grid-row: 1/end;
  }
}

</style>
