<template>
  <div class="lp-header">
    <div class="lp-header-logo">
      <logoIcon class="lp-header-logo__icon" />
    </div>
    <div
      class="lp-header-profile"
      v-if="activeUser._id"
      @click.stop="openMenu = !openMenu"
    >
      <img
        v-if="avatarUser.url"
        class="lp-header-profile__avatar"
        :src="avatarUser.url"
      />
      <userIcon
        v-else
        class="lp-header-profile__avatar"
      />
      <span class="lp-header-profile__username">
        {{ activeUser.firstName }} {{ activeUser.lastName }}
      </span>
    </div>
    <teleport to="body">
      <TooltipMenu
        class="lp-header-profile__tooltip"
        v-model:open="openMenu"
        :options="headerMenu"
      />
    </teleport>
  </div>
</template>

<script>
import { logoIcon, userIcon } from '@/constants/icons';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import TooltipMenu from '@/components/Main/Tooltip/TooltipMenu';
import i18n from '@/i18n';
import { VueCookieNext } from 'vue-cookie-next';
import { useRouter } from 'vue-router';

export default {
  name: 'Header',
  components: {
    TooltipMenu,
    logoIcon,
    userIcon
  },
  setup () {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const setActiveUser = (data) => store.dispatch('setActiveUser', data);
    const avatarUser = computed(() => store.getters.avatarUser);
    const setAvatarUser = (data) => store.dispatch('setAvatarUser', data);

    const openMenu = ref(false);

    const router = useRouter();
    const currentRouteName = computed(() => router.currentRoute.value.name);
    const routerPush = (route) => {
      if (route.name !== currentRouteName.value) router.push(route);
    };

    const logout = () => {
      VueCookieNext.removeCookie('token');
      setAvatarUser({});
      setActiveUser({});
      routerPush({ name: 'Login' });
    };
    const goProfile = () => {
      const type = activeUser.value.role;
      return { name: 'Profile', params: { type } };
    };

    const headerMenu = computed(() => ([
      {
        title: i18n.global.t('profile.header.menu.user'),
        isRoute: true,
        to: goProfile(),
        active: currentRouteName.value === 'Profile'
      },
      // {
      //   title: i18n.global.t('profile.header.menu.settings'),
      //   action: () => {},
      // },
      // {
      //   title: i18n.global.t('profile.header.menu.help'),
      //   action: () => {},
      // },
      {
        title: i18n.global.t('profile.header.menu.logout'),
        action: logout
      }
    ]));

    return {
      activeUser, avatarUser, openMenu, headerMenu
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-header {
  @include global-font;
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  padding: 15px 40px 15px 12px;
  background-color: $color-white;

  &-logo {
    display: inline-grid;
    align-items: center;
    justify-content: center;
  }

  &-profile {
    display: grid;
    grid-template-columns: 24px auto;
    grid-gap: 10px;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease-in;

    &:hover {
      opacity: 0.8;
    }

    &__avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
    }

    &__username {
      color: $color-text;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
    }

    &__tooltip {
      position: fixed;
      top: 62px;
      right: 26px;
      min-width: 204px;
      z-index: 10;
    }
  }
}

</style>
