<template>
  <perfect-scrollbar class="lp-balance-history-scroll">
    <div
      class="lp-balance-history"
      :class="{'lp-balance-history_list-empty': !isShow}"
    >
      <div
        class="lp-balance-history__title"
        :class="{'lp-balance-history__title_list-empty': !isShow}"
      >
        {{ $t(title) }}
      </div>
      <Table
        v-if="isShow"
        :rows="rows"
        :columns="columns"
      />
      <ListEmpty
        v-else
        :imageUrl="imageUrl"
        :widthImg="widthImg"
        :heightImg="heightImg"
        :title="titleListEmpty"
        :text="text"
        :description="description"
      />
    </div>
  </perfect-scrollbar>
</template>

<script>

import Table from '@/components/Main/Table/LPTable';
import ListEmpty from '@/components/Main/ListEmpty/ListEmpty';

export default {
  name: 'BalanceHistory',
  components: { ListEmpty, Table },
  props: {
    rows: Array,
    columns: Array,
    isShow: Boolean,
    title: String,
    imageUrl: String,
    widthImg: String,
    heightImg: String,
    titleListEmpty: String,
    text: String,
    description: String
  }
};

</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-balance-history {
  width: 100%;
  min-width: 600px;
  box-shadow: 0 0 15px rgba($color-black, 0.02);
  background-color: $color-white;
  padding: 0 40px 19px;
  position: sticky;

  &_list-empty {
    background-color: transparent;
  }

  &__title {
    font-weight: 800;
    font-size: 22px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    position: sticky;
    background-color: $color-white;
    top: 0;
    padding-top: 23px;
    padding-bottom: 19px;
    z-index: 5;

    &_list-empty {
      padding-bottom: 0;
      background-color: transparent;
    }
  }

  &-scroll {
    height: 100%;
  }
}

</style>
