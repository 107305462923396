import { uniqueId } from 'lodash';

const columns = [
  {
    title: 'paymentMethods.balanceHistory.date',
    prop: 'date',
    type: 'date',
    width: 'minmax(100px, 0.55fr)',
    pointer: false,
    showTime: true,
    formatDate: 'DD.MM.yyyy',
    formatTime: 'HH:mm'
  },
  {
    title: 'paymentMethods.balanceHistory.teacher',
    prop: {
      img: 'img',
      counterparty: 'counterparty'
    },
    type: 'image',
    width: 'minmax(100px, 0.95fr)',
    pointer: false
  },
  {
    title: 'paymentMethods.balanceHistory.typeOperation',
    prop: 'typeOperation',
    type: 'text',
    width: 'minmax(100px, 0.7fr)',
    pointer: false
  },
  {
    title: 'paymentMethods.balanceHistory.amount',
    prop: 'amount',
    type: 'amount',
    width: 'minmax(62px, 0.1fr)',
    pointer: false
  }
];

const listPaymentsStudent = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default listPaymentsStudent;
