import { uniqueId } from 'lodash';
import {
  walletIcon, calendarIcon, hatIcon, peopleIcon, classroomIcon, tabletSidebarIcon
} from '@/constants/icons';
import ROLE_TYPES from '@/constants/enums/roles';

const menu = [
  {
    title: 'sidebar.allTeachers',
    icon: peopleIcon,
    routeNames: ['Teachers'],
    route: {
      name: 'Teachers'
    },
    access: ROLE_TYPES.STUDENT
  },
  {
    title: 'sidebar.timetable',
    icon: calendarIcon,
    routeNames: ['UserTimetable'],
    route: {
      name: 'UserTimetable',
      params: {
        unit: 'week'
      }
    }
  },
  {
    title: 'sidebar.classroom',
    icon: classroomIcon,
    routeNames: ['Classroom'],
    route: {
      name: 'Classroom'
    }
  },
  {
    title: 'sidebar.myStudents',
    icon: hatIcon,
    routeNames: ['MyStudents'],
    route: {
      name: 'MyStudents'
    },
    access: ROLE_TYPES.TEACHER
  },
  {
    title: 'sidebar.materials',
    icon: tabletSidebarIcon,
    routeNames: [],
    route: {
      name: ''
    },
    access: ROLE_TYPES.TEACHER
  },
  {
    title: 'sidebar.myTeachers',
    icon: hatIcon,
    routeNames: ['MyTeachers'],
    route: {
      name: 'MyTeachers'
    },
    access: ROLE_TYPES.STUDENT
  },
  {
    title: 'sidebar.walletStudent',
    icon: walletIcon,
    routeNames: ['PaymentMethods'],
    route: {
      name: 'PaymentMethods'
    },
    access: ROLE_TYPES.STUDENT
  },
  {
    title: 'sidebar.walletTeacher',
    icon: walletIcon,
    routeNames: [],
    route: {
      name: ''
    },
    access: ROLE_TYPES.TEACHER
  }
];

export default menu.map((item) => {
  item.id = uniqueId('sidebar-menu-');
  return item;
});
