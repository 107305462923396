<template>
  <section class="lp-landing-price">
    <h2 class="lp-landing-price__title">
      Стоимость обучения
    </h2>
    <p class="lp-landing-price__description">
      Длительность 1 занятия — 50 минут.
    </p>
    <div class="lp-landing-price-list">
      <div
        class="lp-landing-price-list-item"
        :class="{
          'lp-landing-price-list-item_accent': tariff.accent,
          'lp-landing-price-list-item_base': tariff.base
        }"
        v-for="(tariff, index) of tariffs"
        :key="index"
      >
        <div class="lp-landing-price-list-item__title">
          {{ tariff.title }}
        </div>
        <div
          class="lp-landing-price-list-item__profit"
          v-if="tariff.profit"
        >
          {{ isRussian ? tariff.profitRub : tariff.profit }}
        </div>
        <div class="lp-landing-price-list-item__price">
          {{ isRussian ? tariff.priceRub : tariff.price }}
        </div>
        <div
          class="lp-landing-price-list-item__lesson"
          v-if="tariff.lesson"
        >
          {{ isRussian ? tariff.lessonRub : tariff.lesson }}
        </div>
        <div class="lp-landing-price-list-item__count">
          <miniCheckIcon />
          {{ tariff.count }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { miniCheckIcon } from '@/constants/icons/landing';
import { computed, inject } from 'vue';

export default {
  name: 'Price',
  components: { miniCheckIcon },
  setup () {
    const countryCode = inject('countryCode');
    const isRussian = computed(() => countryCode === 'RU');

    const tariffs = [
      {
        prefix: 'landing.price.base',
        title: 'Базовый',
        price: '$35',
        priceRub: '2500 ₽',
        count: '1 занятие',
        base: true
      },
      {
        prefix: 'landing.price.standard',
        title: 'Стандартный',
        profit: 'Вы экономите $21',
        price: '$119',
        lesson: 'Стоимость 1 урока $29.75',
        count: '4 занятия',
        accent: true,
        priceRub: '8800 ₽',
        profitRub: 'Вы экономите 1200 ₽',
        lessonRub: 'Стоимость 1 урока 2200 ₽'
      },
      {
        prefix: 'landing.price.premium',
        title: 'Премиум',
        profit: 'Вы экономите $81',
        price: '$199',
        lesson: 'Стоимость 1 урока $24.87',
        count: '8 занятий',
        priceRub: '14 800 ₽',
        profitRub: 'Вы экономите 5200 ₽',
        lessonRub: 'Стоимость 1 урока 1850 ₽'
      }
    ];

    return {
      tariffs,
      isRussian
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-landing-price {
  margin: 110px 0 152px;

  @media (max-width: 767px) {
    margin: 80px 0 107px;
  }

  &__title {
    margin: 0 auto 12px;
    font-weight: bold;
    font-size: 36px;
    line-height: 125%;
    text-align: center;
    color: $color-text;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  &__description {
    font-weight: normal;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    color: $color-text;
    margin-bottom: 40px;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  &-list {
    max-width: 949px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    @media (max-width: 1130px) {
      max-width: 80vw;
    }

    @media (max-width: 767px) {
      max-width: 100vw;
      flex-direction: column;
      grid-gap: 16px;
    }

    &-item {
      $this: &;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 24px 20px;
      background: $color-white;
      box-shadow: 0 0 33px rgba($color-black, 0.08);
      border-radius: 4px;

      &__title {
        margin-bottom: 6px;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        color: $color-text;
        text-transform: uppercase;
      }

      &__profit {
        margin-bottom: 24px;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: $color-red;
      }

      &__price {
        margin-bottom: 8px;
        font-weight: 800;
        font-size: 42px;
        line-height: 125%;
        text-align: center;
        color: $color-text;
      }

      &__lesson {
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        color: $color-text;
      }

      &__count {
        min-width: 238px;
        display: grid;
        grid-template-columns: 16px auto;
        align-items: center;
        justify-content: center;
        grid-gap: 8px;
        padding-top: 27px;
        font-weight: bold;
        font-size: 18px;
        line-height: 125%;
        color: $color-text;
        border-top: 1px solid $color-alto;

        @media (max-width: 1130px) {
          min-width: 170px;
        }
      }

      &_base {
        #{$this}__title {
          margin-bottom: 48px;
        }

        #{$this}__price {
          margin-bottom: 52px;
        }
      }

      &_accent {
        margin: 0 36px;
        padding: 24px 23px 32px;

        #{$this}__count {
          padding-top: 24px;
          min-width: 275px;

          @media (max-width: 1130px) {
            min-width: 204px;
          }
        }

        #{$this}__lesson {
          margin-bottom: 29px;
        }

        #{$this}__price {
          margin-bottom: 29px;
        }
      }

    }
  }
}

</style>
