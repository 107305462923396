import * as types from '@/constants/mutationTypes';

const state = {
  activeUser: {},
  avatarUser: {}
};
const getters = {
  activeUser: (state) => state.activeUser,
  avatarUser: (state) => state.avatarUser
};
const actions = {
  setActiveUser ({ commit }, data) {
    commit(types.SET_ACTIVE_USER, { data });
  },
  setAvatarUser ({ commit }, data) {
    commit(types.SET_AVATAR_USER, { data });
  }
};
const mutations = {
  [types.SET_ACTIVE_USER] (state, { data }) {
    state.activeUser = data;
  },
  [types.SET_AVATAR_USER] (state, { data }) {
    state.avatarUser = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
