<template>
  <div class="lp-payment-form">
    <div class="lp-payment-form-header">
      <div class="lp-payment-form-header__title">
        {{ $t('paymentMethods.modal.paymentCard') }}
      </div>
      <div class="lp-payment-form-header__icons">
        <visaIcon />
        <masterCardIcon />
      </div>
    </div>
    <div class="lp-payment-form-main">
      <CustomInput
        class="lp-payment-form-main__item"
        v-for="input of numberCard"
        :key="input.label"
        @focus="input.error = false"
        :maxlength="input.maxlength"
        v-bind="input"
        v-model="input.model"
      />
      <div class="lp-payment-form-main-footer">
        <CustomInput
          class="lp-payment-form-main__item lp-payment-form-main__item_action"
          v-for="input of actionTerm"
          :key="input.label"
          @focus="input.error = false"
          :maxlength="input.maxlength"
          v-bind="input"
          v-model="input.model"
        />
        <CustomInput
          class="lp-payment-form-main__item  lp-payment-form-main__item_security"
          v-for="input of securityCode"
          :key="input.label"
          @focus="input.error = false"
          :maxlength="input.maxlength"
          v-bind="input"
          v-model="input.model"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from '@/components/Main/Fields/CustomInput';
import { masterCardIcon, visaIcon } from '@/constants/icons';

export default {
  name: 'PaymentForm',
  components: {
    CustomInput
  },
  props: {
    numberCard: Array,
    actionTerm: Array,
    securityCode: Array
  },
  setup () {
    return {
      masterCardIcon,
      visaIcon
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-payment-form {
  @include global-font;
  color: $color-black;
  width: 448px;
  height: 271px;
  margin: 40px 34px 0;
  background: $color-alabaster;
  border: 1px solid $color-alto;
  box-sizing: border-box;
  border-radius: 5px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    border-bottom: 1px solid $color-alto;
    box-sizing: border-box;

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
    }

    &__icons {
      height: 20px;
      width: 87px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-main {
    padding: 0 20px;

    &__item {
      padding-top: 24px;

      &_action {
        padding-right: 5px;
      }

      &_security {
        padding-left: 5px;
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
