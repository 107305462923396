<template>
  <Modal
    title="profile.forms.work.title"
    :hideButtons="typeof open === 'number' ? [] : ['remove']"
    @save="saveForm"
    @remove="removeWork"
    v-model:open="openModal"
  >
    <Form
      class="lp-profile-form-work"
      @submit="saveForm"
      :inputs="profileWorkInputs"
    />
  </Modal>
</template>

<script>
import { ref, watch, computed } from 'vue';
import Modal from '@/components/Main/Modal/Modal';
import Form from '@/components/Main/Form/Form';
import { useStore } from 'vuex';
import profileWorkInputs from '@/constants/inputs/profileTeacherInputs/profileWorkInputs';
import {
  get, flattenDeep, uniq, cloneDeep
} from 'lodash';
import validateProfile from '@/constants/validatesForm/validateProfile';
import UserApi from '@/api/Users/api';
import moment from 'moment';

export default {
  name: 'ProfileFormWork',
  components: {
    Modal,
    Form
  },
  props: {
    user: Object,
    open: [Number, Boolean]
  },
  setup (props, { emit }) {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const setActiveUser = (data) => store.dispatch('setActiveUser', data);

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const openModal = ref(false);
    watch(openModal, () => emit('update:open', openModal.value));

    const setDefaultValues = () => {
      const work = get(props.user, ['work', props.open - 1], []);
      profileWorkInputs.forEach((input) => {
        const value = typeof props.open === 'number' ? get(work, input.modelName, input.defaultValue) : '';
        input.modelValue = input.getFullObject && value ? input.getFullObject(value) : value;
      });
    };

    watch(props, () => {
      if (props.open) setDefaultValues();
      openModal.value = props.open;
    });

    const updateUser = () => {
      setLoaderRun(true);
      UserApi.updateUser(activeUser.value).
        then(({ data }) => {
          setLoaderRun(false);
          setActiveUser(data);
          openModal.value = false;
        }).
        catch((err) => {
          setLoaderRun(false);
          console.error(err);
        });
    };

    const setWorkExperience = (allWork) => {
      const allYears = uniq(flattenDeep(allWork.map((item) => {
        const endDate = item.toDate ? new Date(item.toDate) : new Date();
        const startDate = new Date(item.fromDate);
        const years = +(endDate.getFullYear()) - +(startDate.getFullYear());
        return new Array(years + 1).fill(startDate).map((date, i) => +moment(date).add(i, 'year').format('YYYY'));
      })));
      const count = allYears.length - 2;
      const experience = count < 0 ? 0 : (count + 1 || 1);
      const user = cloneDeep(activeUser.value);
      user.workExperience = experience;
      setActiveUser(user);
    };

    const removeWork = () => {
      const allWork = get(activeUser, 'value.work', []);
      allWork.splice(props.open - 1, 1);
      setWorkExperience(allWork);
      updateUser();
    };

    const saveForm = () => {
      const body = validateProfile(profileWorkInputs);
      if (!body) return;
      const allWork = get(activeUser, 'value.work', []);
      if (typeof props.open === 'number') {
        allWork.splice(props.open - 1, 1, body);
      } else {
        allWork.push(body);
      }
      setWorkExperience(allWork);
      const user = cloneDeep(activeUser.value);
      user.work = allWork;
      setActiveUser(user);
      updateUser();
    };

    return {
      profileWorkInputs, openModal, saveForm, removeWork
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-profile-form-work {
  @include global-font;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 708px;
}

</style>
