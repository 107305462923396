import { createWebHistory, createRouter } from 'vue-router';
import Login from '@/components/Login/Login';
import Profile from '@/components/Profile/Profile';
import Teachers from '@/components/Admin/Teachers';
import ListTeachers from '@/components/ListTeachers/ListTeachers';
import ProfileTeacher from '@/components/ProfileTeacher/ProfileTeacher';
import Classroom from '@/components/Classroom/Classroom';
import MyStudents from '@/components/MyStudents/MyStudents';
import MyTeachers from '@/components/MyTeachers/MyTeachers';
import PaymentMethods from '@/components/PaymentMethods/PaymentMethods';
import UserTimetable from '@/components/UserTimetable/UserTimetable';
import ProfileStudent from '@/components/ProfileStudent/ProfileStudent';
import ClassroomVideo from '@/components/Classroom/ClassroomVideo';
import i18n from '@/i18n';
import ROLE_TYPES from '@/constants/enums/roles';
import Landing from '@/components/Landing/Landing';

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      noRedirect: true,
      title: i18n.global.t('titles.landing')
    }
  },
  {
    path: '/login/',
    name: 'Login',
    component: Login,
    meta: {
      title: i18n.global.t('titles.login')
    }
  },
  {
    path: '/mainvoice',
    name: 'Mainvoice',
    component: Teachers,
    meta: {
      title: i18n.global.t('titles.admin')
    }
  },
  {
    path: '/newteacher/',
    name: 'NewTeacher',
    component: Login,
    meta: {
      role: ROLE_TYPES.TEACHER,
      title: i18n.global.t('titles.joinTeacher'),
      noRedirect: true
    }
  },
  {
    path: '/newstudent/',
    name: 'NewStudent',
    component: Login,
    meta: {
      role: ROLE_TYPES.STUDENT,
      title: i18n.global.t('titles.joinStudent'),
      noRedirect: true
    }
  },
  {
    path: '/profile/:type',
    name: 'Profile',
    component: Profile,
    meta: {
      title: i18n.global.t('titles.profile')
    }
  },
  {
    path: '/teachers/',
    name: 'Teachers',
    component: ListTeachers,
    meta: {
      title: i18n.global.t('titles.listTeachers'),
      noRedirect: true
    }
  },
  {
    path: '/teacher/:id',
    name: 'ProfileTeacher',
    component: ProfileTeacher,
    meta: {
      noRedirect: true
    }
  },
  {
    path: '/student/:id',
    name: 'ProfileStudent',
    component: ProfileStudent
  },
  {
    path: '/my-timetable/:unit',
    name: 'UserTimetable',
    component: UserTimetable,
    meta: {
      title: i18n.global.t('titles.timetable'),
      redirect: 'NewStudent'
    }
  },
  {
    path: '/class/',
    name: 'Classroom',
    component: Classroom,
    meta: {
      title: i18n.global.t('titles.classroom'),
      redirect: 'NewStudent'
    }
  },
  {
    path: '/MyStudents/',
    name: 'MyStudents',
    component: MyStudents,
    meta: {
      title: i18n.global.t('titles.myStudents'),
      redirect: 'MyStudents'
    }
  },
  {
    path: '/MyTeachers/',
    name: 'MyTeachers',
    component: MyTeachers,
    meta: {
      title: i18n.global.t('titles.myTeachers'),
      redirect: 'MyTeachers'
    }
  },
  {
    path: '/PaymentMethods/',
    name: 'PaymentMethods',
    component: PaymentMethods,
    meta: {
      title: i18n.global.t('titles.PaymentMethods'),
      noRedirect: true
    }
  },
  {
    path: '/class/connect/:lessonId',
    name: 'ClassroomConnect',
    component: ClassroomVideo,
    meta: {
      title: i18n.global.t('titles.classroom'),
      redirect: 'NewStudent'
    }
  },
  {
    path: '/:catchAll(.*)',
    component: Profile,
    meta: {
      title: i18n.global.t('titles.profile')
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
