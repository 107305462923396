<template>
  <div
    class="lp-select"
    v-click-outside="closeDropList"
  >
    <span
      class="lp-label lp-select__label"
      :class="{
        'lp-label_required': required,
        'lp-label_required_without-label': required && withoutLabel
      }"
    >
      {{ withoutLabel ? '' : $t(label || 'placeholder.select') }}
    </span>
    <div
      ref="selectHeader"
      class="lp-select-header lp-input"
      :class="{'lp-input_error': error}"
      @click="changeOpenDropList(!openDropList)"
    >
      <slot
        :keyProp="keyProp"
        :value="selectedValue"
        name="header"
      >
        {{ selectedValue[keyProp] || placeholder }}
      </slot>
      <input
        v-if="searchable && openDropList"
        @keyup.esc="closeDropList"
        @click.stop
        v-focus="openDropList"
        class="lp-select-header__input"
        :class="{
          'lp-select-header__input_start-search': openDropList,
          'lp-select-header__input_searching': searchItem
        }"
        type="text"
        v-model="searchItem"
      >
      <arrowIcon
        class="lp-select-header__icon"
        :class="{'lp-select-header__icon_open': openDropList}"
      />
      <transition name="bounce">
        <span
          class="lp-error"
          v-if="error && typeof error === 'string'"
        >
          <dangerIcon />
          {{ $t(error) }}
        </span>
      </transition>
    </div>
    <transition name="slide-select">
      <perfect-scrollbar
        class="lp-select-list"
        @click.stop
        :options="scrollbarSettings"
        :style="fixed ? getStyleDropList() : {}"
        v-if="openDropList"
      >
        <div
          class="lp-select-list-item"
          v-for="(item, index) of currentOptions"
          :key="`key-${index}-${item.keyProp}`"
          @click.stop="setSelected(item)"
        >
          <slot
            name="item"
            :keyProp="keyProp"
            :item="item"
          >
            {{ item[keyProp] }}
          </slot>
        </div>
      </perfect-scrollbar>
    </transition>
  </div>
</template>

<script>
import { ref, onBeforeMount, watch } from 'vue';
import { arrowIcon, dangerIcon } from '@/constants/icons';
import { cloneDeep } from 'lodash';

export default {
  name: 'CustomSelect',
  components: {
    arrowIcon,
    dangerIcon
  },
  props: {
    keyProp: String,
    options: Array,
    label: String,
    modelValue: Object,
    required: Boolean,
    searchable: Boolean,
    error: [Boolean, String],
    withoutLabel: Boolean,
    fixed: Boolean,
    placeholder: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const scrollbarSettings = {
      wheelPropagation: false,
      minScrollbarLength: 25
    };

    const currentOptions = ref(cloneDeep(props.options));
    watch(props, () => {
      currentOptions.value = cloneDeep(props.options);
    });

    const openDropList = ref(false);
    const changeOpenDropList = (val) => {
      openDropList.value = val;
      emit('focus');
    };
    const closeDropList = () => {
      openDropList.value = false;
    };

    const selectHeader = ref(null);
    const getStyleDropList = () => {
      if (!selectHeader.value) return {};
      const position = selectHeader.value.getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${4 + position.top + position.height}px`,
        left: `${position.left}px`,
        width: `${position.width}px`
      };
    };

    const searchItem = ref('');
    const filterOptions = () => {
      if (!searchItem.value) {
        currentOptions.value = cloneDeep(props.options);
      } else {
        const search = searchItem.value.toLowerCase();
        const result = cloneDeep(props.options).filter((item) => {
          const value = String(item[props.keyProp]).toLowerCase();
          return value.includes(search);
        });
        const reg = new RegExp(`^${search}`, 'i');
        result.sort((a, b) => reg.test(b[props.keyProp]) - reg.test(a[props.keyProp]));
        currentOptions.value = result;
      }
    };
    watch(searchItem, filterOptions);

    const selectedValue = ref({});
    const setSelected = (item) => {
      if (!item) return;
      searchItem.value = '';
      openDropList.value = false;
      selectedValue.value = item;
      emit('update:modelValue', item);
    };

    onBeforeMount(() => {
      setSelected(props.modelValue);
    });

    watch(() => props.modelValue, () => {
      setSelected(props.modelValue);
    });

    return {
      scrollbarSettings,
      selectedValue,
      openDropList,
      selectHeader,
      searchItem,
      currentOptions,
      getStyleDropList,
      setSelected,
      changeOpenDropList,
      closeDropList
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-select {
  @include global-font;
  position: relative;
  display: grid;
  grid-gap: 4px;
  color: $color-text;

  &-header {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: auto 23px;
    height: 100%;
    min-height: 42px;
    padding: 0 14px 0 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    transition: 0.3s ease-in;

    &:hover &__icon{
      opacity: 0.8;
    }

    &__icon {
      justify-self: end;
      padding: 11px;
      margin: -11px;
      grid-column: 2;
      box-sizing: content-box;
      transform: rotate(180deg);
      transition: 0.15s ease-in;

      &_open {
        transform: rotate(0deg);
      }
    }

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      padding: 11px 14px 11px 12px;
      width: 100%;
      height: 100%;
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      opacity: 0;
      border: none;

      &_start-search {
        opacity: 0.5;
      }

      &_searching {
        opacity: 1;
      }
    }
  }

  &-list {
    @include global-font;
    position: absolute;
    top: 65px;
    left: 0;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    width: 100%;
    height: auto;
    padding: 4px 0;
    background: $color-white;
    box-shadow: 0 0 0 1px $color-wild-sand, 0 0 15px rgba($color-black, 0.05);
    border-radius: 6px;
    z-index: 100;
    overflow: hidden;
    max-height: 190px;

    &-item {
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      margin: 0 8px;
      background-color: $color-white;
      transition: 0.3s ease-in;
      cursor: pointer;

      &:hover {
        color: $color-accent;
        background-color: $color-moon-raker;
      }
    }
  }
}

</style>
