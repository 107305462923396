<template>
  <div class="lp-countdown">
    <div
      class="lp-countdown-item"
      :style="{gridColumn: `${unit.position} / ${unit.position + 1}`}"
      v-for="unit of countdown"
      :key="unit.text"
    >
      <div class="lp-countdown-item__value">
        {{ unit.num }}
      </div>
      <div class="lp-countdown-item__title">
        {{ unit.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, reactive } from 'vue';
import moment from 'moment';
import numeral from 'numeral';
import i18n from '@/i18n';
import { forEach } from 'lodash';
import declensionOfNum from '@/constants/utils/declensionOfNum';

export default {
  name: 'Countdown',
  props: {
    deadline: String
  },
  setup (props, { emit }) {
    const countdown = reactive({
      seconds: {
        position: 4,
        num: 0,
        text: '',
        declension: 'global.declensionSeconds'
      },
      minutes: {
        position: 3,
        num: 0,
        text: '',
        declension: 'global.declensionMinutes'
      },
      hours: {
        position: 2,
        num: 0,
        text: '',
        declension: 'global.declensionHours'
      },
      days: {
        position: 1,
        num: 0,
        text: '',
        declension: 'global.declensionDays'
      }
    });

    const intervals = {
      second: 1000,
      minute: 1000 * 60,
      hour: 1000 * 60 * 60,
      day: 1000 * 60 * 60 * 24
    };

    const setDeclensions = () => {
      forEach(countdown, (item) => {
        const { declension } = item;
        item.text = declensionOfNum(item.num, i18n.global.tm(declension));
      });
    };

    const updateTime = () => {
      const now = moment();
      const end = moment(props.deadline);
      let diff = end.diff(now, 'millisecond');
      if (diff <= 0) emit('update:expired', true);
      countdown.days.num = numeral(Math.floor(diff / intervals.day)).format('00');
      diff -= countdown.days.num * intervals.day;
      countdown.hours.num = numeral(Math.floor(diff / intervals.hour)).format('00');
      diff -= countdown.hours.num * intervals.hour;
      countdown.minutes.num = numeral(Math.floor(diff / intervals.minute)).format('00');
      diff -= countdown.minutes.num * intervals.minute;
      countdown.seconds.num = numeral(Math.floor(diff / intervals.second)).format('00');
      setDeclensions();
    };

    const interval = setInterval(() => {
      updateTime();
    }, 1000);
    onBeforeUnmount(() => {
      clearInterval(interval);
    });

    return { countdown };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-countdown {
  display: inline-grid;
  grid-template-columns: repeat(4, 109px);
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
  padding: 19px 2px;
  background-color: $color-white;
  box-shadow: 0 0 18px rgba($color-black, 0.05);
  max-width: 450px;
  margin: auto;

  &-item {
    position: relative;
    display: grid;
    grid-gap: 4px;
    align-items: center;
    justify-content: center;
    grid-row: 1;
    border-right: 1px solid $color-alto;
    text-align: center;
    color: $color-text;

    &:first-child {
      border-right: none;
    }

    &__value {
      font-weight: 800;
      font-size: 38px;
      line-height: 51px;
    }

    &__title {
      grid-row: 2;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

</style>
