import * as types from '@/constants/mutationTypes';

const state = {
  teachersFavoritesList: []
};
const getters = {
  teachersFavoritesList: (state) => state.teachersFavoritesList
};
const actions = {
  setTeachersFavoritesList ({ commit }, data) {
    commit(types.SET_TEACHERS_FAVORITES_LIST, { data });
  }
};
const mutations = {
  [types.SET_TEACHERS_FAVORITES_LIST] (state, { data }) {
    state.teachersFavoritesList = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
