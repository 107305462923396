<template>
  <Modal
    title="profile.forms.basic.title"
    :hideButtons="['remove']"
    :cantClose="cantCloseForm"
    @save="saveForm"
    v-model:open="openModal"
  >
    <div class="lp-profile-form-basic">
      <div class="lp-profile-form-basic-header">
        <img
          v-if="fileUrl || originalFileUrl"
          class="lp-profile-form-basic-header__image"
          :src="fileUrl || originalFileUrl"
        />
        <userIcon
          v-else
          class="lp-profile-form-basic-header__image"
        />
        <div
          class="lp-profile-form-basic-header-info"
          :class="{'lp-profile-form-basic-header-info_error': fileErrors.limitExceeded || fileErrors.notImage}"
        >
          <span class="lp-profile-form-basic-header-info__text">
            {{ $t('info.uploadPhoto') }}
          </span>
          <span
            class="lp-profile-form-basic-header-info__text lp-profile-form-basic-header-info__text_format"
            v-if="fileErrors.notImage"
          >
            {{ $t('errors.notImage') }}
          </span>
        </div>
        <div class="lp-profile-form-basic-header-upload">
          <UploadInput
            class="lp-button lp-button_border lp-button_small"
            v-if="!fileUrl && !originalFileUrl"
            accept="image"
            :maxSizeMB="10"
            v-model:limit="fileErrors.limitExceeded"
            v-model:format="fileErrors.notImage"
            v-model:file="file"
          >
            {{ $t('buttons.upload') }}
          </UploadInput>
          <template v-else-if="fileUrl || originalFileUrl">
            <UploadInput
              class="lp-button lp-button_small"
              accept="image"
              :maxSizeMB="10"
              v-model:limit="fileErrors.limitExceeded"
              v-model:format="fileErrors.notImage"
              v-model:file="file"
            >
              {{ $t('buttons.change') }}
            </UploadInput>
            <button
              class="lp-button lp-button_border lp-button_small"
              @click.stop="removeFile()"
            >
              {{ $t('buttons.remove') }}
            </button>
          </template>
        </div>
      </div>
      <h3
        class="lp-profile-form-basic__title lp-profile-form-basic__title_top"
        v-if="!isStudent && !user.firstName"
      >
        {{ $t('profile.sections.about') }}
      </h3>
      <Form
        class="lp-profile-form-basic__form"
        @help="onHelp"
        @submit="saveForm"
        :inputs="inputs"
      />
      <div
        class="lp-profile-form-basic-price"
        v-if="!isStudent && !user.firstName"
      >
        <h3 class="lp-profile-form-basic__title">
          {{ $t('profile.sections.price') }}
        </h3>
        <ProfileFormPrice
          :trial="trial"
          :price="price"
          :error="errorPrice"
          v-model:error="errorPrice"
          v-model:trial="trial"
          v-model:price="price"
        />
      </div>
    </div>
    <transition name="bounce">
      <span
        class="lp-profile-form-basic__error lp-error"
        v-if="errorText"
      >
        <dangerIcon />
        {{ $t(errorText) }}
      </span>
    </transition>
    <transition name="fade">
      <CropperImage
        v-if="openCropper"
        :image="fileUrl"
        :file="file"
        v-model:file="file"
        v-model:open="openCropper"
      />
    </transition>
  </Modal>
</template>

<script>
import {
  ref, reactive, watch, computed, onBeforeMount
} from 'vue';
import Form from '@/components/Main/Form/Form';
import profileBasicInputs from '@/constants/inputs/profileTeacherInputs/profileBasicInputs';
import profileBasicStudentInputs from '@/constants/inputs/profileStudentInputs/profileBasicStudentInputs';
import { userIcon, dangerIcon } from '@/constants/icons';
import Modal from '@/components/Main/Modal/Modal';
import validateProfile from '@/constants/validatesForm/validateProfile';
import UserApi from '@/api/Users/api';
import FilesApi from '@/api/Files/api';
import LocationApi from '@/api/Location/api';
import getFileBinary from '@/constants/utils/getFileBinary';
import { useStore } from 'vuex';
import { get, find, cloneDeep } from 'lodash';
import ROLE_TYPES from '@/constants/enums/roles';
import UploadInput from '@/components/Main/Fields/UploadInput';
import { getErrorText } from '@/constants/utils/setErrorText';
import ProfileFormPrice from '@/components/Profile/ProfileForms/ProfileFormPrice';
import country from '@/constants/country';
import timeout from '@/constants/utils/timeout';
import CropperImage from '@/components/Main/CropperImage/Cropper';

export default {
  name: 'ProfileFormBasic',
  components: {
    CropperImage,
    ProfileFormPrice,
    UploadInput,
    Modal,
    Form,
    userIcon,
    dangerIcon
  },
  props: {
    user: Object,
    avatar: Object,
    open: Boolean,
    cantCloseForm: Boolean,
    recommendation: Boolean
  },
  setup (props, { emit }) {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const setActiveUser = (data) => store.dispatch('setActiveUser', data);

    const isStudent = computed(() => activeUser.value.role === ROLE_TYPES.STUDENT);

    const inputs = computed(() => {
      if (isStudent.value) return profileBasicStudentInputs;
      return profileBasicInputs;
    });

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const openModal = ref(false);
    watch(openModal, () => emit('update:open', openModal.value));

    const trial = ref(true);
    const price = ref(0);
    const errorPrice = ref(false);
    const currentCountry = ref({});
    const geoCoordinates = ref({});
    const watchPosition = ref(null);

    const navigatorOptions = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0
    };

    const navigatorError = (err) => {
      console.error(err);
    };

    const navigatorSuccess = ({ coords }) => {
      const { latitude, longitude } = geoCoordinates.value;
      if (latitude && longitude) return;
      geoCoordinates.value = coords;
      navigator.geolocation.clearWatch(watchPosition.value);
    };

    const setGeoCoordinates = () => {
      watchPosition.value = navigator.geolocation.watchPosition(navigatorSuccess, navigatorError, navigatorOptions);
      navigator.geolocation.getCurrentPosition(navigatorSuccess, navigatorError);
    };

    const getLocation = async () => {
      if (activeUser.value.country || !geoCoordinates.value.latitude) return;
      try {
        const { data } = await LocationApi.getLocation(geoCoordinates.value);
        const code = get(data, 'address.country_code', 'unknown').toUpperCase();
        currentCountry.value = find(country, { code }) || {};
      } catch (err) {
        console.error(err);
      }
    };

    const setDefaultValues = async () => {
      if (!activeUser.value._id) return;
      await getLocation();
      inputs.value.forEach((input) => {
        if (input.modelName === 'country' && currentCountry.value.id) {
          input.defaultValue = currentCountry.value.id;
        }
        const value = get(props.user, input.modelName, input.defaultValue);
        input.modelValue = input.getFullObject ? input.getFullObject(value) : value;
      });
    };

    const openCropper = ref(false);
    const originalFileUrl = ref('');
    const file = ref(null);
    watch(file, () => {
      openCropper.value = openCropper.value ? false : !!file.value;
    });
    const fileUrl = computed(() => (file.value ? URL.createObjectURL(file.value) : ''));
    const setDefaultFile = async () => {
      file.value = null;
      originalFileUrl.value = get(props.avatar, 'url', '');
    };
    const fileErrors = reactive({
      limitExceeded: false,
      notImage: false
    });
    const removeFile = () => {
      originalFileUrl.value = '';
      file.value = null;
    };

    const resetLimit = () => {
      fileErrors.limitExceeded = false;
      fileErrors.notImage = false;
    };

    watch(() => props.avatar, setDefaultFile);
    watch(() => props.open, () => {
      openModal.value = props.open;
      if (openModal.value) setGeoCoordinates();
      if (!openModal.value) file.value = null;
    });
    watch(() => props.user, setDefaultValues);
    watch(geoCoordinates, setDefaultValues);
    watch(fileErrors, () => setTimeout(resetLimit, 2000));

    onBeforeMount(() => {
      setDefaultFile();
      setDefaultValues();
    });

    const errorText = ref('');

    const uploadFile = async (file) => {
      errorText.value = '';
      if (!file) {
        return;
      }
      const fileBinary = getFileBinary(file);
      try {
        const { data } = await FilesApi.uploadFile(fileBinary);
        activeUser.value.uploadFile = data;
        store.dispatch('setAvatarUser', data);
        await setDefaultFile();
      } catch (err) {
        console.error(err);
        errorText.value = getErrorText(err);
      }
    };

    const saveForm = async () => {
      errorText.value = '';
      errorPrice.value = false;
      const body = await validateProfile(inputs.value);
      errorPrice.value = !price.value;
      const emptyPrice = !price.value && !isStudent.value && !props.user.firstName;
      if (!body || emptyPrice) return;
      if (!props.user.firstName) body.lessonCost = price.value;
      body.trialLessonAgreement = trial.value;
      await setLoaderRun(true);
      await uploadFile(file.value);
      try {
        const file = originalFileUrl.value ? props.avatar : null;
        const user = Object.assign(cloneDeep(activeUser.value), body);
        user.uploadFile = get(file, '_id', null);
        const { data } = await UserApi.updateUser(user);
        await timeout(500);
        await setActiveUser(data);
        openModal.value = false;
        data.uploadFile = file;
        await setLoaderRun(false);
      } catch (err) {
        await setLoaderRun(false);
        console.error(err);
        errorText.value = getErrorText(err);
      }
    };

    const onHelp = ({ modelName }) => {
      if (modelName === 'presentationUrl') emit('update:recommendation', true);
    };

    return {
      inputs,
      isStudent,
      openModal,
      removeFile,
      originalFileUrl,
      file,
      fileUrl,
      fileErrors,
      saveForm,
      onHelp,
      errorText,
      trial,
      price,
      errorPrice,
      openCropper
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-profile-form-basic {
  @include global-font;
  position: relative;

  &__title {
    font-weight: 800;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $color-text;

    &_top {
      padding: 0 32px;
      margin-bottom: -8px;
    }
  }

  &__error {
    bottom: -12px;
    top: auto;
    left: 32px;
  }

  &-header {
    display: grid;
    grid-gap: 12px 24px;
    grid-template-columns: 150px auto;
    grid-template-rows: repeat(2, auto);
    padding: 28px 32px 20px;

    &__image {
      grid-row: 1/end;
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 50%;
    }

    &-info {
      position: relative;
      color: $color-dove-gray;
      transition: 0.2s ease-out;

      &__text {
        display: block;
        width: 100%;
        font-weight: bold;
        font-size: 14px;
        line-height: 125%;

        &_format {
          position: absolute;
          top: 0;
          left: 0;
          background-color: $color-white;
        }
      }

      &_error {
        color: $color-red;
        animation: shake 0.5s ease-out;
      }
    }

    &-upload {
      display: flex;
      align-items: center;
      grid-gap: 8px;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 708px;
  }

  &-price {
    display: grid;
    grid-gap: 18px;
    margin-top: 28px;
    padding: 28px 32px 0;
    border-top: 1px solid $color-alto;
  }
}

@keyframes shake {
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}

</style>
