<template>
  <section class="lp-landing-main">
    <h1 class="lp-landing-main__title">
      Вокальная онлайн-школа MUSIO
    </h1>
    <p class="lp-landing-main-info">
      Записывайтесь на
      <span class="lp-landing-main-info__accent">бесплатное пробное занятие!</span>
      Действуют
      <span class="lp-landing-main-info__accent">скидки</span>
      на абонементы от 4 уроков.
    </p>
    <div class="lp-landing-main__button">
      <button
        class="lp-button"
        @click.stop="$emit('update:openForm', true)"
      >
        {{ $t('landing.main.action') }}
      </button>
    </div>
    <div class="lp-landing-main-preview">
      <div class="lp-landing-main-preview__wrapper">
        <img
          class="lp-landing-main-preview__videocall"
          :src="previewImg"
          alt="video call"
        />
        <img
          class="lp-landing-main-preview__player"
          :src="playerImg"
          alt="music player"
        />
        <div class="lp-landing-main-preview__star">
          <personageIcon />
        </div>
      </div>
    </div>
    <Facts />
  </section>
</template>

<script>
import previewImg from '@/assets/images/landing/videocall.jpg';
import playerImg from '@/assets/images/landing/player.png';
import { personageIcon } from '@/constants/icons/landing';
import Facts from '@/components/Landing/Main/Facts';

export default {
  name: 'Main',
  components: {
    Facts,
    personageIcon
  },
  emits: ['update:openForm'],
  setup () {
    return {
      playerImg,
      previewImg
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-landing-main {
  @include global-font;
  margin-top: 116px;
  margin-bottom: 150px;

  @media (max-width: 767px) {
    margin-top: 48px;
    margin-bottom: 100px;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 118%;
    text-align: center;
    color: $color-text;
    margin-bottom: 16px;

    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 118%;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    margin: 0 auto 60px;
  }

  &-info {
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
    text-align: center;
    color: $color-black;
    margin-bottom: 36px;

    @media (max-width: 1080px) {
      max-width: 80vw;
      margin: 0 auto 36px;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 125%;
    }

    &__accent {
      color: $color-accent;
      margin-bottom: 36px;
    }
  }

  &-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 152px;

    @media (max-width: 767px) {
      margin-bottom: 50px;
    }

    &__wrapper {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &__videocall {
      width: 1084px;
      min-height: 650px;
      height: auto;
      object-fit: cover;
      z-index: 2;
      animation: displayVideoCall 0.4s ease-in;
      animation-fill-mode: forwards;
      animation-delay: 0.5s;
      opacity: 0;
      transform: translateX(-100%);

      @media (max-width: 1130px) {
        min-height: auto;
        padding-right: 10%;
        width: calc(100% - 20px);
      }

      @media (max-width: 767px) {
        animation: none;
        opacity: 1;
        transform: translateX(0);
      }

      @keyframes displayVideoCall {
        from {
          transform: translateX(-100%);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    &__player {
      position: absolute;
      top: 232px;
      right: -128px;
      width: 192px;
      height: auto;
      object-fit: cover;
      z-index: 1;

      @media (max-width: 1350px) {
        display: none;
      }
    }

    &__star {
      position: absolute;
      bottom: -41px;
      right: -81px;
      z-index: 3;
      animation: displayStar 1s cubic-bezier(0.75, -0.5, 0.3, 1.5);
      animation-fill-mode: forwards;
      animation-delay: 1s;
      opacity: 0;
      transform: scale(0);

      @media (max-width: 1250px) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 23vw;
        width: 23.698vw;
        bottom: -4%;
        right: 20px;
      }

      @media (max-width: 767px) {
        animation: none;
        opacity: 1;
        transform: scale(1);
      }

      @keyframes displayStar {
        from {
          transform: scale(0);
          opacity: 0;
        }
        to {
          transform: scale(1);
          opacity: 1;
        }
      }
    }

  }
}

</style>
