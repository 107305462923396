<template>
  <div class="lp-teachers">
    <div
      class="lp-teachers-header"
      ref="headerElList"
    >
      <h2 class="lp-teachers-header__title lp-section-title lp-section-title_large">
        {{ $t('listTeachers.title') }}
      </h2>
      <span class="lp-teachers-header__found">
        {{ foundText }}
      </span>
    </div>
    <div
      class="lp-teachers-filters"
      ref="filterElList"
    >
      <Form
        class="lp-teachers-filters__form"
        v-if="readyForm"
        :inputs="filterTeachersInputs"
      />
      <div class="lp-teachers-filters__padding" />
    </div>
    <div class="lp-teachers-list">
      <transition name="bounce">
        <div
          class="lp-teachers-list-no-result"
          v-if="!foundCount"
        >
          <div class="lp-teachers-list-no-result__icon">
            <noResult />
          </div>
          <div class="lp-teachers-list-no-result__title">
            {{ $t('listTeachers.noResults.title') }}
          </div>
          <div class="lp-teachers-list-no-result__note">
            {{ $t('listTeachers.noResults.note') }}
          </div>
        </div>
      </transition>
      <InfiniteScroll
        :height="heightList"
        :loader="loaderPage"
        :distance="5000"
        :page="page"
        :limit="limitDefault"
        :total="foundCount"
        :length="teachersList.length"
        :request="findByFilters"
        v-model:page="page"
        v-model:index="hideIndex"
      >
        <div
          class="lp-teachers-list__card"
          :id="teacher._id"
          v-for="(teacher) of teachersList"
          :key="teacher._id"
        >
          <ListTeachersCard
            :date="queryDate"
            :teacher="teacher"
          />
        </div>
      </InfiniteScroll>
    </div>
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  watch
} from 'vue';
import declensionOfNum from '@/constants/utils/declensionOfNum';
import filterTeachersInputs from '@/constants/inputs/filterTeachersInputs';
import i18n from '@/i18n';
import Form from '@/components/Main/Form/Form';
import ListTeachersCard from '@/components/ListTeachers/ListTeachersCard';
import TeachersApi from '@/api/Teachers/api';
import { useStore } from 'vuex';
import {
  get, isEqual, debounce, uniq, last
} from 'lodash';
import { useRouter } from 'vue-router';
import moment from 'moment';
import { noResult } from '@/constants/icons';
import timeout from '@/constants/utils/timeout';
import InfiniteScroll from '@/components/Main/InfiniteScroll/InfiniteScroll';

export default {
  name: 'ListTeachers',
  components: {
    InfiniteScroll, ListTeachersCard, Form, noResult
  },
  setup () {
    const router = useRouter();
    const queryDate = computed(() => get(router.currentRoute.value, 'query.lessonDate', ''));

    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const page = ref(1);
    const loaderPage = ref(false);
    const teachersList = computed(() => store.getters.teachersList);
    const setTeachersList = (data) => store.dispatch('setTeachersList', data);
    const setTeacherListAsync = async (data = []) => {
      data.forEach((teacher, index) => {
        setTimeout(() => {
          teachersList.value.push(teacher);
        }, index * 50);
      });
    };

    const setCountries = (countries = []) => {
      const countryInput = filterTeachersInputs.find((item) => item.modelName === 'country') || {};
      const currentCountries = countryInput.listFilter || [];
      if (isEqual(currentCountries.sort(), countries.sort())) return;
      countryInput.listFilter = countries;
    };

    const foundCount = ref(1);
    const foundText = computed(() => {
      const foundDecl = declensionOfNum(foundCount.value, i18n.global.tm('global.declensionFound'));
      const teachersDecl = declensionOfNum(foundCount.value, i18n.global.tm('global.declensionTeachers'));
      return `${foundDecl} ${foundCount.value} ${teachersDecl}`;
    });

    const scrollUp = () => {
      const ps = document.querySelector('.lp-container__content');
      ps.scrollTop = 0;
    };

    const headerElList = ref(null);
    const filterElList = ref(null);
    const heightList = ref('calc(100vh - 310px)');
    const setHeightList = () => {
      if (headerElList.value && filterElList.value) {
        heightList.value = `calc(100vh - ${92 + headerElList.value.offsetHeight + filterElList.value.offsetHeight}px)`;
      } else {
        heightList.value = 'calc(100vh - 310px)';
      }
    };
    onBeforeMount(() => {
      window.addEventListener('resize', setHeightList, false);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', setHeightList, false);
    });

    const limitDefault = 30;
    const getListTeachers = ({
      country,
      page = 1,
      lessonCostRange,
      subjects,
      lessonDate,
      lessonTimeRange,
      trialLessonAgreement
    }) => {
      if (page > 1) {
        loaderPage.value = true;
      } else {
        setLoaderRun(true);
      }
      TeachersApi.getTeachers({
        page,
        limit: limitDefault,
        lessonCostRange,
        country,
        subjects,
        lessonDate,
        lessonTimeRange,
        trialLessonAgreement
      }).
        then(async ({ data }) => {
          scrollUp();
          foundCount.value = data.totalDocs;
          const query = { page, limitDefault };
          if (lessonCostRange) query.lessonCostRange = lessonCostRange;
          if (country) query.country = country;
          if (subjects) query.subjects = subjects;
          await router.replace({ query });
          if (!data.totalDocs) {
            await setLoaderRun(false);
            loaderPage.value = false;
            return;
          }
          await setCountries(data.countries);
          if (page === 1) {
            await setTeachersList(data.resource);
          } else {
            setTeacherListAsync(data.resource);
          }
          await timeout(500);
          await setLoaderRun(false);
          loaderPage.value = false;
          setHeightList();
        }).
        catch((err) => {
          setLoaderRun(false);
          loaderPage.value = false;
          console.error(err);
        });
    };

    const oldQuery = ref({});
    const findByFilters = () => {
      const inputs = {};
      filterTeachersInputs.forEach(({ modelName, modelValue }) => {
        inputs[modelName] = modelValue;
      });
      const allHours = get(inputs, 'timesOfDay.times', []).map((item) => item.value).sort((a, b) => b - a);
      const hours = allHours.length ? uniq(allHours.flat()) : [0, 24];
      const getDays = get(inputs, ['timesOfDay', 'days'], []);
      const days = getDays.length ? getDays : [0, 6];
      const times = hours.flat().map((hour) => days.map((day) => {
        const date = moment.utc('2021-01-11');
        if (hour === 24) {
          date.set('hour', 23).set('minute', 59);
        } else {
          date.set('hour', hour).set('minute', 0);
        }
        return date.set('day', day).format('YYYY-MM-DDTHH:mm');
      })).flat();
      const lessonCostRange = get(inputs, 'lessonCostRange', []);
      const query = {
        country: get(inputs, 'country.id', ''),
        subjects: get(inputs, 'subjects.id', ''),
        lessonCostRange: lessonCostRange.join('&lessonCostRange='),
        trialLessonAgreement: get(inputs, 'trialLessonAgreement', false),
        page: page.value
      };
      if (getDays.length || allHours.length) {
        query.lessonTimeRange = [times[0], last(times)].join('&lessonTimeRange=');
      }
      if (isEqual(query, oldQuery.value)) return;
      getListTeachers(query);
      oldQuery.value = query;
    };

    const debounceFind = computed(() => debounce(findByFilters, 500));
    watch(filterTeachersInputs, () => {
      page.value = 1;
      debounceFind.value();
    });
    watch(page, findByFilters);

    const readyForm = ref(false);
    const getMaxCost = async () => {
      const defaultValue = 100;
      try {
        const { data } = await TeachersApi.getMaxCost();
        return data || defaultValue;
      } catch (e) {
        console.error(e);
        return defaultValue;
      }
    };

    const setDefaultInputs = async (query, maxPrice) => {
      filterTeachersInputs.forEach((item) => {
        if (item.modelName === 'lessonCostRange' && maxPrice) item.maxValue = maxPrice;
        const queryValue = get(query, item.modelName, '');
        if (queryValue) item.modelValue = item.getFullObject(queryValue);
        if (item.modelName === 'timesOfDay') item.modelValue = { times: [], days: [] };
      });
      readyForm.value = true;
    };

    onBeforeMount(async () => {
      await setLoaderRun(true);
      const maxPrice = await getMaxCost();
      const { query } = router.currentRoute.value;
      query.lessonCostRange = query.lessonCostRange || `0&lessonCostRange=${maxPrice}`;
      const {
        lessonDate,
        lessonTimeRange,
        ...savedQuery
      } = query;
      await setDefaultInputs(savedQuery, maxPrice);
    });

    // const cardsHeights = ref({});
    // const cardsRef = reactive([]);
    // const setCardsRefs = (el) => {
    //   if (el) cardsRef.push(el);
    //   cardsHeights.value[el.id] = el.offsetHeight;
    // };
    // const clearCardsRefs = () => {
    //   cardsRef.splice(0, cardsRef.length);
    // };
    // onBeforeUnmount(clearCardsRefs);
    const hideIndex = ref([0, 10]);

    return {
      limitDefault,
      foundCount,
      foundText,
      queryDate,
      filterTeachersInputs,
      teachersList,
      activeUser,
      readyForm,
      page,
      loaderPage,
      findByFilters,
      hideIndex,
      headerElList,
      filterElList,
      heightList
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-teachers {
  @include global-font;

  &-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 20px;
    padding-top: 30px;
    margin-top: -30px;
    background-color: $color-alabaster;
    z-index: 5;

    &__title {
      margin-right: 13px;
    }

    &__found {
      padding: 5px 12px;
      background: $color-moon-raker;
      border-radius: 4px;
      font-weight: bold;
      font-size: 14px;
      line-height: 125%;
      color: $color-accent;
      white-space: nowrap;
    }
  }

  &-filters {
    width: 100%;
    background-color: $color-white;
    z-index: 5;

    &__padding {
      width: 100%;
      height: 20px;
      background-color: $color-alabaster;
    }

    &__form {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      align-items: center;
      width: 100%;
      padding: 28px;
    }
  }

  &-list {
    display: grid;
    grid-gap: 20px;

    &__card {
      margin-bottom: 20px;
    }

    &-no-result {
      display: grid;
      grid-template-columns: 55px 1fr;
      grid-template-rows: auto auto;
      grid-gap: 8px 0;
      padding: 24px 30px;
      background: $color-white;
      border-radius: 4px;

      &__icon {
        grid-row: 1/3;
      }

      &__title {
        font-weight: bold;
        font-size: 20px;
        line-height: 125%;
        color: $color-text;
      }

      &__note {
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        color: $color-gray;
      }

    }
  }
}

</style>
