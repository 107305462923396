<template>
  <div>
    <Modal
      title="profile.forms.basic.video.help"
      :hideButtons="['remove', 'save']"
      v-model:open="openModal"
    >
      <template #default>
        <perfect-scrollbar class="lp-information">
          <h3 class="lp-information__h1">
            {{ $t('profile.forms.basic.video.help') }}
          </h3>
          <section class="lp-information__section">
            <h3 class="lp-information__h3">
              {{ $t('profile.forms.basic.video.titleRecommend') }}
            </h3>
            <ul class="lp-information-list">
              <li
                class="lp-information-list__item"
                v-for="(item, index) of $tm('profile.forms.basic.video.recommend')"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </section>
          <section class="lp-information__block">
            <h3 class="lp-information__h3">
              {{ $t('profile.forms.basic.video.titleNotRecommended') }}
            </h3>
            <ul class="lp-information-list">
              <li
                class="lp-information-list__item"
                v-for="(item, index) of $tm('profile.forms.basic.video.notRecommend')"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </section>
          <section class="lp-information__block">
            <h3 class="lp-information__h3">
              {{ $t('profile.forms.basic.video.titleContentVideo') }}
            </h3>
            <ul class="lp-information-list">
              <li
                class="lp-information-list__item"
                v-for="(item, index) of $tm('profile.forms.basic.video.contentVideo')"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </section>
          <section class="lp-information__block">
            <h3 class="lp-information__h3">
              {{ $t('profile.forms.basic.video.moreAdvice') }}
            </h3>
            <ul class="lp-information-list">
              <li
                class="lp-information-list__item"
                v-for="(item, index) of $tm('profile.forms.basic.video.advices')"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </section>
          <section class="lp-information__section">
            {{ $t('profile.forms.basic.video.support') }}
          </section>
        </perfect-scrollbar>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { ref, watch } from 'vue';

export default {
  name: 'ProfileVideoHelp',
  components: { Modal },
  props: {
    status: Boolean
  },
  setup (props, { emit }) {
    const openModal = ref(false);
    watch(openModal, () => emit('update:open', openModal.value));
    watch(() => props.status, (status) => {
      openModal.value = status;
    });

    return { openModal };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

</style>
