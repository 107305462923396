import CURRENT_SERVER from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';

const axios = require('axios');

export default {
  getLessons ({
    page = 1,
    limit = 9999,
    teacher,
    student,
    status,
    sort = 'lessonStartTime',
    lessonDateRange = ['2010-01-01T00:00', '2050-12-30T00:23:59']
  }) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const startDate = moment(lessonDateRange[0]).startOf('day').utc().format();
    const endDate = moment(lessonDateRange[1]).endOf('day').utc().format();
    const dateRange = `${startDate}&lessonDateRange=${endDate}`;
    const query = [
      `page=${page}`,
      `per_page=${limit}`,
      `lessonDateRange=${dateRange}`
    ];
    if (sort) query.push(`sort={"${sort}":1}`);
    if (status) query.push(`lessonStatus=${status}`);
    if (teacher) query.push(`teacher=${teacher}`);
    if (student) query.push(`assignedStudent=${student}`);
    return instCred.get(`lessons?${query.join('&')}`);
  },
  createLesson (lesson) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('lessons/create', lesson);
  },
  updateLesson (lesson) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.patch(`lessons/${lesson._id}`, lesson);
  },
  signUpLesson (lesson) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post(`lessons/subscribelesson?teacherId=${lesson.teacherId}`, lesson);
  },
  deleteLesson (id) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.delete(`lessons/${id}`);
  }
};
