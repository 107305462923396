import CURRENT_SERVER from '@/constants/domains';
import axios from 'axios';

export default {
  getCountry () {
    // const instCred = axios.create({
    //   baseURL: CURRENT_SERVER
    // });
    const url = 'https://www.iplocate.io/api/';
    const data = axios.create({
      baseURL: url
    });
    return data.get('lookup/');
    // return instCred.get('users/geolocation');
  }
};
