<template>
  <div class="lp-profile-teacher">
    <div class="lp-profile-teacher-content">
      <ProfileTeacherBase :teacher="teacher" />
      <ProfileTeacherExperience :teacher="teacher" />
      <ProfileTeacherTimetable
        :teacher="teacher"
        :updatedLesson="lessonSignup"
        v-model:date="lessonDate"
        v-model:timetable="openModal"
      />
      <ProfileTeachersComments :teacher="teacher" />
    </div>
    <div class="lp-profile-teacher-sidebar">
      <ProfileTeacherPrice
        class="lp-profile-teacher-sidebar__price"
        :teacher="teacher"
        v-model:timetable="openModal"
      />
    </div>
    <ProfileTeacherModalLessons
      :teacher="teacher"
      :statusModal="openModal"
      :defaultDate="lessonDate"
      @save="changeLesson"
      @onSingUpLesson="openModalSignUpLesson"
      :listLessons="listLessons"
      v-model:listLessons="listLessons"
      v-model:open="openModal"
      :openAddCard="openAddCardModal"
      v-model:openAddCard="openAddCardModal"
      :successStatus="successStatus"
      v-model:successStatus="successStatus"
      :badStatus="badStatus"
      v-model:badStatus="badStatus"
    />
    <PaymentModal
      :open="openAddCardModal"
      v-model:open="openAddCardModal"
      :newCardOpen="openNewCardModal"
      v-model:newCardOpen="openNewCardModal"
    />
    <OrderModal
      :open="openOrderModal"
      v-model:open="openOrderModal"
      :teacher="teacher"
      :lessons="listLessons"
      v-model:lessons="listLessons"
      @pay="onPay"
      :onCancel="onCancel"
    />
  </div>
</template>

<script>
import ProfileTeacherBase from '@/components/ProfileTeacher/ProfileTeacherBase';
import ProfileTeacherExperience from '@/components/ProfileTeacher/ProfileTeacherExperience';
import ProfileTeacherTimetable from '@/components/ProfileTeacher/ProfileTeacherTimetable';
import ProfileTeachersComments from '@/components/ProfileTeacher/ProfileTeachersComments';
import ProfileTeacherPrice from '@/components/ProfileTeacher/ProfileTeacherPrice';
import PaymentModal from '@/components/PaymentMethods/PaymentModal/PaymentModal';
import {
  computed, watch, ref, onBeforeMount
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { get, find } from 'lodash';
import TeachersApi from '@/api/Teachers/api';
import ProfileTeacherModalLessons from '@/components/ProfileTeacher/ProfileTeacherModalLessons';
import OrderModal from '@/components/PaymentMethods/OrderModal/OrderModal';

export default {
  name: 'ProfileTeacher',
  components: {
    OrderModal,
    ProfileTeacherModalLessons,
    ProfileTeacherPrice,
    ProfileTeachersComments,
    ProfileTeacherTimetable,
    ProfileTeacherExperience,
    ProfileTeacherBase,
    PaymentModal
  },
  setup () {
    const store = useStore();
    const teachersList = computed(() => store.getters.teachersList);

    const lessonSignup = ref({});
    const changeLesson = (data) => {
      lessonSignup.value = data;
    };

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const router = useRouter();
    const teacherId = computed(() => get(router, 'currentRoute.value.params.id', ''));

    const teacher = ref({});

    const setTitle = () => {
      const {
        firstName,
        lastName
      } = teacher.value;
      if (!firstName && !lastName) return;
      document.title = `${firstName} ${lastName}`;
    };

    const setTeacher = () => {
      const teacherFromStore = find(teachersList.value, { _id: teacherId.value });
      if (teacherFromStore) {
        teacher.value = teacherFromStore;
        setTitle();
        return;
      }
      if (!teacherId.value) return;
      setLoaderRun(true);
      TeachersApi.getTeacherById(teacherId.value).
        then(({ data }) => {
          teacher.value = data;
          setTitle();
          setLoaderRun(false);
        }).
        catch((err) => {
          console.error(err);
          setLoaderRun(false);
        });
    };

    const openModal = ref(false);

    watch(teacherId, setTeacher);
    onBeforeMount(setTeacher);

    const lessonDate = ref('');

    const openAddCardModal = ref(false);
    const openNewCardModal = ref(true);
    const openOrderModal = ref(false);
    const successStatus = ref(false);
    const badStatus = ref(false);

    const listLessons = ref([]);

    const onPay = ({ bad, success }) => {
      openOrderModal.value = false;
      openModal.value = true;
      successStatus.value = success;
      badStatus.value = bad;
    };

    const onCancel = () => {
      openOrderModal.value = false;
      openModal.value = true;
      listLessons.value = [];
    };

    const openModalSignUpLesson = (lesson) => {
      openOrderModal.value = true;
      openModal.value = false;
      listLessons.value.push(lesson);
    };

    return {
      teacher,
      openModal,
      lessonDate,
      lessonSignup,
      changeLesson,
      openModalSignUpLesson,
      onPay,
      onCancel,
      listLessons,
      openAddCardModal,
      openNewCardModal,
      openOrderModal,
      successStatus,
      badStatus
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-profile-teacher {
  @include global-font;
  display: grid;
  grid-template-columns: 1fr 462px;
  grid-gap: 20px;

  &-content {
    display: grid;
    grid-gap: 20px;
  }

  &-sidebar {
    &__price {
      position: sticky;
      top: 0;
    }
  }
}

</style>
