import teacherImg from '@/assets/images/landing/teachers/teacher3.jpg';

export default {
  image: teacherImg,
  name: 'Кристина Смоленкова',
  about: `Я выпускница Казанского Музыкального Колледжа им.И.В. Аухадеева по отделению эстрадно-джазового вокала, ученица мастера Марины Владимировны Полтевой. 
 
          Также я являюсь действующей вокалисткой, выступаю с музыкантами кавер-групп, джазовыми гитаристами, саксофонистами на всевозможных площадках Москвы, пишу свое творчество, в свое время была лауреатом всероссийских, международных и городских конкурсов, поэтому помимо знаний вокала также передаю знания артиста. 
 
          На уроках я всегда рассказываю ,что нужно делать,чтобы избежать многих ошибок поведения на сцене, стеснительности и неуверенности (что очень важно на первых порах). Знакомимся со всеми техническими, артистическими аспектами в вокале. 
 
          Работаю как с новичками так и профессионалами :))
          `,
  workText: `2017 - по настоящее время - частная практика
Работа в детских и взрослых вокальных студиях
`,
  hideNext: true,
};
