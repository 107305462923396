import visa from '@/assets/images/visa.png';
import masterCard from '@/assets/images/masterCard.png';

export const numberCardValid = (item) => item.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
export const mmYyValid = (item) => item.replace(/[^\dA-Z]/g, '').replace(/(.{2})/g, '$1/').trim();

export const actionTermValid = (item) => {
  const date = Number(String(new Date().getFullYear()).substring(2, 3));
  if (item.length <= 2) {
    if (Number(item) <= 12) {
      return mmYyValid(item);
    }
    return item.substring(0, 1);
  }
  if (Number(item.substring(3)) >= date) {
    if (item.length < 4) mmYyValid(item);
  } else {
    return item.substring(0, 3);
  }
  return item;
};

export const validateNumberCard = (inputs) => {
  const body = {};
  let errors = 0;
  inputs.forEach((input) => {
    if (!input.model) {
      input.error = input.emptyError;
      errors += 1;
    } else if (input.model.length <= 14) {
      input.error = input.lengthError;
      errors += 1;
    } else {
      input.error = false;
      body[input.modelName] = input.model;
    }
  });
  return errors === 0 ? body : false;
};

export const validateActionTerm = (inputs) => {
  const body = {};
  let errors = 0;
  inputs.forEach((input) => {
    if (!input.model) {
      input.error = input.emptyError;
      errors += 1;
    } else if (input.model.length <= 4) {
      input.error = input.lengthError;
      errors += 1;
    } else {
      input.error = false;
      body[input.modelName] = input.model;
    }
  });
  return errors === 0 ? body : false;
};

export const validateSecurityCode = (inputs) => {
  const body = {};
  let errors = 0;
  inputs.forEach((input) => {
    if (!input.model) {
      input.error = input.emptyError;
      errors += 1;
    } else if (input.model.length < 3) {
      input.error = input.lengthError;
      errors += 1;
    } else {
      input.error = false;
      body[input.modelName] = input.model;
    }
  });
  return errors === 0 ? body : false;
};

export const getTypeCard = (number) => {
  const cards = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    dankort: /^(5019)\d+$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    interpayment: /^(636)\d+$/,
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    unionpay: /^(62|88)\d+$/
  };

  let name = '';
  Object.keys(cards).forEach((card) => {
    if (cards[card].test(number)) {
      name = card;
    }
  });

  const imageName = name === 'visa' ? visa : masterCard;
  return imageName;
};
