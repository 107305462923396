<template>
  <Modal
    :title="'paymentMethods.orderModal.title'"
    v-model:open="openModal"
  >
    <template #default>
      <div
        v-click-outside="closeModal"
        class="lp-order-modal"
      >
        <div class="lp-order-modal-main">
          <!--          <div class="lp-order-modal__title">-->
          <!--            {{ $t('paymentMethods.orderModal.details') }}-->
          <!--          </div>-->
          <div class="lp-order-modal-main-teacher">
            <UserAvatar
              class="lp-order-modal-main-teacher__avatar"
              :avatar="avatar"
              :country="country"
              :order="true"
            />
            <div class="lp-order-modal-main-teacher-info">
              <div class="lp-order-modal-main-teacher-info__initials">
                {{ teacher.firstName }} {{ teacher.lastName }}
              </div>
              <div class="lp-order-modal-main-teacher-info__speciality">
                {{ speciality.title }}
              </div>
            </div>
          </div>
          <div
            class="lp-order-modal-main-lesson"
            v-for="item in lessons"
            :key="item.lessonStartTime"
          >
            <div>
              <div class="lp-order-modal-main-lesson__title">
                {{ $t('paymentMethods.orderModal.dateTime') }}
              </div>
              <div class="lp-order-modal-main-lesson__title lp-order-modal-main-lesson__title_date">
                {{ lessonDateFormat(item.lessonStartTime).value }}
              </div>
            </div>
            <div
              v-if="item.free"
              class="lp-order-modal-main-lesson-cost lp-order-modal-main-lesson-cost_free"
            >
              <div class="lp-order-modal-main-lesson__title lp-order-modal-main-lesson__title_free">
                {{ $t('paymentMethods.orderModal.priceLesson') }}
              </div>
              <div>
                <button class="lp-button lp-order-modal-main-lesson-cost__free">
                  {{ $t('buttons.free') }}
                </button>
              </div>
            </div>
            <div
              v-else
              class="lp-order-modal-main-lesson-cost"
            >
              <div class="lp-order-modal-main-lesson__title lp-order-modal-main-lesson__title_cost">
                {{ $t('paymentMethods.orderModal.priceLesson') }}
              </div>
              <div class="lp-order-modal-main-lesson__title  lp-order-modal-main-lesson__title_cost">
                {{ coast }}
              </div>
            </div>
          </div>
          <div class="lp-order-modal-main-lesson">
            <div></div>
            <div class="lp-order-modal-main-lesson-cost">
              <div class="lp-order-modal-main-lesson-cost__total">
                {{ $t('paymentMethods.orderModal.total') }}
              </div>
              <div class="lp-order-modal-main-lesson-cost__total">
                {{ total }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="lp-order-modal-footer">
        <div
          v-show="showButtonsPayPal"
          class="lp-order-modal-footer-pay-pal"
        >
          <div class="lp-order-modal-footer-pay-pal__title">
            Оплатить с помощью
          </div>
          <div ref="buttonsPayPal"></div>
          <div class="lp-order-modal-footer-pay-pal__triangle">
          </div>
        </div>
        <button
          v-if="!showButtonsPayPal"
          class="lp-button lp-button_thin"
          @click="onCancel"
        >
          {{ $t('buttons.cancel') }}
        </button>
        <button
          class="lp-button"
          @click.stop="onPay"
        >
          {{ $t('buttons.pay') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import {
  computed,
  ref
  // onMounted,
} from 'vue';
import { userIcon } from '@/constants/icons';
import { getCountry, getSpecialty } from '@/constants/utils/getValueById';
import UserAvatar from '@/components/Main/UserElements/UserAvatar';
import { get } from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import i18n from '@/i18n';
// import PayPalApi from '@/api/PayPal/api';
// import { useStore } from 'vuex';
// import CLIENT_ID from '@/constants/clientId';

export default {
  name: 'OrderModal',
  components: {
    UserAvatar,
    Modal
  },
  props: {
    teacher: Object,
    lessons: Array,
    onCancel: Function
  },
  emits: ['pay'],
  setup (props, { emit }) {
    const openModal = ref(false);

    const speciality = getSpecialty(props.teacher.speciality);
    const country = computed(() => getCountry(props.teacher.country));
    const avatar = computed(() => {
      const isNotObject = typeof props.teacher.uploadFile !== 'object';
      const isNull = props.teacher.uploadFile === null;
      return isNotObject || isNull ? {} : props.teacher.uploadFile;
    });
    const locale = get(i18n, 'global.locale.value', 'ru');
    const lessonDateFormat = (item) => computed(() => moment(item).
      locale(locale).
      format('dddd, DD MMMM YYYY, HH:mm'));

    const coastLesson = computed(() => get(props.teacher, 'lessonCost', 0));
    const coast = computed(() => numeral(coastLesson.value).
      format('$0'));
    const lessonFree = computed(() => props.lessons.filter((el) => el.free));
    const total = computed(() => numeral(coastLesson.value * (props.lessons.length - lessonFree.value.length)).
      format('$0'));

    // const store = useStore();
    // const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const buttonsPayPal = ref(null);
    const showButtonsPayPal = ref(false);
    // const isOnApprove = ref(false);
    // const currentCurrency = ref('USD');

    // const createOrder = () => {
    //   const body = {
    //     amount: {
    //       currency_code: currentCurrency.value,
    //       value: coastLesson.value * props.lessons.length,
    //     },
    //   };
    //
    //   return PayPalApi.createOrder(body)
    //     .then((response) => response.data.id)
    //     .catch((err) => (console.err(err)));
    // };
    //
    // const onApprove = ({ orderID }) => {
    //   setLoaderRun(true);
    //   PayPalApi.approvePayment({ orderID })
    //     .then(({ data }) => {
    //       if (data.status === 'COMPLETED') {
    //         emit('pay', { success: true });
    //       } else {
    //         emit('pay', { bad: true });
    //       }
    //       setLoaderRun(false);
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       setLoaderRun(false);
    //     });
    // };

    const onPay = () => {
      // showButtonsPayPal.value = !showButtonsPayPal.value;
      emit('pay', { success: true });
      // if (isOnApprove.value) return;
      // window.paypal.Buttons({
      //   style: {
      //     size: 'small',
      //   },
      //   createOrder,
      //   onApprove,
      // })
      //   .render(buttonsPayPal.value);
      // isOnApprove.value = true;
    };
    const closeModal = () => {
      emit('update:lessons', []);
    };
    // onMounted(() => {
    //   const script = document.createElement('script');
    //   script.src = `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=${currentCurrency.value}`;
    //   script.setAttribute('data-csp-nonce', 'xyz-123');
    //   document.body.appendChild(script);
    // });

    return {
      openModal,
      userIcon,
      onPay,
      speciality,
      avatar,
      country,
      coast,
      total,
      lessonDateFormat,
      buttonsPayPal,
      showButtonsPayPal,
      closeModal
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-order-modal {
  @include global-font;
  max-height: 693px;
  min-width: 951px;
  position: relative;

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 125%;;
  }

  &-header {
    padding: 28px 40px;
    border-bottom: 1px solid $color-alto;

    &__radio {
      margin-top: 16px;
    }
  }

  &-main {
    padding: 28px 40px 0;

    &-teacher {
      margin-bottom: 4px;
      display: flex;

      &__avatar {
        width: 72px;
        height: 72px;
      }

      &-info {
        margin-left: 23px;
        margin-top: 15px;

        &__initials {
          font-weight: bold;
          font-size: 16px;
          line-height: 125%;
        }

        &__speciality {
          font-weight: 500;
          font-size: 14px;
          line-height: 125%;
        }
      }
    }

    &-lesson {
      display: grid;
      grid-template-columns: 1.85fr 1fr;
      align-items: center;
      padding: 20px 0 15px;
      border-bottom: 1px solid $color-alto;

      &:last-child {
        border-bottom: 0;
        padding: 20px 0 0;
      }

      &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        padding-bottom: 8px;

        &_free {
          padding-bottom: 0;
        }

        &_cost {
          padding-bottom: 0;
        }

        &_date {
          font-weight: 500;

          &::first-letter {
            text-transform: capitalize;
          }
        }
      }

      &-cost {
        display: flex;
        justify-content: space-between;

        &_free {
          align-items: center;
        }

        &__free {
          background-color: $color-supernova;
          padding: 9px 20px;
          font-size: 16px;
          line-height: 125%;
          color: $color-text;
          text-transform: capitalize;
          pointer-events: none;
          box-shadow: none;
        }

        &__total {
          font-weight: bold;
          font-size: 20px;
          line-height: 125%;
          text-transform: uppercase;
        }
      }
    }
  }

  &-footer {
    position: relative;

    &-pay-pal {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 8px;
      width: 216px;
      height: 159px;
      position: absolute;
      box-shadow: 0 0 20px rgba($color-black, 0.09);
      background-color: $color-white;
      right: 175px;
      bottom: 4px;
      border-radius: 4px;

      &__triangle {
        position: absolute;
        border: 6px solid transparent;
        border-left: 7px solid $color-white;
        right: -13px;
        bottom: 13px;
      }

      &__title {
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        line-height: 125%;
        padding-bottom: 8px;
        padding-top: 13px;
      }
    }
  }
}

//@media screen and (max-width: 199px) {
//  .paypal-button-container{
//    width: 165px;
//  }
//}
</style>
