<template>
  <div class="lp-profile-teacher-experience">
    <div class="lp-profile-teacher-experience-header lp-tabs">
      <div
        class="lp-profile-teacher-experience-header__item lp-tabs__item"
        :class="{
          'lp-tabs__item_active': activeTab === tab.id,
          'lp-tabs__item_disabled': tab.disabled
        }"
        @click="activeTab = tab.id"
        v-for="tab of tabs"
        v-show="!tab.disabled"
        :key="tab.id"
      >
        {{ $t(tab.title) }}
      </div>
    </div>
    <div class="lp-profile-teacher-experience-body__wrapper">
      <transition
        name="slideY"
        mode="out-in"
      >
        <template v-if="activeTab === 1">
          <div class="lp-profile-teacher-experience-body">
            <div
              class="lp-profile-teacher-experience-body-item"
              v-for="(work, index) of teacher.work"
              :key="`${work.organization}-${index}`"
            >
              <div class="lp-profile-teacher-experience-body-item__dates">
                {{ new Date(work.fromDate).getFullYear() }} - {{ new Date(work.toDate).getFullYear() }}
              </div>
              <div class="lp-profile-teacher-experience-body-item__position">
                {{ work.position }}
              </div>
              <div class="lp-profile-teacher-experience-body-item__company">
                {{ work.organization }}
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="activeTab === 2">
          <div class="lp-profile-teacher-experience-body">
            <div
              class="lp-profile-teacher-experience-body-item"
              v-for="(education, index) of teacher.education"
              :key="`${education.organization}-${index}`"
            >
              <div class="lp-profile-teacher-experience-body-item__dates">
                {{ new Date(education.fromDate).getFullYear() }} - {{ new Date(education.toDate).getFullYear() }}
              </div>
              <div class="lp-profile-teacher-experience-body-item__position">
                {{ education.specialization }}
              </div>
              <div class="lp-profile-teacher-experience-body-item__company">
                {{ education.educationInst }}
              </div>
            </div>
          </div>
        </template>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'ProfileTeacherExperience',
  props: {
    teacher: Object
  },
  setup () {
    const tabs = [
      { id: 1, title: 'profile.sections.experience' },
      { id: 2, title: 'profile.sections.education' },
      { id: 3, title: 'profile.sections.certificates', disabled: true }
    ];

    const activeTab = ref(1);

    return { tabs, activeTab };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-profile-teacher-experience {
  @include global-font;
  background-color: $color-white;
  box-shadow: 0 0 15px rgba($color-black, 0.02);

  &-header {
    grid-template-columns: repeat(3, auto) 1fr;

    &__item {
      padding: 28px 0 20px;
    }
  }

  &-body {
    display: grid;
    grid-gap: 30px;
    padding: 40px 40px 60px;

    &__wrapper {
      overflow: hidden;
    }

    &-item {
      display: grid;
      grid-gap: 4px 20px;
      grid-template-columns: minmax(90px, 272px) 1fr;
      grid-template-rows: 1fr 1fr;
      color: $color-text;

      &__dates {
        grid-column: 1;
        grid-row: 1;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
      }

      &__position {
        grid-column: 2;
        grid-row: 1;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
      }

      &__company {
        grid-column: 2;
        grid-row: 2;
        font-weight: bold;
        font-size: 14px;
        line-height: 125%;
      }
    }
  }
}

</style>
