<template>
  <div
    class="lp-heart-icon"
    :class="{'lp-heart-icon_favorite': isFavorites.length}"
    @click="onChangeStudent(isFavorites.length)"
  >
    <heartIcon />
  </div>
</template>

<script>
import { heartIcon } from '@/constants/icons';
import { useStore } from 'vuex';
import { computed } from 'vue';
import UsersApi from '@/api/Users/api';

export default {
  name: 'Heart',
  components: {
    heartIcon
  },
  props: {
    teacher: Object
  },
  setup (props) {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const setActiveUser = (data) => store.dispatch('setActiveUser', data);

    const setTeachersFavoritesList = (data) => store.dispatch('setTeachersFavoritesList', data);
    const teachersFavoritesList = computed(() => store.getters.teachersFavoritesList);

    const isFavorites = computed(() => (activeUser.value.favorites
      ? activeUser.value.favorites.filter((el) => el === props.teacher._id)
      : []));

    const updateStudent = async (newActiveUser) => {
      try {
        await UsersApi.updateUser(newActiveUser);
      } catch (err) {
        console.error(err);
      }
    };

    const onChangeStudent = (status) => {
      const newActiveUser = {
        ...activeUser.value
      };

      if (status) {
        newActiveUser.favorites = activeUser.value.favorites.filter((el) => el !== props.teacher._id);
        const newTeachersFavoritesList = teachersFavoritesList.value.filter((el) => el._id !== props.teacher._id);
        setTeachersFavoritesList(newTeachersFavoritesList);
      } else {
        const favorites = activeUser.value.favorites || [];
        favorites.push(props.teacher._id);
        newActiveUser.favorites = favorites;
        teachersFavoritesList.value.push(props.teacher);
      }

      updateStudent(newActiveUser);
      setActiveUser(newActiveUser);
    };

    return {
      isFavorites,
      onChangeStudent
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-heart-icon {
    position: absolute;
    top: 14px;
    right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    box-sizing: content-box;
    cursor: pointer;

    svg {
      fill: $color-alto;
      transition: 0.4s ease-in;
    }

    &:hover {
      svg {
        fill: rgba($color-silver-chalice, 0.7);
      }
    }

    &_favorite {
       svg  {
         fill: $color-red;
       }

       &:hover {
         svg {
           fill: rgba($color-red, 0.7);
         }
       }
     }

  }

</style>
