<template>
  <div class="lp-user-timetable">
    <h3
      class="lp-user-timetable__title"
      v-if="isStudent"
    >
      {{ $t('userTimetable.titles.timetable') }}
    </h3>
    <div
      class="lp-user-timetable-header lp-tabs"
      v-else
    >
      <div
        class="lp-user-timetable-header__item lp-tabs__item"
        :class="{
          'lp-tabs__item_active': activeTab === tab.id,
          'lp-tabs__item_disabled': tab.disabled
        }"
        @click="activeTab = tab.id"
        v-for="tab of tabs"
        :key="tab.id"
      >
        {{ tab.title }}
      </div>
    </div>
    <div class="lp-user-timetable-body">
      <Calendar :edit="activeTab === 2" />
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n';
import { computed, ref } from 'vue';
import Calendar from '@/components/Main/Calendar/Calendar';
import ROLE_TYPES from '@/constants/enums/roles';
import { useStore } from 'vuex';

export default {
  name: 'UserTimetable',
  components: { Calendar },
  setup () {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const isStudent = computed(() => activeUser.value.role === ROLE_TYPES.STUDENT);

    const tabs = [
      { id: 1, title: i18n.global.t('userTimetable.titles.timetable') },
      { id: 2, title: i18n.global.t('userTimetable.titles.settings') }
    ];

    const activeTab = ref(1);

    return { tabs, activeTab, isStudent };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-user-timetable {
  @include global-font;

  &__title {
    font-weight: 800;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 18px;
    text-transform: uppercase;
  }

  &-header {
    grid-template-columns: repeat(2, auto) 1fr;
    background-color: $color-white;
    border-radius: 4px 4px 0 0;
    padding: 0 28px;

    &__item {
      font-weight: 800;
      font-size: 22px;
      line-height: 27px;
      padding: 26px 0 12px;
      letter-spacing: 1px;
    }
  }

  &-body {
    padding: 12px 28px 28px;
    background: $color-white;
    border-radius: 0 0 4px 4px;
  }
}

</style>
