<template>
  <div
    class="lp-phone-select"
    v-click-outside="closeDropList"
  >
    <div
      class="lp-phone-select-selected"
      ref="selectHeader"
      @click="openDropList = !openDropList"
    >
      <div class="lp-phone-select-selected__wrapper">
        <img
          class="lp-phone-select-selected__flag"
          v-if="selectedCountry.icon"
          :src="selectedCountry.icon"
          alt="country"
          @mouseenter="openPopupCountry = true"
          @mouseleave="openPopupCountry = false"
        >
        <Tooltip
          class="lp-phone-select-selected__tooltip"
          :text="selectedCountry.name"
          :open="openPopupCountry && !openDropList"
        />
      </div>
      <div
        class="lp-phone-select-selected__arrow"
        :class="{'lp-phone-select-selected__arrow_open': openDropList}"
      >
        <miniArrowIcon />
      </div>
    </div>
    <div class="lp-phone-select__code">
      +{{ selectedCountry.phone }}
    </div>
    <transition name="slide-select">
      <div
        class="lp-phone-select-list"
        @click.stop
        :style="fixed ? getStyleDropList() : {}"
        v-if="openDropList"
      >
        <label class="lp-phone-select-list-searchable lp-input">
          <input
            class="lp-phone-select-list-searchable__input"
            :placeholder="$t('inputLabels.country')"
            type="text"
            v-focus="true"
            v-model="searchCountry"
          />
          <searchIcon class="lp-phone-select-list-searchable__icon" />
        </label>
        <div
          class="lp-phone-select-list-item"
          v-for="(item) of displayCountry"
          :key="item.code"
          @click.stop="setSelected(item)"
        >
          <img
            class="lp-phone-select-list-item__flag"
            v-if="item.icon"
            :alt="item.name"
            :src="item.icon"
          />
          <span> {{ $t(item.name) }} </span>
          <span> +{{ item.phone }} </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import country from '@/constants/country';
import { miniArrowIcon, searchIcon } from '@/constants/icons';
import { computed, inject, onBeforeMount, ref, watch } from 'vue';
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import { useI18n } from 'vue-i18n';
import { sortBy } from 'lodash';

export default {
  name: 'CountrySelect',
  components: {
    Tooltip,
    miniArrowIcon,
    searchIcon
  },
  props: {
    code: String,
    fixed: Boolean
  },
  emits: ['update:country'],
  setup (props, { emit }) {
    const { t } = useI18n();

    const countryCode = inject('countryCode');

    const countryWithName = computed(() => sortBy(country.map(item => {
      item.fullName = t(item.name);
      return item;
    }), 'fullName'));

    const searchCountry = ref('');
    const displayCountry = ref(countryWithName.value);
    watch(searchCountry, (value) => {
      if (!value) displayCountry.value = countryWithName.value;
      const search = searchCountry.value.toLowerCase();
      const result = countryWithName.value.filter((item) => {
        const value = String(t(item.name)).toLowerCase();
        return value.includes(search);
      });
      const reg = new RegExp(`^${search}`, 'i');
      result.sort((a, b) => reg.test(b.name) - reg.test(a.name));
      displayCountry.value = result;
    });

    const userCountry = computed(() => {
      const countryByCode = countryWithName.value.find(item => item.code === countryCode);
      const defaultCountry = countryByCode || countryWithName.value[0];
      const countryValue = countryWithName.value.find(item => item.code === props.code);
      return countryValue || defaultCountry;
    });

    const openPopupCountry = ref(false);
    const openDropList = ref(false);
    const selectedCountry = ref(userCountry.value);

    const selectHeader = ref(null);
    const getStyleDropList = () => {
      if (!selectHeader.value) return {};
      const position = selectHeader.value.getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${4 + position.top + position.height}px`,
        left: `${position.left - 12}px`,
        width: '300px'
      };
    };

    watch(openDropList, (val) => {
      if (!val) searchCountry.value = '';
    });

    const closeDropList = () => openDropList.value = false;

    const setSelected = (country) => {
      selectedCountry.value = country;
      openDropList.value = false;
    };

    const emitCountry = () => {
      emit('update:country', selectedCountry.value);
    };

    watch(() => selectedCountry.value, emitCountry);
    onBeforeMount(() => {
      if (!selectedCountry.value) selectedCountry.value = userCountry.value;
      emitCountry();
    });

    return {
      selectedCountry,
      displayCountry,
      openPopupCountry,
      openDropList,
      selectHeader,
      searchCountry,
      getStyleDropList,
      closeDropList,
      setSelected
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/style";

.lp-phone-select {
  display: flex;
  align-items: center;
  cursor: pointer;

  &-selected {
    display: grid;
    grid-template-columns: 26px 7px;
    grid-gap: 8px;
    align-items: center;
    padding: 10px 8px 10px 0;
    margin: -10px 0;
    border-right: 1px solid $color-gallery;

    &__arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-out;

      &_open {
        transform: rotate(180deg);
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    &__flag {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      object-fit: cover;
      box-sizing: border-box;
      border-radius: 50%;
      cursor: pointer;
      z-index: 1;
    }

    &__tooltip {
      top: 35px
    }
  }

  &__code {
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: $color-text;
    margin-left: 9px;
  }

  &-list {
    @include global-font;
    position: absolute;
    top: 65px;
    left: 0;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    width: 300px;
    height: auto;
    padding: 4px 0;
    background: $color-white;
    box-shadow: 0 0 0 1px $color-wild-sand, 0 0 15px rgba($color-black, 0.05);
    border-radius: 6px;
    z-index: 100;
    overflow: auto;
    max-height: 190px;

    &-searchable {
      position: relative;
      display: flex;
      align-items: center;
      margin: 8px 16px;
      width: calc(100% - 32px);

      &__input {
        margin-left: 22px;
        border: none;
        padding: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        &::placeholder {
          color: $color-silver;
        }
      }

      &__icon {
        position: absolute;
        left: 12px;
        top: auto;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: 18px 1fr auto;
      grid-gap: 8px;
      align-items: center;
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      margin: 0 8px;
      background-color: $color-white;
      transition: 0.3s ease-in;
      cursor: pointer;

      &__flag {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        object-fit: cover;
      }

      &:hover {
        color: $color-accent;
        background-color: $color-moon-raker;
      }
    }
  }
}

</style>
