<template>
  <div class="lp-select-week">
    <div
      class="lp-select-week__label lp-label"
      v-if="label"
    >
      {{ $t(label) }}
    </div>
    <div
      class="lp-select-week-body"
    >
      <div
        class="lp-select-week-body__day"
        :class="{
          'lp-select-week-body__day_selected': modelValue.includes(day.id),
          'lp-select-week-body__day_error': error
        }"
        v-for="day of week"
        :key="day.id"
        @click="selectDay(day.id)"
      >
        {{ day.title }}
      </div>
    </div>
    <transition name="bounce">
      <span
        class="lp-select-week__error lp-error"
        v-if="error && typeof error === 'string'"
      >
        <dangerIcon />
        {{ $t(error) }}
      </span>
    </transition>
  </div>
</template>

<script>
import i18n from '@/i18n';
import { dangerIcon } from '@/constants/icons';
import { get, fill } from 'lodash';
import moment from 'moment';

export default {
  name: 'WeekDays',
  components: { dangerIcon },
  props: {
    error: [Boolean, String],
    modelValue: Array,
    label: String,
    single: Boolean
  },
  setup (props, { emit }) {
    const locale = get(i18n, 'global.locale.value', 'ru');
    const rawWeek = fill(new Array(7), moment().set('day', 1).format('YYYY-MM-DD'));
    const week = rawWeek.map((date, i) => {
      const momentDate = moment(date).add(i, 'day');
      return {
        title: momentDate.locale(locale).format('ddd'),
        id: +momentDate.format('d')
      };
    });

    const selectDay = (numberDay) => {
      emit('focus');
      if (props.modelValue.includes(numberDay)) {
        emit('update:modelValue', props.modelValue.filter((item) => item !== numberDay));
      } else {
        emit('update:modelValue', props.single ? [numberDay] : [numberDay].concat(props.modelValue));
      }
    };

    return { week, selectDay };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-select-week {
  @include global-font;
  position: relative;

  &__label {
    margin-bottom: 4px;
  }

  &-body {
    display: grid;
    grid-template-columns: repeat(7, minmax(45px, 1fr));
    align-items: center;

    &__day {
      position: relative;
      padding: 8px 16px;
      border: 1px solid $color-alto;
      background-color: transparent;
      color: $color-text;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-right: -1px;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      z-index: 1;
      user-select: none;
      text-transform: capitalize;

      &:hover {
        background-color: $color-moon-raker;
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &_selected {
        border-color: $color-accent;
        background-color: $color-moon-raker;
        color: $color-accent;
        z-index: 2;
      }

      &_error {
        border-color: $color-red;
      }
    }
  }

  &__error {
    top: 65px;
  }
}

</style>
