const axios = require('axios');

export default {
  getLocation ({ latitude, longitude }) {
    const instCred = axios.create({
      baseURL: 'https://nominatim.openstreetmap.org/'
    });
    return instCred.get(`reverse.php?format=json&lat=${latitude}&lon=${longitude}`);
  }
};
