<template>
  <p class="lp-login-footer">
    {{ $t(question) }}
    <router-link
      class="lp-login-footer__link lp-link"
      :to="toLogin()"
    >
      {{ $t('registration.login.link') }}
    </router-link>
  </p>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'LoginFooter',
  setup () {
    const router = useRouter();
    const isRegistration = computed(() => router.currentRoute.value.name === 'Join');
    const questionRegistration = 'registration.login.question';
    const questionLogin = 'login.registration.question';
    const question = computed(() => (isRegistration.value ? questionLogin : questionRegistration));

    const toJoin = () => ({
      name: 'Join'
    });

    const toLogin = () => ({
      name: 'Login'
    });

    return {
      question,
      isRegistration,
      toLogin,
      toJoin
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-login-footer {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: $color-text;
  min-height: 36px;

  &__link {
    color: $color-azure-radiance;
  }
}

</style>
