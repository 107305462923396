<template>
  <div class="lp-calendar-months">
    <perfect-scrollbar class="lp-calendar-months-body">
      <div
        class="lp-calendar-months-body-header"
        v-for="day of week"
        :key="day.id"
      >
        <span class="lp-calendar-months-body-header__title lp-calendar-months-body-header__title_full">
          {{ day.title }}
        </span>
        <span class="lp-calendar-months-body-header__title lp-calendar-months-body-header__title_short">
          {{ day.shortTitle }}
        </span>
      </div>
      <div
        class="lp-calendar-months-body-date"
        :class="{
          'lp-calendar-months-body-date_out': item.month !== month || item.year !== year,
          'lp-calendar-months-body-date_today': item.date === today,
          'lp-calendar-months-body-date_past': isPast(item)
        }"
        :ref="index === 0 ? 'cell' : null"
        :id="`date${item.date}`"
        v-for="(item, index) of dates"
        :key="item.date"
      >
        <div class="lp-calendar-months-body-date__day">
          {{ item.day }}
        </div>
        <div
          class="lp-calendar-months-body-date-lessons"
          v-if="item.month === month || item.year === year"
        >
          <CalendarLesson
            v-show="index < includeLessons"
            v-for="(lesson, index) of lessons[item.date]"
            mode="month"
            :key="lesson._id"
            :lesson="lesson"
          />
          <div
            class="lp-calendar-months-body-date-lessons__more"
            @click.stop="openFullList = item.date"
            v-if="lessons[item.date] && includeLessons < lessons[item.date].length"
          >
            {{ $t('global.more') }} {{ lessons[item.date].length - includeLessons }}
          </div>
        </div>
        <transition name="bounce">
          <CalendarLessonsPopup
            class="lp-calendar-months-body-date__full-list"
            :lessons="lessons[openFullList]"
            :date="openFullList"
            v-model:open="openFullList"
            v-if="openFullList === item.date"
          />
        </transition>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import i18n from '@/i18n';
import {
  computed, onBeforeMount, onBeforeUnmount, ref, watch, watchEffect
} from 'vue';
import { fill, get, last } from 'lodash';
import moment from 'moment';
import CalendarLesson from '@/components/Main/Calendar/CalendarLesson';
import CalendarLessonsPopup from '@/components/Main/Calendar/CalendarLessonsPopup';

export default {
  name: 'CalendarMonths',
  components: { CalendarLessonsPopup, CalendarLesson },
  props: {
    date: String,
    lessons: Object,
    editMode: Boolean
  },
  setup (props, { emit }) {
    const locale = get(i18n, 'global.locale.value', 'ru');
    const week = fill(new Array(7), moment().set('day', 1)).map((el, i) => {
      const date = moment(el).add(i, 'day').locale(locale);
      return {
        id: date.format('d'),
        title: date.format('dddd'),
        shortTitle: date.format('dd')
      };
    });
    const today = computed(() => moment().format('YYYY-MM-DD'));
    const month = computed(() => moment(props.date).format('M'));
    const year = computed(() => moment(props.date).format('YYYY'));

    const innerHeight = ref(window.innerHeight);
    const setInnerHeight = () => {
      innerHeight.value = window.innerHeight;
    };
    const cell = ref(null);
    const includeLessons = ref(2);
    const setIncludeLessons = () => {
      if (!cell.value) {
        includeLessons.value = 2;
        return;
      }
      const heightCell = cell.value.offsetHeight - 57;
      includeLessons.value = Math.floor(heightCell / 31);
    };
    watch(cell, setIncludeLessons);
    watch(innerHeight, setIncludeLessons);
    onBeforeMount(() => {
      window.addEventListener('resize', setInnerHeight, false);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', setInnerHeight, false);
    });

    const getStartDate = () => {
      const week = fill(new Array(7), 1).map((el, i) => {
        const startDateMonth = moment(props.date).startOf('month');
        return startDateMonth.subtract(i, 'd');
      });
      return week.find((date) => date.day() === 1);
    };
    const dates = computed(() => fill(new Array(35), 1).map((date, index) => {
      const startDate = getStartDate();
      const momentDate = startDate.add(index, 'd');
      return {
        day: momentDate.format('DD'),
        date: momentDate.format('YYYY-MM-DD'),
        month: momentDate.format('M'),
        year: momentDate.format('YYYY')
      };
    }));

    watchEffect(dates, emit('update:range', [dates.value[0].date, last(dates.value).date]));

    const openFullList = ref('');

    const isPast = ({ date }) => moment().isAfter(date);

    return {
      week,
      dates,
      today,
      month,
      year,
      openFullList,
      isPast,
      cell,
      includeLessons
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-calendar-months {
  @include global-font;

  &-body {
    display: grid;
    grid-template-columns: repeat(7, minmax(120px, 1fr));
    grid-template-rows: 40px repeat(5, minmax(113px, 1fr));
    overflow: hidden;
    height: calc(100vh - 280px);
    border: 1px solid $color-alto;
    border-radius: 4px 4px 0 0 ;

    &-header {
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      background: $color-moon-raker;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: $color-accent;
      box-shadow:0 0 0 1px $color-alto;
      z-index: 1;

      &__title {
        &_full {
          @media (max-width: 1200px) {
            display: none;
          }
        }

        &_short {
          display: none;
          @media (max-width: 1200px) {
            display: block;
          }
        }
      }
    }

    &-date {
      position: relative;
      padding: 8px;
      height: 100%;
      width: 100%;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: $color-text;
      transition: 0.2s ease-out;
      background-color: $color-white;
      box-shadow:0 0 0 1px $color-alto;
      cursor: default;

      &__full-list {
        position: absolute;
        top: -22px;
        left: calc(50% - 133px);
        z-index: 3;
      }

      &_past {
        color: $color-gray;
      }

      &_out {
        color: transparent;
        pointer-events: none;
      }

      &_today {
        background-color: $color-moon-raker;
        color: $color-accent;

        &:hover {
          background-color: $color-moon-raker;
        }
      }

      &__day {
        margin-bottom: 3px;
      }

      &-lessons {
        display: grid;
        grid-gap: 4px;

        &__more {
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: $color-accent;
          transition: 0.3s ease-out;
          cursor: pointer;

          &:hover {
            color: $color-accent-hover;
          }
        }
      }
    }
  }
}

</style>
