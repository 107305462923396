import { reactive } from 'vue';

export default reactive([
  {
    label: 'login.inputs.login',
    autofocus: true,
    type: 'text',
    model: '',
    modelName: 'email',
    emptyError: 'errors.emptyLogin'
  },
  {
    label: 'login.inputs.password',
    type: 'password',
    model: '',
    modelName: 'password',
    emptyError: 'errors.emptyPassword',
    canShowPass: true
  }
]);
