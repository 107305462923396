<template>
  <div class="lp-teacher-card">
    <div class="lp-teacher-card-profile">
      <div class="lp-teacher-card-profile-col1">
        <router-link
          :to="goTeacherProfile()"
          target="_blank"
        >
          <UserAvatar
            :avatar="avatar || {}"
            :country="country"
          />
        </router-link>
        <UserRate
          class="lp-teacher-card-profile-col1__rate"
          :rate="teacher.rate || 0"
        />
        <button
          class="lp-button"
          @click.stop="activeUser._id ? openModal = true : goRegistration()"
        >
          {{ $t('buttons.signUp') }}
        </button>
      </div>
      <div class="lp-teacher-card-profile-col2">
        <router-link
          class="lp-teacher-card-profile-col2__name"
          target="_blank"
          :to="goTeacherProfile()"
        >
          {{ teacher.firstName }} {{ teacher.lastName }}
        </router-link>
        <div class="lp-teacher-card-profile-col2__speciality">
          {{ speciality.title }}
        </div>
        <UserSubjects :subjects="subjects" />
        <div class="lp-teacher-card-profile-col2-location">
          <locationIcon />
          <span>
            {{ country.name }}
            {{ teacher.city ? ', ' + teacher.city : '' }}
          </span>
        </div>
        <div class="lp-teacher-card-profile-col2-stats">
          <div class="lp-teacher-card-profile-col2-stats__item">
            <tabletDocIcon /> {{ lessons }}
          </div>
          <div class="lp-teacher-card-profile-col2-stats__item">
            <shieldIcon /> {{ years }}
          </div>
          <div class="lp-teacher-card-profile-col2-stats__item">
            <chatIcon /> {{ comments }}
          </div>
        </div>
        <div class="lp-teacher-card-profile-col2__about">
          {{ teacher.about }}
        </div>
        <div class="lp-teacher-card-profile-col2-price">
          <div class="lp-teacher-card-profile-col2-price-item">
            <span class="lp-teacher-card-profile-col2-price-item__title">
              {{ $t('listTeachers.coast') }}
            </span>
            {{ price }}
            <span class="lp-teacher-card-profile-col2-price-item__duration">
              {{ $t('profile.forms.price.lessonDuration') }}
            </span>
          </div>
          <div
            class="lp-teacher-card-profile-col2-price-item"
            v-if="hasTrial"
          >
            <span class="lp-teacher-card-profile-col2-price-item__title">
              {{ $t('listTeachers.trialCoast') }}
            </span>
            <FreeLabel />
          </div>
        </div>
      </div>
      <Heart :teacher="teacher" />
    </div>
    <div class="lp-teacher-card-calendar">
      <div class="lp-teacher-card-calendar-header">
        <div
          class="lp-teacher-card-calendar-header__item"
          :class="{'lp-teacher-card-calendar-header__item_active': rightTab === 0}"
          @click="rightTab = 0"
        >
          {{ $t('listTeachers.calendar') }}
        </div>
        <div
          class="lp-teacher-card-calendar-header__item"
          :class="{'lp-teacher-card-calendar-header__item_active': rightTab === 1}"
          v-if="teacher.presentationUrl"
          @click="rightTab = 1"
        >
          {{ $t('listTeachers.video') }}
        </div>
      </div>
      <transition
        name="slideX"
        mode="out-in"
      >
        <div v-if="!rightTab">
          <ListTeachersCalendar
            :date="date"
            :teacher="teacher"
          />
          <button
            class="lp-button lp-button_border lp-button_small lp-teacher-card-calendar__button"
            @click.stop="openModal = true"
          >
            {{ $t('listTeachers.fullCalendar') }}
          </button>
        </div>
        <ListTeachersVideo
          v-else-if="rightTab === 1"
          :teacher="teacher"
        />
      </transition>
    </div>
    <ProfileTeacherModalLessons
      @onSingUpLesson="openModalSignUpLesson"
      :listLessons="listLessons"
      v-model:listLessons="listLessons"
      :teacher="teacher"
      :statusModal="openModal"
      v-model:open="openModal"
      :openAddCard="openAddCardModal"
      v-model:openAddCard="openAddCardModal"
      :successStatus="successStatus"
      v-model:successStatus="successStatus"
      :badStatus="badStatus"
      v-model:badStatus="badStatus"
    />
    <PaymentModal
      :open="openAddCardModal"
      v-model:open="openAddCardModal"
      :newCardOpen="openNewCardModal"
      v-model:newCardOpen="openNewCardModal"
    />
    <OrderModal
      :open="openOrderModal"
      v-model:open="openOrderModal"
      :teacher="teacher"
      :lessons="listLessons"
      v-model:lessons="listLessons"
      @pay="onPay"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import PaymentModal from '@/components/PaymentMethods/PaymentModal/PaymentModal';
import {
  locationIcon, chatIcon, shieldIcon, tabletDocIcon
} from '@/constants/icons';
import { computed, ref } from 'vue';
import UserAvatar from '@/components/Main/UserElements/UserAvatar';
import { getCountry, getSubjects, getSpecialty } from '@/constants/utils/getValueById';
import UserRate from '@/components/Main/UserElements/UserRate';
import UserSubjects from '@/components/Main/UserElements/UserSubjects';
import declensionOfNum from '@/constants/utils/declensionOfNum';
import i18n from '@/i18n';
import ListTeachersCalendar from '@/components/ListTeachers/ListTeachersTimetable';
import numeral from 'numeral';
import ProfileTeacherModalLessons from '@/components/ProfileTeacher/ProfileTeacherModalLessons';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import FreeLabel from '@/components/Main/FreeLabel/FreeLabel';
import Heart from '@/components/Main/HeartIcon/HeartIcon';
import OrderModal from '@/components/PaymentMethods/OrderModal/OrderModal';
import ListTeachersVideo from '@/components/ListTeachers/ListTeachersVideo';

export default {
  name: 'ListTeachersCard',
  components: {
    ListTeachersVideo,
    OrderModal,
    FreeLabel,
    ProfileTeacherModalLessons,
    ListTeachersCalendar,
    UserSubjects,
    UserRate,
    UserAvatar,
    locationIcon,
    chatIcon,
    shieldIcon,
    tabletDocIcon,
    Heart,
    PaymentModal
  },
  props: {
    teacher: Object,
    date: String
  },
  setup (props) {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);

    const goTeacherProfile = () => ({ name: 'ProfileTeacher', params: { id: props.teacher._id } });

    const router = useRouter();
    const goRegistration = () => router.push({ name: 'NewStudent' });

    const country = getCountry(props.teacher.country);
    console.log(country);
    const subjects = getSubjects(props.teacher.subjects);
    const speciality = getSpecialty(props.teacher.speciality);

    const lessonsCount = computed(() => props.teacher.lessons || 0);
    const lessonsText = declensionOfNum(lessonsCount.value, i18n.global.tm('global.declensionLessons'));
    const lessons = `${lessonsCount.value} ${lessonsText}`;

    const yearsCount = computed(() => props.teacher.workExperience || 0);
    const yearsText = declensionOfNum(yearsCount.value, i18n.global.tm('global.declensionYears'));
    const years = `${yearsCount.value} ${yearsText}`;

    const commentsCount = computed(() => props.teacher.comments || 0);
    const commentsText = declensionOfNum(commentsCount.value, i18n.global.tm('global.declensionComment'));
    const comments = `${commentsCount.value} ${commentsText}`;

    const avatar = typeof props.teacher.uploadFile !== 'object' ? {} : props.teacher.uploadFile;

    const price = computed(() => numeral(props.teacher.lessonCost || 0).format('$0.00'));
    const openModal = ref(false);

    const hasTrial = computed(() => props.teacher.trialLessonAgreement);

    const openAddCardModal = ref(false);
    const openNewCardModal = ref(true);
    const openOrderModal = ref(false);
    const successStatus = ref(false);
    const badStatus = ref(false);

    const listLessons = ref([]);

    const onPay = ({ bad, success }) => {
      openOrderModal.value = false;
      openModal.value = true;
      successStatus.value = success;
      badStatus.value = bad;
    };

    const onCancel = () => {
      openOrderModal.value = false;
      openModal.value = true;
      listLessons.value = [];
    };

    const openModalSignUpLesson = (lesson) => {
      openOrderModal.value = true;
      openModal.value = false;
      listLessons.value.push(lesson);
    };

    const rightTab = ref(0);

    return {
      activeUser,
      listLessons,
      goTeacherProfile,
      goRegistration,
      onPay,
      onCancel,
      openModalSignUpLesson,
      country,
      subjects,
      speciality,
      avatar,
      lessons,
      years,
      comments,
      price,
      openModal,
      hasTrial,
      openAddCardModal,
      openNewCardModal,
      openOrderModal,
      successStatus,
      badStatus,
      rightTab
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-teacher-card {
  @include global-font;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: minmax(auto, 1fr) 428px;

  &-profile {
    position: relative;
    display: grid;
    grid-gap: 56px;
    align-items: start;
    grid-template-columns: 181px 1fr;
    padding: 24px 32px 51px;
    background: $color-white;
    color: $color-text;

    &-col1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__rate {
        margin: 16px auto 20px;
      }
    }

    &-col2 {
      &__name {
        position: relative;
        display: inline-block;
        font-weight: bold;
        font-size: 20px;
        line-height: 125%;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 1px;
          background-color: $color-text;
          transition: 0.2s ease-out;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      &__speciality {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        margin-bottom: 8px;
      }

      &-location {
        display: grid;
        grid-template-columns: 16px auto;
        grid-gap: 6px;
        align-items: center;
        margin: 16px 0;
        color: $color-text;
        font-weight: 500;
        font-size: 14px;
        line-height: 125%;
      }

      &-stats {
        display: inline-grid;
        grid-template-columns: repeat(3, auto);
        grid-gap: 60px;
        align-items: center;
        margin-bottom: 25px;
        text-transform: lowercase;
        font-size: 12px;
        line-height: 125%;
        color: rgba($color-black, 0.87);

        &__item {
          display: grid;
          grid-template-columns: 16px auto;
          grid-gap: 7px;
          align-items: center;
        }
      }

      &__about {
        margin-bottom: 17px;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
      }

      &-price {
        display: inline-grid;
        grid-template-columns: auto auto;
        align-items: start;
        grid-gap: 62px;

        &-item {
          display: inline-grid;
          grid-gap: 0 15px;
          align-items: center;
          grid-template-columns: auto auto;
          font-weight: bold;
          font-size: 16px;
          line-height: 125%;
          color: $color-text;

          &__title {
            font-weight: 800;
            font-size: 14px;
            line-height: 125%;
            color: $color-accent;
            text-transform: uppercase;
          }

          &__duration {
            font-weight: 500;
            font-size: 12px;
            line-height: 152%;
            color: $color-gray;
          }
        }
      }
    }
  }

  &-calendar {
    padding: 6px 24px 30px 24px;
    background: $color-white;
    overflow: hidden;

    &-header {
      display: flex;
      align-items: center;
      grid-gap: 32px;
      border-bottom: 1px solid $color-alto;
      padding: 0 24px 0;
      margin: 0 -24px 17px;

      &__item {
        position: relative;
        color: $color-gray;
        font-weight: bold;
        font-size: 14px;
        line-height: 125%;
        text-transform: uppercase;
        padding: 8px 0;
        transition: 0.3s;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 0;
          background-color: $color-accent;
          transition: 0.2s ease-out;
        }

        &:hover,
        &_active {
          color: $color-text;

          &:after {
            width: 100%;
          }
        }

        &_active {
          pointer-events: none;
        }
      }
    }

    &__button {
      margin-top: 34px;
      width: 100%;
    }
  }

}

</style>
