<template>
  <div class="lp-calendar-settings-schedule">
    <perfect-scrollbar class="lp-calendar-settings-schedule__table">
      <TemplateSchedule
        v-model:times="workTimes"
        @changeSchedule="changeSchedule"
        :updateDates="updateDates"
        :fullHeight="true"
        :selectPast="true"
        :date="date"
        :schedule="originalSchedule.availableTime || []"
        :lessons="lessons"
        :editMode="true"
      />
    </perfect-scrollbar>
    <div class="lp-calendar-settings-schedule-footer">
      <div class="lp-calendar-settings-schedule-footer__mark">
        <p>{{ $t('userTimetable.template.calendar.select') }}</p>
        <p>{{ $t('userTimetable.template.calendar.cancel') }}</p>
      </div>
      <transition name="bounce">
        <div v-if="updateDates.length || savingSchedule.afterSave">
          <button
            class="lp-button"
            :class="{'lp-calendar-settings-schedule-footer__button': savingSchedule.afterSave}"
            @click="saveSchedule"
          >
            <transition
              name="slideY"
              mode="out-in"
            >
              <template v-if="savingSchedule.afterSave">
                {{ $t('userTimetable.template.calendar.saved') }}
              </template>
              <template v-else-if="savingSchedule.saving">
                {{ $t('buttons.save') }}
              </template>
              <template v-else>
                {{ $t('buttons.save') }}
              </template>
            </transition>
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import TemplateSchedule from '@/components/Main/Calendar/TemplateSchedule';
import {
  computed, onBeforeMount, reactive, ref, watchEffect
} from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import {
  find, findIndex, get, groupBy
} from 'lodash';
import LessonsApi from '@/api/Lessons/api';
import ScheduleTemplatesApi from '@/api/ScheduleTemplates/api';
import LESSON_STATUS from '@/constants/enums/lessonStatus';

export default {
  name: 'CalendarSettingsSchedule',
  components: { TemplateSchedule },
  props: {
    lessons: Object,
    date: String
  },
  setup (props, { emit }) {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);
    const originalSchedule = ref({});
    const workTimes = ref([]);

    const getWorkTimes = (data) => data.map((time) => ({
      repeatable: time.repeatable,
      dayOfWeek: moment(time.startDate).day(),
      endDate: moment(time.endDate).format(),
      startDate: moment(time.startDate).format()
    }));

    const getTemplate = () => {
      const { _id: teacher } = activeUser.value;
      if (!teacher) return;
      setLoaderRun(true);
      ScheduleTemplatesApi.getSchedule(teacher).
        then(({ data }) => {
          workTimes.value = getWorkTimes(data.availableTime);
          originalSchedule.value = data;
          setLoaderRun(false);
        }).
        catch((err) => {
          setLoaderRun(false);
          console.error(err);
        });
    };

    onBeforeMount(getTemplate);

    const getWeekendLesson = (date) => {
      const momentDate = moment(date);
      const lessonsDate = momentDate.format('YYYY-MM-DD');
      const lessonStartTime = momentDate.format();
      const lessons = get(props.lessons, lessonsDate, []);
      return find(lessons, (lesson) => {
        const time = moment(lesson.lessonStartTime).format() === lessonStartTime;
        const status = lesson.lessonStatus === LESSON_STATUS.WEEKEND;
        return time && status;
      }) || {};
    };

    const delWeekends = async (lessons) => {
      const promises = lessons.map(async ({ lesson }) => {
        if (!lesson._id) return;
        await LessonsApi.deleteLesson(lesson._id);
        emit('update-lessons');
      });
      try {
        await Promise.all(promises);
      } catch (err) {
        console.error(err);
      }
    };

    const setWeekends = async (dates) => {
      const promises = dates.map(async ({ date }) => {
        const lesson = {
          lessonStartTime: moment(date).utc().format(),
          lessonEndTime: moment(date).add(1, 'hour').utc().format(),
          teacherId: activeUser.value._id
        };
        const { data } = await LessonsApi.signUpLesson(lesson);
        emit('update-lessons');
        return data;
      });
      try {
        await Promise.all(promises);
      } catch (err) {
        console.error(err);
      }
    };

    const setWorkTimes = async (dates) => {
      const fullList = dates.map(({ date }) => ({
        lesson: getWeekendLesson(date),
        repeatable: false,
        dayOfWeek: moment(date).day(),
        startDate: moment(date).format(),
        endDate: moment(date).add(1, 'hour').format()
      }));
      const weekends = fullList.filter(({ lesson }) => lesson._id);
      delWeekends(weekends);
      const schedule = fullList.filter(({ lesson }) => !lesson._id);
      const { _id, teacher, availableTime } = originalSchedule.value;
      const newSchedule = {
        _id,
        teacher,
        availableTime: [...availableTime, ...schedule]
      };
      try {
        const { data } = await ScheduleTemplatesApi.updateSchedule(newSchedule);
        workTimes.value = getWorkTimes(data.availableTime);
        originalSchedule.value = data;
      } catch (err) {
        console.error(err);
      }
    };

    const updateDates = reactive([]);
    const changeSchedule = (date, type) => {
      const index = findIndex(updateDates, (item) => item.date === date);
      if (index === -1) {
        updateDates.push({ date, type });
      } else {
        updateDates.splice(index, 1);
      }
    };

    const savingSchedule = reactive({
      notSave: true,
      saving: false,
      afterSave: false
    });

    const clearUpdateDates = () => {
      updateDates.splice(0, updateDates.length);
    };

    const saveSchedule = async () => {
      if (!updateDates.length || !savingSchedule.notSave) return;
      const dates = groupBy(updateDates, 'type');
      savingSchedule.notSave = false;
      savingSchedule.saving = true;
      await setLoaderRun(true);
      if (dates.del) await setWeekends(dates.del);
      if (dates.add) await setWorkTimes(dates.add);
      await setLoaderRun(false);
      savingSchedule.saving = false;
      savingSchedule.afterSave = true;
      clearUpdateDates();
      setTimeout(() => {
        savingSchedule.notSave = true;
        savingSchedule.saving = false;
        savingSchedule.afterSave = false;
      }, 3000);
    };

    const dates = computed(() => {
      const start = moment(props.date).format();
      const end = moment(props.date).add(7, 'day').format();
      return [start, end];
    });
    watchEffect(dates, emit('update:range', dates.value));

    return {
      workTimes,
      originalSchedule,
      updateDates,
      savingSchedule,
      saveSchedule,
      changeSchedule
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-calendar-settings-schedule {
  @include global-font;

  &__table {
    margin-bottom: 23px;
    width: calc(100% + 12px);
    padding-right: 10px;
    overflow: hidden;
    height: calc(100vh - 355px);
  }

  &-footer {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;
    align-items: start;
    min-height: 50px;

    &__mark {
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      color: $color-text;
    }

    &__button {
      background-color: $color-chateau-green;
      pointer-events: none;
    }
  }
}

</style>
