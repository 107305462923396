import { get, set } from 'lodash';
import i18n from '@/i18n';

const getErrorText = ({ response }) => {
  const defaultError = get(response, 'data.exception.message', 'errors.unique');
  const locales = {
    en: get(response, 'data.exception.response.eng', 'errors.unique'),
    ru: get(response, 'data.exception.response.rus', 'errors.unique')
  };
  const currentLocale = get(i18n, 'global.locale.value', 'ru');
  return locales[currentLocale] || defaultError;
};

const highlightAllBorders = (target, indexText) => {
  target.forEach((input, i) => {
    if (i !== indexText) set(input, 'error', true);
  });
};

export default ({
  err, target, index, all
}) => {
  const errorText = getErrorText(err);
  const valIndex = typeof index === 'number' ? index : target.length - 1;
  set(target, [valIndex, 'error'], errorText);
  if (all) highlightAllBorders(target, valIndex);
};

export { getErrorText };
