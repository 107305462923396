<template>
  <div class="lp-template-schedule">
    <div
      class="lp-template-schedule-column"
      :class="{'lp-template-schedule-column_full-height': fullHeight}"
      v-for="weekDay of fullWeek"
      :key="weekDay.date"
    >
      <div
        class="lp-template-schedule-column-header"
        :class="{
          'lp-template-schedule-column-header_past': selectPast && weekDay.isPast,
          'lp-template-schedule-column-header_monday': weekDay.id === 0,
          'lp-template-schedule-column-header_sunday': weekDay.id === 6
        }"
      >
        <span class="lp-template-schedule-column-header__title lp-template-schedule-column-header__title_full">
          {{ weekDay.title }}
        </span>
        <span class="lp-template-schedule-column-header__title lp-template-schedule-column-header__title_short">
          {{ weekDay.shortTitle }}
        </span>
      </div>
      <div
        class="lp-template-schedule-column__time"
        :class="{
          'lp-template-schedule-column__time_selected': time.freeDay,
          'lp-template-schedule-column__time_lesson': time.haveLesson
        }"
        @mousedown="setWorkTimes(time)"
        @mouseenter="mouseMoveOnTime(time)"
        v-for="time of weekDay.times"
        :key="time.id"
      >
        {{ time.title }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  fill, get, isEqual, find, findIndex
} from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  watch
} from 'vue';
import LESSON_STATUS from '@/constants/enums/lessonStatus';
import i18n from '@/i18n';

export default {
  name: 'TemplateSchedule',
  props: {
    weekSchedule: {
      type: Boolean,
      default: false
    },
    date: String,
    lessons: Object,
    selectPast: Boolean,
    fullHeight: Boolean,
    updateDates: Array,
    schedule: {
      type: Array,
      default: () => []
    },
    editMode: Boolean
  },
  setup (props, { emit }) {
    const workTimes = ref([]);

    const setDefaultWorkTimes = (newSchedule, oldSchedule) => {
      const newVal = newSchedule.map((item) => item.startDate).sort();
      const oldVal = oldSchedule.map((item) => item.startDate).sort();
      if (isEqual(newVal, oldVal)) return;
      workTimes.value = props.schedule.map((item) => {
        const momentDate = moment(item.startDate);
        item.fullDate = momentDate.format();
        item.day = momentDate.day();
        item.time = momentDate.format('HH:mm');
        return item;
      });
    };
    watch(() => props.schedule, setDefaultWorkTimes);

    const isWeekendLesson = (date) => {
      const momentDate = moment(date);
      const lessonsDate = momentDate.format('YYYY-MM-DD');
      const lessonStartTime = momentDate.format();
      const lessons = get(props.lessons, lessonsDate, []);
      return find(lessons, (lesson) => {
        const time = moment(lesson.lessonStartTime).format() === lessonStartTime;
        const status = lesson.lessonStatus === LESSON_STATUS.WEEKEND;
        return time && status;
      });
    };

    const checkSelected = (date) => {
      if (!workTimes.value.length) return false;
      const momentDate = moment(date);
      const day = momentDate.day();
      const time = momentDate.format('HH:mm');
      const fullDate = momentDate.format();
      const workTime = find(workTimes.value, { day, time });
      const workTimeByDate = find(workTimes.value, { fullDate });
      if (isWeekendLesson(date)) {
        return find(props.updateDates, { date: momentDate.format(), type: 'add' });
      }
      return workTime && (workTime.repeatable || workTimeByDate);
    };

    const haveLesson = (date) => {
      if (!workTimes.value.length) return false;
      const momentDate = moment(date);
      const lessonsDate = momentDate.format('YYYY-MM-DD');
      const lessonStartTime = momentDate.format();
      const lessons = get(props.lessons, lessonsDate, []);
      const lesson = find(lessons, (lesson) => moment(lesson.lessonStartTime).format() === lessonStartTime);
      return lesson && lesson.lessonStatus !== LESSON_STATUS.WEEKEND;
    };

    const updateWorkTimes = () => {
      const times = workTimes.value.map((time) => {
        const date = moment(time.fullDate);
        time.dayOfWeek = date.day();
        time.startDate = date.format();
        time.endDate = date.add(1, 'hour').format();
        return time;
      });
      emit('update:times', times);
    };
    watch(workTimes, updateWorkTimes);

    const locale = get(i18n, 'global.locale.value', 'ru');
    const startWeek = computed(() => {
      const date = props.editMode && props.date ? moment(props.date) : moment().startOf('isoWeek');
      return date.format('YYYY-MM-DD');
    });
    const week = computed(() => fill(new Array(7), startWeek.value).map((el, i) => {
      const date = moment(el).add(i, 'd').locale(locale);
      const formatDays = props.fullHeight ? 'DD dddd' : 'DD dd';
      return {
        date: date.format('YYYY-MM-DD'),
        id: i,
        isPast: moment().subtract(1, 'day').isAfter(date),
        title: date.format(props.editMode ? formatDays : 'dd'),
        shortTitle: date.format(props.editMode ? 'DD dd' : 'dd')
      };
    }));

    const startTime = 6;
    const times = fill(new Array(24), startTime).map((time, i) => {
      const num = (time + i);
      const hour = numeral(num >= 24 ? num - 24 : num).format('00');
      const timezone = numeral(moment().utcOffset() / 60).format('00');
      return {
        title: `${hour}:00`,
        id: `${hour}:00:00+${timezone}:00`
      };
    });

    const fullWeek = ref([]);
    const setFullWeek = () => {
      fullWeek.value = week.value.map((weekDay) => {
        weekDay.times = times.map((time) => {
          const fullDate = `${weekDay.date}T${time.id}`;
          return {
            title: time.title,
            id: time.id,
            haveLesson: haveLesson(fullDate),
            freeDay: checkSelected(fullDate),
            fullDate
          };
        });
        return weekDay;
      });
    };
    watch(props, setFullWeek);
    watch(startWeek, setFullWeek);
    onBeforeMount(setFullWeek);

    const startSelectTimes = ref(false);
    const setWorkTimes = (item) => {
      if (item.haveLesson) return;
      const { fullDate } = item;
      startSelectTimes.value = true;
      const weekendLesson = isWeekendLesson(fullDate);
      const momentDate = moment(fullDate);
      const day = momentDate.day();
      const time = momentDate.format('HH:mm');
      const index = findIndex(workTimes.value, { day, time });
      const workTime = workTimes.value[index] || {};
      const indexByTime = findIndex(workTimes.value, { fullDate, repeatable: false });
      const res = {
        fullDate, day, time, repeatable: props.weekSchedule
      };
      if (index === -1 || (workTime.fullDate !== fullDate && indexByTime === -1 && !workTime.repeatable)) {
        workTimes.value.push(res);
        emit('change-schedule', fullDate, 'add');
      } else if (!weekendLesson) {
        workTimes.value.splice(indexByTime === -1 ? index : indexByTime, 1);
        emit('change-schedule', fullDate, 'del');
      } else {
        emit('change-schedule', fullDate, 'add');
      }
      if (props.weekSchedule) {
        const timesByDate = find(fullWeek.value, { date: momentDate.format('YYYY-MM-DD') }) || {};
        const currentTime = find(timesByDate.times, { fullDate }) || {};
        currentTime.freeDay = checkSelected(fullDate);
        updateWorkTimes();
      }
    };
    const mouseMoveOnTime = (time) => {
      if (!startSelectTimes.value) return;
      setWorkTimes(time);
    };
    const onMouseup = () => {
      startSelectTimes.value = false;
    };

    onBeforeMount(() => {
      document.addEventListener('mouseup', onMouseup, false);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('mouseup', onMouseup, false);
    });

    return {
      workTimes,
      setWorkTimes,
      startSelectTimes,
      mouseMoveOnTime,
      week,
      times,
      fullWeek,
      checkSelected,
      haveLesson
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-template-schedule {
  @include global-font;
  display: grid;
  grid-template-columns: repeat(7, minmax(50px, 1fr));
  grid-gap: 6px;
  width: calc(100% - 2px);
  height: 100%;

  &-column {
    display: grid;
    grid-gap: 2px;
    text-align: center;
    user-select: none;

    &_full-height {
      grid-template-rows: 36px repeat(24, minmax(24px, auto));
    }

    &-header {
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      margin-bottom: 6px;
      background: $color-moon-raker;
      box-sizing: border-box;
      text-transform: uppercase;
      color: $color-accent;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.07em;
      z-index: 3;

      &__title {
        position: relative;
        height: 100%;
        white-space: nowrap;
        z-index: 2;

        &_full {
          @media (max-width: 1300px) {
            display: none;
          }
        }

        &_short {
          display: none;
          @media (max-width: 1300px) {
            display: block;
          }
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% + 6px);
        height: 100%;
        background-color: $color-moon-raker;
        border-top: 1px solid $color-alto;
        border-bottom: 1px solid $color-alto;
        z-index: 1;
      }

      &_past {
        background: $color-alabaster;
        color: $color-gray;

        &:after {
          background-color: $color-alabaster;
        }
      }

      &_monday {
       &:after {
         border-radius: 4px 0 0 4px;
         border-left: 1px solid $color-alto;
       }
      }

      &_sunday {
        &:after {
          border-radius: 0 4px 4px 0;
          border-right: 1px solid $color-alto;
          width: 100%;
        }
      }
    }

    &__time {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-alto;
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      padding: 3px;
      font-weight: 500;
      font-size: 13px;
      line-height: 125%;
      color: $color-gray;
      cursor: pointer;
      transition: 0.3s ease-in;

      &_selected {
        background-color: $color-emerald;
        border-color: $color-emerald;
        color: $color-white;
        font-weight: bold;
      }

      &_lesson {
        background-color: $color-white;
        border: 1px solid $color-alto;
        color: $color-gray;
        cursor: not-allowed;
        opacity: 0.7;

        &:hover {
          opacity: 0.7;
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

</style>
