<template>
  <div class="lp-teachers-video">
    <iframe
      class="lp-teachers-video__iframe"
      frameborder="0"
      allowfullscreen
      :src="videoUrl"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { get } from 'lodash';
import urlParser from 'js-video-url-parser';
import VIDEO_PROVIDER from '@/constants/enums/videoProvider';

export default {
  name: 'ListTeachersVideo',
  props: {
    teacher: Object
  },
  setup (props) {
    const videoUrl = computed(() => {
      const url = get(props.teacher, 'presentationUrl', '');
      const videoInfo = urlParser.parse(url) || {};
      if (videoInfo.provider === VIDEO_PROVIDER.YOUTUBE) return `https://www.youtube.com/embed/${videoInfo.id}`;
      if (videoInfo.provider === VIDEO_PROVIDER.VIMEO) return `https://player.vimeo.com/video/${videoInfo.id}`;
      return '';
    });

    return { videoUrl };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-teachers-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin-top: 28px;

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

</style>
