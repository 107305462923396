import { uniqueId, cloneDeep } from 'lodash';
import { reactive } from 'vue';
import { getCountry } from '@/constants/utils/getValueById';

const inputs = [
  {
    autofocus: true,
    type: 'text',
    required: true,
    label: 'profile.forms.basic.inputs.firstName',
    modelName: 'firstName',
    modelValue: '',
    upperFirst: true,
    emptyError: 'errors.emptyName'
  },
  {
    type: 'text',
    required: true,
    label: 'profile.forms.basic.inputs.lastName',
    modelName: 'lastName',
    modelValue: '',
    upperFirst: true,
    emptyError: 'errors.emptyLastName',
    style: {
      gridColumn: '2'
    }
  },
  {
    type: 'countrySelect',
    required: true,
    label: 'profile.forms.basic.inputs.country',
    modelName: 'country',
    modelValue: {},
    emptyError: 'errors.emptyCountry',
    getValue: (model) => model.id,
    getFullObject: getCountry
  },
  {
    type: 'text',
    label: 'profile.forms.basic.inputs.city',
    modelName: 'city',
    modelValue: '',
    upperFirst: true,
    style: {
      gridColumn: '2'
    }
  },
  {
    type: 'dateSelect',
    required: true,
    fixed: true,
    label: 'profile.forms.basic.inputs.birthday',
    modelName: 'age',
    modelValue: '',
    emptyError: 'errors.notDate',
    validError: 'errors.notDate',
    isValid: (val) => val.split('-').every((item) => item !== 'undefined'),
    style: {
      gridColumn: '1/3',
      gridRow: '3'
    }
  },
  {
    type: 'textarea',
    label: 'profile.forms.basic.inputs.aboutYourself',
    modelName: 'about',
    modelValue: '',
    style: {
      gridColumn: '1/3',
      height: '141px'
    }
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('profile-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  return item;
}));
