<template>
  <section class="lp-landing-teachers">
    <h2 class="lp-landing-teachers__title">
      Наши преподаватели
    </h2>
    <div class="lp-landing-teachers-list">
      <TeacherCard
        v-for="(teacher, index) of teachers"
        :key="index"
        :teacher="teacher"
      />
    </div>
    <div class="lp-landing-teachers__button">
      <button
        class="lp-button"
        @click.stop="$emit('update:openForm', true)"
      >
        {{ $t('landing.teachers.action') }}
      </button>
    </div>
  </section>
</template>

<script>
import TeacherCard from '@/components/Landing/Teachers/TeacherCard';
import teachers from '@/constants/landing/teachers/teachers';

export default {
  name: 'Teachers',
  components: { TeacherCard },
  emits: ['update:openForm'],
  setup () {
    return {
      teachers
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-landing-teachers {
  margin-bottom: 150px;

  @media (max-width: 767px) {
    margin-bottom: 100px;
  }

  &__title {
    font-weight: bold;
    font-size: 36px;
    line-height: 125%;
    text-align: center;
    color: $color-text;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 50px;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(2, 617px);
    grid-gap: 56px;
    justify-content: center;
    align-items: start;
    margin-bottom: 80px;

    @media (max-width: 1300px) {
      grid-gap: 20px;
      grid-template-columns: 1fr;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    margin: 0 auto 60px;
  }
}

</style>
