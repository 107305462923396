<template>
  <div
    class="lp-free-label"
    ref="bodyLabel"
  >
    <div
      class="lp-free-label__icon"
      :class="{'lp-free-label__icon_past': past}"
      @mouseenter="openTooltip = true"
      @mouseleave="openTooltip = false"
    >
      <freeIcon />
    </div>
    <Tooltip
      class="lp-free-label__tooltip"
      :text="$t('listTeachers.filters.free')"
      :style="getPosition()"
      :open="openTooltip"
    />
  </div>
</template>

<script>
import { freeIcon } from '@/constants/icons';
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import { ref } from 'vue';

export default {
  name: 'FreeLabel',
  components: { Tooltip, freeIcon },
  props: {
    past: Boolean
  },
  setup () {
    const openTooltip = ref(false);
    const bodyLabel = ref(null);

    const getPosition = () => {
      if (!bodyLabel.value) return {};
      const position = bodyLabel.value.getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${7 + position.top + position.height}px`,
        left: 'auto',
        width: '170px'
      };
    };

    return { bodyLabel, getPosition, openTooltip };
  }
};
</script>

<style lang="scss">
@import "../../../sass/style";

.lp-free-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: $color-text;

        &:first-child {
          fill: $color-supernova;
        }
      }
    }

    &_past {
      svg {
        path {
          fill: $color-gray;

          &:first-child {
            fill: $color-alto;
          }
        }
      }
    }
  }

  &__tooltip {
    top: 28px;
    left: auto;
    white-space: nowrap;
  }
}

</style>
