<template>
  <div class="lp-profile-stat-experience">
    <div class="lp-profile-stat-experience__value">
      {{ value || 0 }}
    </div>
    <div class="lp-profile-stat-experience__title">
      {{ isTeacher ? `${year} ${$t('profile.stats.teacher.experience')}` : $t('profile.stats.student.attendance') }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import declensionOfNum from '@/constants/utils/declensionOfNum';
import i18n from '@/i18n';

export default {
  name: 'ProfileStatsExperience',
  props: {
    value: Number,
    isTeacher: Boolean
  },
  setup (props) {
    const year = computed(() => declensionOfNum(props.value, i18n.global.tm('global.declensionYears')));

    return { year };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-profile-stat-experience {
  @include global-font;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: $color-mabel;
  background-image: url("../../../assets/images/chartBarBackground.svg");
  background-repeat: no-repeat;
  background-position: 0 21px;

  &__value {
    text-align: center;
    font-weight: bold;
    font-size: 37px;
    line-height: 1;
  }

  &__title {
    text-align: center;
    margin-top: 4px;
    font-weight: bold;
    font-size: 14px;
  }
}

</style>
