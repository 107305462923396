<template>
  <div class="lp-webcam-modal">
    <div class="lp-webcam-modal-devices">
      <div class="lp-webcam-modal-devices__title">
        {{ $t('webcamModal.audio') }}
      </div>
      <div
        class="lp-webcam-modal-devices__item"
        v-for="item in audioDevises"
        :key="item.deviceId"
        @click="$emit('set-device', item)"
      >
        {{ item.label }}
      </div>
      <div class="lp-webcam-modal-devices__title lp-webcam-modal-devices__title_video">
        {{ $t('webcamModal.video') }}
      </div>
      <div
        class="lp-webcam-modal-devices__item"
        v-for="item in videoDevises"
        :key="item.deviceId"
        @click="$emit('set-device', item)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { VIDEO_INPUT, AUDIO_INPUT } from '@/constants/webCam';
import { computed } from 'vue';
import { groupBy, get } from 'lodash';

export default {
  name: 'WebcamModal',
  props: {
    allDevises: Array
  },
  setup (props) {
    const devicesByType = computed(() => groupBy(props.allDevises, 'kind'));
    const videoDevises = computed(() => get(devicesByType.value, VIDEO_INPUT, []));
    const audioDevises = computed(() => get(devicesByType.value, AUDIO_INPUT, []));

    return {
      videoDevises,
      audioDevises
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-webcam-modal {
  width: 100%;
  height: auto;
  min-width: 300px;
  z-index: 2;

  &-devices {
    box-shadow: 0 0 15px rgba($color-black, 0.05);
    border-radius: 4px;
    background-color: $color-white;
    padding: 8px 0;

    &__title {
      font-size: 12px;
      line-height: 125%;
      font-weight: bold;
      color: $color-mine-shaft;
      padding: 8px 24px;

      &_video {
        border-top: 1px solid $color-alto;
        margin-top: 8px;
      }
    }

    &__item {
      color: $color-text;
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      margin: 0 8px;
      background-color: $color-white;
      transition: 0.3s ease-in;
      cursor: pointer;

      &:hover {
        color: $color-accent;
        background-color: $color-moon-raker;
      }
    }
  }
}

</style>
