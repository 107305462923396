import { find } from 'lodash';
import country from '@/constants/country';
import specialty from '@/constants/specialty';
import subjects from '@/constants/subjects';

export const getCountry = (id) => find(country, { id }) || {};

export const getSpecialty = (id) => find(specialty, { id }) || {};

export const getSubjects = (arraySubjects) => arraySubjects.map((subject) => find(subjects, { id: subject }) || {});
