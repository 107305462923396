<template>
  <section
    class="admin-teachers"
    v-if="!loaderRun"
  >
    <LocalLoader v-if="loader" />
    <adminPageHeader
      :headerPageOptions="headerPageOptions"
      :activeUsers="activeUsers"
      :activeCallUsers="activeCallUsers"
    />
    <LPTable
      :columns="teachersList"
      :rows="teachers"
      :limit="queryParams.perPage"
      :page="queryParams.page"
      :totalPages="fullInfo.totalPages"
      :maxHeight="'calc(100vh - 260px)'"
      :customTableClass="'lp-table_admin'"
      :sortQuery="sortByTeachers"
      :sort="queryParams.sort"
    />
  </section>
  <LPPagination
    v-if="teachers.length"
    :data="fullInfo"
    :getQuery="getQueryPagination"
    :limit="queryParams.perPage"
    :page="queryParams.page"
    :sticky="false"
    :customClass="'lp-pagination_offset'"
  />
</template>

<script>
import adminPageHeader from '@/components/Admin/AdminPageHeader';
import LPPagination from '@/components/Main/Table/Pagination/LPPagination';
import LPTable from '@/components/Main/Table/LPTable';
import LocalLoader from '@/components/Main/Loader/LocalLoader';
import { computed, ref, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import teachersList from '@/constants/tableSettings/teachersList';
import TeachersApi from '@/api/Teachers/api';
import { useStore } from 'vuex';
import queryString from 'query-string';

export default {
  name: 'Teachers',
  components: {
    adminPageHeader,
    LPTable,
    LPPagination,
    LocalLoader
  },
  setup () {
    const store = useStore();
    const loaderRun = computed(() => store.getters.loaderRun);
    const loader = ref(false);

    const router = useRouter();
    const headerPageOptions = computed(() => ({
      title: 'teachers.title'
    }));
    const teachers = ref([]);
    const fullInfo = ref({});
    const noData = reactive({
      status: false,
      title: '',
      description: ''
    });
    const queryParams = ref({
      page: 1,
      perPage: 50,
      sort: ''
    });

    const getPaginationData = ({
      totalDocs, page, perPage
    }) => {
      const totalPages = Math.ceil(totalDocs / perPage);
      const nextPage = totalPages > page ? page + 1 : undefined;
      const prevPage = page !== 1 ? page - 1 : undefined;
      fullInfo.value = {
        totalPages,
        page,
        nextPage,
        prevPage,
        totalDocs
      };
    };

    const downlink = () => {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnetion;
      const downlink = Number(connection.downlink);
      return downlink;
    };

    const getRequests = (queryParams) => {
      const {
        page = queryParams.value.page,
        perPage = queryParams.value.perPage,
        sort = queryParams.value.sort
      } = queryParams;
      queryParams.value = {
        page,
        perPage,
        sort
      };
      router.push({
        query: {
          page,
          perPage,
          sort: sort || undefined
        }
      }).catch(() => {});
      loader.value = downlink() <= 3;
      TeachersApi.getToolTeachersStatisticks({
        page, perPage, sort
      }).
        then((resp) => {
          loader.value = false;
          const { totalDocs, resource } = resp.data;
          noData.status = totalDocs === 0;
          getPaginationData({ totalDocs, page, perPage });
          teachers.value = [];
          resource.forEach((teacher) => {
            const {
              firstName,
              lastName,
              averageLessonLength,
              lastActivity,
              subscriptionExpireDate,
              finishedLessons,
              pendingCRMLessons,
              totalCRMStudents,
              totalEarnedOnCRM,
              totalPayments
            } = teacher;
            teachers.value.push({
              ...teacher,
              crm: {
                finishedLessons,
                pendingCRMLessons,
                totalCRMStudents,
                totalPayments,
                totalEarnedOnCRM
              },
              fullName: `${firstName || ''} ${lastName || ''}`,
              averageLessonLength: averageLessonLength === null ? '' : averageLessonLength,
              lastActivity: lastActivity === null ? '' : lastActivity,
              subscriptionExpireDate: subscriptionExpireDate === null ? '' : subscriptionExpireDate
            });
          });
        }).catch(() => {
          loader.value = false;
          const type = 'teacher';
          router.push({ name: 'Profile', params: { type } });
        });
    };

    const getQueryPagination = ({ page, perPage }) => {
      queryParams.value = {
        ...queryParams.value,
        page,
        perPage
      };
      getRequests(queryParams);
    };

    const sortByTeachers = (sort) => {
      queryParams.value = {
        ...queryParams.value,
        sort
      };
      getRequests(queryParams);
    };

    const activeUsers = ref(0);

    const activeCallUsers = ref(0);

    const getToolTeachersAciveCounter = () => {
      TeachersApi.getToolTeachersAciveCounter().
        then(({ data }) => {
          activeUsers.value = data;
        }).
        catch((error) => {
          console.error(error);
        });
    };

    const getActiveCallUsersCounter = () => {
      TeachersApi.getCallAciveCounter().
        then(({ data }) => {
          activeCallUsers.value = data;
        }).
        catch((error) => {
          console.error(error);
        });
    };

    onMounted(async () => {
      const { location } = window;
      const parsed = queryString.parse(location.search);
      const {
        page = queryParams.value.page,
        perPage = queryParams.value.perPage,
        sort = queryParams.value.sort
      } = parsed;
      queryParams.value = {
        page: +page < 1 ? 1 : +page,
        perPage: +perPage < 1 ? 1 : +perPage,
        sort
      };
      await getRequests(queryParams);
      getToolTeachersAciveCounter();
      getActiveCallUsersCounter();
    });

    return {
      headerPageOptions,
      teachers,
      teachersList,
      fullInfo,
      queryParams,
      getRequests,
      loaderRun,
      loader,
      activeUsers,
      activeCallUsers,
      sortByTeachers,
      getQueryPagination
    };
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/style";

.admin-teachers {
  background: $color-white;
  padding: 28px 40px 0 40px;
}
</style>
