<template>
  <div class="lp-status">
    <div
      v-if="status === 0"
      class="lp-status-time"
      :class="{'lp-status-time_empty': !hours && !minutes}"
    >
      <div class="lp-status-time__val">
        {{ daysVal ? `${daysVal}:` : '' }}{{ hoursVal }}:{{ minutesVal }}
      </div>
    </div>
    <div
      v-else
      class="lp-status-star"
    >
      <starIconTable />
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed } from 'vue';
import { starIconTable } from '@/constants/icons';

export default {
  name: 'StatusCell',
  components: {
    starIconTable
  },
  props: {
    column: Object,
    row: Object
  },
  setup (props) {
    const status = computed(() => get(props.row, props.column.prop.accountStatus, ''));
    const freeTimeLeft = computed(() => get(props.row, props.column.prop.freeTimeLeft, ''));

    const time = computed(() => new Date(freeTimeLeft.value));

    const hours = computed(() => time.value.getUTCHours());
    const minutes = computed(() => time.value.getUTCMinutes());
    const hoursVal = computed(() => (hours.value.toString().length === 1 ? `0${hours.value}` : hours.value));
    const minutesVal = computed(() => (minutes.value.toString().length === 1 ? `0${minutes.value}` : minutes.value));

    const daysTime = computed(() => time.value - (hours.value * 3600 * 1000) - (minutes.value * 60 * 1000));
    const days = computed(() => Math.trunc((daysTime.value / 1000) / 86400));
    const daysVal = computed(() => (days.value && days.value < 10 ? `0${days.value}` : days.value));

    return {
      status,
      freeTimeLeft,
      hours,
      minutes,
      hoursVal,
      minutesVal,
      daysVal
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/style.scss";

.lp-status {

  &__text {
    display: inline-block;
    margin-left: 5px;
  }

  &-start {
    svg {
      max-width: 50px;
    }
  }

  &-time {
    background: $color-supernova;
    font-family: $global-font;
    border-radius: 4px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    &_empty {
      background: $color-alto;
    }

    &__val {
      color: $color-mine-shaft;
      font-size: 10px;
      line-height: 12px;
      font-weight: 800;
      font-family: $global-font;

      &_minutes {
        margin-left: 3px;
      }
    }
  }
}

</style>
