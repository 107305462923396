<template>
  <div
    class="lp-webcam-controls"
    v-click-outside="closeModal"
  >
    <div class="lp-webcam-controls-settings">
      <div
        class="lp-webcam-controls-settings__icon"
        @click="isShowSettings = !isShowSettings"
      >
        <videoSettingsIcon />
        <transition name="bounce">
          <WebcamModal
            class="lp-webcam-controls-settings__modal"
            v-if="isShowSettings"
            :allDevises="allDevises"
            @setDevice="$emit('set-device', $event)"
          />
        </transition>
      </div>
    </div>
    <div class="lp-webcam-controls-item">
      <div
        class="lp-webcam-controls-item__icon"
        :class="{'lp-webcam-controls-item__icon_disabled': !videoEnabled}"
        @click="changeEnableVideo"
      >
        <cameraIcon />
      </div>
    </div>
    <div class="lp-webcam-controls-item">
      <div
        class="lp-webcam-controls-item__icon"
        :class="{'lp-webcam-controls-item__icon_disabled': !audioEnabled}"
        @click="changeEnableAudio"
      >
        <microphoneIcon />
      </div>
    </div>
  </div>
</template>

<script>
import { microphoneIcon, cameraIcon, videoSettingsIcon } from '@/constants/icons';
import { ref } from 'vue';
import WebcamModal from '@/components/Main/Webcam/WebcamModal';

export default {
  name: 'WebcamControls',
  components: {
    WebcamModal,
    microphoneIcon,
    cameraIcon,
    videoSettingsIcon
  },
  props: {
    allDevises: Array
  },
  setup (props, { emit }) {
    const isShowSettings = ref(false);
    const closeModal = () => {
      isShowSettings.value = false;
    };

    const videoEnabled = ref(true);
    const audioEnabled = ref(true);

    const changeEnableVideo = () => {
      const value = !videoEnabled.value;
      videoEnabled.value = value;
      emit('video-enabled', value);
    };
    const changeEnableAudio = () => {
      const value = !audioEnabled.value;
      audioEnabled.value = value;
      emit('audio-enabled', value);
    };

    return {
      videoEnabled,
      audioEnabled,
      changeEnableVideo,
      changeEnableAudio,
      closeModal,
      isShowSettings
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-webcam-controls {
  @include global-font;
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 1fr 42px 42px 1fr;
  grid-gap: 10px;
  padding: 0 8px;
  background-color: $color-mine-shaft;
  border-radius: 0 0 4px 4px;

  &-settings {
    position: relative;
    display: flex;
    align-items: center;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.3s ease-out;
      position: relative;

      &:hover {
        border-radius: 40%;
        background-color: rgba($color-white, 0.07);
      }
    }

    &__modal {
      position: absolute;
      bottom: 0;
      left: 45px;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    justify-self: center;

    &__icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $color-gray;
      cursor: pointer;
      transition: 0.3s ease-out;
      overflow: hidden;

      &:hover {
        border-radius: 40%;
        background-color: rgba($color-dusty-gray, 0.7);
      }

      &:before {
        content: '';
        position: absolute;
        top: -3px;
        right: 20px;
        width: 3px;
        height: calc(100% + 6px);
        background-color: rgba($color-red, 0.5);
        transform: rotate(45deg) scale(0);
        transition: 0.2s ease-out;
      }

      &_disabled {
        &:before {
          transform: rotate(45deg) scale(1);
        }
      }
    }
  }
}

</style>
