<template>
  <div class="lp-profile-header">
    <img
      src="@/assets/images/profileBackground.png"
      class="lp-profile-header__background"
    />
    <div
      class="lp-profile-header__edit lp-edit-button lp-edit-button_white"
      v-if="false"
    >
      <!--  v-if="!viewMode"  -->
      <editIconBig />
    </div>
    <section class="lp-profile-header-user">
      <UserAvatar
        :avatar="avatar"
        :country="userCountry"
      />
      <div class="lp-profile-header-user-info">
        <div class="lp-profile-header-user-info__name">
          {{ user.firstName }} {{ user.lastName }}
        </div>
        <div
          class="lp-profile-header-user-info__location"
          v-if="viewMode"
        >
          <locationIcon />
          <span>
            {{ userCountry.name }}
            {{ user.city ? ', ' + user.city : '' }}
          </span>
        </div>
        <div class="lp-profile-header-user-info__specialty">
          {{ userSpeciality.title }}
        </div>
        <div
          class="lp-profile-header__edit lp-profile-header__edit_info lp-edit-button"
          v-if="!viewMode"
          @click.stop="$emit('update:open', true)"
        >
          <editIcon />
        </div>
      </div>
    </section>
    <template v-if="isTeacher">
      <h3 class="lp-section-title">
        {{ $t('profile.sections.subjects') }}
      </h3>
      <UserSubjects
        class="lp-profile-header-subjects"
        :subjects="userSubjects"
      />
    </template>
    <template v-if="user.about">
      <h3 class="lp-section-title">
        {{ $t('profile.sections.about') }}
      </h3>
      <section class="lp-profile-header-about">
        <pre class="lp-profile-header-about__text">{{ user.about }}</pre>
      </section>
    </template>
    <div
      class="lp-profile-header-presentation"
      v-if="videoUrl"
    >
      <iframe
        class="lp-profile-header-presentation__video"
        frameborder="0"
        allowfullscreen
        :src="videoUrl"
      />
    </div>
  </div>
</template>

<script>
import {
  ref, onBeforeMount, watch, computed
} from 'vue';
import { editIcon, editIconBig, locationIcon } from '@/constants/icons';
import { get } from 'lodash';
import { getCountry, getSpecialty, getSubjects } from '@/constants/utils/getValueById';
import ROLE_TYPES from '@/constants/enums/roles';
import UserAvatar from '@/components/Main/UserElements/UserAvatar';
import UserSubjects from '@/components/Main/UserElements/UserSubjects';
import urlParser from 'js-video-url-parser';
import VIDEO_PROVIDER from '@/constants/enums/videoProvider';

export default {
  name: 'ProfileHeader',
  components: {
    UserSubjects,
    UserAvatar,
    editIcon,
    editIconBig,
    locationIcon
  },
  props: {
    user: Object,
    avatar: Object,
    viewMode: Boolean
  },
  setup (props) {
    const isTeacher = computed(() => props.user.role === ROLE_TYPES.TEACHER);

    const userCountry = ref({});
    const setUserCountry = () => {
      const id = get(props.user, 'country', '');
      userCountry.value = getCountry(id);
    };

    const userSpeciality = ref({});
    const setUserSpeciality = () => {
      const id = get(props.user, 'speciality', '');
      userSpeciality.value = getSpecialty(id);
    };

    const userSubjects = ref([]);
    const setUserSubjects = () => {
      const arraySubjects = get(props.user, 'subjects', []);
      userSubjects.value = getSubjects(arraySubjects);
    };

    const updateAll = () => {
      setUserCountry();
      setUserSpeciality();
      setUserSubjects();
    };

    watch(() => props.user, updateAll);

    onBeforeMount(updateAll);

    const videoUrl = computed(() => {
      const url = get(props.user, 'presentationUrl', '');
      const videoInfo = urlParser.parse(url) || {};
      if (videoInfo.provider === VIDEO_PROVIDER.YOUTUBE) return `https://www.youtube.com/embed/${videoInfo.id}`;
      if (videoInfo.provider === VIDEO_PROVIDER.VIMEO) return `https://player.vimeo.com/video/${videoInfo.id}`;
      return '';
    });

    return {
      userCountry,
      userSpeciality,
      userSubjects,
      isTeacher,
      videoUrl
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-profile-header {
  @include global-font;
  position: relative;
  background-color: $color-white;
  padding: 102px 40px 40px 40px;
  box-shadow: 0 0 15px rgba($color-black, 0.02);

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    height: 178px;
    width: 100%;
    object-fit: cover;
    object-position: left top;
  }

  &__edit {
    position: absolute;
    top: 16px;
    right: 16px;

    &_info {
      top: -7px;
      right: -17px;
    }
  }

  &-user {
    position: relative;
    display: flex;
    margin-bottom: 33px;

    &-info {
      position: relative;
      flex-grow: 1;
      margin-top: 95px;
      margin-left: 19px;
      color: $color-text;

      &__name {
        font-weight: bold;
        font-size: 25px;
        line-height: 125%;
        margin-bottom: 4px;
      }

      &__location {
        display: grid;
        grid-template-columns: 16px auto;
        grid-gap: 6px;
        align-items: center;
        color: $color-text;
        font-weight: 500;
        font-size: 14px;
        line-height: 125%;
      }

      &__specialty {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
      }
    }
  }

  &-subjects {
    margin: 13px 0 24px -12px;
  }

  &-about {
    margin-top: 12px;

    &__text {
      @include global-font;
      white-space: pre-wrap;
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
    }
  }

  &-presentation {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    margin-top: 28px;

    &__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
