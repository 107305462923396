<template>
  <div class="lp-profile-form-price">
    <div class="lp-profile-form-price-slider">
      <div class="lp-profile-form-price-slider-info">
        <div class="lp-profile-form-price-slider-info__title">
          {{ $t('profile.forms.price.title') }}
        </div>
        <div class="lp-profile-form-price-slider-info__value">
          ${{ value }}
        </div>
        <div class="lp-profile-form-price-slider-info__duration">
          {{ $t('profile.forms.price.lessonDuration') }}
        </div>
        <transition name="bounce">
          <span
            class="lp-error lp-profile-form-price-slider-info__error"
            v-if="error"
          >
            <dangerIcon />
            {{ $t('errors.emptyPrice') }}
          </span>
        </transition>
      </div>
      <div
        class="lp-profile-form-price-slider__input"
        @click.stop="setValue"
        ref="inputSlider"
      >
        <VueSlider
          v-model="value"
          @change="onRangeChanged"
          :adsorb="true"
          :interval="1"
          :marks="[5, 10, 15, 20, 25, 30, 35, 40, 45, 50]"
          :min="minValue"
          :max="maxValue"
          :dotSize="16"
          :height="16"
          :useKeyboard="false"
          :process="true"
          tooltip="none"
        />
      </div>
    </div>
    <div class="lp-profile-form-price-trial">
      <div class="lp-profile-form-price-trial__title">
        {{ $t('profile.forms.price.hasTrial') }}
      </div>
      <CustomRadio
        class="lp-profile-form-price-trial__radio"
        :options="hasTrial"
      />
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import { dangerIcon } from '@/constants/icons';
import 'vue-slider-component/theme/default.css';
import {
  onBeforeMount, reactive, ref, watch
} from 'vue';
import CustomRadio from '@/components/Main/Fields/CustomRadio';

export default {
  name: 'ProfileFormPrice',
  components: {
    CustomRadio,
    VueSlider,
    dangerIcon
  },
  props: {
    trial: Boolean,
    price: Number,
    error: Boolean
  },
  setup (props, { emit }) {
    const maxValue = 50;
    const minValue = 5;
    const value = ref(0);
    const onRangeChanged = (e) => {
      emit('update:error', false);
      emit('update:price', e);
    };

    const hasTrial = reactive([
      {
        id: true,
        label: 'profile.forms.price.agreeTrial',
        value: true,
        name: 'trial'
      },
      {
        id: false,
        label: 'profile.forms.price.disagreeTrial',
        value: false,
        name: 'trial'
      }
    ]);

    onBeforeMount(() => {
      value.value = props.price;
      hasTrial.forEach((item) => {
        item.value = props.trial === item.id;
      });
    });

    watch(hasTrial, () => {
      emit('update:trial', hasTrial[0].value);
    });

    const inputSlider = ref(null);
    watch(() => props.error, () => {
      if (props.error) inputSlider.value.scrollIntoView();
    });

    const setValue = () => {
      if (value.value) return;
      value.value = minValue;
    };

    return {
      setValue,
      minValue,
      maxValue,
      value,
      onRangeChanged,
      hasTrial,
      inputSlider
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-profile-form-price {
  @include global-font;
  color: $color-text;

  &-slider {
    padding-bottom: 20px;
    margin-bottom: 31px;

    &-info {
      position: relative;
      display: inline-grid;
      grid-template-columns: 1fr auto;
      grid-gap: 0 26px;
      margin-bottom: 25px;
      font-weight: bold;
      font-size: 16px;
      line-height: 152%;

      &__value {
        color: $color-accent;
      }

      &__error {
        bottom: 0;
        left: 0;
        height: 24px;
        align-items: center;
      }

      &__duration {
        font-size: 12px;
        color: $color-gray
      }
    }
  }

  &-trial {
    &__title {
      margin-bottom: 22px;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
    }

    &__radio {
      grid-template-columns: repeat(2, 127px);
    }
  }
}

</style>
