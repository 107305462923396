<template>
  <div class="lp-landing">
    <div class="lp-landing-main-wrapper">
      <LandingHeader />
      <Main v-model:openForm="openForm" />
    </div>
    <div class="lp-landing-teachers-wrapper">
      <Teachers v-model:openForm="openForm" />
      <Plus />
    </div>
    <div class="lp-landing-price-wrapper">
      <Price />
      <Feedback />
    </div>
    <LandingFooter />
    <TrialLessonForm
      :openForm="openForm"
      v-model:openForm="openForm"
    />
  </div>
</template>

<script>
import LandingHeader from '@/components/Landing/Header/LandingHeader';
import Main from '@/components/Landing/Main/Main';
import Teachers from '@/components/Landing/Teachers/Teachers';
import Plus from '@/components/Landing/Plus/Plus';
import Price from '@/components/Landing/Price/Price';
import Feedback from '@/components/Landing/Feedback/Feedback';
import LandingFooter from '@/components/Landing/Footer/LandingFooter';
import { ref } from 'vue';
import TrialLessonForm from '@/components/Landing/TrialLessonForm/TrialLessonForm';

export default {
  name: 'Landing',
  components: {
    TrialLessonForm,
    LandingFooter,
    Feedback,
    Price,
    Plus,
    Teachers,
    Main,
    LandingHeader
  },
  setup () {
    const openForm = ref(false);

    return {
      openForm
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-landing {
  display: grid;
  background-color: $color-white;
  width: 100%;
  scroll-behavior: smooth;

  &-main-wrapper {
    background-image: url('~@/assets/images/landing/backgrounds/lines1.svg'),
                      url('~@/assets/images/landing/backgrounds/background1.png');
    background-position: center top, center bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: auto 100%, 100% 100%;
    width: 100%;
    height: 100%;
  }

  &-teachers-wrapper {
    background-image: url('~@/assets/images/landing/backgrounds/lines2.svg'),
                      url('~@/assets/images/landing/backgrounds/background2.png');
    background-position: center top, center top;
    background-repeat: no-repeat, no-repeat;
    background-size: auto 100%, 100% 100%;
    width: 100%;
    height: 100%;
  }

  &-price-wrapper {
    background-image: url('~@/assets/images/landing/backgrounds/background3.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% 105%;
    width: 100%;
    height: 100%;
  }
}

</style>
