<template>
  <div class="lp-current-teachers">
    <div
      class="lp-current-teachers-cards"
      v-if="isShow"
    >
      <ListTeachersCard
        class="lp-current-teachers-cards__card"
        v-for="teacher of teachersList"
        :key="teacher._id"
        :teacher="teacher"
      />
    </div>
    <TeachersListEmpty
      v-else
      :imageUrl="teachersListEmpty"
      :title="$t('myTeachers.teachersListEmpty.title')"
      :text="$t('myTeachers.teachersListEmpty.text')"
      :description="$t('myTeachers.teachersListEmpty.description')"
    />
  </div>
</template>

<script>
import TeachersListEmpty from '@/components/MyTeachers/TeachersListEmpty';
import teachersListEmpty from '@/assets/images/teachersListEmpty.png';
import ListTeachersCard from '@/components/ListTeachers/ListTeachersCard';

export default {
  name: 'CurrentTeachers',
  components: {
    TeachersListEmpty,
    ListTeachersCard
  },
  props: {
    teachersList: Array,
    isShow: Boolean
  },
  setup () {
    return { teachersListEmpty };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-current-teachers {
  @include global-font;
  display: grid;
  font-weight: normal;
  grid-column: 2;

  &-cards {
    padding-top: 36px;

    &__card {
      padding-bottom: 22px;
    }
  }
}

</style>
