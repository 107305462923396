<template>
  <div class="lp-landing-header">
    <div class="lp-landing-header__wrapper">
      <div
        class="lp-landing-header__logo"
        @click="scrollTo('lp-landing')"
      >
        <logoIcon />
      </div>
      <nav class="lp-landing-header-menu">
        <button
          class="lp-landing-header-menu__item lp-landing-header-menu__item_active"
          @click="scrollTo('lp-landing-main-wrapper')"
        >
          О школе
        </button>
        <button
          class="lp-landing-header-menu__item"
          @click="scrollTo('lp-landing-teachers-wrapper', -150)"
        >
          Наши преподаватели
        </button>
        <button
          class="lp-landing-header-menu__item"
          @click="scrollTo('lp-landing-price-wrapper')"
        >
          Стоимость обучения
        </button>
      </nav>
    </div>
  </div>
</template>

<script>
import { logoIcon } from '@/constants/icons/landing';

export default {
  name: 'LandingHeader',
  components: {
    logoIcon
  },
  setup () {
    const scrollTo = (className, offset = 0) => {
      const ps = document.querySelector('.lp-container__content');
      const [target] = document.getElementsByClassName(className);
      const coordinates = target.getBoundingClientRect();
      ps.scrollTo({
        top: coordinates.top + offset,
        behavior: 'smooth'
      });
    };

    return {
      scrollTo
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-landing-header {
  @include global-font;
  margin: 37px auto 0;
  width: 100%;
  max-width: 1290px;
  position: relative;

  @media (max-width: 1250px) {
    max-width: calc(100vw - 80px);
  }

  &__logo {
    cursor: pointer;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  &-menu {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 48px;

    @media (max-width: 767px) {
      display: none;
    }

    &__item {
      @include global-font;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-text;
      cursor: pointer;
      transition: 0.3s ease-in;

      &:hover, &_active {
        color: $color-accent
      }
    }
  }
}

</style>
