import { cloneDeep, uniqueId } from 'lodash';
import { reactive } from 'vue';
import moment from 'moment';
import subjects from '@/constants/subjects';
import { getSubjects } from '@/constants/utils/getValueById';

const today = moment().format('YYYY-MM-DD');

const inputs = [
  {
    type: 'select',
    label: 'listTeachers.filters.subjects',
    modelName: 'subject',
    modelValue: subjects[0],
    keyProp: 'title',
    fixed: true,
    options: subjects,
    getValue: (model) => model.id,
    getFullObject: (item) => getSubjects([item])[0]
  },
  {
    type: 'date',
    fixed: true,
    hideTime: true,
    label: 'userTimetable.lessonForm.date',
    format: 'DD MMM YYYY',
    modelName: 'date',
    modelValue: {
      time: [],
      date: [today, today]
    }
  },
  {
    type: 'time',
    fixed: true,
    onlyStart: true,
    label: 'userTimetable.lessonForm.time',
    modelName: 'time',
    validError: 'errors.incorrectTime',
    isValid: (arr) => arr && arr.length === 2 && arr[0] !== arr[1],
    modelValue: ['00:00', '00:00']
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('lesson-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  return item;
}));
