<template>
  <div
    class="lp-calendar-lesson"
    :class="[
      `lp-calendar-lesson_${mode}`,
      {'lp-calendar-lesson_past': isPastLesson}
    ]"
    ref="lessonEl"
    @click="openInfo = mode === 'month' || mode === 'modal'"
    v-click-outside="closeInfo"
  >
    <div
      class="lp-calendar-lesson__indicator"
      :class="{'lp-calendar-lesson__indicator_past': isPastLesson}"
    />
    <div
      class="lp-calendar-lesson__time"
      :class="[`lp-calendar-lesson__time_${mode}`]"
    >
      {{ startTime }} - {{ endTime }}

      <div
        class="lp-calendar-lesson__free-label"
        v-if="mode !== 'day' && lesson.free"
      >
        <FreeLabel :past="isPastLesson" />
      </div>
    </div>
    <div
      class="lp-calendar-lesson__subject"
      :class="[`lp-calendar-lesson__subject_${mode}`]"
      v-if="mode !== 'month'"
    >
      {{ subject.title }}
    </div>
    <router-link
      class="lp-calendar-lesson-user"
      target="_blank"
      :to="goTeacherProfile(student._id)"
      v-if="student._id && mode !== 'month' && mode !== 'modal'"
    >
      <img
        v-if="student.photo"
        alt="photo"
        class="lp-calendar-lesson-user__avatar"
        :src="student.photo"
      />
      <userIcon
        v-else
        class="lp-calendar-lesson-user__avatar"
      />
      <div class="lp-calendar-lesson-user__name">
        {{ student.firstName }} {{ student.lastName }}
      </div>
    </router-link>
    <div
      class="lp-calendar-lesson__free-label lp-calendar-lesson__free-label_day"
      v-if="mode === 'day' && lesson.free"
    >
      <FreeLabel :past="isPastLesson" />
    </div>
    <transition name="bounce">
      <CalendarLessonInfo
        class="lp-calendar-lesson__info"
        :class="['lp-calendar-lesson__info_' + getStyleInfo().className]"
        :style="getStyleInfo().style"
        v-model:open="openInfo"
        :isStudent="isStudent"
        :lessonId="lesson._id"
        :student="student"
        :subject="subject.title"
        :time="`${startTime} - ${endTime}`"
        v-if="openInfo"
      />
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import { computed, ref } from 'vue';
import { getSubjects } from '@/constants/utils/getValueById';
import { get } from 'lodash';
import { userIcon } from '@/constants/icons';
import { useStore } from 'vuex';
import ROLE_TYPES from '@/constants/enums/roles';
import CalendarLessonInfo from '@/components/Main/Calendar/CalendarLessonInfo';
import FreeLabel from '@/components/Main/FreeLabel/FreeLabel';

export default {
  name: 'CalendarLesson',
  components: { FreeLabel, CalendarLessonInfo, userIcon },
  props: {
    lesson: Object,
    mode: String
  },
  setup (props) {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const isStudent = computed(() => activeUser.value.role === ROLE_TYPES.STUDENT);

    const startTime = computed(() => moment(props.lesson.lessonStartTime).format('HH:mm'));
    const endTime = computed(() => moment(props.lesson.lessonEndTime).format('HH:mm'));

    const isPastLesson = computed(() => moment().isAfter(props.lesson.lessonEndTime));

    const subject = computed(() => getSubjects([props.lesson.subject])[0]);

    const student = computed(() => {
      const role = isStudent.value ? 'teacher' : 'assignedStudent';
      return {
        _id: get(props.lesson, `${role}._id`, ''),
        firstName: get(props.lesson, `${role}.firstName`, ''),
        lastName: get(props.lesson, `${role}.lastName`, ''),
        photo: get(props.lesson, `${role}.uploadFile.url`, '')
      };
    });

    const openInfo = ref(false);
    const closeInfo = () => {
      openInfo.value = false;
    };

    const lessonEl = ref(null);
    const getStyleInfo = () => {
      if (!lessonEl.value) return {};
      const infoSize = { width: 295, height: 205 };
      const documentWidth = window.innerWidth;
      const documentHeight = window.innerHeight;
      const position = lessonEl.value.getBoundingClientRect();
      const marginLeft = 9;
      const marginTop = 0;
      const top = marginTop + position.top;
      const left = position.left + position.width + marginLeft;
      let className = '';
      const style = {
        position: 'fixed',
        top: `${top}px`,
        left: `${left}px`
      };
      if (left + infoSize.width > documentWidth) {
        className = 'right';
        style.left = `${position.left - marginLeft - infoSize.width}px`;
      }
      if (top + infoSize.height > documentHeight) {
        className += 'bot';
        style.top = `${position.top + position.height - marginTop - infoSize.height}px`;
      }
      return {
        style,
        className
      };
    };

    const goTeacherProfile = (id) => {
      if (!id) return '';
      const name = isStudent.value ? 'ProfileTeacher' : 'ProfileStudent';
      return { name, params: { id } };
    };

    return {
      isStudent,
      startTime,
      endTime,
      subject,
      student,
      lessonEl,
      openInfo,
      isPastLesson,
      closeInfo,
      getStyleInfo,
      goTeacherProfile
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-calendar-lesson {
  @include global-font;
  display: grid;
  position: relative;
  padding: 3px 16px 3px 14px;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  border-radius: 2px;
  color: $color-text;
  text-align: left;

  &_past {
    color: $color-gray;

    .lp-calendar-lesson-user {
      opacity: 0.5;
    }
  }
  &_week {
    padding: 10px 16px 10px 20px;
    grid-template-rows: auto 1fr auto;
    min-height: 132px;
  }

  &_day {
    padding: 0 16px 0 20px;
    align-items: center;
    grid-template-columns: auto 1fr auto auto;
    grid-gap: 12px;
    min-height: 40px;
  }

  &__info {
    position: absolute;
    left: calc(100% + 9px);
    top: 0;
    z-index: 3;

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid $color-white;
      box-shadow: 0 0 20px rgba($color-black, 0.09);
      left: -6px;
      top: 8px;
    }

    &_right {
      &:before {
        border-right: 6px solid $color-white;
        border-left: 6px solid $color-white;
        left: auto;
        right: -6px;
      }
    }

    &_bot {
      &:before {
        top: auto;
        bottom: 8px;
      }
    }
  }

  &__indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    border-radius: 2px 0 0 2px;
    background-color: $color-accent;

    &_past {
      background-color: $color-gray;
    }

    &_trial {
      background-color: $color-supernova;
    }
  }

  &__time {
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;

    &_modal,
    &_day,
    &_week {
      font-size: 16px;
    }

    &_month, &_week {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__free-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 -13px 0 0;

    &_day {
      margin-left: 70px;
      margin-right: 0;
    }
  }

  &__subject {
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;

    &_day {
      font-weight: bold;
    }
  }

  &-user {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 10px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }

    &__avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    &__name {
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
    }
  }
}

</style>
