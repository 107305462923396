<template>
  <div
    class="lp-times-day"
    v-click-outside="closeDropList"
  >
    <div
      class="lp-times-day-header"
      @click="openDropList = !openDropList"
    >
      <span class="lp-label lp-times-day-header__label">
        {{ $t(label) }}
      </span>
      <div class="lp-times-day-header-input lp-input">
        <div class="lp-times-day-header-input__selected">
          {{ selectedTime || placeholder }}
        </div>
        <arrowIcon
          class="lp-times-day-header-input__icon"
          :class="{'lp-times-day-header-input__icon_open': openDropList}"
        />
      </div>
    </div>
    <transition name="slide-select">
      <div
        class="lp-times-day-dropdown"
        v-if="openDropList"
        @click.stop
      >
        <div class="lp-times-day-dropdown__label lp-label">
          {{ $t('timesOfDays.title') }}
        </div>
        <div class="lp-times-day-dropdown-values">
          <div
            class="lp-times-day-dropdown-values-item"
            :class="{'lp-times-day-dropdown-values-item_selected': time.include}"
            v-for="time of timesRangeOfDay"
            @click="addTime(time)"
            :key="time.value"
          >
            <div class="lp-times-day-dropdown-values-item__icon">
              <component :is="time.icon" />
            </div>
            <div class="lp-times-day-dropdown-values-item__time">
              {{ time.time }}
            </div>
            <div class="lp-times-day-dropdown-values-item__title">
              {{ time.title }}
            </div>
          </div>
        </div>
        <WeekDays
          v-bind="weekDaysInput"
          v-model="weekDaysInput.modelValue"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import WeekDays from '@/components/Main/Fields/WeekDays';
import timesRangeOfDay from '@/constants/inputs/timesRangeOfDay';
import {
  computed, reactive, ref, watch
} from 'vue';
import { arrowIcon } from '@/constants/icons';
import { get, upperFirst } from 'lodash';
import i18n from '@/i18n';
import moment from 'moment';

export default {
  name: 'SelectTimesOfDay',
  components: {
    WeekDays,
    arrowIcon
  },
  props: {
    modelValue: Object,
    label: String,
    placeholder: String,
    single: Boolean
  },
  setup (props, { emit }) {
    const weekDaysInput = reactive({
      label: 'userTimetable.lessonForm.week',
      modelName: 'week',
      single: props.single,
      modelValue: []
    });
    const locale = get(i18n, 'global.locale.value', 'ru');

    const openDropList = ref(false);
    const closeDropList = () => {
      openDropList.value = false;
    };

    const getValues = () => {
      const times = timesRangeOfDay.filter((item) => item.include);
      const days = weekDaysInput.modelValue;
      emit('update:modelValue', { days, times });
    };
    watch(weekDaysInput, getValues);
    watch(timesRangeOfDay, getValues);

    const selectedTime = computed(() => {
      const times = timesRangeOfDay.filter((item) => item.include).map((item) => item.title).join(', ');
      const days = weekDaysInput.modelValue.map((day) => {
        const date = moment().set('day', day).locale(locale);
        return date.format('dddd');
      }).join(', ');
      const formatDays = upperFirst(days);
      const formatTimes = upperFirst(times);
      if (times && !days) return `${formatTimes}`;
      if (!times && days) return `${formatDays}`;
      if (times && days) return `${formatDays}, ${upperFirst(times)}`;
      return '';
    });

    const addTime = (time) => {
      if (props.single) {
        timesRangeOfDay.forEach((item) => {
          if (time.time === item.time) {
            item.include = !item.include;
          } else {
            item.include = false;
          }
        });
      } else {
        time.include = !time.include;
      }
    };

    return {
      weekDaysInput,
      openDropList,
      selectedTime,
      timesRangeOfDay,
      addTime,
      closeDropList
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-times-day {
  @include global-font;
  position: relative;

  &-header {
    display: grid;
    grid-gap: 4px;

    &-input {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 18px;
      height: 100%;
      min-height: 42px;
      cursor: pointer;
      transition: 0.3s;

      &__icon {
        justify-self: end;
        padding: 11px;
        margin: -11px;
        grid-column: 2;
        box-sizing: content-box;
        transform: rotate(180deg);
        transition: 0.15s ease-in;

        &_open {
          transform: rotate(0deg);
        }
      }
    }
  }

  &-dropdown {
    position: absolute;
    left: 0;
    top: 65px;
    background: $color-white;
    box-shadow: 0 0 15px rgba($color-black, 0.05);
    border-radius: 4px;
    padding: 20px;
    z-index: 1;

    &__label {
      margin-bottom: 4px;
    }

    &-values {
      display: grid;
      grid-template-columns: repeat(4, 94px);
      box-sizing: border-box;
      margin-bottom: 16px;

      &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 7px;
        min-height: 110px;
        border: 1px solid $color-alto;
        background-color: $color-white;
        box-sizing: border-box;
        margin: 0 0 -1px -1px;
        cursor: pointer;
        transition: border 0.3s ease-out, background-color 0.2s ease-in;

        &:hover {
          background-color: $color-moon-raker;
          z-index: 1;
        }

        &_selected {
          border: 1px solid $color-accent;
          background-color: $color-moon-raker;
          z-index: 2;
        }

        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 6px;
        }

        &__time {
          text-align: center;
          font-weight: bold;
          font-size: 14px;
          line-height: 125%;
          color: $color-text;
          transition: 0.3s ease-out;
        }

        &__title {
          text-align: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 125%;
          color: $color-gray;

          &::first-letter {
            text-transform: capitalize;
          }
        }

      }
    }
  }
}

</style>
