<template>
  <div class="lp-login-settings">
    <div
      class="lp-login-settings__checkbox"
      v-if="!isRegistration"
    >
      <CustomCheckbox
        v-bind="remember"
        v-model="remember.modelValue"
      />
    </div>
    <LoginLicense
      v-model:license="acceptLicense"
      v-else
    />
  </div>
</template>

<script>
import CustomCheckbox from '@/components/Main/Fields/CustomCheckbox';
import { reactive, watch, ref } from 'vue';
import LoginLicense from '@/components/Login/LoginLicense';

export default {
  name: 'LoginSettings',
  components: {
    LoginLicense,
    CustomCheckbox
  },
  props: {
    isRegistration: Boolean
  },
  emits: ['update:remember', 'update:license'],
  setup (props, { emit }) {
    const acceptLicense = ref(false);

    const remember = reactive({
      label: 'login.remember',
      modelValue: true
    });

    const setLicense = () => {
      emit('update:license', acceptLicense.value);
    };

    const setRemember = () => {
      emit('update:remember', remember.modelValue);
    };

    watch(acceptLicense, setLicense);
    watch(remember, setRemember);

    return { remember, acceptLicense };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-login-settings {
  @include global-font-ubuntu();
  position: relative;
  align-self: flex-start;
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  color: $color-text;
  min-height: 30px;
  margin-bottom: 36px;

  &__checkbox {
    font-size: 12px;
  }
}

</style>
