import teacherImg from '@/assets/images/landing/teachers/teacher4.jpg';
import achievement1 from '@/assets/images/landing/achievements/teacher4/achievement1.jpg';
import achievement2 from '@/assets/images/landing/achievements/teacher4/achievement2.jpg';
import achievement3 from '@/assets/images/landing/achievements/teacher4/achievement3.jpg';

export default {
  image: teacherImg,
  name: 'Лариса Зозуля',
  about: `Здравствуйте!
          Меня зовут Лариса Зозуля.
          Я вокалистка, музыкант, аранжировщик, звукорежиссер в студии звукозаписи,член Международной Ассоциации преподавателей вокала IATS , основатель Вокального Клуба "СветСтудио" ( г.Днепр Украина) и сертифицированный преподаватель современного вокала официальной школы вокала Международной Ассоциации Преподавателей Вокала IATS.
          Член жюри многих вокальных конкурсов с большим вокальным и педагогическим опытом.

          Работаю по программе IATS:
          - начального,
          - среднего,
          - продвинутого уровней.

          Сертификат об обучении международного образца IATS.
 

          Предлагаю обучение:
          - индивидуальное;
          - игре на синтезаторе;
          - основам гармонии, музыкальной грамотности и подбору инструментальных композиций и песен.

          Подготовку:
          - к музыкальным фестивалям и конкурсам;
          - к поступлению в профильные учебные учреждения.

          Использую методики:
          Estill Voice Training (USA);
          колледжа Беркли (Boston, USA);
          основы американского вокального образования;
          техники современного вокала и  экстрим вокала.

          Образование, научные степени:
          КГПИ, музыкально-педагогический факультет, учитель музыки и пения, 1990 г.
          вокальный тренинг Estill Voice Training, 2020 г.
          Курсы повышения квалификации (переподготовки) по программе «Преподаватель современного вокала»  IATS, 2020 г.
          вокальный тренинг по Экстремальным техникам вокала 2020.
          `,
  works: [
    {
      date: '2019 - по настоящее время',
      position: 'частная практика',
      company: 'company',
    },
    {
      date: '2019 - по настоящее время',
      position: 'частная практика',
    },
  ],
  achievements: [achievement1, achievement2, achievement3],
  workText: `Репетиторская деятельность — 8 лет.

Опыт преподавания с 1984 года:
- музыкальный руководитель детских дошкольных учреждений – 17 лет; 
- специалист воспитательного отдела АМСУ (вокальные и инструментальные кружки, самодеятельность) - 6 лет; 
- вокальный тренер народной студии современного вокала "МРИЯ" - 2 года.
- руководитель вокальной студии Центра Искусств "Образ" г.Днепр - 3 года
- основатель и преподаватель современного вокала в Вокальном Клубе "СветСтудио" - 6 лет по настоящее время
`,
};
