import io from 'socket.io-client';

export const socketConfig = {
  connection: window.location.hostname === 'localhost'
    ? 'http://localhost:3000/'
    : `${window.location.protocol}//${window.location.hostname}/`,
  debug: false,
  options: {
    transports: ['websocket']
  }
};

const installSocket = (app) => {
  const { connection, debug, options } = socketConfig;
  localStorage.setItem('debug', debug ? '*' : '');
  const socket = io(connection, options);
  app.config.globalProperties.$socket = socket;
  app.provide('socket', socket);
};

export default installSocket;
