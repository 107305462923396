<template>
  <div class="lp-profile">
    <ProfileHeader
      class="lp-profile__header"
      v-model:open="openBasicFrom"
      :avatar="avatarUser"
      :user="activeUser"
    />
    <ProfilePrice
      class="lp-profile__price"
      v-if="isTeacher"
      v-model:openModal="openPriceForm"
      :user="activeUser"
    />
    <ProfileInfo
      class="lp-profile__info"
      v-if="isTeacher"
      v-model:openWork="openWorkForm"
      v-model:openEducation="openEducationForm"
      :user="activeUser"
    />
    <ProfileSettings
      class="lp-profile__settings"
      v-model:openContacts="openContactsForm"
      v-model:openPassword="openPasswordForm"
      :user="activeUser"
    />
    <ProfileStats
      class="lp-profile__stats"
      :user="activeUser"
    />
    <!--  forms  -->
    <ProfileFormBasic
      :user="activeUser"
      :avatar="avatarUser"
      :cantCloseForm="cantCloseForm"
      v-model:open="openBasicFrom"
      v-model:recommendation="openVideoInfo"
    />
    <Modal
      title="profile.forms.price.title"
      :hideButtons="['remove']"
      @save="savePriceForm"
      v-model:open="openPriceForm"
    >
      <ProfileFormPrice
        class="lp-profile__price-form"
        :trial="trial"
        :price="price"
        v-model:trial="trial"
        v-model:price="price"
      />
    </Modal>
    <ProfileFormWork
      :user="activeUser"
      v-model:open="openWorkForm"
    />
    <ProfileFormEducation
      :user="activeUser"
      v-model:open="openEducationForm"
    />
    <ProfileFormPassword
      :user="activeUser"
      v-model:open="openPasswordForm"
    />
    <TemplateTimetable
      v-if="isTeacher"
      :user="activeUser"
      v-model:open="openTemplateTimetable"
    />
    <ProfileVideoHelp
      :status="openVideoInfo"
      v-model:open="openVideoInfo"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import {
  computed, ref, onMounted, watch
} from 'vue';
import ProfileHeader from '@/components/Profile/ProfileHeader/ProfileHeader';
import ProfileSettings from '@/components/Profile/ProfileSettings/ProfileSettings';
import ProfileInfo from '@/components/Profile/ProfileInfo/ProfileInfo';
import ProfileStats from '@/components/Profile/ProfileStats/ProfileStats';
import ProfileFormBasic from '@/components/Profile/ProfileForms/ProfileFormBasic';
import ProfileFormWork from '@/components/Profile/ProfileForms/ProfileFormWork';
import ProfileFormEducation from '@/components/Profile/ProfileForms/ProfileFormEducation';
import ProfileFormPassword from '@/components/Profile/ProfileForms/ProfileFormPassword';
import ROLE_TYPES from '@/constants/enums/roles';
import ProfileFormPrice from '@/components/Profile/ProfileForms/ProfileFormPrice';
import Modal from '@/components/Main/Modal/Modal';
import UserApi from '@/api/Users/api';
import ProfilePrice from '@/components/Profile/ProfilePrice/ProfilePrice';
import TemplateTimetable from '@/components/UserTimetable/TemplateTimetable';
import { cloneDeep } from 'lodash';
import ProfileVideoHelp from '@/components/Profile/ProfileText/ProfileVideoHelp';

export default {
  name: 'Profile',
  components: {
    ProfileVideoHelp,
    TemplateTimetable,
    ProfilePrice,
    Modal,
    ProfileFormPrice,
    ProfileFormPassword,
    ProfileFormEducation,
    ProfileFormWork,
    ProfileFormBasic,
    ProfileStats,
    ProfileInfo,
    ProfileSettings,
    ProfileHeader
  },
  setup () {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const avatarUser = computed(() => store.getters.avatarUser);
    const isTeacher = computed(() => activeUser.value.role === ROLE_TYPES.TEACHER);

    const cantCloseForm = ref(false);

    const openBasicFrom = ref(false);
    const openVideoInfo = ref(false);
    const openPriceForm = ref(false);
    const openWorkForm = ref(false);
    const openEducationForm = ref(false);
    const openContactsForm = ref(false);
    const openPasswordForm = ref(false);
    const openTemplateTimetable = ref(false);

    const trial = ref(false);
    const price = ref(25);

    const checkOpenForm = () => {
      if (!activeUser.value._id) return;
      const hasName = activeUser.value.firstName || activeUser.value.lastName;
      if (!hasName) {
        cantCloseForm.value = !hasName;
        openBasicFrom.value = !hasName;
      }
      price.value = activeUser.value.lessonCost;
      trial.value = activeUser.value.trialLessonAgreement;
    };

    const setActiveUser = (data) => store.dispatch('setActiveUser', data);

    const savePriceForm = async () => {
      try {
        const user = cloneDeep(activeUser.value);
        user.lessonCost = price.value;
        user.trialLessonAgreement = trial.value;
        const { data } = await UserApi.updateUser(user);
        await setActiveUser(data);
        openPriceForm.value = false;
      } catch (err) {
        console.error(err);
      }
    };

    onMounted(checkOpenForm);

    watch(activeUser, checkOpenForm);

    return {
      activeUser,
      avatarUser,
      isTeacher,
      cantCloseForm,
      openBasicFrom,
      openVideoInfo,
      openPriceForm,
      openContactsForm,
      openPasswordForm,
      openWorkForm,
      openEducationForm,
      openTemplateTimetable,
      savePriceForm,
      trial,
      price
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-profile {
  @include global-font;
  display: grid;
  grid-template-columns: 1fr 293px;
  grid-template-rows: repeat(3, auto);
  grid-gap: 20px 24px;
  cursor: default;

  &__stats {
    grid-column: 2;
    grid-row: 1/end;
  }

  &__price-form {
    width: 708px;
    max-width: 90vw;
    padding: 29px 32px;
  }
}

</style>
