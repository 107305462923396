<template>
  <div
    class="lp-range"
    v-click-outside="closeRange"
  >
    <label
      class="lp-label"
      :class="{'lp-label_required': required}"
      :for="label"
    >
      {{ $t(label) }}
    </label>
    <div
      class="lp-range-header lp-input"
      :class="{'lp-input_error': error}"
      ref="rangeHeader"
      @click="openRange = !openRange"
    >
      <div class="lp-range-header__input">
        <span>{{ startText }}</span>
        -
        <span>{{ endText }}</span>
      </div>
      <arrowIcon
        class="lp-range-header__icon"
        :class="{'lp-range-header__icon_open': openRange}"
      />
      <transition name="bounce">
        <span
          class="lp-error"
          v-if="error && typeof error === 'string'"
        >
          <dangerIcon />
          {{ $t(error) }}
        </span>
      </transition>
    </div>
    <transition name="slide-select">
      <div
        @click.stop
        :style="fixed ? getStylePopup() : {}"
        class="lp-range-dropdown"
        v-if="openRange"
      >
        <div class="lp-range-dropdown-values">
          <span>{{ startText }}</span>
          -
          <span>{{ endText }}</span>
        </div>
        <div>
          <VueSlider
            v-model="range"
            @change="onRangeChanged"
            :min="0"
            :step="10"
            :max="maxValue"
            :dotSize="16"
            :height="16"
            tooltip="none"
            :useKeyboard="false"
            :process="true"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { dangerIcon, arrowIcon } from '@/constants/icons';
import numeral from 'numeral';
import {
  computed, ref, onBeforeMount, reactive, watchEffect
} from 'vue';
import { get } from 'lodash';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'CustomRange',
  components: { dangerIcon, arrowIcon, VueSlider },
  props: {
    modelValue: Array,
    error: [Boolean, String],
    required: Boolean,
    label: String,
    format: String,
    maxValue: Number,
    fixed: Boolean
  },
  setup (props, { emit }) {
    const formatValue = (val) => numeral(val).format(props.format);

    const range = reactive([0, 0]);

    watchEffect(range, emit('update:modelValue', range));

    const startText = computed(() => formatValue(props.modelValue[0]));
    const endText = computed(() => formatValue(props.modelValue[1]));

    const onRangeChanged = (e) => {
      const [start, end] = e;
      range[0] = start;
      range[1] = end;
    };

    const openRange = ref(false);
    const rangeHeader = ref(null);
    const getStylePopup = () => {
      if (!rangeHeader.value) return {};
      const position = rangeHeader.value.getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${4 + position.top + position.height}px`,
        left: `${position.left}px`,
        width: `${position.width}px`
      };
    };

    const setDefaultValues = () => {
      const [start, end] = props.modelValue;
      range[0] = start || 0;
      range[1] = end || props.maxValue;
    };

    const rangeField = ref(null);

    const fillStyle = () => {
      const width = get(rangeField.value, 'offsetWidth', 0);
      const stepPx = width / props.maxValue;
      const left = 6 + (stepPx * range[0]);
      const right = width - (stepPx * range[1]);
      return {
        left: `${left}px`,
        right: `${right}px`
      };
    };

    const closeRange = () => {
      openRange.value = false;
    };

    onBeforeMount(setDefaultValues);

    return {
      startText,
      endText,
      openRange,
      rangeHeader,
      range,
      rangeField,
      closeRange,
      onRangeChanged,
      fillStyle,
      getStylePopup
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-range {
  @include global-font;
  position: relative;
  display: grid;
  grid-template-rows: 15px 42px;
  grid-gap: 4px;
  align-items: start;

  &-header {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: auto 23px;
    min-height: 42px;
    cursor: pointer;

    &__input {
      position: relative;
    }

    &__icon {
      justify-self: end;
      padding: 11px;
      margin: -11px;
      grid-column: 2;
      box-sizing: content-box;
      transform: rotate(180deg);
      transition: 0.15s ease-in;

      &_open {
        transform: rotate(0deg);
      }
    }
  }

  &-dropdown {
    @include global-font;
    position: absolute;
    top: 65px;
    left: 0;
    display: grid;
    width: 100%;
    height: auto;
    padding: 16px 20px 32px;
    background: $color-white;
    box-shadow: 0 0 15px rgba($color-black, 0.05);
    border-radius: 6px;
    border: 1px solid $color-wild-sand;
    z-index: 100;
    overflow: hidden;
    max-height: 190px;
    box-sizing: border-box;
    cursor: default;

    &-values {
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 14px;
      line-height: 125%;
    }
  }
}

</style>
