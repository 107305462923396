<template>
  <div class="lp-classroom-stream">
    <div class="lp-classroom-stream__interlocutor">
      <Webcam
          :stream="interlocutorStream"
          :my-stream="myStream"
          :sessionInfo="sessionInfo"
          v-model:my-stream="myStream"
      />
    </div>
  </div>
</template>

<script>
import { computed, inject, ref, onBeforeUnmount, watch, onBeforeMount } from 'vue';
import Webcam from '@/components/Main/Webcam/Webcam';
import { useRouter } from 'vue-router';
import { get, isEqual } from 'lodash';
import { useStore } from 'vuex';

export default {
  name: 'ClassroomVideo',
  components: { Webcam },
  setup() {
    const socket = inject('socket');

    const router = useRouter();
    const lessonId = computed(() => get(router, 'currentRoute.value.params.lessonId', ''));

    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const sessionInfo = computed(() => ({
      user: activeUser.value._id,
      lesson: lessonId.value
    }));
    const myStream = ref(null);
    const interlocutorStream = ref({});

    socket.on('test', (data) => {
      console.log('on test');
      console.log(data);
    });

    const config = {
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302',
        },
      ],
    };
    const peers = {};
    let peerConnection;

    socket.on('answer', (data) => {
      const {
        id,
        description,
      } = data;
      peers[id].setRemoteDescription(description);
    });

    socket.on('watcher', (id) => {
      const peer = new RTCPeerConnection(config);
      peers[id] = peer;
      const stream = myStream.value || {};
      stream.getTracks()
          .forEach((track) => peer.addTrack(track, stream));
      peer.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit('candidate', {
            id,
            candidate: event.candidate,
          });
        }
      };
      peer.createOffer()
          .then((sdp) => peer.setLocalDescription(sdp))
          .then(() => {
            socket.emit('offer', {
              id,
              localDescription: peer.localDescription,
            });
          });
    });

    socket.on('candidate', (data) => {
      const {
        id,
        candidate
      } = data;
      if (peerConnection) {
        peerConnection
            .addIceCandidate(new RTCIceCandidate(candidate))
            .catch((e) => console.error(e));
      }
      console.log(peers[id], 'peers[id]');
      peers[id]
          .addIceCandidate(new RTCIceCandidate(candidate))
          .then(() => {
            console.log('succeess candidate');
          })
          .catch((err) => {
            console.log(err.message);
          });
    });

    socket.on('offer', (data) => {
      const {
        id,
        localDescription,
      } = data;
      peerConnection = new RTCPeerConnection(config);
      peerConnection
          .setRemoteDescription(localDescription)
          .then(() => peerConnection.createAnswer())
          .then((sdp) => peerConnection.setLocalDescription(sdp))
          .then(() => {
            socket.emit('answer', {
              id,
              description: peerConnection.localDescription,
            });
          });
      peerConnection.ontrack = (event) => {
        interlocutorStream.value = event.streams[0];
      };
      peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit('candidate', {
            id,
            candidate: event.candidate,
          });
        }
      };
    });

    async function watcherButtonFunc() {
      const {user, lesson} = sessionInfo.value
      if (!user || !lesson) return;
      socket.emit('watcher', sessionInfo.value);
      console.log('watch');
    }
    watch(sessionInfo, watcherButtonFunc)
    onBeforeMount(watcherButtonFunc)

    socket.on('broadcaster', () => {
      console.log('broadcast');
    });

    socket.on('disconnectPeer', (id) => {
      console.log('disconnectPeer');
      peers[id].close();
      delete peers[id];
    });

    onBeforeUnmount(() => {
      socket.close();
    });

    return {
      sessionInfo,
      interlocutorStream,
      myStream
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-classroom-stream {
  @include global-font;
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  justify-content: center;
  grid-gap: 15px 50px;

  &__interlocutor {
    height: 100%;
    min-height: 648px;
  }

  &__controls {
    display: grid;
    grid-gap: 15px;
  }

  &__my-cam {
    height: 285px;
  }
}

</style>
