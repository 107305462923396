<template>
  <div class="lp-form">
    <div
      class="lp-form__item"
      :class="{'lp-form__item_checkbox': input.type === 'checkbox'}"
      v-for="input of inputs"
      :key="input.id"
      :style="input.style"
    >
      <component
        :is="getInputComponent(input)"
        v-model="input.modelValue"
        @help="$emit('help', input)"
        @submit="$emit('submit')"
        @focus="input.error = false"
        v-bind="input"
      />
    </div>
  </div>
</template>

<script>
import CustomInput from '@/components/Main/Fields/CustomInput';
import CustomSelect from '@/components/Main/Fields/CustomSelect';
import CustomTextarea from '@/components/Main/Fields/CustomTextarea';
import MultiplySelect from '@/components/Main/Fields/MultiplySelect';
import CountrySelect from '@/components/Main/Fields/CountrySelect';
import DateSelect from '@/components/Main/Fields/DateSelect';
import CustomRange from '@/components/Main/Fields/CustomRange';
import DatePicker from '@/components/Main/DatePicker/DatePicker';
import TimePicker from '@/components/Main/DatePicker/TimePicker';
import WeekDays from '@/components/Main/Fields/WeekDays';
import CustomCheckbox from '@/components/Main/Fields/CustomCheckbox';
import SelectTimesOfDay from '@/components/Main/Fields/SelectTimesOfDay';
import PhoneInput from '@/components/Main/Fields/PhoneInput/PhoneInput';

export default {
  name: 'Form',
  props: {
    inputs: Array
  },
  setup () {
    const getInputComponent = (input) => {
      switch (input.type) {
      case 'select':
        return CustomSelect;
      case 'multiselect':
        return MultiplySelect;
      case 'countrySelect':
        return CountrySelect;
      case 'dateSelect':
        return DateSelect;
      case 'textarea':
        return CustomTextarea;
      case 'range':
        return CustomRange;
      case 'date':
        return DatePicker;
      case 'time':
        return TimePicker;
      case 'week':
        return WeekDays;
      case 'checkbox':
        return CustomCheckbox;
      case 'timesOfDay':
        return SelectTimesOfDay;
      case 'phone':
        return PhoneInput;
      default:
        return CustomInput;
      }
    };

    return {
      getInputComponent
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-form {
  @include global-font;
  display: grid;
  grid-gap: 28px 18px;
  padding: 24px 32px 0;

  &__item {
    &_checkbox {
      margin-top: 19px;
    }
  }
}

</style>
