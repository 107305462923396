import teacher1 from '@/constants/landing/teachers/teacher1';
import teacher2 from '@/constants/landing/teachers/teacher2';
import teacher3 from '@/constants/landing/teachers/teacher3';
import teacher4 from '@/constants/landing/teachers/teacher4';
import teacher5 from '@/constants/landing/teachers/teacher5';
import teacher6 from '@/constants/landing/teachers/teacher6';

export default [
  teacher1,
  teacher2,
  teacher3,
  teacher4,
  teacher5,
  teacher6,
];
