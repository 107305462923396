<template>
  <Modal
    title="profile.forms.education.title"
    :hideButtons="typeof open === 'number' ? [] : ['remove']"
    @save="saveForm"
    @remove="removeEducation"
    v-model:open="openModal"
  >
    <Form
      class="lp-profile-form-education"
      @submit="saveForm"
      :inputs="profileEducationInputs"
    />
  </Modal>
</template>

<script>
import { ref, watch, computed } from 'vue';
import Modal from '@/components/Main/Modal/Modal';
import Form from '@/components/Main/Form/Form';
import { useStore } from 'vuex';
import profileEducationInputs from '@/constants/inputs/profileTeacherInputs/profileEducationInputs';
import { get, set } from 'lodash';
import validateProfile from '@/constants/validatesForm/validateProfile';
import UserApi from '@/api/Users/api';

export default {
  name: 'ProfileFormEducation',
  components: {
    Modal,
    Form
  },
  props: {
    user: Object,
    open: [Number, Boolean]
  },
  setup (props, { emit }) {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const setActiveUser = (data) => store.dispatch('setActiveUser', data);

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const openModal = ref(false);
    watch(openModal, () => emit('update:open', openModal.value));

    const setDefaultValues = () => {
      const education = get(props.user, ['education', props.open - 1], []);
      profileEducationInputs.forEach((input) => {
        const value = typeof props.open === 'number' ? get(education, input.modelName, input.defaultValue) : '';
        input.modelValue = input.getFullObject && value ? input.getFullObject(value) : value;
      });
    };

    watch(props, () => {
      if (props.open) setDefaultValues();
      openModal.value = props.open;
    });

    const updateUser = () => {
      setLoaderRun(true);
      UserApi.updateUser(activeUser.value).
        then(({ data }) => {
          setLoaderRun(false);
          setActiveUser(data);
          openModal.value = false;
        }).
        catch((err) => {
          setLoaderRun(false);
          console.error(err);
        });
    };

    const removeEducation = () => {
      const allEducation = get(activeUser, 'value.education', []);
      allEducation.splice(props.open - 1, 1);
      updateUser();
    };

    const saveForm = () => {
      const body = validateProfile(profileEducationInputs);
      if (!body) return;
      const allEducation = get(activeUser, 'value.education', []);
      if (typeof props.open === 'number') {
        allEducation.splice(props.open - 1, 1, body);
      } else {
        allEducation.push(body);
      }
      set(activeUser, 'value.education', allEducation);
      updateUser();
    };

    return {
      profileEducationInputs, openModal, saveForm, removeEducation
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-profile-form-education {
  @include global-font;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 708px;
}

</style>
