import CURRENT_SERVER from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';
import { get } from 'lodash';

const axios = require('axios');

export default {
  getActiveUser () {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get('users/me');
  },
  getFullUser (user) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const roleUrl = `${user.role.toLowerCase()}s`;
    return instCred.get(`users/${roleUrl}/${user._id}`);
  },
  updateUser (user) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const roleUrl = `${user.role.toLowerCase()}s`;
    const file = get(user, 'uploadFile._id', null);
    if (typeof user.uploadFile === 'object') user.uploadFile = file;
    return instCred.patch(`users/${roleUrl}/${user._id}`, user);
  },
  getStudentsByTeacher (id) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get(`users/students/mystudents?teacherId=${id}`);
  },
  getCurrentTeachers (id) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get(`users/teachers/myteachers?studentId=${id}`);
  },
  getFavoritesTeachers (id) {
    const instCred = axios.create({
      baseURL: CURRENT_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get(`users/teachers/myteachers?studentId=${id}`);
  }
};
