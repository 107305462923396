<template>
  <div class="lp-date-picker-header">
    <div
      class="lp-date-picker-header__arrow"
      @click="$emit('change-month', false)"
    >
      <arrowCalendarIcon />
    </div>
    <div class="lp-date-picker-header__today">
      {{ month }} {{ year }}
    </div>
    <div
      class="lp-date-picker-header__arrow lp-date-picker-header__arrow_next"
      @click="$emit('change-month', true)"
    >
      <arrowCalendarIcon />
    </div>
  </div>
</template>

<script>
import { arrowCalendarIcon } from '@/constants/icons';

export default {
  name: 'DatePickerHeader',
  components: { arrowCalendarIcon },
  props: {
    month: String,
    year: String
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-date-picker-header {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 20px 1fr 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    cursor: pointer;
    transition: 0.3s ease-out;

    svg {
      fill: $color-gray;
    }

    &:hover {
      opacity: 0.7;
    }

    &_next {
      transform: scaleX(-1);
    }
  }

  &__today {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: $color-black;
    user-select: none;
  }
}

</style>
