<template>
  <div
    v-if="accountStatus !== 0"
    class="lp-date"
  >
    <div v-if="till">
      {{ $t(till) }} {{ paymentDate }}
    </div>
    <div v-else>
      {{ paymentDate }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { get } from 'lodash';
import moment from 'moment';
import i18n from '@/i18n';

export default {
  name: 'SubscribeCell',
  props: {
    column: Object,
    row: Object
  },
  setup (props) {
    const locale = get(i18n, 'global.locale.value', 'ru');
    const date = computed(() => get(props.row, props.column.prop.subscriptionExpireDate, ''));
    const accountStatus = computed(() => get(props.row, props.column.prop.accountStatus, ''));
    const till = computed(() => props.column.prefix);
    const paymentDate = computed(() => moment(date.value).locale(locale).format(props.column.formatDate));
    return {
      paymentDate,
      till,
      accountStatus
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/style.scss";

.lp-date {

    &__time {
      color: $color-gray;
    }
}

</style>
