<template>
  <div>
    <Modal
      :title="sentForm ? '' : 'landing.form.title'"
      @save="saveForm"
      @remove="closeForm"
      :hideButtons="['remove']"
      :saveBtnText="sentForm ? 'ok' : 'landing.form.save'"
      v-model:open="openModal"
    >
      <div class="lp-trial-form">
        <Form
          v-if="!sentForm"
          @submit="saveForm"
          :inputs="trialLessonInput"
        />
        <div
          class="lp-trial-form-placeholder"
          v-else
        >
          <img
            class="lp-trial-form-placeholder__image"
            :src="placeholder"
            alt="placeholder"
          />
          <p class="lp-trial-form-placeholder__title">
            {{ $t('landing.form.feedback.thanks') }}
          </p>
          <div class="lp-trial-form-placeholder__text">
            <p>{{ $t('landing.form.feedback.accept') }}</p>
            <p>{{ $t('landing.form.feedback.manager') }}</p>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { ref, watch } from 'vue';
import Form from '@/components/Main/Form/Form';
import trialLessonInput from '@/constants/landing/trialLessonInput';
import validateProfile from '@/constants/validatesForm/validateProfile';
import EmailApi from '@/api/Email/api';
import placeholder from '@/assets/images/sentTrialLesson.png';

export default {
  name: 'TrialLessonForm',
  components: { Form, Modal },
  props: {
    openForm: Boolean
  },
  emits: ['update:openForm'],
  setup (props, { emit }) {
    const sentForm = ref(false);
    const resetInputs = () => {
      trialLessonInput.forEach((item) => {
        item.modelValue = item.defaultValue;
      });
    };

    const toggleJivo = (val) => {
      const el = document.querySelector('jdiv');
      el.style.display = val ? 'none' : 'inline';
    };

    const openModal = ref(false);
    watch(openModal, (value) => emit('update:openForm', value));
    watch(() => props.openForm, (value) => {
      sentForm.value = false;
      openModal.value = value;
      toggleJivo(value);
      if (!value) resetInputs();
    });

    const generateTextMail = ({ name, phone, email = '-' }) => {
      const fields = [`Имя: ${name}`, `Телефон: ${phone}`, `Email: ${email}`];
      return fields.join(';\n');
    };

    const generateHtmlMail = ({ name, phone, email = '-' }) => {
      const fields = [`<p>Имя: ${name}</p>`, `<p>Телефон: ${phone}</p>`, `<p>Email: ${email}</p>`];
      return fields.join('');
    };

    const closeForm = () => {
      openModal.value = false;
    };

    const saveForm = async () => {
      if (sentForm.value) {
        closeForm();
        return;
      }
      const body = validateProfile(trialLessonInput);
      if (!body) return;
      sentForm.value = true;
      try {
        await EmailApi.sendEmail({
          subject: 'Запись на пробный урок',
          text: generateTextMail(body),
          html: generateHtmlMail(body)
        });
      } catch (e) {
        console.error(e);
      }
    };

    return {
      placeholder,
      trialLessonInput,
      openModal,
      saveForm,
      closeForm
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-trial-form {
  min-width: 496px;

  @media (max-width: 496px) {
    min-width: 90vw;
  }

  &-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 536px;

    @media (max-width: 536px) {
      min-width: 100%;
    }

    &__image {
      width: 216px;
      height: auto;
      object-fit: cover;
      margin: 40px auto 20px;
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
      text-align: center;
      margin-bottom: 8px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      text-align: center;
    }
  }
}

</style>
