import { reactive } from 'vue';
import i18n from '@/i18n';
import {
  morningIcon,
  lateMorningIcon,
  dayIcon,
  afternoonIcon,
  eveningIcon,
  lateEveningIcon,
  nightIcon,
  afterMidnightIcon
} from '@/constants/icons';

export default reactive([
  {
    value: [6, 7, 8, 9],
    icon: morningIcon,
    title: i18n.global.t('timesOfDays.morning'),
    time: '6-9',
    include: false
  },
  {
    value: [9, 10, 11, 12],
    icon: lateMorningIcon,
    title: i18n.global.t('timesOfDays.lateMorning'),
    time: '9-12',
    include: false
  },
  {
    value: [12, 13, 14, 15],
    icon: dayIcon,
    title: i18n.global.t('timesOfDays.day'),
    time: '12-15',
    include: false
  },
  {
    value: [15, 16, 17, 18],
    icon: afternoonIcon,
    title: i18n.global.t('timesOfDays.afternoon'),
    time: '15-18',
    include: false
  },
  {
    value: [18, 19, 20, 21],
    icon: eveningIcon,
    title: i18n.global.t('timesOfDays.evening'),
    time: '18-21',
    include: false
  },
  {
    value: [21, 22, 23, 24],
    icon: lateEveningIcon,
    title: i18n.global.t('timesOfDays.lateEvening'),
    time: '21-24',
    include: false
  },
  {
    value: [0, 1, 2, 3],
    icon: nightIcon,
    title: i18n.global.t('timesOfDays.night'),
    time: '0-3',
    include: false
  },
  {
    value: [3, 4, 5, 6],
    icon: afterMidnightIcon,
    title: i18n.global.t('timesOfDays.afterMidnight'),
    time: '3-6',
    include: false
  }
]);
