<template>
  <div
    class="lp-cropper"
    @click.stop
    v-click-outside="closeCropper"
  >
    <div class="lp-cropper-content">
      <div class="lp-cropper-content-header">
        <span class="lp-cropper-content-header__title">{{ $t(title) }}</span>
        <span
          class="lp-cropper-content-header__icon lp-cross-button"
          @click="closeCropper"
        >
          <crossIcon />
        </span>
      </div>
      <Cropper
        class="lp-cropper-content__resizer"
        :src="image"
        :minHeight="100"
        :stencilProps="{aspectRatio: 1}"
        :stencilComponent="CircleStencil"
        imageRestriction="fill-area"
        @change="changeCropper"
      />
      <div class="lp-cropper-content-footer">
        <button
          class="lp-button lp-cropper-content-footer__button"
          @click.stop="saveImage"
        >
          {{ $t('buttons.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import { crossIcon } from '@/constants/icons';
import { ref } from 'vue';

export default {
  name: 'CropperImage',
  components: {
    Cropper,
    crossIcon
  },
  props: {
    image: String,
    file: Object
  },
  setup (props, { emit }) {
    const title = 'profile.forms.basic.cropper';
    const imageResize = ref(null);

    const closeCropper = () => {
      emit('update:file', null);
      emit('update:open', false);
    };

    const changeCropper = ({ canvas }) => {
      imageResize.value = canvas.toDataURL();
    };

    const saveImage = async () => {
      const blob = await fetch(imageResize.value).then((r) => r.blob());
      const blobType = blob.type.replace(/.+\//g, '');
      blob.originalname = props.file.name.replace(/([a-z|A-Z]+)$/g, blobType);
      emit('update:file', blob);
    };

    return {
      CircleStencil,
      title,
      changeCropper,
      closeCropper,
      saveImage
    };
  }
};
</script>

<style lang="scss">
@import "../../../sass/style";

.lp-cropper {
  @include global-font;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba($color-black, 0.5);
  z-index: 100;

  &-content {
    display: grid;
    grid-template-rows: auto 1fr auto;
    max-height: 95vh;
    background-color: $color-white;
    border-radius: 4px;
    width: 400px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-bottom: 1px solid $color-gallery;

      &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.07em;
        user-select: none;
      }

      &__icon {
        width: 22px;
        height: 22px;
        margin-left: 100px;
      }
    }

    &__resizer {
      position: relative;
      margin: 24px 25px 0;
      height: 350px;
      width: 350px;
      border-radius: 4px;
      overflow: hidden;
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      padding: 30px 20px 18px;
    }
  }
}

</style>
