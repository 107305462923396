<template>
  <div
    id="app"
    class="lp-container"
    :class="{
      'lp-container_landing': isLanding,
      'lp-container_auth': isAuthPage,
      'lp-container_top': isSuperAdmin
    }"
  >
    <Loader />
    <template v-if="!isAuthPage && !loaderRun && !isLanding">
      <template v-if="!isSuperAdmin">
        <Header class="lp-container__header" />
        <Sidebar class="lp-container__sidebar" />
      </template>
      <template v-else>
        <SidebarAdmin />
      </template>
    </template>
    <div
      class="lp-container__content"
      :class="{
        'lp-container__content_landing': isLanding,
        'lp-container__content_auth': isAuthPage,
        'lp-container__content_admin': isSuperAdmin
      }"
      ref="mainScroll"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, watch, ref, provide
} from 'vue';
import { useRouter } from 'vue-router';
import { VueCookieNext } from 'vue-cookie-next';
import { useStore } from 'vuex';
import { find } from 'lodash';
import UsersApi from '@/api/Users/api';
import FilesApi from '@/api/Files/api';
import ROLE_TYPES from '@/constants/enums/roles';
import Loader from '@/components/Main/Loader/Loader';
import Sidebar from '@/components/Main/Sidebar/Sidebar';
import Header from '@/components/Main/Header/Header';
import SidebarAdmin from '@/components/Main/Sidebar/SidebarAdmin';
import { socketConfig } from '@/constants/socket.io';
import io from 'socket.io-client';

export default {
  name: 'App',
  components: {
    Header,
    Loader,
    Sidebar,
    SidebarAdmin
  },
  setup () {
    const installSocket = () => {
      const { connection, debug, options } = socketConfig;
      localStorage.setItem('debug', debug ? '*' : '');
      const socket = io(connection, options);
      provide('socket', socket);
    };

    const remember = computed(() => VueCookieNext.getCookie('remember'));
    const store = useStore();
    const loaderRun = computed(() => store.getters.loaderRun);
    const roleType = ROLE_TYPES;

    const activeUser = computed(() => store.getters.activeUser);
    const setActiveUser = (data) => store.dispatch('setActiveUser', data);
    const userRole = computed(() => {
      const role = find(ROLE_TYPES, (role) => role === activeUser.value.role) || '';
      return role.toLowerCase();
    });
    const isSuperAdmin = computed(() => userRole.value === roleType.SUPER_ADMIN_SECOND);
    const setAvatarUser = () => {
      const imageId = activeUser.value.uploadFile;
      if (!imageId || typeof imageId === 'object') {
        store.dispatch('setAvatarUser', imageId || {});
        return;
      }
      FilesApi.getFile(imageId).
        then(({ data }) => {
          store.dispatch('setAvatarUser', data);
        }).
        catch((err) => {
          console.error(err);
        });
    };
    watch(activeUser, setAvatarUser);

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const mainScroll = ref(null);
    const setMainScrollStore = (data) => store.dispatch('setMainScroll', data);

    watch(mainScroll, () => {
      setMainScrollStore(mainScroll.value);
    });
    const scrollUp = () => {
      const ps = document.querySelector('.lp-container__content');
      ps.scrollTop = 0;
    };

    const router = useRouter();
    const currentRouteName = computed(() => router.currentRoute.value.name);
    const routesLogin = ['Login', 'NewTeacher', 'NewStudent'];
    const isAuthPage = computed(() => routesLogin.includes(currentRouteName.value));
    const isLanding = computed(() => currentRouteName.value === 'Landing');
    const routerPush = (route) => {
      if (route.name !== currentRouteName.value) router.push(route);
    };

    const changeTitle = () => {
      const { title } = router.currentRoute.value.meta;
      if (!title) return;
      document.title = title;
    };

    const getActiveUser = async () => {
      try {
        await setLoaderRun(true);
        const { data } = await UsersApi.getActiveUser();
        await setActiveUser(data);
        const route = { name: 'Profile', params: { type: userRole.value } };
        if (currentRouteName.value === 'Login') routerPush(route);
        await setLoaderRun(false);
      } catch (err) {
        const { noRedirect } = router.currentRoute.value.meta;
        await setLoaderRun(false);
        if (noRedirect) return;
        routerPush({ name: 'Login' });
        console.error(err);
      }
    };

    const checkToken = () => {
      if (!router.currentRoute.value.name) return;
      if (router.currentRoute.value.name !== 'Landing') installSocket();
      const token = VueCookieNext.getCookie('token');
      const { noRedirect, redirect } = router.currentRoute.value.meta;
      if (isLanding.value) return;
      if ((!currentRouteName.value || noRedirect) && !token) return;
      if (remember.value === 'false') {
        VueCookieNext.removeCookie('token');
        VueCookieNext.removeCookie('remember');
      }
      const { _id: userId } = activeUser.value;
      if (token && userId) return;
      if (token && !userId) {
        getActiveUser();
      } else {
        routerPush({ name: redirect || 'Login' });
      }
    };

    watch(router.currentRoute, () => {
      checkToken();
      scrollUp();
      changeTitle();
    });

    onMounted(checkToken);

    return {
      isAuthPage,
      activeUser,
      mainScroll,
      userRole,
      loaderRun,
      roleType,
      isSuperAdmin,
      isLanding
    };
  }
};
</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css" />

<style lang="scss">
@import './sass/style';

.lp-container {
  @include global-font;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 60px 1fr;
  min-height: 100vh;
  background-color: $color-alabaster;

  &_auth {
    justify-content: center;
    align-items: center;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }

  &_landing {
    display: block;
    width: 100%;
    height: 100%;
  }

  &.lp-container {
    &_auth {
      grid-template-rows: 1fr;
    }
  }

  &_top {
    grid-template-rows: 0 1fr;
  }

  &__header {
    grid-column: 1/3;
    grid-row: 1;
  }

  &__sidebar {
    grid-column: 1;
    grid-row: 2;
  }

  &__content {
    grid-column: 2;
    grid-row: 2;
    padding: 30px 21px;
    height: calc(100vh - 60px);
    overflow: auto;

    &_landing {
      padding: 0;
      height: 100vh;
    }

    &_auth {
      grid-column: 1;
      grid-row: 1;
      align-self: center;
      justify-self: center;
      width: 100%;
      height: auto;
      max-height: 100vh;
      background-color: transparent;
    }

    &.lp-container__content {
      &_auth {
        height: auto;
      }
    }

    &_admin {
      height: 100vh;
    }
  }
}

</style>
