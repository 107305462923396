export default {
  beforeMount (el, binding) {
    el.clickOutsideEvent = (event) => {
      if (event.button) return;
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('mousedown', el.clickOutsideEvent);
  },
  unmounted (el) {
    document.body.removeEventListener('mousedown', el.clickOutsideEvent);
  }
};
