<template>
  <section class="lp-profile-price">
    <div class="lp-profile-price-title">
      <h3 class="lp-section-title">
        {{ $t('profile.sections.price') }}
      </h3>
      <div class="lp-plus-button">
        <editIcon @click.stop="$emit('update:openModal', true)" />
      </div>
    </div>
    <div class="lp-profile-price__value">
      ${{ user.lessonCost }}
    </div>
  </section>
</template>

<script>
import { editIcon } from '@/constants/icons';

export default {
  name: 'ProfilePrice',
  components: { editIcon },
  props: {
    user: Object
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-profile-price {
  @include global-font;
  position: relative;
  background-color: $color-white;
  box-shadow: 0 0 15px rgba($color-black, 0.02);
  padding: 40px 23px 40px 32px;

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__value {
    margin-top: 14px;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: $color-text;
  }

}

</style>
