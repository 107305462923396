<template>
  <div class="lp-payment-methods">
    <!--    <PaymentCards />-->
    <BalanceHistory
      :title="'paymentMethods.balanceHistory.payments'"
      :rows="historyBalanceList"
      :columns="listPaymentsStudent"
      :isShow="isShow"
      :imageUrl="paymentMethod"
      :widthImg="'321'"
      :heightImg="'215'"
      :titleListEmpty="'listEmpty.payments.title'"
      :text="'listEmpty.payments.text'"
      :description="'listEmpty.payments.description'"
    />
  </div>
</template>

<script>
// import PaymentCards from '@/components/PaymentMethods/PaymentCards/PaymentCards';
import BalanceHistory from '@/components/BalanceHistory/BalanceHistory';
import listPaymentsStudent from '@/constants/tableSettings/listPaymentsStudent';
import paymentMethod from '@/assets/images/paymentMethod.png';

import { ref } from 'vue';

export default {
  name: 'PaymentMethods',
  components: {
    BalanceHistory
    // PaymentCards,
  },
  setup () {
    const historyBalanceList = ref([]);

    const isShow = ref(false);

    return {
      historyBalanceList,
      isShow,
      paymentMethod,
      listPaymentsStudent
    };
  }
};

</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-payment-methods {
  height: 100%;
  margin-top: -10px;
}

</style>
