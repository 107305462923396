<template>
  <div class="lp-image">
    <img
      v-if="imgUrl"
      class="lp-image__avatar"
      :src="imgUrl"
    />
    <userIcon
      v-else
      class="lp-image__avatar"
    />
    <div>
      {{ counterparty }}
    </div>
  </div>
</template>

<script>
import { userIcon } from '@/constants/icons';
import { get } from 'lodash';
import { computed } from 'vue';

export default {
  name: 'ImageCell',
  props: {
    column: Object,
    row: Object
  },
  setup (props) {
    const imgUrl = computed(() => get(props.row, props.column.prop.img, ''));
    const counterparty = computed(() => get(props.row, props.column.prop.counterparty, ''));
    return {
      userIcon,
      imgUrl,
      counterparty
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/style.scss";

.lp-image {
  display: flex;
  align-items: center;

  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
  }
}

</style>
