import teacher1Img from '@/assets/images/landing/teachers/teacher1.jpg';

export default {
  image: teacher1Img,
  name: 'Анастасия Самойлова',
  about: `Я работаю по методике разножанрового вокала Improvination. В основе этой методики - понимание принципов работы вокального аппарата. Она учит, как именно можно и нужно повлиять на твой звук, сделать его лучше, овладеть вокальными приемами. 

          Я знаю что пение и музыка это не только умение красиво петь, но и лечение души. Я уверена в том, что научить петь можно каждого, главное - это твое желание развиваться, терпение и помощь профессионального преподавателя. 

          Потому, я буду рада тебе помочь найти себя в этой сфере, окунуться в мир творчества и наслаждения. Жду тебя!
          `,
  works: [{
    date: '2019 - по настоящее время',
    position: 'частная практика',
  }],
  workText: '2019 - по настоящее время - частная практика',
  hideNext: true,
};
