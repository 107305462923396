<template>
  <div class="lp-calendar-days">
    <div
      class="lp-calendar-days-header"
      :class="{'lp-calendar-days-header_past': isPastDate}"
    >
      {{ formatDate }}
    </div>
    <perfect-scrollbar class="lp-calendar-days-table">
      <div
        class="lp-calendar-days-table-row"
        v-for="(item, index) of timetable"
        :key="item.time"
      >
        <div class="lp-calendar-days-table-row__time">
          {{ item.time }}
        </div>
        <div
          class="lp-calendar-days-table-row-item"
          :class="{
            'lp-calendar-days-table-row-item_empty': !item.lesson,
            'lp-calendar-days-table-row-item_next': !item.lesson && timetable[index - 1] && timetable[index - 1].lesson
          }"
        >
          <CalendarLesson
            v-if="item.lesson"
            mode="day"
            :key="item.lesson._id"
            :lesson="item.lesson"
          />
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { computed, watchEffect } from 'vue';
import moment from 'moment';
import { fill, find } from 'lodash';
import CalendarLesson from '@/components/Main/Calendar/CalendarLesson';

export default {
  name: 'CalendarDays',
  components: { CalendarLesson },
  props: {
    date: String,
    locale: String,
    lessons: Object
  },
  setup (props, { emit }) {
    const formatDate = computed(() => moment(props.date).locale(props.locale).format('DD dddd'));
    const isPastDate = computed(() => moment().subtract(1, 'day').isAfter(props.date));
    const times = fill(new Array(24), '2020-01-01T06:00').map((el, i) => moment(el).add(i, 'hour').format('HH:mm'));
    const lessonsByDate = computed(() => (props.lessons[props.date] || []).map((lesson) => {
      lesson.startTime = moment(lesson.lessonStartTime).format('HH:mm');
      return lesson;
    }));
    const timetable = computed(() => times.map((time) => {
      const lessonByTime = find(lessonsByDate.value, { startTime: time });

      return { time, lesson: lessonByTime };
    }));

    watchEffect(props, emit('update:range', [props.date, props.date]));
    return { formatDate, timetable, isPastDate };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-calendar-days {
  @include global-font;

  &-header {
    position: sticky;
    top: 0;
    padding: 10px 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-moon-raker;
    border: 1px solid $color-alto;
    border-radius: 4px 4px 0 0;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $color-accent;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    z-index: 1;

    &_past {
      background: $color-alabaster;
      color: $color-gray;
    }
  }

  &-table {
    display: grid;
    grid-gap: 1px;
    padding: 24px 0 35px 24px;
    border: 1px solid $color-alto;
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    overflow: hidden;
    height: calc(100vh - 320px);

    &-row {
      display: grid;
      grid-template-columns: 39px 1fr;
      grid-gap: 16px;

      &__time {
        color: $color-gray;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        height: 18px;
        margin-top: -9px;
      }

      &-item {
        min-height: 40px;
        width: 100%;

        &_empty {
          border-top: 1px solid $color-alto;
        }

        &_next {
          border: none;
        }
      }
    }
  }
}

</style>
