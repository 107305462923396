import { uniqueId } from 'lodash';
import {
  logoutIcon
} from '@/constants/icons';
import ROLE_TYPES from '@/constants/enums/roles';

const menu = [
  {
    title: 'sidebar.logout',
    icon: logoutIcon,
    routeNames: [],
    route: {
      name: ''
    },
    access: ROLE_TYPES.SUPER_ADMIN
  }
];

export default menu.map((item) => {
  item.id = uniqueId('sidebar-menu-admin');
  return item;
});
