<template>
  <div class="lp-list-empty">
    <img
      class="lp-list-empty__image"
      :src="imageUrl"
      :style="{
        width: `${widthImg}px`,
        height: `${heightImg}px`
      }"
    />
    <div class="lp-list-empty__title">
      {{ $t(title) }}
    </div>
    <div class="lp-list-empty__text">
      <div>
        {{ $t(text) }}
      </div>
      <div>
        {{ $t(description) }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ListEmpty',
  props: {
    imageUrl: String,
    widthImg: String,
    heightImg: String,
    title: String,
    text: String,
    description: String
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-list-empty {
  @include global-font;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  &__image {
    object-fit: contain;
    margin: 95px 0 25px;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__text {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
  }
}

</style>
