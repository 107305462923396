import CURRENT_SERVER from '@/constants/domains';

const emails = [
  'gleb.dudko@brymonsoft.com',
  'irina.narinskaya@webfriends.by',
  'maksim.hrynevich@webfriends.by'
];

const axios = require('axios');

export default {
  sendEmail (data) {
    data.to = emails.join(', ');
    const instCred = axios.create({
      baseURL: CURRENT_SERVER
    });
    return instCred.post('mailer/sendmail', data);
  }
};
