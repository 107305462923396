<template>
  <section class="lp-landing-feedback">
    <div class="lp-landing-feedback-header">
      <h2 class="lp-landing-feedback__title">
        {{ $t('landing.feedback.title') }}
      </h2>
      <div
        class="lp-landing-feedback-header-nav"
        ref="sliderNav"
        id="lpFeedbackNav"
      />
    </div>
    <div class="lp-landing-feedback-list">
      <div
        class="lp-landing-feedback-list-item"
        v-for="(item, index) of reviews"
        :key="index"
      >
        <img
          class="lp-landing-feedback-list-item__image"
          :src="item.img"
          alt="photo"
        />
        <div class="lp-landing-feedback-list-item-comment">
          <p class="lp-landing-feedback-list-item-comment__name">
            {{ item.name }}
          </p>
          <p class="lp-landing-feedback-list-item-comment__text">
            {{ item.comment }}
          </p>
        </div>
      </div>
    </div>
    <FeedbackSlider
      class="lp-landing-feedback-slider"
      v-if="sliderNav"
      :reviews="reviews"
    />
  </section>
</template>

<script>
import reviews from '@/constants/landing/reviews';
import FeedbackSlider from '@/components/Landing/Feedback/FeedbackSlider';
import { ref } from 'vue';

export default {
  name: 'Feedback',
  components: { FeedbackSlider },
  setup () {
    const sliderNav = ref(null);

    return {
      sliderNav,
      reviews
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-landing-feedback {
  background-image: url('~@/assets/images/landing/backgrounds/lines3.svg');
  background-position: center 37px;
  background-repeat: no-repeat;
  background-size: auto 400px;
  width: 100%;
  min-height: 668px;

  @media (max-width: 1024px) {
    min-height: auto;
    padding-bottom: 60px;
  }

  @media (max-width: 767px) {
    background-image: none;
  }

  &-header {
    margin: 0 auto 60px;

    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      padding: 0 78px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
      margin: 0 auto 24px;
    }

    &-nav {
      display: grid;
      grid-template-columns: 44px 44px;
      grid-gap: 10px;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: -1;
      left: -100vw;

      @media (max-width: 1024px) {
        position: relative;
        z-index: 1;
        left: 0;
      }

      @media (max-width: 767px) {
        grid-template-columns: 36px 36px;
        grid-gap: 8px;
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 36px;
    line-height: 125%;
    text-align: center;
    color: $color-text;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  &-slider {
    display: none;

    @media (max-width: 1024px) {
      display: block;
    }
  }

  &-list {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    max-width: 1350px;
    min-height: 466px;
    margin: auto;

    @media (max-width: 1350px) {
      padding-left: 5vw;
      max-width: 100vw
    }

    @media (max-width: 1024px) {
      display: none;
    }

    &-item {
      $this: &;
      position: relative;

      &__image {
        border-radius: 50%;
        border: 4px solid $color-white;
        filter: drop-shadow(0px 0px 22.5285px rgba(0, 0, 0, 0.09));
        box-sizing: border-box;
        object-fit: cover;
      }

      &-comment {
        position: absolute;
        padding: 20px;
        background: $color-white;
        box-shadow: 0 0 20px rgba($color-black, 0.05);
        border-radius: 4px;
        color: $color-text;

        &__name {
          margin-bottom: 8px;
          font-weight: bold;
          font-size: 14px;
          line-height: 125%;
        }

        &__text {
          font-weight: 500;
          font-size: 14px;
          line-height: 125%;
        }
      }

      &:nth-child(1) {
        margin-right: 158px;
        top: -40px;

        @media (max-width: 1350px) {
          margin-right: 5vw
        }

        #{$this}-comment {
          width: 320px;
          bottom: 82px;
          right: -331px;
        }

        #{$this}__image {
          width: 90px;
          height: 90px;
        }
      }

      &:nth-child(2) {
        margin-right: 157px;
        top: -2px;

        @media (max-width: 1350px) {
          margin-right: 10vw
        }

        #{$this}-comment {
          width: 274px;
          top: 80px;
          left: -87px;
        }

         #{$this}__image {
          width: 70px;
          height: 70px;
        }
      }

      &:nth-child(3) {
        margin-right: 321px;
        top: -6px;

        @media (max-width: 1350px) {
          margin-right: 10vw
        }

        #{$this}-comment {
          width: 266px;
          bottom: 105px;
          left: 151px;
        }

         #{$this}__image {
          width: 140px;
          height: 140px;
        }
      }

      &:nth-child(4) {
        margin-right: 174px;
        top: 8px;

        @media (max-width: 1350px) {
          margin-right: 18vw
        }

        #{$this}-comment {
          width: 254px;
          top: 91px;
          left: -87px;
        }

        #{$this}__image {
         width: 80px;
         height: 80px;
       }
      }

      &:nth-child(5) {
        top: 9px;

        #{$this}-comment {
          width: 254px;
          bottom: 73px;
          left: -95px;
        }

         #{$this}__image {
          width: 63px;
          height: 63px;
        }
      }
    }
  }
}

</style>

<style lang="scss">
  @import "../../../sass/style";

  .lp-landing-feedback-header-nav {
    .carousel__prev, .carousel__next {
      top: auto;
      left: auto;
      transform: none;
      border: 1px solid $color-alto;
      box-sizing: border-box;
      filter: drop-shadow(0 0 16px rgba($color-black, 0.09));
      background-color: transparent;
      width: 44px;
      height: 44px;
      opacity: 1;
      cursor: pointer;

      @media (max-width: 767px) {
        width: 36px;
        height: 36px;
      }

      &:hover {
        background-color: rgba($color-white, 0.2);

        svg {
          path {
            fill: $color-accent-hover;
          }
        }
      }

      svg {
        transition: 0.3s ease;

        path {
          fill: $color-accent;
        }
      }
    }
  }

</style>
