<template>
  <section class="lp-user-subjects">
    <div
      class="lp-user-subjects__subject"
      v-for="subject of subjects"
      :style="subject.style"
      :key="subject.id"
    >
      {{ subject.title }}
    </div>
  </section>
</template>

<script>
export default {
  name: 'UserSubjects',
  props: {
    subjects: Array
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-user-subjects {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -12px -12px;

  &__subject {
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    padding: 5px 12px;
    margin: 0 0 12px 12px;
    color: $color-text;
    border-radius: 4px;
  }
}

</style>
