<template>
  <teleport to="body">
    <transition name="fade">
      <div
        class="lp-modal"
        v-if="open"
      >
        <div
          class="lp-modal-content"
          :class="{'lp-modal-content_slider': slider}"
          @click.stop
          v-click-outside="closeModal"
        >
          <div
            class="lp-modal-content-header"
            :class="{'lp-modal-content-header_slider': slider}"
          >
            <span class="lp-modal-content-header__title">{{ title ? $t(title) : null }}</span>
            <span
              class="lp-modal-content-header__icon lp-cross-button"
              :class="{'lp-modal-content-header__icon_slider': slider}"
              v-if="!cantClose"
              @click="closeModal"
            >
              <crossIcon />
            </span>
          </div>
          <perfect-scrollbar
            class="lp-modal-content-body"
            :class="{'lp-modal-content-body_slider': slider}"
          >
            <slot></slot>
          </perfect-scrollbar>
          <div class="lp-modal-content-footer">
            <slot name="footer">
              <button
                class="lp-button lp-button_thin lp-modal-content-footer__button"
                @click="$emit('remove')"
                v-if="!hideButtons || !hideButtons.includes('remove')"
              >
                {{ $t(removeBtnText || 'buttons.remove') }}
              </button>
              <button
                class="lp-button lp-modal-content-footer__button"
                @click.stop="$emit('save')"
                v-if="!hideButtons || !hideButtons.includes('save')"
              >
                {{ $t(saveBtnText || 'buttons.save') }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { crossIcon } from '@/constants/icons';
import {
  computed,
  onBeforeMount, onBeforeUnmount, ref, watch
} from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'Modal',
  components: {
    crossIcon
  },
  props: {
    title: String,
    open: [Boolean, Number],
    hideButtons: Array,
    cantClose: Boolean,
    saveBtnText: String,
    removeBtnText: String,
    slider: Boolean
  },
  emits: ['save', 'remove', 'update:open'],
  setup (props, { emit }) {
    const store = useStore();
    const modalIds = computed(() => store.getters.modalIds);
    const setModalIds = (data) => store.dispatch('setModalIds', data);

    const modalId = ref(null);
    watch(modalId, (newId, oldId) => {
      if (newId) {
        modalIds.value.push(newId);
      } else {
        setModalIds(modalIds.value.filter((item) => item !== oldId));
      }
    });

    const closeModal = () => {
      const maxId = Math.max(...modalIds.value);
      if (props.cantClose || maxId !== modalId.value) return;
      emit('update:open', false);
    };

    watch(() => props.open, (newVal) => {
      modalId.value = newVal ? Date.now() : null;
    });

    const onKeyUp = (e) => {
      if (e.keyCode === 27) closeModal();
    };
    onBeforeMount(() => {
      document.addEventListener('keyup', onKeyUp, false);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('keyup', onKeyUp, false);
    });

    return {
      closeModal
    };
  }
};
</script>

<style lang="scss">
@import "../../../sass/style";

.lp-modal {
  @include global-font;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba($color-black, 0.5);
  z-index: 100;

  &-content {
    display: grid;
    grid-template-rows: auto 1fr auto;
    max-height: 95vh;
    background-color: $color-white;
    border-radius: 4px;

    &_slider {
      background-color: transparent;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-bottom: 1px solid $color-gallery;

      &_slider {
        width: 80vw;
        padding: 0;
        border-color: transparent;
      }

      &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.07em;
        user-select: none;
      }

      &__icon {
        width: 22px;
        height: 22px;
        margin-left: 100px;
        cursor: pointer;

        &_slider {
          transform: scale(1.55);
          svg {
            transition: 0.3s ease-out;

            path {
              fill: $color-white;
            }
          }
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }

    &-body {
      min-height: 200px;
      padding-bottom: 20px;
      overflow: hidden;

      &_slider {
        width: 80vw;
        padding: 0 20px;
        overflow: visible;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      padding: 20px 20px 18px;

      @media (max-width: 767px) {
        justify-content: center;
      }

      &__button {
        margin-left: 4px;
      }

    }
  }
}

</style>
