<template>
  <div class="lp-landing-feedback-slider">
    <Carousel
      :itemsToShow="1"
      :snapAlign="'center'"
      :wrapAround="true"
      :currentSlide="currentSlide"
    >
      <Slide
        v-for="(item, index) in reviews"
        :key="index"
      >
        <div class="lp-landing-feedback-slider-item">
          <div class="lp-landing-feedback-slider-item-comment">
            <p class="lp-landing-feedback-slider-item-comment__name">
              {{ item.name }}
            </p>
            <p class="lp-landing-feedback-slider-item-comment__text">
              {{ item.comment }}
            </p>
          </div>
          <img
            class="lp-landing-feedback-slider-item__image"
            :src="item.img"
            alt="photo"
          />
        </div>
      </Slide>

      <template #addons>
        <teleport to="#lpFeedbackNav">
          <Navigation />
        </teleport>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
import {
  Carousel,
  Slide,
  Pagination,
  Navigation
} from 'vue3-carousel';
import { ref, onBeforeMount } from 'vue';

export default {
  name: 'FeedbackSlider',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  props: {
    reviews: Array
  },
  setup () {
    const currentSlide = ref(0);

    onBeforeMount(() => {
      setTimeout(() => {
        currentSlide.value += 1;
      }, 1000);
    });

    return {
      currentSlide
    };
  }
};
</script>

<style lang="scss" scoped>
    @import "../../../sass/style";

    .lp-landing-feedback-slider {
      max-width: 99vw;
      padding: 0 25px;
      margin: auto;

      &-item {
        display: grid;
        grid-gap: 20px;
        justify-content: center;

        &-comment {
          max-width: 425px;
          padding: 20px;
          background: $color-white;
          box-shadow: 0 0 20px rgba($color-black, 0.05);
          border-radius: 4px;
          color: $color-text;
          text-align: left;

          &__name {
            margin-bottom: 8px;
            font-weight: bold;
            font-size: 18px;
            line-height: 125%;

            @media (max-width: 767px) {
              font-size: 14px;
            }
          }

          &__text {
            font-weight: 500;
            font-size: 18px;
            line-height: 125%;

            @media (max-width: 767px) {
              font-size: 14px;
            }
          }
        }

        &__image {
          width: 60px;
          height: 60px;
          margin: 0 auto 16px;
          border-radius: 50%;
          border: 4px solid $color-white;
          filter: drop-shadow(0px 0px 22.5285px rgba(0, 0, 0, 0.09));
          box-sizing: border-box;
          object-fit: cover;
        }
      }
    }

</style>
