import { createApp } from 'vue';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import router from '@/router/index';
import store from '@/store/store';
import { VueCookieNext } from 'vue-cookie-next';
import focus from '@/constants/directive/focus';
import clickOutside from '@/constants/directive/clickOutside';
import Maska from 'maska';
import setLocation from '@/constants/setLocation';
import App from './App';
import i18n from './i18n';

const app = createApp(App);

const mount = async () => {
  const countryCode = await setLocation();
  app.provide('countryCode', countryCode);

  app.directive('focus', focus);
  app.directive('click-outside', clickOutside);

  app.use(Maska);
  app.use(VueCookieNext);
  app.use(PerfectScrollbar);
  app.use(store);
  app.use(router);
  app.use(i18n);

  app.mount('#app');
};

mount();
