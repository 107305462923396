<template>
  <div class="lp-custom-textarea">
    <div class="lp-custom-textarea-header">
      <label
        class="lp-label"
        :class="{'lp-label_required': required}"
        :for="label"
      >
        {{ $t(label) }}
      </label>
      <slot name="header"></slot>
    </div>
    <div class="lp-custom-textarea-filed">
      <textarea
        class="lp-input lp-custom-textarea-filed__input"
        :class="{'lp-input_error': error}"
        v-focus="autofocus"
        autocomplete="off"
        @keyup.enter="$emit('submit')"
        :key="label"
        :id="label"
        :value="modelValue"
        @focus="$emit('focus')"
        @input="inputValue"
      />
      <transition name="bounce">
        <span
          class="lp-custom-textarea-filed__error"
          v-if="error && typeof error === 'string'"
        >
          <dangerIcon />
          {{ $t(error) }}
        </span>
      </transition>
    </div>
  </div>
</template>

<script>
import { dangerIcon } from '@/constants/icons';
import { upperFirst, trimStart } from 'lodash';

export default {
  name: 'CustomTextarea',
  components: {
    dangerIcon
  },
  props: {
    modelValue: [Number, String],
    type: String,
    label: String,
    autofocus: Boolean,
    error: [Boolean, String],
    required: String,
    upperFirst: Boolean
  },
  setup (props, { emit }) {
    const inputValue = ({ target }) => {
      target.value = trimStart(target.value);
      if (props.upperFirst) target.value = upperFirst(target.value);
      emit('update:modelValue', target.value);
    };

    return {
      inputValue
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-custom-textarea {
  @include global-font;
  display: grid;
  grid-template-rows: 15px auto;
  grid-gap: 4px;
  align-items: start;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-filed {
    position: relative;
    min-height: 42px;
    height: 100%;

    &__input {
      display: block;
      resize: none;
      height: 100%;
    }

    &__error {
      position: absolute;
      top: 46px;
      left: 0;
      background-color: $color-white;
      display: inline-grid;
      grid-template-columns: auto auto;
      grid-gap: 4px;
      align-items: center;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: $color-red;
    }
  }
}

</style>
