<template>
  <form
    class="lp-login-form"
    @submit.prevent="$emit('submit')"
  >
    <CustomInput
      v-for="input of loginInputs"
      :key="input.label"
      v-bind="input"
      @submit="$emit('submit')"
      @focus="input.error = false"
      v-model="input.model"
    >
      <template
        v-if="input.type === 'password'"
        #header
      >
        <span class="lp-login-form__link lp-link">
          {{ $t('login.forgotPassword') }}
        </span>
      </template>
    </CustomInput>
  </form>
</template>

<script>
import loginInputs from '@/constants/inputs/loginInputs';
import CustomInput from '@/components/Main/Fields/CustomInput';

export default {
  name: 'LoginForm',
  components: {
    CustomInput
  },
  setup () {
    return { loginInputs };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-login-form {
  display: grid;
  grid-template-rows: repeat(2, 80px);
  grid-gap: 16px;
  width: 100%;
  margin-bottom: 7px;

  &__link {
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    color: $color-accent;
    cursor: not-allowed;
  }
}

</style>
