<template>
  <Modal
    :title="noMoneyCard || newCardOpen ? '' : 'paymentMethods.modal.title'"
    v-model:open="openModal"
  >
    <template #default>
      <div
        class="lp-payment-modal"
        :class="{'lp-payment-modal_new-card': newCardOpen}"
      >
        <NoMoneyCardModal
          v-if="newCardOpen || noMoneyCard"
          :openModalNewCard="openModalNewCard"
          :cardImage="cardImage"
          :open="noMoneyCard"
          v-model:open="noMoneyCard"
        />
        <PaymentForm
          v-else
          :numberCard="numberCard"
          :actionTerm="actionTerm"
          :securityCode="securityCode"
        />
      </div>
    </template>
    <template
      #footer
    >
      <div
        v-if="!newCardOpen"
        class="lp-payment-modal-footer"
      >
        <button
          class="lp-button lp-button_thin"
          @click.stop="closeModal"
        >
          {{ $t('buttons.cancel') }}
        </button>
        <button
          class="lp-button"
          @click="noMoneyCard ? noMoneyCard = !noMoneyCard: addCard()"
        >
          {{ noMoneyCard ? $t('buttons.repeat') : $t('buttons.add') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import {
  getTypeCard,
  validateActionTerm,
  validateNumberCard,
  validateSecurityCode
} from '@/constants/validatesForm/validateCard';
import visa from '@/assets/images/enoughFundsVisa.png';
import masterCard from '@/assets/images/enoughFundsMasterCard.png';
import newCard from '@/assets/images/addCard.png';
import { numberCard, actionTerm, securityCode } from '@/constants/inputs/cardInputs';
import { computed, ref } from 'vue';
import PaymentApi from '@/api/Users/api';
import { useStore } from 'vuex';
import PaymentForm from '@/components/PaymentMethods/PaymentForm/PaymentForm';
import NoMoneyCardModal from '@/components/PaymentMethods/NoMoneyCardModal/NoMoneyCardModal';

export default {
  name: 'PaymentModal',
  components: {
    NoMoneyCardModal,
    PaymentForm,
    Modal
  },
  props: {
    newCardOpen: Boolean
  },
  setup (props, { emit }) {
    const openModal = ref(false);

    const store = useStore();
    const cardList = computed(() => store.getters.cardList);
    const cardImage = ref(null);

    const noMoneyCard = ref(false);

    const createCard = async (card) => {
      cardList.value.push(card);
      noMoneyCard.value = true;
      try {
        const response = await PaymentApi.createCard({ ...card, image: undefined });
        if (response) {
          emit('update:open', false);
        }
        noMoneyCard.value = true;
      } catch (err) {
        console.error(err);
      }
    };

    const openModalNewCard = () => {
      emit('update:newCardOpen', false);
    };

    const addCard = () => {
      const { cardNumber } = validateNumberCard(numberCard);
      const { ternAction } = validateActionTerm(actionTerm);
      const { codeSecurity } = validateSecurityCode(securityCode);

      const number = Number(cardNumber.replace(/\s/g, ''));
      cardImage.value = getTypeCard(cardNumber);
      const card = {
        cardNumber: number,
        ternAction,
        codeSecurity,
        image: cardImage.value
      };
      if (cardNumber && ternAction && codeSecurity) {
        createCard(card);
      }
    };

    const closeModal = () => {
      if (noMoneyCard.value) {
        noMoneyCard.value = false;
        emit('update:open', false);
      } else {
        emit('update:open', false);
      }
    };

    return {
      openModal,
      numberCard,
      actionTerm,
      securityCode,
      masterCard,
      getTypeCard,
      visa,
      newCard,
      noMoneyCard,
      cardImage,
      addCard,
      closeModal,
      openModalNewCard
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-payment-modal {
  @include global-font;
  max-height: 483px;
  max-width: 516px;

  &_new-card {
    max-height: 460px;
    max-width: 650px;
  }

  &-footer {
    padding: 3px;
  }
}

</style>
