<template>
  <label
    :for="label"
    class="lp-checkbox"
    :class="{'lp-checkbox_checked': modelValue}"
  >
    <input
      class="lp-checkbox__input"
      :id="label"
      :checked="modelValue"
      @input="$emit('update:modelValue', $event.target.checked)"
      type="checkbox"
    >
    <slot
      v-if="hasLabelSlot"
      name="label"
    ></slot>
    <span
      v-else
      class="lp-checkbox__label"
    >
      {{ $t(label) }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  props: {
    label: String,
    modelValue: Boolean,
    hasLabelSlot: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-checkbox {
  display: grid;
  grid-template-columns: 16px auto;
  grid-gap: 8px;
  user-select: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {

    &:before {
      border-color: $color-accent-hover;
    }
  }

  &:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $color-accent;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center 3px;
    background-size: 0;
    box-sizing: border-box;
    transition: 0.2s ease-in;
  }

  &_checked {
    &:before {
      background-color: $color-accent;
      background-size: 10px 10px;
      background-image: url("../../../assets/images/checkmark.svg")
    }

    &:hover {
      &:before {
        background-color: $color-accent-hover;
      }
    }
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__label {
    @include global-font;
    font-weight: inherit;
    font-size: inherit;
    line-height: 16px;
  }
}

</style>
