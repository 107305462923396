<template>
  <div class="lp-phone-input">
    <div
      class="lp-phone-input-header"
      v-if="label"
    >
      <label
        class="lp-label lp-phone-input-header__label"
        :class="{'lp-label_required': required}"
        :for="label"
      >
        {{ $t(label) }}
      </label>
      <slot name="header"></slot>
    </div>
    <div
      class="lp-phone-input-body lp-input"
      :class="{'lp-input_error': (error && typeof error === 'string')}"
    >
      <PhoneCountrySelect
        :fixed="fixed"
        :code="modelValue.country"
        v-model:country="selectedCountry"
      />
      <input
        class="lp-phone-input-body__field"
        v-if="selectedCountry"
        v-focus="autofocus"
        v-maska="selectedCountry.mask"
        :placeholder="placeholder"
        autocomplete="off"
        @keyup.enter="$emit('submit')"
        :key="label"
        :id="label"
        :value="modelValue.number"
        @focus="$emit('focus')"
        @input="inputValue"
        @blur="$emit('blur')"
        type="tel"
      >
      <transition name="bounce">
        <span
          class="lp-error"
          v-if="error"
          :class="{'lp-error_nowrap': noWrap}"
        >
          <dangerIcon />
          {{ $t(error) }}
        </span>
      </transition>
    </div>
  </div>
</template>

<script>
import PhoneCountrySelect from '@/components/Main/Fields/PhoneInput/PhoneCountrySelect';
import { computed, ref } from 'vue';
import { get, random } from 'lodash';
import { dangerIcon } from '@/constants/icons';

export default {
  name: 'PhoneInput',
  components: {
    PhoneCountrySelect,
    dangerIcon
  },
  props: {
    modelValue: Object,
    label: String,
    autofocus: Boolean,
    error: [Boolean, String],
    fixed: Boolean,
    noWrap: Boolean,
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'submit', 'focus', 'blur'],
  setup (props, { emit }) {
    const selectedCountry = ref(null);
    const placeholder = computed(() => {
      const mask = get(selectedCountry.value, 'mask', '');
      if (!mask) return '';
      let newMask = '';
      for (const char of mask) {
        newMask += char.replace(/#/gmi, random(1, 9));
      }
      return newMask;
    });

    const inputValue = ({ target }) => {
      emit('update:modelValue', { number: target.value, country: selectedCountry.value.code, placeholder });
    };

    return {
      inputValue,
      placeholder,
      selectedCountry
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/style";

.lp-phone-input {

  &-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 4px;
    height: 15px;

    &__label {
      line-height: 15px;
    }
  }

  &-body {
    position: relative;
    height: 42px;
    display: flex;
    align-items: center;

    &__field {
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      box-sizing: border-box;
      border: none;
      width: 100%;
      padding-top: 3px;
      height: 20px;

      &::placeholder {
        color: $color-gray
      }
    }
  }
}

</style>
