<template>
  <div class="lp-profile-stat-lessons">
    <svg
      class="lp-profile-stat-lessons-progress"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-1 -1 34 34"
    >
      <circle
        cx="16"
        cy="16"
        r="14"
        class="lp-profile-stat-lessons-progress__background"
      />
      <circle
        cx="16"
        cy="16"
        r="14"
        :style="{strokeDashoffset}"
        class="lp-profile-stat-lessons-progress__value"
      />
    </svg>
    <div class="lp-profile-stat-lessons__number">
      {{ lessons || 0 }}
    </div>
    <div class="lp-profile-stat-lessons__title">
      {{ isTeacher ? $t('profile.stats.teacher.lessons') : $t('profile.stats.student.lessons') }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'ProfileStatsLessons',
  props: {
    lessons: Number,
    isTeacher: Boolean
  },
  setup (props) {
    const strokeDashoffset = computed(() => 99 - ((props.lessons / 500) * 100 || 0));

    return {
      strokeDashoffset
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

$progress-bar-stroke-width: 3.2;
$progress-bar-size: 98px;

.lp-profile-stat-lessons {
  @include global-font;
  display: grid;
  align-items: center;
  justify-content: center;
  background: $color-moon-raker;

  &-progress {
    margin: auto;
    width: $progress-bar-size;
    height: $progress-bar-size;
    transform: rotate(-90deg);
    box-sizing: content-box;

    &__background {
      fill: none;
      stroke: rgba($color-mine-shaft, 0.1);
      stroke-width: $progress-bar-stroke-width;
    }

    &__value {
      fill: none;
      stroke: $color-mine-shaft;
      stroke-dasharray: 100 100;
      stroke-dashoffset: 100;
      stroke-linecap: round;
      stroke-width: $progress-bar-stroke-width;
      transition: stroke-dashoffset 1s ease-in-out;
    }
  }

  &__number {
    text-align: center;
    margin-top: 4px;
    font-weight: bold;
    font-size: 37px;
    line-height: 1;
  }

  &__title {
    text-align: center;
    margin-top: 4px;
    font-weight: bold;
    font-size: 14px;
  }
}

</style>
