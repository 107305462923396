<template>
  <div
    class="lp-amount"
    :class="{
      'lp-amount_minus': price < 0,
      'lp-amount_plus': price > 0
    }"
  >
    {{ amount(price) }}
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed } from 'vue';

export default {
  name: 'AmountCell',
  props: {
    column: Object,
    row: Object
  },
  setup (props) {
    const price = computed(() => get(props.row, props.column.prop, ''));

    const amount = (amount) => {
      if (amount > 0) {
        return `+$${amount}`;
      }
      if (amount < 0) {
        return `-$${amount.substr(1)}`;
      }
      return `$${amount}`;
    };
    return {
      amount,
      price
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/style.scss";

.lp-amount {
  width: 100%;
  text-align: end;

  &_minus {
    color: $color-red;
  }

  &_plus {
    color: $color-emerald;
  }
}

</style>
