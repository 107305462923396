<template>
  <div class="lp-image">
    <template v-if="!Object.keys(country).length">
      <div
        class="lp-image__user-active"
        v-if="column.isActive"
        :class="isActiveType ? `lp-image__user-active_type-${isActiveType}` : ''"
      >
        <userIconActive
          v-if="isActiveType"
          @mouseover="onMouseover(row.id)"
          @mouseleave="isActiveUser = -1"
        />
      </div>
      <img
        v-if="imgUrl"
        class="lp-image__avatar"
        :src="imgUrl"
      />
      <template v-else>
        <userIcon
          v-if="placeholderCountry !== 'country'"
          class="lp-image__avatar"
        />
        <countryIcon
          v-else
          class="lp-image__avatar"
        />
      </template>
      <div>
        {{ value }}
        <template v-if="subValue">
          <div
            v-for="item in subValue"
            :key="item.id"
            class="lp-image__sub-text"
            :style="item.style"
          >
            <span v-if="item.text">
              {{ $t(item.text) }}:
            </span>
            {{ item.value }}
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <img
        v-if="country.icon"
        class="lp-image__avatar"
        :src="country.icon"
      />
      <userIcon
        v-else
        class="lp-image__avatar"
      />
      <p>{{ $t(country.name) }}</p>
    </template>
    <teleport
      v-if="isActiveUser === row.id"
      to=".lp-table"
    >
      <div
        class="lp-image__modal"
        :style="style"
      >
        {{ $t('teachers.userActive') }}
      </div>
    </teleport>
  </div>
</template>

<script>
import { userIcon, countryIcon, userIconActive } from '@/constants/icons';
import { get } from 'lodash';
import { computed, ref } from 'vue';
import { getCountry } from '@/constants/utils/getValueById';
import moment from 'moment';

export default {
  name: 'ImageWithTextCell',
  props: {
    column: Object,
    row: Object,
    index: Number,
    heightRow: Number
  },
  setup (props) {
    const value = computed(() => get(props.row, props.column.prop, ''));
    const subValue = computed(() => {
      const isSubProps = !!props.column.subProp;
      if (!isSubProps) {
        return undefined;
      } ;
      const subPropIsArray = typeof props.column.subProp === 'object';
      const subPropsArray = subPropIsArray ? props.column.subProp : [props.column.subProp];
      const subArray = [];
      subPropsArray.forEach((item) => {
        if (typeof item === 'object') {
          const { text, prop, dateFormat, style } = item;
          const propValue = get(props.row, prop, '');
          const value = dateFormat ? moment(propValue).format(dateFormat) : dateFormat;
          subArray.push({
            id: Date.now(),
            text,
            value,
            style
          });
        } else {
          subArray.push({
            id: Date.now(),
            value: get(props.row, item, '')
          });
        }
      });
      /*const subProp = typeof props.column.subProp === 'object' ? props.column.subProp*/
      return subArray;
    });

    const countryTitle = computed(() => get(props.row, props.column.prop.country, ''));
    const placeholderCountry = computed(() => props.column.placeholder);
    const imgUrl = computed(() => get(props.row, props.column.displayImage, ''));
    const country = computed(() => getCountry(countryTitle.value));

    const isActiveType = computed(() => {
      const isActive = get(props.row, props.column.isActive, false);
      const isActiveVideoCallUser = get(props.row, props.column.isActiveVideoCallUser, false);
      const isActiveAll = isActive && isActiveVideoCallUser;
      if (isActive) {
        return isActiveAll ? 'all' : 'crm';
      }
      return isActiveVideoCallUser ? 'call' : '';
    });

    const isActiveUser = ref(-1);

    const scrollHeight = ref(0);
    const onMouseover = (id) => {
      const element = document.getElementById('table-scroll');
      scrollHeight.value = element.scrollTop;
      isActiveUser.value = id;
    };

    const style = computed(() => `top: ${(props.index + 1) * props.heightRow + 14 - scrollHeight.value}px`);

    return {
      userIcon,
      userIconActive,
      countryIcon,
      isActiveUser,
      value,
      subValue,
      isActiveType,
      imgUrl,
      country,
      placeholderCountry,
      style,
      scrollHeight,
      onMouseover
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/style.scss";

.lp-image {
  display: flex;
  align-items: center;

  &__user-active {
    margin: 0 8px 0 0;
    width: 20px;
    cursor: pointer;
    transition: 0.3s;

    &_type-crm {
      color: $color-emerald;
    }

    &_type-call {
      color: $color-accent;
    }

    &_type-all {
      color: $color-red;
    }

  }

  &__modal {
    position: absolute;
    background-color: $color-mine-shaft;
    padding: 6px 10px;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    color: $color-white;
    opacity: 0.9;
    left: -45px;

    &::after {
      content: '';
      position: absolute;
      border: 6px solid transparent;
    }

    &::after {
      border-bottom: 6px solid $color-mine-shaft;
      top: -10px;
      left: 46%;
    }
  }

  &__sub-text {
    margin-top: 3px;
    font-size: 0.6em;
    color: $color-gray;
  }

  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
  }
}

</style>
