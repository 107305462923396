import { uniqueId, cloneDeep } from 'lodash';
import { reactive } from 'vue';
import country from '@/constants/country';

const inputs = [
  {
    autofocus: true,
    required: true,
    type: 'text',
    label: 'landing.form.name',
    modelName: 'name',
    modelValue: '',
    upperFirst: true,
    emptyError: 'errors.emptyName'
  },
  {
    type: 'phone',
    label: 'landing.form.phone',
    required: true,
    modelName: 'phone',
    modelValue: {
      number: '',
      country: ''
    },
    fixed: true,
    validError: 'errors.emptyPhone',
    isValid: (val) => val && typeof val === 'object' && val.number && val.number.length === val.placeholder.length,
    getValue: (val) => {
      const countryObj = country.find((item) => item.code === val.country) || {};
      return `${countryObj.phone}${val.number}`;
    }
  },
  {
    label: 'landing.form.email',
    type: 'text',
    model: '',
    modelName: 'email',
    emptyError: 'errors.emptyLogin'
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('trial-lesson-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  return item;
}));
