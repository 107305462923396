<template>
  <div class="lp-favorites-teachers">
    <div
      class="lp-favorites-teachers-cards"
      v-if="isShow"
    >
      <ListTeachersCard
        class="lp-favorites-teachers-cards__card"
        v-for="teacher of teachersList"
        :key="teacher._id"
        :teacher="teacher"
      />
    </div>
    <TeachersListEmpty
      v-else
      :imageUrl="favorites"
      :favoritesShow="true"
      :title="$t('myTeachers.favorites.title')"
      :text="$t('myTeachers.favorites.text')"
      :description="$t('myTeachers.favorites.description')"
    />
  </div>
</template>

<script>
import TeachersListEmpty from '@/components/MyTeachers/TeachersListEmpty';
import favorites from '@/assets/images/favorites.png';
import ListTeachersCard from '@/components/ListTeachers/ListTeachersCard';

export default {
  name: 'FavoritesTeachers',
  components: { TeachersListEmpty, ListTeachersCard },
  props: {
    teachersList: Array,
    isShow: Boolean
  },
  setup () {
    return { favorites };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-favorites-teachers {
  @include global-font;
  display: grid;
  font-weight: normal;
  grid-column: 2;

  &-cards {
    padding-top: 36px;

    &__card {
      padding-bottom: 22px;
    }
  }
}

</style>
