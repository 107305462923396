import * as types from '@/constants/mutationTypes';

const state = {
  lessonsList: [],
  editForm: ''
};
const getters = {
  lessonsList: (state) => state.lessonsList,
  editForm: (state) => state.editForm
};
const actions = {
  setLessonsList ({ commit }, data) {
    commit(types.SET_LESSONS_LIST, { data });
  },
  setEditForm ({ commit }, data) {
    commit(types.SET_EDIT_FORM, { data });
  }
};
const mutations = {
  [types.SET_LESSONS_LIST] (state, { data }) {
    state.lessonsList = data;
  },
  [types.SET_EDIT_FORM] (state, { data }) {
    state.editForm = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
