import teacherImg from '@/assets/images/landing/teachers/teacher2.jpg';
import achievement1 from '@/assets/images/landing/achievements/teacher2/achievement1.jpeg';
import achievement2 from '@/assets/images/landing/achievements/teacher2/achievement2.jpeg';
import achievement3 from '@/assets/images/landing/achievements/teacher2/achievement3.jpeg';

export default {
  image: teacherImg,
  name: 'Виктория Осипова',
  about: `Я научу Вас ПЕТЬ СВОБОДНО И С УДОВОЛЬСТВИЕМ! )
          На занятиях Вы узнаете возможности своего вокального аппарата и научитесь профессионально управлять ими!!

          В моей работе ГЛАВНОЕ - Индивидуальный подход!! Методик много, Но Каждому из вас необходимо ОСОБОЕ ВНИМАНИЕ!

          МОЯ ЗАДАЧА - найти контакт с Вами, помочь сформулировать и обозначить цель и последующие шаги (пути ее достижения), увлечь Вас обучением, УЧИТЬ ВАС ПРОФЕССИОНАЛЬНО! :-)
          ВАША ЗАДАЧА - наблюдать за собой, усваивать и улавливать любые изменения в ощущениях во время того или иного упражнения, фиксировать наработки, выполнять задачи, быть мотивированным на успех!!

          Я точно уверена - ПЕТЬ МОЖЕТ КАЖДЫЙ!!
          Освоить такие основные вещи как, Певческое Правильное дыхание и Опора, Озвучить Резонаторы, Певческую позицию, Полетность голоса и т.п. МОЖЕТ КАЖДЫЙ!!!
          ВЫ ЭТО МОЖЕТЕ!
          `,
  works: [
    {
      date: '1999 - 2002 г.',
      position: 'Солистка ансамбля',
      company: 'ансамбль «Надежда России»',
    },
    {
      date: '2002 - 2004 г.',
      position: 'Солистка ансамбля',
      company: 'ансамбль «Русские тайны»',
    },
    {
      date: '2006 - 2011 г.',
      position: 'Солистка фолк-группы',
      company: 'группа «Родная сторонка»',
    },
    {
      date: '2010 - 2012 г.',
      position: 'Педагог по музыке',
      company: 'Лицей №6',
    },
    {
      date: '2011 - 2014 г.',
      position: 'Солистка хора',
      company: 'Академический Хор Радиотелевидения',
    },
  ],
  achievements: [achievement1, achievement2, achievement3],
  workText: `1999 - 2002 г. - работала солисткой в ансамбле "Надежда России" 
2002 - 2004 г. - работала солисткой в губернаторском ансамбле "Русские тайны"
2006 - 2011 г. - работала солисткой и создателем фолк - группы "Родная сторонка"
2010 - 2012 г. - Педагог по музыке в Лицее №6
2011 - 2014 г. - Солистка Академического Хора Радиотелевидения
2012 г. - Культурно-исторический центр Измайлово. Артист и солист Театра
2010 г. - Репетитор по вокалу
2020 г. - Активно работала с педагогом,певицей и композитором Руладой 
2021 г. - Начала учиться на международной платформе у Грега Энрикеса (тренер Селин Дион и Мадонны)
Опыт преподавания и частного репетиторства 10 лет! Опыт сценический с 5 лет.
`,
};
