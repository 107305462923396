<template>
  <div class="lp-multiselect">
    <CustomSelect
      :options="options"
      :error="error"
      :label="label"
      :fixed="fixed"
      :required="required"
      :keyProp="keyProp"
      v-model="selected"
      @focus="$emit('focus')"
    >
      <template #header>
        <div class="lp-multiselect-selected">
          <div
            class="lp-multiselect-selected-item"
            v-for="item of selectedValues"
            :key="item[keyProp]"
          >
            {{ item[keyProp] }}
            <crossMiniIcon
              class="lp-multiselect-selected-item__remove"
              @click.stop="removeSelectedValues(item)"
            />
          </div>
        </div>
      </template>
    </CustomSelect>
  </div>
</template>

<script>
import {
  ref, reactive, watch, onBeforeMount
} from 'vue';
import { crossMiniIcon } from '@/constants/icons';
import CustomSelect from '@/components/Main/Fields/CustomSelect';

export default {
  name: 'MultiplySelect',
  components: { CustomSelect, crossMiniIcon },
  props: {
    options: Array,
    keyProp: String,
    modelValue: Array,
    required: Boolean,
    label: String,
    fixed: Boolean,
    error: [Boolean, String]
  },
  setup (props, { emit }) {
    const selectedValues = reactive([]);
    const selected = ref({});

    const setSelectedValues = (newVal) => {
      if (!newVal[props.keyProp]) return;
      const index = selectedValues.findIndex((item) => item.id === newVal.id);
      if (index === -1) selectedValues.push(newVal);
      emit('update:modelValue', selectedValues);
    };

    const removeSelectedValues = (newVal) => {
      const index = selectedValues.findIndex((item) => item === newVal);
      if (index !== -1) selectedValues.splice(index, 1);
      selected.value = {};
    };

    onBeforeMount(() => {
      props.modelValue.forEach((item) => {
        setSelectedValues(item);
      });
    });

    watch(selected, setSelectedValues);

    return { selectedValues, selected, removeSelectedValues };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-multiselect {
  @include global-font;

  &-selected {
    display: flex;
    flex-wrap: wrap;
    margin: 4px -8px -4px 0;

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 6px 24px 6px 6px;
      background: $color-moon-raker;
      border-radius: 2px;
      margin: 0 8px 8px 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      color: $color-accent;
      z-index: 2;

      &__remove {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        border-radius: 4px;
        box-sizing: content-box;
        right: 0;
        top: auto;
        cursor: pointer;
        transition: 0.3s ease-in;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
