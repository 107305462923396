<template>
  <div class="lp-time-picker">
    <div
      class="lp-time-picker__label lp-label"
      v-if="label"
    >
      {{ $t(label) }}
    </div>
    <div
      class="lp-time-picker-fields"
      @click.stop
    >
      <label
        class="lp-input lp-time-picker-fields__label"
        :class="{'lp-input_error': error}"
        ref="leftInput"
      >
        <input
          class="lp-time-picker-fields__input"
          v-model="time[0]"
          @focus="setDisplayHours('left')"
          @change="validateInputs"
          type="text"
        />
        <span class="lp-time-picker-fields__clock">
          <clockIcon />
        </span>
      </label>
      -
      <label
        class="lp-input lp-time-picker-fields__label"
        :class="{
          'lp-input_error': error,
          'lp-time-picker-fields__label_disabled': onlyStart
        }"
        ref="rightInput"
      >
        <input
          class="lp-time-picker-fields__input"
          v-model="time[1]"
          @focus="setDisplayHours('right')"
          @change="validateInputs"
          type="text"
        />
        <span class="lp-time-picker-fields__clock">
          <clockIcon />
        </span>
      </label>
      <transition name="slide-select">
        <perfect-scrollbar
          @click.stop
          v-click-outside="setDisplayHours"
          class="lp-time-picker-fields-hours"
          :class="{'lp-time-picker-fields-hours_right': displayHours === 'right'}"
          :style="fixed ? getStylePopup() : {}"
          :options="{wheelPropagation: false}"
          v-if="displayHours"
        >
          <div
            class="lp-time-picker-fields-hours__item"
            v-for="hour of times"
            :key="hour"
            @click.stop="setTime(hour)"
          >
            {{ hour }}
          </div>
        </perfect-scrollbar>
      </transition>
    </div>
    <transition name="bounce">
      <span
        class="lp-time-picker__error lp-error"
        v-if="error && typeof error === 'string'"
      >
        <dangerIcon />
        {{ $t(error) }}
      </span>
    </transition>
  </div>
</template>

<script>
import { clockIcon, dangerIcon } from '@/constants/icons';
import {
  ref, reactive, onMounted
} from 'vue';
import { forEach, cloneDeep, fill } from 'lodash';
import moment from 'moment';

export default {
  name: 'TimePicker',
  components: { clockIcon, dangerIcon },
  props: {
    error: [Boolean, String],
    label: String,
    onlyStart: Boolean,
    modelValue: Array,
    fixed: Boolean
  },
  setup (props, { emit }) {
    const times = fill(new Array(24), '2020-01-01T00:00').map((el, i) => moment(el).add(i, 'hour').format('HH:mm'));
    const time = reactive(['00:00', '00:00']);

    const displayHours = ref('');
    const setDisplayHours = (val) => {
      emit('focus');
      displayHours.value = '';
      if (val !== 'left' && val !== 'right') return;
      setTimeout(() => {
        displayHours.value = val;
      }, 150);
    };

    const updateTime = () => {
      const sort = ((a, b) => +a.match(/\d{2}/) - +b.match(/\d{2}/));
      const sorted = cloneDeep(time).sort(sort);
      emit('update:modelValue', props.onlyStart ? time : sorted);
    };

    const validateInputs = () => {
      forEach(time, (input, key) => {
        const momentDate = moment(new Date(`2020-01-01T${input}:00`));
        const valid = momentDate.isValid();
        time[key] = momentDate.format('HH:mm');
        if (!valid) {
          const hour = input.match(/\d{2}/);
          time[key] = hour ? `${hour[0]}:00` : '00:00';
        }
      });
      updateTime();
    };

    const setTime = (value) => {
      const index = displayHours.value === 'right' ? 1 : 0;
      time[index] = value;
      updateTime();
      setDisplayHours('');
    };

    onMounted(() => {
      if (!props.modelValue) return;
      const [start, end] = props.modelValue;
      time[0] = start;
      time[1] = end;
    });

    const leftInput = ref(null);
    const rightInput = ref(null);
    const getStylePopup = () => {
      if (!leftInput.value || !rightInput.value) return {};
      const positionLeft = leftInput.value.getBoundingClientRect();
      const positionRight = rightInput.value.getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${4 + positionLeft.top + positionLeft.height}px`,
        left: displayHours.value === 'right' ? `${positionRight.left}px` : `${positionLeft.left}px`,
        width: `${positionLeft.width}px`
      };
    };

    return {
      times,
      time,
      displayHours,
      setDisplayHours,
      validateInputs,
      setTime,
      leftInput,
      rightInput,
      getStylePopup
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-time-picker {
  @include global-font;
  position: relative;
  color: $color-text;

  &__error {
    top: 65px
  }

  &__label {
    margin-bottom: 4px;
  }

  &-fields {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;

    &__label {
      display: grid;
      grid-template-columns: 1fr 16px;
      height: 42px;

      &_disabled {
        pointer-events: none;
      }
    }

    &__input {
      display: block;
      width: 100%;
      border: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      color: $color-text;
      background-color: transparent;
    }

    &__clock {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-column: 2;
    }

    &-hours {
      @include global-font;
      position: absolute;
      top: 45px;
      left: 0;
      display: grid;
      grid-gap: 8px;
      width: 100%;
      height: auto;
      max-height: 150px;
      max-width: 130px;
      padding: 4px 0;
      background: $color-white;
      box-shadow: 0 0 15px rgba($color-black, 0.05);
      border-radius: 6px;
      border: 1px solid $color-wild-sand;
      z-index: 100;
      overflow: hidden;

      &_right {
        left: auto;
        right: 0;
      }

      &__item {
        color: $color-text;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        padding: 8px 16px;
        box-sizing: border-box;
        border-radius: 3px;
        margin: 0 8px;
        background-color: $color-white;
        transition: 0.3s ease-in;
        cursor: pointer;

        &:hover {
          color: $color-accent;
          background-color: $color-moon-raker;
        }
      }
    }
  }
}

</style>
