<template>
  <Modal
    :title="successStatus || badStatus ? '' : 'signUpLesson.title'"
    :hideButtons="['remove', 'save']"
    v-model:open="openModal"
  >
    <template #default>
      <div
        class="lp-profile-teacher-modal"
        v-if="statusModal"
      >
        <div
          class="lp-profile-teacher-modal-status"
          v-if="successStatus"
        >
          <img
            class="lp-profile-teacher-modal-status__img"
            :src="successImage"
            alt="placeholder"
          />
          <h2 class="lp-profile-teacher-modal-status__title">
            {{ $t('signUpLesson.success.title') }}
          </h2>
          <h3 class="lp-profile-teacher-modal-status__subtitle">
            <div>
              {{ $t('signUpLesson.success.subtitle') }} {{ teacher.firstName }} {{ teacher.lastName }}.
            </div>
            <span v-if="!studentHasTrial">
              {{ $t('signUpLesson.success.price') }} {{ coast }}.
            </span>
          </h3>
          <div class="lp-profile-teacher-modal-status__description">
            <div>{{ descriptionSuccess }}</div>
            <div>{{ $t('signUpLesson.success.descriptionInfo') }}</div>
          </div>
        </div>
        <div
          class="lp-profile-teacher-modal-status"
          v-else-if="badStatus"
        >
          <img
            class="lp-profile-teacher-modal-status__img"
            :src="errorImage"
            alt="placeholder"
          />
          <h2 class="lp-profile-teacher-modal-status__title">
            {{ $t('signUpLesson.error.title') }}
          </h2>
          <h3 class="lp-profile-teacher-modal-status__subtitle lp-profile-teacher-modal-status__subtitle_error">
            {{ $t('signUpLesson.error.subtitle') }}
          </h3>
        </div>
        <Timetable
          v-else
          :title="$t('listTeachers.chooseLessonTime')"
          :teacherId="teacher._id"
          :selectDate="lessonDate"
          :sticky="true"
          v-model:lesson="selectedLesson"
          v-model:selected="lessonDate"
        />
      </div>
    </template>
    <template #footer>
      <div
        class="lp-profile-teacher-modal-footer"
        :class="{'lp-profile-teacher-modal-footer_status': successStatus || badStatus}"
        v-if="statusModal"
      >
        <template v-if="successStatus || badStatus">
          <button
            class="lp-profile-teacher-modal-footer__button-next lp-button"
            @click="onContinue"
          >
            {{ $t('buttons.continue') }}
          </button>
        </template>
        <template v-else>
          <div class="lp-profile-teacher-modal-footer-teacher">
            <img
              v-if="avatarTeacher"
              class="lp-profile-teacher-modal-footer-teacher__avatar"
              alt="avatar"
              :src="avatarTeacher"
            />
            <userIcon
              v-else
              class="lp-profile-teacher-modal-footer-teacher__avatar"
            />
            <div class="lp-profile-teacher-modal-footer-teacher__name">
              {{ teacher.firstName }} {{ teacher.lastName }}
            </div>
            <div class="lp-profile-teacher-modal-footer-teacher__speciality">
              {{ specialty.title }}
            </div>
          </div>
          <div
            class="lp-profile-teacher-modal-footer__date"
            v-if="lessonDate"
          >
            {{ lessonDateFormat }}
          </div>
          <div
            class="lp-profile-teacher-modal-footer__coast"
            :class="{'lp-profile-teacher-modal-footer__coast_free': studentHasTrial}"
          >
            {{ studentHasTrial ? $t('listTeachers.filters.free') : coast }}
          </div>
          <button
            class="lp-button"
            :disabled="!lessonDate|| !coast"
            @click.stop="openModalLesson"
          >
            {{ $t('buttons.signUpLesson') }}
          </button>
        </template>
      </div>
    </template>
  </Modal>
</template>

<script>
import Timetable from '@/components/Main/Timetable/Timetable';
import Modal from '@/components/Main/Modal/Modal';
import numeral from 'numeral';
import {
  computed, ref, watch
} from 'vue';
import { userIcon } from '@/constants/icons';
import { getSpecialty } from '@/constants/utils/getValueById';
import { get } from 'lodash';
import LessonsApi from '@/api/Lessons/api';
import { useStore } from 'vuex';
import errorImage from '@/assets/images/error.png';
import successImage from '@/assets/images/success.png';
import i18n from '@/i18n';
import moment from 'moment';
import { useRouter } from 'vue-router';

export default {
  name: 'ProfileTeacherModalLessons',
  components: {
    Timetable,
    Modal,
    userIcon
  },
  props: {
    statusModal: Boolean,
    successStatus: Boolean,
    badStatus: Boolean,
    openAddCard: Boolean,
    teacher: Object,
    defaultDate: String,
    listLessons: Array,
    openModalSignUpLesson: Function
  },
  emits: [
    'onSingUpLesson',
    'save',
    'update:openAddCard',
    'update:listLessons',
    'update:successStatus',
    'update:badStatus'
  ],
  setup (props, { emit }) {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const locale = get(i18n, 'global.locale.value', 'ru');
    const lessonDate = ref('');
    const lessonDateFormat = computed(() => moment(lessonDate.value).
      locale(locale).
      format('DD MMMM YYYY, HH:mm'));
    const selectedLesson = ref({});

    watch(() => props.defaultDate, () => {
      lessonDate.value = props.defaultDate;
    });

    const descriptionSuccess = computed(() => {
      const textArray = i18n.global.tm('signUpLesson.success.description');
      const startTime = selectedLesson.value.lessonStartTime;
      const date = moment(startTime).locale(locale).format('DD MMMM');
      const time = moment(startTime).locale(locale).format('HH:mm');
      return textArray.map((text) => {
        if (text === '$date') text = date;
        if (text === '$time') text = time;
        return text;
      }).join(' ');
    });

    const openModal = ref(false);
    watch(openModal, () => {
      lessonDate.value = '';
      emit('update:open', openModal.value);
    });

    const studentHasTrial = ref(false);
    const teacherHasTrial = computed(() => props.teacher.trialLessonAgreement);
    const checkTrialLesson = () => {
      studentHasTrial.value = false;
      const query = {
        limit: 1,
        teacher: props.teacher._id,
        student: activeUser.value._id
      };
      if (!query.teacher || !query.student || !teacherHasTrial.value) return;
      LessonsApi.getLessons(query).
        then(({ data }) => {
          studentHasTrial.value = !data.totalDocs;
        }).
        catch((err) => {
          console.error(err);
        });
    };

    const avatarTeacher = computed(() => get(props.teacher, 'uploadFile.url', ''));
    const coastNumber = computed(() => get(props.teacher, 'lessonCost', 0));
    const coast = computed(() => numeral(coastNumber.value).format('$0.00'));
    const specialty = computed(() => getSpecialty(props.teacher.speciality));

    const router = useRouter();
    const goRegistration = () => router.push({ name: 'NewStudent' });

    const signUpLesson = () => {
      const { _id: userId } = activeUser.value;
      if (!userId) goRegistration();
      if (!coast.value || !userId) return;

      setLoaderRun(true);
      LessonsApi.signUpLesson(props.listLessons[0]).
        then(({ data }) => {
          selectedLesson.value = data;
          setLoaderRun(false);
          emit('save', data);
        }).
        catch((err) => {
          emit('update:badStatus', true);
          emit('update:successStatus', false);
          console.error(err);
          setLoaderRun(false);
        });
    };

    // const cardList = computed(() => store.getters.cardList);
    const openModalLesson = () => {
      // if (cardList.value.length > 0) {
      const lesson = {
        lessonStartTime: moment(lessonDate.value).utc().format(),
        lessonEndTime: moment(lessonDate.value).add(1, 'hour').utc().format(),
        free: studentHasTrial.value && teacherHasTrial.value,
        teacherId: props.teacher._id
      };
      emit('on-sing-up-lesson', lesson);
      // emit('on-sing-up-lesson', listLessons.value);
      // } else {
      //   emit('update:openAddCard', true);
      //   emit('update:open', false);
      // }
    };

    const onContinue = () => {
      emit('update:open', false);
      emit('update:successStatus', false);
      emit('update:badStatus', false);
      emit('update:listLessons', []);
    };

    watch(props, () => {
      lessonDate.value = '';
      selectedLesson.value = {};
      if (props.statusModal) checkTrialLesson();
      if (props.successStatus) signUpLesson();
    });

    return {
      coast,
      openModal,
      specialty,
      lessonDate,
      lessonDateFormat,
      avatarTeacher,
      selectedLesson,
      signUpLesson,
      openModalLesson,
      errorImage,
      successImage,
      descriptionSuccess,
      studentHasTrial,
      // listLessons,
      onContinue
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-profile-teacher-modal {
  @include global-font;
  max-height: 597px;
  padding: 37px 40px 0;

  &-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 59px;
    width: 43vw;
    min-width: 424px;
    max-width: 628px;
    color: $color-text;

    &__img {
      width: 424px;
      height: 228px;
      object-fit: cover;
      margin: auto;
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
      margin: 0 auto 8px;
      text-align: center;
    }

    &__subtitle {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      text-align: center;
      margin: 0 auto 12px;

      &_error {
        max-width: 240px;
      }
    }

    &__description {
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      margin: 0 auto 12px;
      text-align: center;
    }
  }

  &-footer {
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    align-items: center;
    width: calc(100% + 40px);
    margin: -20px -20px -18px;
    padding: 20px;
    border-top: 1px solid $color-alto;
    color: $color-text;
    background-color: $color-white;
    z-index: 2;

    &_status {
      border-top: none;
    }

    &__button-next {
      grid-column: 4;
    }

    &__date {
      grid-column: 2;
      margin-right: 16px;
      padding: 8px 9px;
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      color: $color-accent;
      background-color: $color-moon-raker;
      text-transform: capitalize;
    }

    &__coast {
      grid-column: 3;
      margin-right: 28px;
      color: $color-accent;
      font-weight: 800;
      font-size: 18px;
      line-height: 125%;
      cursor: default;

      &_free {
        margin-right: 12px;
        padding: 8px 12px;
        border-radius: 3px;
        color: $color-text;
        background-color: $color-supernova;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
      }
    }

    &-teacher {
      display: grid;
      grid-template-columns: 50px 1fr;
      grid-gap: 0 20px;
      grid-template-rows: 25px 25px;

      &__avatar {
        grid-row: 1/3;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }

      &__name {
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
      }

      &__speciality {
        font-weight: 500;
        font-size: 14px;
        line-height: 125%;
        grid-row: 2;
      }
    }
  }
}

</style>
