<template>
  <div
    class="lp-classroom-placeholder"
    :class="{'lp-classroom-placeholder_expired': timeIsOver && lessonId}"
  >
    <img
      class="lp-classroom-placeholder__image"
      :src="timeIsOver && lessonId ? imageConnect : imageAwait"
      alt="placeholder"
    />
    <div class="lp-classroom-placeholder-countdown">
      <router-link
        class="lp-button lp-classroom-placeholder-countdown__button"
        :to="{name: 'ClassroomConnect', params: {lessonId}}"
      >
        <!--        v-if="timeIsOver && lessonId"-->
        {{ $t('buttons.connectLesson') }}
      </router-link>
      <Countdown
        :deadline="lessonStart"
        v-model:expired="timeIsOver"
        v-if="lessonId"
      />
      <div
        class="lp-classroom-placeholder-countdown-await"
        v-if="!timeIsOver && lessonId"
      >
        <span class="lp-classroom-placeholder-countdown-await__left">
          {{ $t('classroom.await.left') }}
        </span>
        <span class="lp-classroom-placeholder-countdown-await__user">
          {{ $t(`classroom.await.${isStudent ? 'teacher' : 'student'}`) }}
          <router-link
            class="lp-classroom-placeholder-countdown-await__name"
            :to="routeProfile"
            target="_blank"
          >
            {{ userName }}.
          </router-link>
        </span>
        <span class="lp-classroom-placeholder-countdown-await__notify">
          {{ $t('classroom.await.notify') }}
        </span>
      </div>
      <div
        class="lp-classroom-placeholder-countdown-await"
        v-else
      >
        <span class="lp-classroom-placeholder-countdown-await__left">
          {{ $t(`classroom.await.${isStudent ? 'noLessonStudent' : 'noLessonTeacher'}`) }}
        </span>
        <router-link
          class="lp-classroom-placeholder-countdown-await__name"
          :to="{name: 'Teachers'}"
          v-if="isStudent"
        >
          {{ $t('classroom.await.listTeachers') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import imageAwait from '@/assets/images/classroomPlaceholder.png';
import imageConnect from '@/assets/images/classroomConnect.png';
import moment from 'moment';
import Countdown from '@/components/Main/Countdown/Countdown';
import ROLE_TYPES from '@/constants/enums/roles';
import { get } from 'lodash';

export default {
  name: 'ClassroomPlaceholder',
  components: { Countdown },
  props: {
    lesson: Object,
    user: Object
  },
  setup (props) {
    const lessonStart = computed(() => moment(props.lesson.lessonStartTime).format());
    const timeIsOver = ref(false);
    const lessonId = computed(() => props.lesson._id);

    const isStudent = computed(() => props.user.role === ROLE_TYPES.STUDENT);
    const userName = computed(() => {
      const targetUser = isStudent.value ? 'teacher' : 'assignedStudent';
      const firstName = get(props.lesson, [targetUser, 'firstName'], '');
      const lastName = get(props.lesson, [targetUser, 'lastName'], '');
      return `${firstName} ${lastName}`;
    });

    const routeProfile = computed(() => {
      const targetUser = isStudent.value ? 'teacher' : 'assignedStudent';
      const id = get(props.lesson, [targetUser, '_id'], '');
      if (!id) return {};
      const name = isStudent.value ? 'ProfileTeacher' : 'ProfileStudent';
      return { name, params: { id } };
    });

    return {
      imageAwait,
      imageConnect,
      lessonStart,
      timeIsOver,
      userName,
      isStudent,
      routeProfile,
      lessonId
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-classroom-placeholder {
  display: grid;
  grid-gap: 28px;
  align-items: center;
  justify-content: center;
  padding: 55px 0 0;

  &_expired {
    grid-gap: 46px;
  }

  &__image {
    width: auto;
    height: 390px;
    object-fit: fill;
  }

  &-countdown {
    position: relative;
    left: 14px;
    display: inline-grid;
    justify-content: center;
    margin: auto;

    &__button {
      max-width: 282px;
      white-space: nowrap;
      margin: 0 auto 20px;
    }

    &-await {
      display: grid;
      align-items: center;
      justify-content: center;
      margin: 16px auto;
      color: $color-text;
      text-align: center;
      line-height: 125%;
      font-weight: 500;
      font-size: 14px;

      &__left {
        margin-bottom: 17px;
        font-weight: bold;
        font-size: 16px;
      }

      &__user {
        margin-bottom: 13px;
      }

      &__name {
        text-decoration: underline;
      }
    }
  }
}

</style>
