import { reactive } from 'vue';

export const numberCard = reactive([{
  label: 'paymentMethods.modal.numberCard',
  autofocus: true,
  type: 'text',
  model: '',
  card: 'number-card',
  error: '',
  modelName: 'cardNumber',
  placeholder: 'XXXX XXXX XXXX XXXX',
  maxlength: 23,
  emptyError: 'errors.emptyNumberCard',
  lengthError: 'errors.isShortNumberCard'
}]);

export const actionTerm = reactive([{
  label: 'paymentMethods.modal.actionTerm',
  autofocus: true,
  type: 'text',
  model: '',
  card: 'action-tern',
  error: '',
  modelName: 'ternAction',
  placeholder: 'paymentMethods.modal.placeholder.mmYy',
  maxlength: 5,
  emptyError: 'errors.emptyActionTerm',
  lengthError: 'errors.isShortActionTerm'
}]);

export const securityCode = reactive([{
  label: 'paymentMethods.modal.securityCode',
  autofocus: true,
  type: 'text',
  model: '',
  card: 'security-code',
  error: '',
  modelName: 'codeSecurity',
  placeholder: 'paymentMethods.modal.placeholder.securityCode',
  maxlength: 4,
  emptyError: 'errors.emptySecurityCode',
  lengthError: 'errors.isShortSecurityCode'
}]);
