<template>
  <div class="lp-my-students">
    <div class="lp-my-students__title">
      {{ $t('myStudents.title') }}
    </div>
    <Students
      v-if="isShow"
      :students="students"
    />
    <StudentsListEmpty v-else />
  </div>
</template>

<script>
import {
  ref,
  onBeforeMount,
  computed,
  watch
} from 'vue';
import StudentsListEmpty from '@/components/MyStudents/StudentsListEmpty/StudentsListEmpty';
import Students from '@/components/MyStudents/Students/Students';
import { useStore } from 'vuex';
import UsersApi from '@/api/Users/api';

export default {
  name: 'MyStudents',
  components: {
    StudentsListEmpty,
    Students
  },
  setup () {
    const store = useStore();
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);
    const isShow = ref(true);
    const activeUser = computed(() => store.getters.activeUser);

    const students = ref([]);
    const getStudents = async () => {
      const { _id: userId } = activeUser.value;
      if (userId === undefined) return;
      await setLoaderRun(true);
      try {
        const { data: { resource } } = await UsersApi.getStudentsByTeacher(userId);
        students.value = resource;
        isShow.value = resource.length > 0;
        await setLoaderRun(false);
      } catch (err) {
        await setLoaderRun(false);
        console.error(err);
      }
    };

    watch(activeUser, getStudents);
    onBeforeMount(getStudents);

    return {
      students,
      isShow
    };
  }
};

</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-my-students {
  @include global-font;
  color: $color-black;
  height: 100%;

  &__title {
    font-size: 22px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 18px;
  }
}

</style>
