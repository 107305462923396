<template>
  <div class="lp-table-column-item">
    {{ text }}
  </div>
</template>

<script>
import { computed } from 'vue';
import { get } from 'lodash';
import { userIcon } from '@/constants/icons';

export default {
  name: 'TextCell',
  props: {
    column: Object,
    row: Object
  },
  setup (props) {
    const text = computed(() => get(props.row, props.column.prop, ''));
    return {
      userIcon,
      text
    };
  }
};
</script>

<style scoped>

</style>
