<template>
  <section class="lp-landing-plus">
    <h2 class="lp-landing-plus__title">
      Что вы получите, записавшись на онлайн уроки вокала в школе MUSIO?
    </h2>
    <div class="lp-landing-plus-list">
      <div
        class="lp-landing-plus-list-item"
        v-for="item of plus"
        :key="item.text"
      >
        <div class="lp-landing-plus-list-item__icon">
          <component :is="item.icon" />
        </div>
        <div class="lp-landing-plus-list-item__text">
          {{ item.text }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { shallowRef } from 'vue';
import {
  checkIcon, smileIcon, microphoneIcon, earthIcon, starIcon, peopleIcon
} from '@/constants/icons/landing';

export default {
  name: 'Plus',
  setup () {
    const plus = [
      {
        icon: shallowRef(checkIcon).value,
        text: 'Реализацию мечты'
      },
      {
        icon: shallowRef(smileIcon).value,
        text: 'Отличное настроение и позитивные эмоции'
      },
      {
        icon: shallowRef(microphoneIcon).value,
        text: 'Возможность обрести новый навык и раскрыть талант'
      },
      {
        icon: shallowRef(earthIcon).value,
        text: 'Шанс обучаться пению из любой точки мира без географической привязки к месту проживания'
      },
      {
        icon: shallowRef(starIcon).value,
        text: 'Повышение самооценки и уверенности в себе'
      },
      {
        icon: shallowRef(peopleIcon).value,
        text: 'Общение с интересными творческими личностями'
      }
    ];

    return { plus };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-landing-plus {
  @include global-font;
  margin-bottom: 182px;

  @media (max-width: 767px) {
    margin-bottom: 60px;
  }

  &__title {
    max-width: 810px;
    margin: 0 auto 40px;
    font-weight: bold;
    font-size: 36px;
    line-height: 125%;
    text-align: center;
    color: $color-text;

    @media (max-width: 767px) {
      font-size: 20px;
      max-width: 100vw;
      margin: 0 33px 40px;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 386px);
    justify-content: center;
    align-items: start;
    grid-gap: 40px;
    max-width: 1240px;
    margin: 0 auto;

    @media (max-width: 1240px) {
      grid-template-columns: repeat(auto-fit, 226px);
      grid-gap: 20px;
      max-width: 100%;
    }

    @media (max-width: 767px) {
      max-width: 100vw;
      grid-template-columns: repeat(auto-fit, 170px);
      grid-gap: 0 20px;
      padding: 10px;
    }

    &-item {
      display: grid;
      grid-gap: 20px;
      align-items: start;
      justify-content: center;
      padding-top: 40px;

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 100px;
        height: 100px;
        border: 1px solid $color-white;
        background-color: $color-white;
        box-sizing: border-box;
        box-shadow: 0 0 17px rgba($color-black, 0.08);
        border-radius: 50%;

        @media (max-width: 767px) {
          padding: 19px;
          width: 60px;
          height: 60px;
        }
      }

      &__text {
        max-width: 306px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        color: $color-text;

        @media (max-width: 767px) {
          font-size: 14px;
          max-width: 180px;
        }
      }
    }
  }
}

</style>
