<template>
  <div class="lp-profile-info">
    <section class="lp-profile-info-experience">
      <div class="lp-profile-info-title">
        <h3 class="lp-section-title">
          {{ $t('profile.sections.experience') }}
        </h3>
        <div class="lp-plus-button">
          <plusIcon @click.stop="$emit('update:openWork', true)" />
        </div>
      </div>
      <div class="lp-profile-info-list">
        <div
          class="lp-profile-info-list-item"
          v-for="(work, index) of user.work"
          :key="`${work.organization}-${index}`"
        >
          <div class="lp-profile-info-list-item__dates">
            {{ new Date(work.fromDate).getFullYear() }}
            -
            {{ work.toDate ? new Date(work.toDate).getFullYear() : $t('global.presentTimeShort') }}
          </div>
          <div class="lp-profile-info-list-item__position">
            {{ work.position }}
          </div>
          <div class="lp-profile-info-list-item__company">
            {{ work.organization }}
          </div>
          <div class="lp-profile-header__edit lp-edit-button lp-profile-info-list-item__edit">
            <editIcon @click.stop="$emit('update:openWork', index + 1)" />
          </div>
        </div>
      </div>
    </section>
    <section class="lp-profile-info-education">
      <div class="lp-profile-info-title">
        <h3 class="lp-section-title">
          {{ $t('profile.sections.education') }}
        </h3>
        <div class="lp-plus-button">
          <plusIcon @click.stop="$emit('update:openEducation', true)" />
        </div>
      </div>
      <div class="lp-profile-info-list">
        <div
          class="lp-profile-info-list-item"
          v-for="(education, index) of user.education"
          :key="`${education.organization}-${index}`"
        >
          <div class="lp-profile-info-list-item__dates">
            {{ new Date(education.fromDate).getFullYear() }}
            -
            {{ education.toDate ? new Date(education.toDate).getFullYear() : $t('global.presentTimeShort') }}
          </div>
          <div class="lp-profile-info-list-item__position">
            {{ education.specialization }}
          </div>
          <div class="lp-profile-info-list-item__company">
            {{ education.educationInst }}
          </div>
          <div class="lp-profile-header__edit lp-edit-button lp-profile-info-list-item__edit">
            <editIcon @click.stop="$emit('update:openEducation', index + 1)" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { plusIcon, editIcon } from '@/constants/icons';

export default {
  name: 'ProfileInfo',
  components: {
    editIcon,
    plusIcon
  },
  props: {
    user: Object
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-profile-info {
  @include global-font;
  position: relative;
  background-color: $color-white;
  box-shadow: 0 0 15px rgba($color-black, 0.02);
  padding: 40px 23px 0 32px;

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-list {
    display: grid;

    &-item {
      display: grid;
      padding-top: 30px;
      grid-gap: 4px 20px;
      grid-template-columns: minmax(90px, 272px) 1fr;
      grid-template-rows: 1fr 1fr;
      color: $color-text;

      &__dates {
        grid-column: 1;
        grid-row: 1;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
      }

      &__position {
        grid-column: 2;
        grid-row: 1;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
      }

      &__company {
        grid-column: 2;
        grid-row: 2;
        font-weight: bold;
        font-size: 14px;
        line-height: 125%;
      }

      &__edit {
        position: absolute;
        right: 0;
        transition: 0.3s ease-out;
      }
    }
  }

  &-experience {
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 33px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -32px;
      width: calc(100% + 55px);
      height: 1px;
      background-color: $color-alto;;
    }
  }

  &-education {
    position: relative;
    padding-bottom: 40px;
  }
}

</style>
