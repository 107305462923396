<template>
  <div class="lp-timetable-week lp-input">
    <div
      class="lp-timetable-week__arrow"
      @click="changeWeek(false)"
    >
      <arrowCalendarIcon />
    </div>
    <div class="lp-timetable-week__value">
      {{ formatDate }}
    </div>
    <div
      class="lp-timetable-week__arrow lp-timetable-week__arrow_next"
      @click="changeWeek(true)"
    >
      <arrowCalendarIcon />
    </div>
  </div>
</template>

<script>
import { arrowCalendarIcon } from '@/constants/icons';
import {
  computed, ref, watch, onBeforeMount
} from 'vue';
import moment from 'moment';
import { get } from 'lodash';
import i18n from '@/i18n';

export default {
  name: 'TimetableSelectWeek',
  components: { arrowCalendarIcon },
  props: {
    date: String,
    format: {
      type: Array,
      default: () => ['MMM D', 'MMM D, YYYY']
    }
  },
  setup (props, { emit }) {
    const currentWeek = ref([]);

    const setCurrentWeek = (date) => {
      const startWeek = moment(date);
      const endWeek = moment(date).add(6, 'day');
      emit('update:start', startWeek.format('YYYY-MM-DD'));
      currentWeek.value = [startWeek, endWeek];
    };

    watch(props, () => setCurrentWeek(props.date));
    onBeforeMount(() => setCurrentWeek(props.date));

    const formatDate = computed(() => {
      const locale = get(i18n, 'global.locale.value', 'ru');
      const [startWeek, endWeek] = currentWeek.value;
      return `${startWeek.locale(locale).format(props.format[0])} - ${endWeek.locale(locale).format(props.format[1])}`;
    });

    const changeWeek = (val) => {
      const momentDate = moment(props.date);
      const newDate = val ? momentDate.add(1, 'week') : momentDate.subtract(1, 'week');
      emit('update:date', newDate.format('YYYY-MM-DD'));
    };

    return { formatDate, changeWeek };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-timetable-week {
  @include global-font;
  display: grid;
  grid-template-columns: 16px auto 16px;
  grid-gap: 9px;
  align-items: center;
  min-width: 255px;
  height: 100%;
  padding: 3px 11px !important;
  user-select: none;

  &__value {
    text-align: center;
    color: $color-text;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    text-transform: capitalize;
    cursor: default;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    transform: scale(0.75);
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      svg {
        fill: $color-accent-hover;
      }
    }

   svg {
      fill: $color-accent;
    }

    &_next {
      transform: scaleX(-1) scale(0.75);
    }
  }
}

</style>
