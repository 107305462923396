<template>
  <div class="lp-calendar-controls">
    <transition
      name="slideY"
      mode="out-in"
    >
      <div
        class="lp-calendar-controls-today"
        :class="{'lp-calendar-controls-today_edit': editMode}"
        :key="editMode"
      >
        <div
          class="lp-calendar-controls-today__arrow"
          v-if="!editMode"
          @mouseenter="openPrevText = true"
          @mouseleave="openPrevText = false"
          @click="changeDate(-1)"
        >
          <arrowCalendarIcon />
          <Tooltip
            class="lp-calendar-controls-today__tooltip"
            :text="prevText"
            :open="openPrevText"
          />
        </div>
        <div
          class="lp-calendar-controls-today__date"
          :class="{'lp-calendar-controls-today__date_disabled': isToday}"
          @click="changeDate(0)"
        >
          {{ $t('global.today') }}
        </div>
        <div
          class="lp-calendar-controls-today__arrow lp-calendar-controls-today__arrow_next"
          v-if="!editMode"
          @mouseenter="openNextText = true"
          @mouseleave="openNextText = false"
          @click="changeDate(+1)"
        >
          <arrowCalendarIcon />
          <Tooltip
            class="lp-calendar-controls-today__tooltip"
            :text="nextText"
            :open="openNextText"
          />
        </div>
      </div>
    </transition>
    <transition
      name="slideX"
      mode="out-in"
    >
      <div
        class="lp-calendar-controls-tabs"
        v-if="!editMode"
      >
        <button
          class="lp-calendar-controls-tabs__item"
          :class="{'lp-calendar-controls-tabs__item_active': activeTab === tab.id}"
          @click="setActiveTab(tab.id)"
          v-for="tab of tabs"
          :key="tab.id"
        >
          {{ tab.title }}
        </button>
      </div>
      <div
        class="lp-calendar-controls-picker"
        v-else
      >
        <TimetableSelectWeek
          :date="chooseDate[0]"
          v-model:date="chooseDate[0]"
          v-model:start="chooseDate[0]"
        />
        <DatePicker
          v-bind="calendarSettings"
          :modelValue="{date: [date], time: []}"
          v-model="calendarSettings.modelValue"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import { arrowCalendarIcon } from '@/constants/icons';
import {
  computed, ref, onBeforeMount, watch, reactive
} from 'vue';
import { capitalize } from 'lodash';
import moment from 'moment';
import i18n from '@/i18n';
import TimetableSelectWeek from '@/components/Main/Timetable/TimetableSelectWeek';
import DatePicker from '@/components/Main/DatePicker/DatePicker';

export default {
  name: 'CalendarControls',
  components: {
    DatePicker,
    TimetableSelectWeek,
    arrowCalendarIcon,
    Tooltip
  },
  props: {
    date: String,
    editMode: Boolean,
    currentTab: String
  },
  setup (props, { emit }) {
    const calendarSettings = reactive({
      hideInput: true,
      hideReset: true,
      hideTime: true,
      minDate: new Date(),
      modelValue: {
        date: [moment().format('YYYY-MM-DD')]
      }
    });

    const activeTab = ref('month');
    const setActiveTab = (id) => {
      activeTab.value = id;
      emit('update:tab', id);
    };
    const setDefaultActiveTab = () => {
      activeTab.value = props.currentTab;
    };
    watch(props, setDefaultActiveTab);
    onBeforeMount(setDefaultActiveTab);
    const tabs = [
      { id: 'day', title: i18n.global.t('global.day') },
      { id: 'week', title: i18n.global.t('global.week') },
      { id: 'month', title: i18n.global.t('global.month') }
    ];
    const chooseDate = computed(() => calendarSettings.modelValue.date);
    watch(calendarSettings, () => {
      const [newDate] = chooseDate.value;
      emit('update:date', moment(newDate).format('YYYY-MM-DD'));
    });
    const changeDate = (val) => {
      const timeUnit = activeTab.value || 'month';
      let newDate = moment();
      if (val < 0) newDate = moment(props.date).subtract(1, timeUnit);
      if (val > 0) newDate = moment(props.date).add(1, timeUnit);
      emit('update:date', newDate.format('YYYY-MM-DD'));
    };

    const openPrevText = ref(false);
    const prevText = computed(() => {
      const prev = i18n.global.tm('global.prev')[activeTab.value === 'week' ? 1 : 0];
      const unit = tabs.find((item) => item.id === activeTab.value);
      return `${capitalize(prev)} ${unit.title.toLowerCase()}`;
    });

    const openNextText = ref(false);
    const nextText = computed(() => {
      const next = i18n.global.tm('global.next')[activeTab.value === 'week' ? 1 : 0];
      const unit = tabs.find((item) => item.id === activeTab.value);
      return `${capitalize(next)} ${unit.title.toLowerCase()}`;
    });

    const isToday = computed(() => {
      const today = moment().format('YYYY-MM-DD');
      const currentDay = moment(props.date).format('YYYY-MM-DD');
      return today === currentDay;
    });

    return {
      tabs,
      activeTab,
      setActiveTab,
      isToday,
      changeDate,
      openPrevText,
      prevText,
      openNextText,
      nextText,
      calendarSettings,
      chooseDate
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-calendar-controls {
  @include global-font;
  display: grid;
  grid-template-columns: auto minmax(220px, 1fr);
  grid-gap: 12px;
  align-items: center;
  transition: 0.3s ease-out;

  &-today {
    display: flex;
    border-radius: 4px;
    border: 1px solid $color-gallery;
    align-items: center;
    user-select: none;
    min-width: 125px;

    &_edit {
      padding: 0 8px;
      min-width: auto;
    }

    &__date {
      padding: 8px 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: $color-text;
      transition: 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        color: $color-accent;
      }

      &_disabled {
        opacity: 0.4;
        cursor: not-allowed;

        &:hover {
          color: $color-text;
        }
      }
    }

    &__arrow {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      width: 12px;
      padding: 8px 4px 8px 12px;
      box-sizing: content-box;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      &:hover {
        svg {
          fill: $color-accent-hover;
        }
      }

      svg {
        fill: $color-accent;
      }

      &_next {
        padding: 8px 12px 8px 4px;

        svg {
          transform: scaleX(-1);
        }
      }
    }

    &__tooltip {
      top: 38px;
      white-space: nowrap;
    }
  }

  &-tabs {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
      @include global-font;
      position: relative;
      padding: 7px 12px;
      border: 1px solid $color-alto;
      background-color: transparent;
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      margin-right: -1px;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      z-index: 1;
      user-select: none;

      &_active,
      &:hover {
        border-color: $color-accent;
        color: $color-accent;
        z-index: 2;
      }

      &_active {
        background-color: $color-moon-raker;
        pointer-events: none;
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  &-picker {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: auto 36px;
    max-height: 36px;
  }
}

</style>
