export default [
  {
    id: 1,
    title: '06:00-12:00',
    min: 6,
    max: 12
  },
  {
    id: 2,
    title: '12:00-18:00',
    min: 12,
    max: 18
  },
  {
    id: 3,
    title: '18:00-00:00',
    min: 18,
    max: 24
  },
  {
    id: 4,
    title: '00:00-06:00',
    min: 0,
    max: 6
  }
];
