<template>
  <div class="lp-profile-teacher-base">
    <UserAvatar
      class="lp-profile-teacher-base__avatar"
      :avatar="avatar"
      :country="country"
    />
    <div class="lp-profile-teacher-base__favorite">
      <heartIcon />
    </div>
    <div class="lp-profile-teacher-base-info">
      <div class="lp-profile-teacher-base-info__name">
        {{ teacher.firstName }} {{ teacher.lastName }}
      </div>
      <div class="lp-profile-teacher-base-info__speciality">
        {{ speciality.title }}
      </div>
      <div class="lp-profile-teacher-base-info-row">
        <div class="lp-profile-teacher-base-info__location">
          <locationIcon />
          <span>
            {{ country.name }}
            {{ teacher.city ? ', ' + teacher.city : '' }}
          </span>
        </div>
        <div class="lp-profile-teacher-base-info__rate">
          <starIcon />
          <span>
            {{ rate }}
          </span>
        </div>
      </div>
      <div class="lp-profile-teacher-base-info-stats">
        <div class="lp-profile-teacher-base-info-stats__item">
          <tabletDocIcon /> {{ lessons }}
        </div>
        <div class="lp-profile-teacher-base-info-stats__item">
          <shieldIcon /> {{ years }}
        </div>
        <div class="lp-profile-teacher-base-info-stats__item">
          <chatIcon /> {{ comments }}
        </div>
      </div>
    </div>
    <div class="lp-profile-teacher-base__subjects">
      <h3 class="lp-section-title">
        {{ $t('profile.sections.subjects') }}
      </h3>
      <UserSubjects :subjects="subjects" />
    </div>
    <div
      class="lp-profile-teacher-base__about"
      v-if="teacher.about"
    >
      <h3 class="lp-section-title">
        {{ $t('profile.sections.about') }}
      </h3>
      {{ teacher.about }}
    </div>
    <div
      class="lp-profile-teacher-base-presentation"
      v-if="videoUrl"
    >
      <iframe
        class="lp-profile-teacher-base-presentation__video"
        frameborder="0"
        allowfullscreen
        :src="videoUrl"
      />
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/Main/UserElements/UserAvatar';
import { getCountry, getSpecialty, getSubjects } from '@/constants/utils/getValueById';
import { computed } from 'vue';
import {
  locationIcon,
  starIcon,
  tabletDocIcon,
  shieldIcon,
  chatIcon,
  heartIcon
} from '@/constants/icons';
import numeral from 'numeral';
import declensionOfNum from '@/constants/utils/declensionOfNum';
import i18n from '@/i18n';
import UserSubjects from '@/components/Main/UserElements/UserSubjects';
import { get } from 'lodash';
import urlParser from 'js-video-url-parser';
import VIDEO_PROVIDER from '@/constants/enums/videoProvider';

export default {
  name: 'ProfileTeacherBase',
  components: {
    UserSubjects,
    UserAvatar,
    locationIcon,
    starIcon,
    tabletDocIcon,
    shieldIcon,
    chatIcon,
    heartIcon
  },
  props: {
    teacher: Object
  },
  setup (props) {
    const country = computed(() => getCountry(props.teacher.country));
    const speciality = computed(() => getSpecialty(props.teacher.speciality));
    const subjects = computed(() => getSubjects(props.teacher.subjects || []));
    const avatar = computed(() => {
      const isNotObject = typeof props.teacher.uploadFile !== 'object';
      const isNull = props.teacher.uploadFile === null;
      return isNotObject || isNull ? {} : props.teacher.uploadFile;
    });
    const rate = computed(() => numeral(props.teacher.rate).format('0.0'));

    const lessonsCount = computed(() => props.teacher.lessons || 0);
    const lessonsText = declensionOfNum(lessonsCount.value, i18n.global.tm('global.declensionLessons'));
    const lessons = computed(() => `${lessonsCount.value} ${lessonsText}`);

    const yearsCount = computed(() => props.teacher.workExperience || 0);
    const yearsText = declensionOfNum(yearsCount.value, i18n.global.tm('global.declensionYears'));
    const years = computed(() => `${yearsCount.value} ${yearsText}`);

    const commentsCount = computed(() => props.teacher.comments || 0);
    const commentsText = declensionOfNum(commentsCount.value, i18n.global.tm('global.declensionComment'));
    const comments = computed(() => `${commentsCount.value} ${commentsText}`);

    const videoUrl = computed(() => {
      const url = get(props.teacher, 'presentationUrl', '');
      const videoInfo = urlParser.parse(url) || {};
      if (videoInfo.provider === VIDEO_PROVIDER.YOUTUBE) return `https://www.youtube.com/embed/${videoInfo.id}`;
      if (videoInfo.provider === VIDEO_PROVIDER.VIMEO) return `https://player.vimeo.com/video/${videoInfo.id}`;
      return '';
    });

    return {
      avatar,
      country,
      speciality,
      subjects,
      rate,
      lessons,
      years,
      comments,
      videoUrl
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-profile-teacher-base {
  @include global-font;
  position: relative;
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-gap: 32px 46px;
  padding: 30px 40px 54px;
  background-color: $color-white;
  box-shadow: 0 0 15px rgba($color-black, 0.02);

  &__favorite {
    position: absolute;
    top: 14px;
    right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    box-sizing: content-box;
    cursor: pointer;

    svg {
      fill: $color-alto;
      transition: 0.4s ease-in;
    }

    &:hover {
      svg {
        fill: rgba($color-silver-chalice, 0.7);
      }
    }
  }

  &-info {
    color: $color-text;

    &__name {
      font-weight: bold;
      font-size: 25px;
      line-height: 125%;
      margin-bottom: 4px;
    }

    &__speciality {
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      margin-bottom: 8px;
    }

    &-row {
      display: flex;
      align-items: center;
      margin: 0 0 26px;
    }

    &__location {
      display: grid;
      grid-template-columns: 16px auto;
      grid-gap: 6px;
      align-items: center;
      color: $color-text;
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
    }

    &__rate {
      display: grid;
      grid-template-columns: 16px auto;
      grid-gap: 8px;
      margin-left: 18px;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      color: $color-text;
    }

    &-stats {
      display: inline-grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: 60px;
      align-items: center;
      margin-bottom: 25px;
      text-transform: lowercase;
      font-size: 12px;
      line-height: 125%;
      color: rgba($color-black, 0.87);

      &__item {
        display: grid;
        grid-template-columns: 16px auto;
        grid-gap: 7px;
        align-items: center;
      }
    }
  }

  &__about,
  &__subjects {
    display: grid;
    grid-gap: 12px;
    grid-column: 1/3;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
  }

  &-presentation {
    grid-column: 1/3;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;

    &__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

</style>
