import { reactive } from 'vue';
import { isEmail } from '@/constants/utils/validationRegex';

export default reactive([
  {
    label: 'registration.inputs.email',
    type: 'text',
    model: '',
    modelName: 'email',
    emptyError: 'errors.emptyEmail',
    isValid: isEmail,
    autofocus: true
  }
]);
