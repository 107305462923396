<template>
  <Modal
    :title="title"
    @save="saveForm"
    @remove="closeForm"
    :saveBtnText="editLesson ? 'buttons.save' : 'buttons.add'"
    removeBtnText="buttons.cancel"
    v-model:open="openModal"
  >
    <Form
      class="lp-lesson-form"
      @submit="saveForm"
      :inputs="editLesson ? lessonEditInputs : lessonAddInputs"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import Form from '@/components/Main/Form/Form';
import lessonAddInputs from '@/constants/inputs/LessonsInputs/lessonAddInputs';
import lessonEditInputs from '@/constants/inputs/LessonsInputs/lessonEditInputs';
import { computed, ref, watch } from 'vue';
import { fill, flattenDeep } from 'lodash';
import validateLessons from '@/constants/validatesForm/validateLessons';
import moment from 'moment';
import LESSON_STATUS from '@/constants/enums/lessonStatus';
import { useStore } from 'vuex';
import LessonsApi from '@/api/Lessons/api';
import { getSubjects } from '@/constants/utils/getValueById';

export default {
  name: 'LessonForm',
  components: { Modal, Form },
  props: {
    open: [Number, Boolean],
    editLesson: String,
    lessons: Array,
    subjects: Array
  },
  setup (props, { emit }) {
    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);
    const setEditLesson = (data) => store.dispatch('setEditForm', data);
    const setLessons = (data) => store.dispatch('setLessonsList', data);

    const title = computed(() => `userTimetable.lessonForm.${props.editLesson ? 'titleEdit' : 'title'}`);

    const formatTimes = (times) => times.map((item) => moment(item).format('HH:mm'));

    const setDefaultInputs = () => {
      const editLesson = props.lessons.find((item) => item._id === props.editLesson);
      if (!editLesson) return;
      const subjects = getSubjects([editLesson.subject])[0];
      const { lessonStartTime, lessonEndTime } = editLesson;
      const times = formatTimes([lessonStartTime, lessonEndTime]);
      lessonEditInputs.forEach((input) => {
        if (input.modelName === 'subject') input.modelValue = subjects;
        if (input.modelName === 'date') input.modelValue.date = [editLesson.date];
        if (input.modelName === 'time') input.modelValue = times;
      });
    };

    const checkTimes = () => {
      const time = lessonEditInputs.find((item) => item.modelName === 'time') || {};
      const [start] = time.modelValue;
      time.modelValue[1] = moment(`2020-01-01T${start}`).add(1, 'hour').format('HH:mm');
    };

    watch(lessonEditInputs, checkTimes);

    const openModal = ref(false);
    watch(openModal, () => {
      emit('update:open', openModal.value);
      if (!openModal.value) setEditLesson('');
    });
    watch(props, () => {
      if (props.editLesson) setDefaultInputs();
      const subjectsList = lessonAddInputs.find((item) => item.modelName === 'subject') || {};
      subjectsList.options = props.subjects || [];
      openModal.value = props.open;
    });
    const closeForm = () => {
      openModal.value = false;
    };

    const getFullDates = (date, weekDays) => {
      const dateDiff = moment(date[1]).diff(moment(date[0]), 'days') + 1;
      const dates = fill(new Array(dateDiff), date[0]).map((date, i) => moment(date).add(i, 'day'));
      const datesWeek = dates.filter((date) => weekDays.includes(date.isoWeekday() - 1));
      return datesWeek.map((item) => item.format('YYYY-MM-DD'));
    };

    const getHoursRange = (times) => {
      const [start, end] = times;
      const startHour = start.match(/\d{2}/) || 0;
      const endHour = end.match(/\d{2}/) || 0;
      const countHour = +endHour - +startHour;
      return fill(new Array(countHour), start).map((time, i) => {
        const hour = +(time.match(/\d{2}/) || 0) + i;
        const startHour = String(hour).length === 1 ? `0${hour}` : hour;
        const endHour = String(hour + 1).length === 1 ? `0${hour + 1}` : hour + 1;
        return [`${startHour}:00`, `${endHour}:00`];
      });
    };

    const createLessons = async () => {
      const body = validateLessons(lessonAddInputs);
      if (!body) return;
      const { date } = body.date;
      const { time, week, subject } = body;
      const hoursRange = getHoursRange(time);
      const datesWeek = getFullDates(date, week);
      if (!datesWeek.length) {
        (lessonAddInputs.find((input) => input.modelName === 'week') || {}).error = 'errors.lessonRange';
        return;
      }
      const lessons = flattenDeep(datesWeek.map((date) => hoursRange.map((time) => ({
        lessonDate: moment(date).format(),
        lessonStartTime: moment(`${date}T${time[0]}`).format(),
        lessonEndTime: moment(`${date}T${time[1]}`).format(),
        subject,
        lessonStatus: LESSON_STATUS.PENDING,
        teacher: activeUser.value._id
      }))));
      await setLoaderRun(true);
      const promisesLessons = lessons.map(async (lesson) => {
        const { data } = await LessonsApi.createLesson(lesson);
        return data;
      });
      try {
        const result = await Promise.all(promisesLessons);
        setLessons(props.lessons.concat(result));
        await setLoaderRun(false);
        closeForm();
      } catch (err) {
        console.error(err);
        await setLoaderRun(false);
        closeForm();
      }
    };

    const updateLesson = () => {
      const body = validateLessons(lessonEditInputs);
      if (!body) return;
      const date = body.date.date[0];
      const { time, subject } = body;
      const lesson = {
        _id: props.editLesson,
        lessonDate: moment(date).format(),
        lessonStartTime: moment(`${date}T${time[0]}`).format(),
        lessonEndTime: moment(`${date}T${time[1]}`).format(),
        subject,
        lessonStatus: LESSON_STATUS.PENDING
      };
      setLoaderRun(true);
      LessonsApi.updateLesson(lesson).
        then(({ data }) => {
          const updatedList = props.lessons.map((lesson) => (lesson._id === data._id ? data : lesson));
          setLessons(updatedList);
          setLoaderRun(false);
          closeForm();
        }).
        catch((err) => {
          console.error(err);
          setLoaderRun(false);
        });
    };

    const saveForm = () => {
      if (props.editLesson) {
        updateLesson();
      } else {
        createLessons();
      }
    };

    return {
      title,
      lessonAddInputs,
      lessonEditInputs,
      openModal,
      saveForm,
      closeForm
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-lesson-form {
  @include global-font;
  display: grid;
  width: 482px;
}

</style>
