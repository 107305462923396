<template>
  <div
    v-if="currentDate !== 'Invalid date'"
    class="lp-date"
  >
    {{ currentDate }}
    <div
      v-if="column.showTime"
      class="lp-date__time"
    >
      {{ currentTime }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { get } from 'lodash';
import moment from 'moment';
import i18n from '@/i18n';

export default {
  name: 'DateCell',
  props: {
    column: Object,
    row: Object
  },
  setup (props) {
    const locale = get(i18n, 'global.locale.value', 'ru');
    const date = computed(() => get(props.row, props.column.prop, ''));
    const currentDate = computed(() => moment(date.value).locale(locale).format(props.column.formatDate));
    const currentTime = computed(() => moment(date.value).locale(locale).format(props.column.formatTime));

    return {
      currentDate,
      currentTime
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/style.scss";

.lp-date {
  white-space: nowrap;

    &__time {
      color: $color-gray;
    }
}

</style>
