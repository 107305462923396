<template>
  <div class="lp-login">
    <LoginHeader :isRegistration="isRegistration" />
    <LoginForm
      v-if="!isRegistration"
      @submit="login"
    />
    <RegistrationForm
      v-else-if="isRegistration"
      v-model:languageLetters="languageLetters"
      @submit="register"
    />
    <LoginSettings
      v-if="registrationStudent"
      :isRegistration="isRegistration"
      v-model:remember="remember"
      v-model:license="acceptLicense"
    />
    <button
      class="lp-button lp-login__enter"
      :disabled="isRegistration && registrationStudent ? !acceptLicense : false"
      @click="isRegistration ? register() : login()"
    >
      {{ $t(submitText) }}
    </button>
    <LoginFooter
      class="lp-login__footer"
      v-if="isRegistration"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import LoginHeader from '@/components/Login/LoginHeader';
import LoginForm from '@/components/Login/LoginForm';
import LoginSettings from '@/components/Login/LoginSettings';
import RegistrationForm from '@/components/Login/RegistrationForm';
import AuthApi from '@/api/Auth/api';
import loginInputs from '@/constants/inputs/loginInputs';
import LoginFooter from '@/components/Login/LoginFooter';
import registrationInputs from '@/constants/inputs/registrationInputs';
import validateLogin from '@/constants/validatesForm/validateLogin';
import { VueCookieNext } from 'vue-cookie-next';
import validateRegistration from '@/constants/validatesForm/validateRegistration';
import generator from 'generate-password';
import UsersApi from '@/api/Users/api';
import setErrorText from '@/constants/utils/setErrorText';
import { get } from 'lodash';

export default {
  name: 'Login',
  components: {
    RegistrationForm,
    LoginFooter,
    LoginSettings,
    LoginForm,
    LoginHeader
  },
  setup () {
    const store = useStore();
    const router = useRouter();
    const registrationStudent = computed(() => router.currentRoute.value.name === 'NewStudent');
    const registrationTeacher = computed(() => router.currentRoute.value.name === 'NewTeacher');
    const isRegistration = computed(() => registrationTeacher.value || registrationStudent.value);
    const submitText = computed(() => (isRegistration.value ? 'registration.buttons.login' : 'login.buttons.login'));

    const setActiveUser = (data) => store.dispatch('setActiveUser', data);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const remember = ref(true);
    const acceptLicense = ref(false);
    const languageLetters = ref(false);
    const selectedRole = computed(() => get(router, 'currentRoute.value.meta.role', ''));

    const goToProfile = (data) => {
      const type = (data.role || '').toLowerCase();
      router.push({ name: 'Profile', params: { type } });
    };

    const goToAdmin = () => {
      router.push({ name: 'Mainvoice' });
    };

    const login = async () => {
      const validateBody = validateLogin(loginInputs);
      if (!validateBody) return;
      try {
        await setLoaderRun(true);
        const minimal = await AuthApi.login(validateBody);
        const { data: payload } = minimal;
        const { role } = payload;
        VueCookieNext.setCookie('remember', remember.value);
        VueCookieNext.setCookie('token', minimal.data.token.accessToken);
        if (role === 'Admin' || role === 'Super_Admin') {
          goToAdmin();
        } else {
          const { data } = await UsersApi.getFullUser(minimal.data);
          await setActiveUser(data);
          goToProfile(data);
        }
        await setLoaderRun(false);
      } catch (err) {
        console.error(err);
        await setLoaderRun(false);
        setErrorText({
          err, target: loginInputs, index: 1, all: true
        });
      }
    };

    const register = async () => {
      const validateBody = validateRegistration(registrationInputs);
      if (registrationTeacher.value) acceptLicense.value = true;
      if (!validateBody || !acceptLicense.value) return;
      validateBody.password = generator.generate({ length: 8, numbers: true });
      validateBody.role = selectedRole.value;
      validateBody.login = validateBody.email;
      try {
        await setLoaderRun(true);
        const language = languageLetters.value ? 'en' : 'ru';
        const minimal = await AuthApi.registration(validateBody, language);
        VueCookieNext.setCookie('token', minimal.data.token.accessToken);
        const { data } = await UsersApi.getFullUser(minimal.data);
        await setActiveUser(data);
        goToProfile(data);
        await setLoaderRun(false);
      } catch (err) {
        console.error(err);
        await setLoaderRun(false);
        setErrorText({
          err, target: registrationInputs, index: 0, all: true
        });
      }
    };

    return {
      submitText,
      isRegistration,
      registrationStudent,
      login,
      register,
      remember,
      acceptLicense,
      selectedRole,
      languageLetters
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-login {
  @include global-font;
  display: flex;
  width: 487px;
  padding: 60px;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  background: $color-white;

  &-delimiter {
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    margin-bottom: 18px;
    color: $color-silver-chalice;
  }

  &__enter {
    min-width: 100%;
  }

  &__footer {
    margin-top: 36px;
  }
}
</style>
