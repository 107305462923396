<template>
  <div class="lp-custom-input">
    <div class="lp-custom-input-header">
      <label
        class="lp-label"
        :class="{'lp-label_required': required}"
        :for="label"
      >
        {{ $t(label) }}
      </label>
      <div
        class="lp-custom-input-header__link"
        @click.stop="$emit('help')"
        v-if="help"
      >
        {{ $t(help) }}
      </div>
      <slot name="header"></slot>
    </div>
    <div class="lp-custom-input-filed">
      <input
        class="lp-input"
        :class="{'lp-input_error': error}"
        v-focus="autofocus"
        :autocomplete="autocomplete || 'on'"
        @keyup.enter="$emit('submit')"
        :key="label"
        :id="label"
        :placeholder="placeholder ? $t(placeholder): ''"
        :value="modelValue"
        :maxlength="maxlength"
        @focus="$emit('focus')"
        @input="inputValue"
        :type="!type || displayPassword ? 'text' : type"
      >
      <span
        class="lp-custom-input-filed__show-pass"
        v-if="type === 'password' && canShowPass"
        @click.stop="displayPassword = !displayPassword"
      >
        <showPassIcon v-if="!displayPassword" />
        <hidePassIcon v-else />
      </span>
      <transition
        name="bounce"
        mode="out-in"
      >
        <span
          class="lp-error"
          v-if="error && typeof error === 'string'"
        >
          <dangerIcon />
          {{ $t(error) }}
        </span>
        <span
          class="lp-custom-input-filed__info"
          v-else-if="info"
        >
          <infoIcon />
          {{ $t(info) }}
        </span>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { upperFirst, trimStart } from 'lodash';
import { actionTermValid, numberCardValid } from '@/constants/validatesForm/validateCard';
import {
  dangerIcon, showPassIcon, hidePassIcon, infoIcon
} from '@/constants/icons';

export default {
  name: 'CustomInput',
  components: {
    dangerIcon,
    infoIcon,
    showPassIcon,
    hidePassIcon
  },
  props: {
    modelValue: [Number, String],
    type: String,
    label: String,
    help: String,
    info: String,
    autofocus: Boolean,
    error: [Boolean, String],
    required: Boolean,
    upperFirst: Boolean,
    format: String,
    autocomplete: String,
    canShowPass: Boolean,
    placeholder: String,
    card: String,
    maxlength: Number
  },
  setup (props, { emit }) {
    const displayPassword = ref(false);

    const inputValue = ({ target }) => {
      if (props.card === 'number-card') target.value = numberCardValid(target.value);
      if (props.card === 'action-tern') target.value = actionTermValid(target.value);
      target.value = trimStart(target.value);
      if (props.upperFirst) target.value = upperFirst(target.value);
      emit('update:modelValue', target.value);
    };

    return {
      inputValue,
      displayPassword
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-custom-input {
  @include global-font;
  display: grid;
  grid-template-rows: 15px 42px;
  grid-gap: 4px;
  align-items: start;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__link {
      font-weight: 500;
      font-size: 12px;
      line-height: 125%;
      text-align: right;
      color: $color-accent;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: $color-accent-hover;
      }
    }
  }

  &-filed {
    position: relative;
    min-height: 42px;

    &__show-pass {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 18px;
      height: 18px;
      border-radius: 4px;
      padding: 11px 12px;
      box-sizing: content-box;
      cursor: pointer;
      transition: 0.2s ease-in;

      &:hover {
        opacity: 0.7;
      }
    }

    &__info {
      @include global-font;
      position: absolute;
      top: 44px;
      left: 0;
      background-color: $color-white;
      display: inline-grid;
      grid-template-columns: auto auto;
      grid-gap: 4px;
      align-items: start;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: $color-gray;

      svg {
        fill: $color-alto;
      }
    }
  }
}

</style>
