<template>
  <h2 class="lp-login-title">
    {{ $t(title) }}
  </h2>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'LoginHeader',
  props: {
    isRegistration: Boolean
  },
  setup (props) {
    const loginTitle = 'login.title';
    const registrationTitle = 'registration.title';
    const title = computed(() => (props.isRegistration ? registrationTitle : loginTitle));

    return { title };
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/style";

.lp-login-title {
  font-weight: bold;
  font-size: 25px;
  line-height: 125%;
  text-align: center;
  color: $color-black;
  margin: 0 -40px 34px;
}

</style>
