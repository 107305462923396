<template>
  <div class="lp-timetable">
    <div
      class="lp-timetable-header"
      :class="{'lp-timetable-header_sticky': sticky}"
    >
      <div class="lp-timetable-header__title">
        {{ title }}
      </div>
      <div class="lp-timetable-header__picker">
        <TimetableSelectWeek
          :date="chooseDate[0]"
          v-model:date="chooseDate[0]"
          v-model:start="chooseDate[0]"
        />
        <DatePicker
          v-bind="calendarSettings"
          v-model="calendarSettings.modelValue"
        />
      </div>
    </div>
    <div class="lp-timetable-content">
      <div class="lp-timetable-content-table">
        <div
          class="lp-timetable-content-table-column"
          :class="{
            'lp-timetable-content-table-column_today': today === weekDay.date,
            'lp-timetable-content-table-column_first': index === 0,
            'lp-timetable-content-table-column_last': index === 6
          }"
          v-for="(weekDay, index) of fullWeek"
          :key="weekDay.date"
        >
          <div
            class="lp-timetable-content-table-column__head"
            :class="{
              'lp-timetable-content-table-column__head_sticky': sticky,
              'lp-timetable-content-table-column__head_past': weekDay.isPast,
              'lp-timetable-content-table-column__head_first': index === 0,
              'lp-timetable-content-table-column__head_last': index === 6
            }"
          >
            {{ weekDay.title }}
          </div>
          <div
            class="lp-timetable-content-table-column__time"
            :class="{
              'lp-timetable-content-table-column__time_past': time.past,
              'lp-timetable-content-table-column__time_today': time.today,
              'lp-timetable-content-table-column__time_lesson': time.lesson,
              'lp-timetable-content-table-column__time_selected': selectDate === time.fullDate,
              'lp-timetable-content-table-column__time_my-lesson': time.myLesson
            }"
            :id="time.fullDate"
            :ref="setDatesRef"
            @mouseenter="openTooltip = time.fullDate"
            @mouseleave="openTooltip = ''"
            v-for="(time, index) of weekDay.times"
            v-show="index >= timeRange[0] && index <= timeRange[1]"
            :key="time.title"
            @click.stop="setLesson(time.fullDate)"
          >
            {{ time.title }}
            <div
              class="lp-timetable-content-table-column__icon"
              v-if="time.myLesson"
            >
              <profileIcon />
            </div>
            <Tooltip
              v-if="time.myLesson"
              class="lp-timetable-content-table-column__tooltip"
              :text="$t('userTimetable.myLesson')"
              :open="openTooltip === time.fullDate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimetableSelectWeek from '@/components/Main/Timetable/TimetableSelectWeek';
import DatePicker from '@/components/Main/DatePicker/DatePicker';
import {
  reactive, computed, ref, watch, onBeforeMount, onMounted
} from 'vue';
import moment from 'moment';
import {
  find, fill, get, isEqual
} from 'lodash';
import i18n from '@/i18n';
import { useStore } from 'vuex';
import LessonsApi from '@/api/Lessons/api';
import { profileIcon } from '@/constants/icons';
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import ScheduleTemplatesApi from '@/api/ScheduleTemplates/api';

export default {
  name: 'Timetable',
  components: {
    Tooltip,
    DatePicker,
    TimetableSelectWeek,
    profileIcon
  },
  props: {
    teacherId: String,
    title: String,
    sticky: Boolean,
    selectDate: String,
    updatedLesson: Object,
    timeRange: {
      type: Array,
      default: () => [0, 23]
    }
  },
  setup (props, { emit }) {
    const calendarSettings = reactive({
      hideInput: true,
      hideReset: true,
      hideTime: true,
      minDate: new Date(),
      modelValue: {
        date: [moment().format('YYYY-MM-DD')]
      }
    });

    const openTooltip = ref('');

    const locale = get(i18n, 'global.locale.value', 'ru');
    const today = moment().format('YYYY-MM-DD');
    const chooseDate = computed(() => calendarSettings.modelValue.date);
    const dateRange = computed(() => {
      const start = moment(chooseDate.value[0]).format('YYYY-MM-DD');
      const end = moment(start).add(7, 'days').format('YYYY-MM-DD');
      return [start, end];
    });

    const store = useStore();
    const activeUser = computed(() => store.getters.activeUser);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const getLessons = async () => {
      if (!props.teacherId) return [];
      try {
        const query = {
          lessonDateRange: dateRange.value,
          teacher: props.teacherId
        };
        const { data } = await LessonsApi.getLessons(query);
        return data.resource.map((lesson) => {
          const date = moment(lesson.lessonStartTime).format('YYYY-MM-DD');
          const time = moment(lesson.lessonStartTime).format('HH:mm');
          lesson.date = `${date}T${time}`;
          return lesson;
        });
      } catch (err) {
        console.error(err);
        return [];
      }
    };

    const getTemplate = async () => {
      if (!props.teacherId) return [];
      try {
        const { data } = await ScheduleTemplatesApi.getSchedule(props.teacherId);
        return data.availableTime.map((date) => {
          date.endDate = moment(date.endDate).format();
          date.startDate = moment(date.startDate).format();
          date.time = moment(date.startDate).format('HH:mm');
          return date;
        });
      } catch (err) {
        console.error(err);
        return [];
      }
    };

    const teacherId = computed(() => props.teacherId);

    const lessons = ref([]);
    const schedule = ref([]);

    const datesRef = [];
    const setDatesRef = (el) => {
      if (el) datesRef.push(el);
    };

    const getSchedule = async () => {
      await setLoaderRun(true);
      datesRef.splice(0, datesRef.length);
      lessons.value = await getLessons();
      schedule.value = await getTemplate();
      await setLoaderRun(false);
    };

    watch(() => props.updatedLesson, async () => {
      lessons.value = await getLessons();
    });
    watch(teacherId, getSchedule);
    watch(dateRange, (newVal, oldVal) => {
      if (isEqual(newVal, oldVal)) return;
      getSchedule();
    });
    onBeforeMount(getSchedule);

    const selectLesson = computed(() => find(lessons.value, { date: props.selectDate }));
    watch(selectLesson, () => emit('update:lesson', selectLesson.value));

    onMounted(() => {
      const el = find(datesRef, { id: props.selectDate });
      if (el) el.scrollIntoView();
    });

    const isFreeDate = (date) => {
      const momentDate = moment(date);
      const dayOfWeek = momentDate.day();
      const time = momentDate.format('HH:mm');
      const lesson = find(lessons.value, { date });
      const scheduleNotRepeatable = find(schedule.value, ({ startDate, repeatable }) => {
        const dateSchedule = moment(startDate);
        const daySchedule = dateSchedule.day();
        const timeSchedule = dateSchedule.format('HH:mm');
        return daySchedule === dayOfWeek && timeSchedule === time && !repeatable;
      });
      if (scheduleNotRepeatable) {
        return momentDate.format() === moment(scheduleNotRepeatable.startDate).format() && !lesson;
      }
      const freeTime = find(schedule.value, { dayOfWeek, time, repeatable: true });
      return freeTime && !lesson;
    };

    const isMyLesson = (date) => {
      const lesson = find(lessons.value, { date });
      const studentId = get(lesson, 'assignedStudent._id', '');
      return studentId === activeUser.value._id;
    };

    const isPastDate = (date) => moment(date).isBefore(moment());

    const setLesson = (date) => {
      if (isMyLesson(date) || !isFreeDate(date) || isPastDate(date)) return;
      emit('update:selected', date);
    };

    const fullWeek = computed(() => {
      const [startDate] = chooseDate.value;
      const array = fill(new Array(7), startDate);
      return array.map((d, i) => {
        const date = moment(d).add(i, 'day');
        const dateFormat = date.format('YYYY-MM-DD');
        const times = fill(new Array(24), '2020-01-01T06:00');
        const timesWithLessons = times.map((el, i) => {
          const time = moment(el).add(i, 'hour').format('HH:mm');
          const fullDate = `${dateFormat}T${time}`;
          const past = isPastDate(fullDate);
          const free = past ? false : isFreeDate(fullDate);
          return {
            title: time,
            fullDate,
            past,
            today: dateFormat === today.value,
            lesson: free,
            myLesson: isMyLesson(fullDate)
          };
        });
        return {
          title: date.locale(locale).format('DD dd'),
          date: dateFormat,
          times: timesWithLessons,
          isPast: moment().subtract('day', 1).isAfter(date)
        };
      });
    });

    return {
      openTooltip,
      activeUser,
      today,
      calendarSettings,
      chooseDate,
      fullWeek,
      isFreeDate,
      isMyLesson,
      setLesson,
      setDatesRef
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-timetable {
  @include global-font;

  &-header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin-top: -37px;
    padding-top: 37px;
    padding-bottom: 14px;
    background-color: $color-white;
    z-index: 1;

    &_sticky {
      position: sticky;
      top: 0;
    }

    &__title {
      font-weight: 800;
      font-size: 16px;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: $color-text;
      cursor: default;
    }

    &__picker {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: auto 36px;
    }
  }

  &-content {
    height: calc(100% + 500px);
    min-height: 100px;

    &-table {
      display: grid;
      grid-template-columns: repeat(7, minmax(120px, 1fr));
      grid-gap: 0 8px;
      border-radius: 4px;
      width: 100%;
      height: 100%;

      &-column {
        text-align: center;
        background-color: $color-white;
        transition: 0.3s ease-in-out;

        &__head {
          position: relative;
          padding: 8px 0;
          background: $color-moon-raker;
          font-weight: bold;
          font-size: 14px;
          line-height: 125%;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          color: $color-accent;
          z-index: 1;
          margin-bottom: 7px;

          &_sticky {
            position: sticky;
            top: 87px;
          }

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: calc(100% + 8px);
            height: 100%;
            border-top: 1px solid $color-alto;
            border-bottom: 1px solid $color-alto;
            background: $color-moon-raker;
            z-index: -1;
          }

          &_past {
            background: $color-alabaster;
            color: $color-gray;

            &:after {
              background: $color-alabaster;
            }
          }

          &_first {
            &:after {
              border-left: 1px solid $color-alto;
              border-radius: 4px 0 0 0;
              width: 100%;
            }
          }

          &_last {
            &:after {
              border-right: 1px solid $color-alto;
              border-radius: 0 4px 0 0;
            }
          }
        }

        &__icon {
          margin-left: 2px;
          margin-right: -20px;
          width: 18px;
          height: 18px;
        }

        &__tooltip {
          top: 100%;
          left: auto;
          white-space: nowrap;
        }

        &__time {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 27px;
          border-radius: 4px;
          border: 1px solid $color-alto;
          font-weight: bold;
          font-size: 13px;
          line-height: 125%;
          color: $color-gray;
          transition: 0.3s ease-in-out;
          margin: 0 auto 3px;
          cursor: not-allowed;
          user-select: none;

          &_today {
            background-color: $color-moon-raker;
          }

          &_lesson {
            font-weight: 700;
            background-color: $color-emerald;
            color: $color-white;
            border-color: $color-emerald;
            pointer-events: auto;
            cursor: pointer;

            &:hover {
              border-color: $color-chateau-green;
              background-color: $color-chateau-green;
            }
          }

          &_selected {
            background-color: $color-accent;
            color: $color-white;
            border-color: $color-accent;
            cursor: pointer;

            &:hover {
              background-color: $color-accent;
              border-color: $color-accent;
            }
          }

          &_my-lesson {
            color: $color-accent;
            background-color: $color-white;
            padding-left: 20px;
            padding-right: 20px;
            pointer-events: auto;
            border-color: $color-accent;
            cursor: pointer;

            &:hover {
              background-color: $color-white;
              border-color: $color-accent;
              color: $color-accent;
            }
          }

          &_past {
            color: $color-gray;
            background-color: $color-wild-sand;
          }
        }
      }
    }
  }
}

</style>

<style lang="scss">

.lp-timetable-content-table {
  .ps__thumb-y {
    right: -10px;
  }
}

</style>
