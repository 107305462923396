<template>
  <div
    class="lp-table-column-item"
    ref="columnItem"
    @mouseleave="setShowDropBlock(false)"
    @mouseenter="setShowDropBlock(true)"
  >
    <div class="lp-table-column-item__content">
      <div
        v-for="item in text"
        :key="item.id"
      >
        {{ item.value }}
      </div>
    </div>
  </div>
  <teleport to="body">
    <transition name="bounce">
      <div
        ref="popup"
        class="lp-table-popup-block"
        v-if="showDropBlock"
        :style="getStylePopup()"
      >
        <div
          class="lp-table-popup-block-item"
          v-for="item in dropList"
          :key="item.id"
        >
          <div class="lp-table-popup-block-item__title">
            {{ $t(item.title) }}{{ item.prefixValue ? `, ${item.prefixValue}` : '' }}
          </div>
          <div class="lp-table-popup-block-item__value">
            {{ item.value }}
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { computed, ref } from 'vue';
import { get } from 'lodash';
import numeral from 'numeral';

export default {
  name: 'MixinCell',
  props: {
    column: Object,
    row: Object,
    index: Number,
    heightRow: Number
  },
  setup (props) {
    const text = computed(() => {
      const { mixinProps = [] } = props.column;
      const arr = [];
      mixinProps.forEach(({ prop, prefix, format }) => {
        if (typeof prop === 'string') {
          const value = get(props.row, prop, 0);
          const formatted = numeral(value).format(format || '0');
          const prefixValue = get(props.row, prefix);
          arr.push({
            id: Date.now(),
            value: prefixValue ? `${formatted} ${prefixValue}` : formatted
          });
        } else {
          const valueArr = [];
          prop.forEach((item) => {
            valueArr.push(get(props.row, item, 0));
          });
          const value = valueArr.join('/');
          arr.push({
            id: Date.now(),
            value: value
          });
        }
      });
      return arr;
    });

    const dropList = computed(() => {
      const arr = [];
      const { mixinDropProps = [] } = props.column;
      mixinDropProps.forEach(({ title, prop, prefix, format }) => {
        const value = get(props.row, prop, 0);
        const formatted = numeral(value).format(format || '0');
        const prefixValue = get(props.row, prefix);
        arr.push({
          id: Date.now(),
          title,
          value: formatted,
          prefixValue
        });
      });
      return arr;
    });

    const columnItem = ref(null);
    const popup = ref(null);
    const showDropBlock = ref(false);
    const setShowDropBlock = (value) => {
      showDropBlock.value = value;
    };
    const getStylePopup = () => {
      const parent = columnItem.value;
      if (!parent || !popup.value) return {};
      const { offsetHeight, offsetWidth } = popup.value;
      const { innerWidth, innerHeight } = window;
      const position = parent.getBoundingClientRect();
      const { left, top } = position;
      const style = {
        position: 'fixed',
        top: `${top + 35}px`,
        left: `${left - 20}px`
      };
      const comesLeft = innerWidth < (left + offsetWidth);
      const comesTop = innerHeight < (top + offsetHeight);
      if (comesLeft) style.left = `${left - 17 - (offsetWidth / 2)}px`;
      if (comesTop) {
        style.top = `${top - offsetHeight - 20}px`;
      }
      return style;
    };

    return {
      text,
      dropList,
      setShowDropBlock,
      showDropBlock,
      columnItem,
      popup,
      getStylePopup
    };
  }
};
</script>

<style scoped lang="scss">
@import '../../../../sass/style';

.lp-table-column-item {
  &__content {
    display: flex;

    div + div {
      &:before {
        content: '|';
        margin-left: 5px;
        margin-right: 5px;
      }
    }

  }
}

.lp-table-popup-block {
  @include global-font;
  position: absolute;
  padding: 18px 25px;
  box-shadow: 0 0 17px rgba($color-black, 0.08);
  background: $color-white;
}

.lp-table-popup-block-item {
  display: grid;
  grid-template-columns: minmax(120px,53fr) minmax(90px,20fr);
  grid-gap: 30px;
  padding: 18px 0;
  font-size: 13px;
  line-height: 1;
  font-weight: bold;

  & + & {
    border-top: 1px solid $color-alto;
  }

  &__title {
    text-transform: uppercase;
    color: $color-purple;
    white-space: nowrap;
    letter-spacing: 0.07em;
  }

  &__value {
    color: $color-mine-shaft;
    text-align: center;
  }

}

</style>
<!-- Created by zas on 22.06.2021  -->
