<template>
  <div
    v-if="isCorrectTime"
    class="lp-time"
  >
    <span class="lp-time__value">
      {{ hoursVal ? `${hoursVal}:` : '' }}{{ minutesVal }}:{{ secondsVal }}
    </span>
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed } from 'vue';

export default {
  name: 'TimeCell',
  props: {
    column: Object,
    row: Object
  },
  setup (props) {
    const time = computed(() => get(props.row, props.column.prop, ''));
    const timeVal = computed(() => new Date(time.value));
    const minutes = computed(() => timeVal.value.getUTCMinutes());
    const seconds = computed(() => timeVal.value.getUTCSeconds());
    const hoursTime = computed(() => timeVal.value - (minutes.value * 60 * 1000) - (seconds.value * 1000));
    const hours = computed(() => Math.trunc((hoursTime.value / 1000) / 3600));
    const hoursVal = computed(() => (hours.value && hours.value < 10 ? `0${hours.value}` : hours.value));
    const minutesVal = computed(() => (minutes.value && minutes.value < 10 ? `0${minutes.value}` : minutes.value));
    const secondsVal = computed(() => (seconds.value && seconds.value < 10 ? `0${seconds.value}` : seconds.value));
    const isCorrectTime = computed(() => !Number.isNaN(minutesVal.value) && !Number.isNaN(secondsVal.value));

    return {
      hoursVal,
      minutesVal,
      secondsVal,
      isCorrectTime
    };
  }
};
</script>
