<template>
  <div class="admin-header">
    <h2 class="admin-header__title">
      {{ $t(headerPageOptions.title) }}
    </h2>
    <div class="admin-header__users">
      {{ $t('teachers.users') }}
      {{ activeUsers }} / {{ activeCallUsers }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminPageHeader',
  props: {
    headerPageOptions: {
      type: Object,
      default: () => ({})
    },
    activeUsers: {
      type: Number,
      default: 0
    },
    activeCallUsers: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/style";

.admin-header {
  display: flex;
  min-height: 40px;
  margin-bottom: 14px;
  align-items: center;

  &__title {
    font-size: 22px;
    line-height: 27px;
    font-family: $global-font;
    color: $color-mine-shaft-dark;
    user-select: none;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  &__users {
    font-size: 16px;
    line-height: 20px;
    font-family: $global-font;
    color: $color-accent;
    background-color: $color-moon-raker;
    padding: 4px 12px;
    margin-left: 10px;
    font-weight: 700;
    border-radius: 4px;
  }
}
</style>
