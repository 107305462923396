<template>
  <div class="lp-profile-settings">
    <section class="lp-profile-settings-contacts">
      <div class="lp-profile-settings-title">
        <h3 class="lp-section-title">
          {{ $t('profile.sections.contacts') }}
        </h3>
        <button
          class="lp-profile-settings-title__edit"
          v-if="false"
          @click.stop="$emit('update:openContacts', true)"
        >
          {{ $t('buttons.edit') }}
        </button>
      </div>
      <div class="lp-profile-settings-list">
        <div
          class="lp-profile-settings-list-item"
          v-if="user.phone"
        >
          <div class="lp-profile-settings-list-item__title">
            {{ $t('global.phone') }}:
          </div>
          <div class="lp-profile-settings-list-item__value">
            {{ user.phone }}
          </div>
        </div>
        <div class="lp-profile-settings-list-item">
          <div class="lp-profile-settings-list-item__title">
            {{ $t('global.email') }}:
          </div>
          <div class="lp-profile-settings-list-item__value">
            {{ user.email }}
          </div>
        </div>
      </div>
    </section>
    <section class="lp-profile-settings-password">
      <div class="lp-profile-settings-title">
        <h3 class="lp-section-title">
          {{ $t('profile.sections.password') }}
        </h3>
        <button
          class="lp-profile-settings-title__edit"
          @click.stop="$emit('update:openPassword', true)"
        >
          {{ $t('buttons.edit') }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ProfileSettings',
  props: {
    user: Object
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-profile-settings {
  @include global-font;
  position: relative;
  background-color: $color-white;
  box-shadow: 0 0 15px rgba($color-black, 0.02);
  padding: 40px 31px 20px;

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    &__edit {
      @include global-font;
      font-weight: bold;
      font-size: 14px;
      line-height: 125%;
      color: $color-dove-gray;
      cursor: pointer;
      transition: 0.3s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &-list {
    display: grid;

    &-item {
      display: grid;
      align-items: center;
      grid-gap: 6px;
      grid-template-columns: auto 1fr;
      color: $color-text;
      padding-bottom: 7px;

      &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.44px;
      }

      &__value {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
      }
    }
  }

  &-contacts {
    position: relative;
    padding-bottom: 34px;
    margin-bottom: 40px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -32px;
      width: calc(100% + 64px);
      height: 1px;
      background-color: $color-alto;;
    }
  }
}

</style>
