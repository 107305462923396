import teacherImg from '@/assets/images/landing/teachers/teacher6.jpg';
import achievement1 from '@/assets/images/landing/achievements/teacher6/achievement1.jpg';
import achievement2 from '@/assets/images/landing/achievements/teacher6/achievement2.jpg';
import achievement3 from '@/assets/images/landing/achievements/teacher6/achievement3.jpeg';

export default {
  image: teacherImg,
  name: 'Тамара Михайлова',
  about: `В своей работе не придерживаюсь одного подхода или направления, так как считаю что все люди разные, у каждого свои особенности и природные данные и то, что подойдет одному, не всегда подойдет другому. Поэтому, владея знаниями со многих методик, можно более грамотно и основательно объяснить определенные приемы и навыки с разных ракурсов. 
          
          Со мной легко, просто и непринужденно! Атмосфера на занятиях максимально комфортная и доверительная, люблю пошутить, и приветствую это со стороны учащихся, так как это помогает разрядить обстановку и раскрепоститься!
          
          Легко нахожу общий язык и подход как с детьми, так и со взрослыми. 
          Постоянно прохожу марафоны и повышаю квалификацию, для максимального погружения в вопросы развития голоса и внедрения новых знаний в свою педагогическую практику.
          Я довольно гибкий человек в вопросах подбора репертуара, всегда приветствую ваше желание петь то, что вам нравится.
           
          Искренне верю в то, что научиться петь может каждый (даже с заблуждениями о том, что им "медведь на ухо наступил") было бы желание, время и усердие
          `,
  works: [
    {
      date: '2012 - по настоящее время',
      position: 'Народному и Эстрадному вокалу',
      company: 'школа искусств г. Кингисепп',
    },
    {
      date: '2020 - по настоящее время',
      position: 'частная практика',
    },
  ],
  achievements: [achievement1, achievement2, achievement3],
  workText: `С 2012 года и по настоящее время работаю педагогом по Народному и Эстрадному вокалу в школе искусств 
  г. Кингисепп
С 2020 года так же работаю он-лайн. 
В качестве певицы, периодически выступаю на различных мероприятиях города и района.`,
  hideNext: true,
};
