import student1 from '@/assets/images/landing/students/student1.jpg';
import student2 from '@/assets/images/landing/students/student2.jpg';
import student3 from '@/assets/images/landing/students/student3.jpg';
import student4 from '@/assets/images/landing/students/student4.jpg';
import student5 from '@/assets/images/landing/students/student5.jpg';

export default [
  {
    img: student1,
    comment: `В юности я мечтала научится петь, но в свои силы не верила, однако мечта осталась. 
    Оказалось, что нет ничего бездарного и голос подобно инструменту сможет заиграть. Я очень рада, что нашла Musio!`,
    name: 'Елизавета Дубнина',
    prefix: 'feedback1'
  },
  {
    img: student2,
    comment: `Записала свою дочь (5 лет) на занятия вокалом.  Дочке нравится, не скучно. 
    Ждет следующее занятие с нетерпением. А я радуюсь, что можно заниматься из дома, не тратить время на поездки.`,
    name: 'Юлия Киминова',
    prefix: 'feedback2'
  },
  {
    img: student3,
    comment: `Потрясающее место для тех, кто хочет развить свои музыкальные навыки! 
    Отдельное спасибо моему преподавателю за методы обучения, благодаря которым начинаешь верить в себя!`,
    name: 'Константин Пономаренко',
    prefix: 'feedback3'
  },
  {
    img: student4,
    comment: `Здорово, что есть возможность заниматься вокалом онлайн. 
    Для жительницы маленького города это уникальная возможность. Спасибо, что помогли поверить в себя!`,
    name: 'Наталья Нестеренко',
    prefix: 'feedback4'
  },
  {
    img: student5,
    comment: `Наконец-то я нашел то место, где можно прокачать свои навыки 
    и совершенствоваться в кругу крутых преподавателей! Я узнал новые приемы и теперь понимаю, как мне развиваться.`,
    name: 'Кирилл Вокалюк',
    prefix: 'feedback5'
  }
];
