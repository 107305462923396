<template>
  <div
    class="lp-calendar-lesson-info"
    :class="{'lp-calendar-lesson-info_delete': isDelete}"
  >
    <div
      class="lp-calendar-lesson-info-header"
      :class="{'lp-calendar-lesson-info-header_short': isStudent || student._id || isDelete}"
    >
      <div
        class="lp-calendar-lesson-info-header__button lp-edit-button"
        v-if="!isStudent && !student._id && !isDelete"
      >
        <editIcon @click.stop="updateLesson" />
      </div>
      <div
        class="lp-calendar-lesson-info-header__button lp-edit-button"
        v-if="!isStudent && !student._id && !isDelete"
      >
        <removeIcon @click.stop="isDelete = true" />
      </div>
      <div
        class="lp-calendar-lesson-info-header__button lp-cross-button"
        @click.stop="$emit('update:open', false)"
      >
        <crossIcon />
      </div>
    </div>
    <div
      class="lp-calendar-lesson-info-delete"
      v-if="isDelete"
    >
      <div class="lp-calendar-lesson-info-delete__text">
        {{ $t('lessonInfo.deleteQuest') }}
      </div>
      <div class="lp-calendar-lesson-info-delete__buttons">
        <button
          class="lp-button lp-button_small lp-button_thin"
          @click.stop="isDelete = false"
        >
          {{ $t('buttons.cancel') }}
        </button>
        <button
          class="lp-button lp-button_small"
          @click="removeLesson"
        >
          {{ $t('buttons.remove') }}
        </button>
      </div>
    </div>
    <template v-else>
      <div class="lp-calendar-lesson-info-item">
        <div class="lp-calendar-lesson-info-item__title">
          {{ $t('lessonInfo.time') }}
        </div>
        {{ time }}
      </div>
      <div
        class="lp-calendar-lesson-info-item"
        v-if="student._id"
      >
        <div class="lp-calendar-lesson-info-item__title">
          {{ $t(isStudent ? 'lessonInfo.teacher' : 'lessonInfo.student') }}
        </div>
        <router-link
          class="lp-calendar-lesson-info-user"
          target="_blank"
          :to="goTeacherProfile()"
        >
          <img
            v-if="student.photo"
            alt="photo"
            class="lp-calendar-lesson-info-user__avatar"
            :src="student.photo"
          />
          <userIcon
            v-else
            class="lp-calendar-lesson-info-user__avatar"
          />
          <div class="lp-calendar-lesson-info-user__name">
            {{ student.firstName }} {{ student.lastName }}
          </div>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import {
  editIcon, crossIcon, removeIcon, userIcon
} from '@/constants/icons';
import { computed, ref } from 'vue';
import LessonsApi from '@/api/Lessons/api';
import { useStore } from 'vuex';

export default {
  name: 'CalendarLessonInfo',
  components: {
    editIcon,
    crossIcon,
    removeIcon,
    userIcon
  },
  props: {
    isStudent: Boolean,
    student: Object,
    subject: String,
    time: String,
    lessonId: String
  },
  setup (props, { emit }) {
    const isDelete = ref(false);

    const store = useStore();
    const lessons = computed(() => store.getters.lessonsList);
    const setLessons = (data) => store.dispatch('setLessonsList', data);
    const setEditLesson = (data) => store.dispatch('setEditForm', data);

    const updateLesson = () => {
      setEditLesson(props.lessonId);
    };
    const removeLesson = () => {
      if (!props.lessonId) return;
      LessonsApi.deleteLesson(props.lessonId).
        then(() => {
          const data = lessons.value.filter((item) => item._id !== props.lessonId);
          setLessons(data);
          emit('update:open', false);
        }).
        catch((err) => {
          console.error(err);
        });
    };

    const goTeacherProfile = () => {
      const id = props.student._id;
      if (!id) return '';
      const name = props.isStudent ? 'ProfileTeacher' : 'ProfileStudent';
      return { name, params: { id } };
    };

    return {
      isDelete,
      updateLesson,
      removeLesson,
      goTeacherProfile
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/style";

.lp-calendar-lesson-info {
  @include global-font;
  position: relative;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  border-radius: 4px;
  width: 295px;
  height: 205px;
  padding: 0 20px 24px;

  &_delete {
    height: auto;
    padding-bottom: 18px;
  }

  &-header {
    display: grid;
    grid-template-columns: 28px 28px 28px;
    justify-content: end;
    grid-gap: 8px;
    align-items: center;
    padding: 13px 0 0 0;
    margin-right: -8px;

    &_short {
      grid-template-columns: 28px;
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }

    &__button {
      justify-self: end;
      width: 28px;
      height: 28px;
    }
  }

  &-item {
    display: grid;
    grid-gap: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: $color-text;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      font-weight: bold;
      font-size: 12px;
    }
  }

  &-user {
    display: grid;
    grid-template-columns: 32px auto;
    align-items: center;
    grid-gap: 10px;
    margin-top: 4px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }

    &__avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &-delete {
    display: grid;
    grid-gap: 40px;

    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      color: $color-text;
    }

    &__buttons {
      display: grid;
      grid-gap: 8px;
      grid-template-columns: repeat(2, 114px);
      justify-content: end;
    }
  }
}

</style>
