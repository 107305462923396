import CountryApi from '@/api/Country/api';

export default async () => {
  try {
    const { data } = await CountryApi.getCountry();
    return data.country_code;
  } catch (e) {
    console.error(e);
    return 'RU';
  }
};
